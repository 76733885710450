import { Row, Col, Space, Button, Tooltip } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import { useState, useEffect } from 'react'
import { applyVideoColors, countColorUsage } from './helpers'

const ChangeAll = ({ color, visible, video, onVideoColorsChange, changesCounter, setChangesCounter }) => {
  const [previousColor, setPreviousColor] = useState()
  const [canBeChanged, setCanBeChanged] = useState(false)

  useEffect(() => {
    setPreviousColor(color)
    setCanBeChanged(false)
  }, [visible])

  useEffect(() => {
    if (!previousColor) setPreviousColor(color)
  }, [color])

  useEffect(() => {
    setCanBeChanged(countColorUsage(video, previousColor) > 1)
  }, [previousColor, changesCounter])

  const changeColor = async () => {
    const initialPreviousColor = previousColor
    applyVideoColors(
      video,
      () => color,
      (c) => c === previousColor,
    )
    setPreviousColor(color)
    setChangesCounter((c) => c + 1)
    onVideoColorsChange(() => {
      setPreviousColor(initialPreviousColor)
      setCanBeChanged(true)
    })
  }

  if (previousColor === color || !canBeChanged) return null

  return (
    <Row style={{ marginTop: 28 }} align="middle">
      <Col span={24}>
        <Space align="center">
          <Tooltip title="Change all elements on all slides that has the same color">
            <Button type="primary" onClick={changeColor}>
              Change all
            </Button>
          </Tooltip>
          <button
            type="button"
            key={previousColor}
            className="color-item inactive preview"
            style={{ marginLeft: '16px' }}
          >
            <div className="color-value" style={{ backgroundColor: previousColor }}></div>
          </button>
          <ArrowRightOutlined />
          <button type="button" key={color} className="color-item inactive preview">
            <div className="color-value" style={{ backgroundColor: color }}></div>
          </button>
        </Space>
      </Col>
    </Row>
  )
}

export default ChangeAll
