import { useEffect, useMemo, useRef } from 'react'
import { Input, Button, Modal, Form, Select, notification } from 'antd'

import { Editor } from './editor'
import { useStore } from '../../../../store'
import { request } from '../../../../utils/api'

import { LESSONS_URL, LESSON_EDITOR_PLUGINS } from '../constants'
import { equal } from '../../../../store/helpers'

export const CreateLessonModal = (props) => {
  const { courseId, lessonForm, isModalOpen, setLessonsData, editableLesson, setIsModalOpen, setEditableLesson } = props

  const isEditMode = editableLesson && Object.keys(editableLesson).length > 0

  const editorRef = useRef(null)

  const clearModal = () => {
    setEditableLesson({})
    lessonForm.resetFields()
    setIsModalOpen(false)
  }

  const onCancelModal = () => {
    // get needed fields for equal check
    const fieldsForm = lessonForm.getFieldsValue()
    const activeLesson = {}
    activeLesson.content = editableLesson.content
    activeLesson.order = editableLesson.order
    activeLesson.templateId = editableLesson.templateId
    activeLesson.interactivityId = editableLesson.interactivityId
    activeLesson.name = editableLesson.name
    fieldsForm.content = editorRef.current.getContent()
    if (isEditMode && !equal(fieldsForm, activeLesson)) {
      return Modal.confirm({
        title: `Do you want to save your changes?`,
        onOk: onFinish,
        onCancel: clearModal,
        okText: 'Save',
        cancelText: 'Close',
      })
    } else {
      clearModal()
    }
  }

  const { publicTemplates } = useStore((stores) => stores.videosStore)

  const templateOptions = useMemo(() => {
    if (!publicTemplates.length) return []
    const templates = publicTemplates.map((template) => ({
      value: template._id,
      label: template.name,
    }))
    templates.unshift({
      value: null,
      label: 'No template',
    })
    return templates
  }, [publicTemplates])

  const onFinish = async () => {
    const data = lessonForm.getFieldsValue()
    if (editorRef.current) {
      data.content = editorRef.current.getContent()
    }
    if (isEditMode) {
      if (data.interactivityId === '') data.interactivityId = undefined
      data.courseId = editableLesson.courseId
      const result = await request({ method: 'patch', url: `${LESSONS_URL}/${editableLesson._id}`, data })
      if (result) {
        setLessonsData((prevState) => {
          const index = prevState.lessons.findIndex((lesson) => lesson._id === result._id)
          const updatedLessons = [...prevState.lessons] // Create a copy of the lessons array
          updatedLessons[index] = result
          return { ...prevState, lessons: updatedLessons } // Update the state with the new lessons array
        })
        // show notification and close on done
        notification.success({ message: 'Lesson updated successfully', duration: 4 })
        clearModal()
      }
    } else {
      const result = await request({ method: 'post', url: `${LESSONS_URL}/${courseId}`, data })
      if (result) {
        setLessonsData((prevState) => ({ ...prevState, lessons: [...prevState.lessons, result] }))
        notification.success({ message: 'Lesson created successfully', duration: 4 })
        clearModal()
      }
    }
  }

  const onTemplateSelect = (value) => {
    lessonForm.setFieldValue('templateId', value)
  }

  useEffect(() => {
    if (isEditMode) {
      lessonForm.setFieldsValue({
        ...editableLesson,
      })
    }
  }, [isEditMode, editableLesson])

  return (
    <Modal
      title={`${isEditMode ? 'Edit' : 'Create'} lesson`}
      open={isModalOpen}
      width={500}
      footer={null}
      centered
      destroyOnClose
      onCancel={onCancelModal}
    >
      <Form form={lessonForm} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="name"
          label="Lesson name"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: 'Lesson name is required',
            },
          ]}
        >
          <Input placeholder="Lesson name" />
        </Form.Item>
        <Form.Item
          name="order"
          label="Order"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: 'Order is required',
            },
          ]}
        >
          <Input placeholder="Order" type="number" />
        </Form.Item>
        <div className="lesson-admin-editor">
          <label>Content</label>
          <Editor
            editorRef={editorRef}
            value={isEditMode ? editableLesson.content : ''}
            plugins={LESSON_EDITOR_PLUGINS}
          />
        </div>
        <Form.Item name="interactivityId" label="Interactivity video">
          <Input placeholder="Interactivity Id" />
        </Form.Item>
        <Form.Item name="templateId" label="Template" validateTrigger="onBlur">
          <Select
            showSearch
            optionFilterProp="children"
            placeholder="Select template"
            onChange={onTemplateSelect}
            options={templateOptions}
          />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>
    </Modal>
  )
}
