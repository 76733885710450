import React, { useEffect } from 'react'
import axios from 'axios'
import { Spin } from 'antd'
import { useLocation } from 'react-router'
import { useStore } from '../../store'
import { useElaiNotification } from '../../hooks/useElaiNotification'
import useAppRedirect from '../../hooks/useAppRedirect'
import { checkForExistingVideos } from '../../utils/videoCreation/videoCreation'
import configService from '../../utils/config'

const Auth = () => {
  const notification = useElaiNotification()
  const authStore = useStore((stores) => stores.authStore)
  const { search } = useLocation()
  const params = new URLSearchParams(search)

  const { handleRedirect } = useAppRedirect()

  const { apiUrl } = configService.get().urls

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        if (params.has('email')) {
          await axios.get(apiUrl + '/auth/verify' + search)
          notification.success({
            message: 'Thank you! Your email was verified.',
          })
          await authStore.refreshSession()

          // ensure it's a recently registered user, not a regular user with email change verification
          await handleRedirect({ isJustRegistered: !(await checkForExistingVideos()) })
        }
      } catch (error) {
        const message = error.response?.data?.message || error.message
        notification.error({ message, duration: false })
      }
    }
    verifyEmail()
  }, [])

  return <Spin size="large" />
}

export default Auth
