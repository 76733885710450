import { useState, useRef } from 'react'
import { Row, Col, Button, List, Tooltip, Carousel } from 'antd'
import voice_audio from '../../../../../assets/images/public_custom_voice/voice-audio.png'
import languages from '../../../../../data/voiceCloningLanguages'
import Icon from '../../../../../components/Icon'
import './voice.less'

const arrowRightIcon = <Icon name="right_arrow" />
const arrowLeftIcon = <Icon name="left_arrow" />

const flagsUrl = 'https://d3u63mhbhkevz8.cloudfront.net/flags/'

const voices = [
  {
    real: 'https://d3u63mhbhkevz8.cloudfront.net/voices/clonning/real.mp3',
    cloned: 'https://d3u63mhbhkevz8.cloudfront.net/voices/clonning/generated.mp3',
  },
  {
    real: 'https://d3u63mhbhkevz8.cloudfront.net/voices/clonning/alex-real.mp3',
    cloned: 'https://d3u63mhbhkevz8.cloudfront.net/voices/clonning/alex-generated.mp3',
  },
  {
    real: 'https://d3u63mhbhkevz8.cloudfront.net/voices/clonning/alla-real.mp3',
    cloned: 'https://d3u63mhbhkevz8.cloudfront.net/voices/clonning/alla-generated.mp3',
  },
  {
    real: 'https://d3u63mhbhkevz8.cloudfront.net/voices/clonning/daria-real.mp3',
    cloned: 'https://d3u63mhbhkevz8.cloudfront.net/voices/clonning/daria-generated.mp3',
  },
]

const Voice = ({ isAvatarsPage, canBuyVoice, setIsOpenPurchaseModal }) => {
  const carouselRef = useRef(null)
  const [currentCarouselSlide, setCurrentCarouselSlide] = useState(0)

  const afterSlideCarousel = (current) => setCurrentCarouselSlide(current)
  const goToPrevPage = () => carouselRef.current.prev()
  const goToNextPage = () => carouselRef.current.next()

  return (
    <div className="voice-container content-wrapper">
      <Row className="voice-content" gutter={[40, 30]}>
        <Col lg={8} md={24} sm={24}>
          <div className="aside">
            <p>
              Clone your own voice and use it to turn all your ideas into reality. Just type a speech text and the
              Avatar will narrate it with your voice.
            </p>
            <img src={voice_audio} alt="microphone" />
          </div>
        </Col>
        <Col lg={16} md={24} sm={24}>
          <div className="steps">
            <Row className="cards-container" align="stretch" gutter={[40, 30]}>
              <Col lg={12} md={24} xs={24}>
                <h3>Turn your narration into a digital voice</h3>
                <p>
                  To record your voice, you can use any voice-recording software. Your speech can be either emotional or
                  calm, but the intonations will be kept the same after cloning.
                </p>
              </Col>
              <Col lg={12} md={24} xs={24}>
                <h3>3-5 minutes recording</h3>
                <p>
                  Our team requires 3-5 minutes of a well-prepared voice recording of a person speaking with no extra
                  noise. The quality of your custom voice will 100% depend on the quality of the audio recording you
                  provide.
                </p>
              </Col>
              <Col span={24}>
                <h3>Multilingual. 32 languages supported</h3>
                <List
                  className="languages-list"
                  grid={{ gutter: 40, xxl: 5, xl: 5, lg: 5, md: 5, sm: 5, xs: 3 }}
                  dataSource={languages}
                  renderItem={(language) => (
                    <List.Item className="languages-list-item">
                      <img
                        src={`${flagsUrl}${language.icon}.svg`}
                        width="16"
                        className="flag-icon"
                        style={{ marginRight: 8 }}
                        alt={language.name}
                      />
                      <span>{language.name}</span>
                    </List.Item>
                  )}
                />
              </Col>
              <Col lg={24} md={24} sm={24} xs={24} className="voices-audio-col">
                <h3>Compare real and cloned voices</h3>
                <div className="headings-container">
                  <h5>Real</h5>
                  <h5>Cloned</h5>
                </div>
                <div className="carousel-container">
                  <Button
                    type="text"
                    disabled={currentCarouselSlide === 0}
                    className="btn-carousel-arrow"
                    onClick={goToPrevPage}
                  >
                    {arrowLeftIcon}
                  </Button>
                  <Carousel ref={carouselRef} dots={false} afterChange={afterSlideCarousel}>
                    {voices.map((voice, index) => (
                      <div key={index}>
                        <div className="voices-audio-container">
                          <div className="audio-wrapper">
                            <h5>Real</h5>
                            <audio controls controlsList="nodownload noplaybackrate">
                              <source src={voice.real} type="audio/mp3" />
                              Your browser does not support the element <code>audio</code>.
                            </audio>
                          </div>
                          <div className="audio-wrapper">
                            <h5>Cloned</h5>
                            <audio controls controlsList="nodownload noplaybackrate">
                              <source src={voice.cloned} type="audio/mp3" />
                              Your browser does not support the element <code>audio</code>.
                            </audio>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Carousel>
                  <Button
                    type="text"
                    disabled={currentCarouselSlide === voices.length - 1}
                    className="btn-carousel-arrow"
                    onClick={goToNextPage}
                  >
                    {arrowRightIcon}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {!isAvatarsPage && (
        <Tooltip
          title="Please contact your account owner to perform this action."
          overlayStyle={{
            visibility: canBuyVoice ? 'hidden' : 'visible',
          }}
        >
          <Button
            type="primary"
            disabled={!canBuyVoice}
            size="large"
            className="btn-buy-now"
            onClick={() => setIsOpenPurchaseModal(true)}
          >
            Buy now
          </Button>
        </Tooltip>
      )}
    </div>
  )
}

export default Voice
