import { FileUploader } from '../../../routes/video/sidebar/assets/fileUploader'

/**
 *
 * @param { onChooseFile} props
 * @returns
 */
export const UpdateLogo = (props) => {
  const { onChooseFile } = props

  return (
    <div style={{ height: '130px' }}>
      <FileUploader
        visible={true}
        isLogotype={true}
        allowMultiple={false}
        isVideoAllowed={false}
        onChooseFile={onChooseFile}
      />
    </div>
  )
}
