import { useState, useEffect } from 'react'
import { Form, Input, Button, Checkbox, Tooltip } from 'antd'
import { UserOutlined, MailOutlined, RightOutlined } from '@ant-design/icons'
import Turnstile from 'react-turnstile'
import { useStore } from '../../../../../store'
import { request } from '../../../../../utils/api'
import './form.less'
import { useElaiNotification } from '../../../../../hooks/useElaiNotification'

export default ({ title }) => {
  const notification = useElaiNotification()
  const authStore = useStore((stores) => stores.authStore)
  const [turnstileToken, setTurnstileToken] = useState()
  const [sendFormDataAttempts, setSendFormDataAttempts] = useState(0)
  const [form] = Form.useForm()

  useEffect(() => {
    if (!authStore.user) return
    const { email, name } = authStore.user
    form.setFieldsValue({ name, email })
  }, [])

  const onFinish = async (data) => {
    const res = await request({ method: 'post', url: `/public/send-email`, data: { ...data, turnstileToken } })
    if (res === false) return setSendFormDataAttempts((attempts) => attempts + 1)
    form.resetFields()
    notification.success({
      message: 'Thank you for submitting your question. Our support team will get back to you within 12 hours.',
    })
    setSendFormDataAttempts((attempts) => attempts + 1)
  }

  return (
    <div className="contact-form content-wrapper tabs-content-wrapper">
      <div className="form-container">
        <h2>Contact us for your questions regarding {title}</h2>
        <Form form={form} onFinish={onFinish} layout="vertical" requiredMark={false}>
          <Form.Item
            name="name"
            label="Name"
            validateTrigger="onBlur"
            rules={[{ required: true, min: 2, message: 'Name should be longer than 2 chars' }]}
          >
            <Input prefix={<UserOutlined />} placeholder="First and last name" size="large" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            validateTrigger="onBlur"
            rules={[{ required: true, type: 'email', message: 'Please, enter your email' }]}
          >
            <Input prefix={<MailOutlined />} placeholder="you@company.com" size="large" />
          </Form.Item>
          <Form.Item
            name="details"
            label="Details"
            validateTrigger="onBlur"
            rules={[{ required: true, message: 'Please, enter your question' }]}
          >
            <Input.TextArea rows={8} placeholder="Your comments" style={{ resize: 'none' }} />
          </Form.Item>
          <Form.Item
            name="accept"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(new Error('Please accept our Terms & Conditions and Privacy Policy')),
              },
            ]}
          >
            <Checkbox>
              I accept the{' '}
              <a href="https://elai.io/privacy-policy" target="_blank" rel="noreferrer">
                Privacy Policy
              </a>
            </Checkbox>
          </Form.Item>
          <Form.Item key={sendFormDataAttempts} noStyle>
            <Turnstile
              sitekey={process.env.REACT_APP_TURNSTILE_SITE_KEY}
              autoResetOnExpire={true}
              action="contact-form-buy-avatar"
              appearance="interaction-only"
              className="turnstile-captcha"
              onVerify={(token) => setTurnstileToken(token)}
              onError={() => setSendFormDataAttempts((attempts) => attempts + 1)}
            />
          </Form.Item>
          <Tooltip
            title="We are applying captcha. Please wait a second…"
            overlayStyle={{
              visibility: turnstileToken ? 'hidden' : 'visible',
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              loading={!turnstileToken}
              icon={<RightOutlined />}
              className="btn-login btn-arrow"
              size="large"
            >
              Send
            </Button>
          </Tooltip>
        </Form>
      </div>
    </div>
  )
}
