import { Button, Card, Col, Empty, Row, Spin, Typography } from 'antd'

import FolderItem from '../folderItem'
import Icon from '../../../components/Icon'

const addVideoIcon = <Icon name="add_video" />
const addFolderIcon = <Icon name="add_folder" />
const deleteIcon = <Icon name="delete" />

const deletedFolderDescription = <Typography.Text type="secondary">This folder has been deleted</Typography.Text>

export const VideosHeader = (props) => {
  const {
    folders,
    folderId,
    filterInfo,
    openFolder,
    activeItem,
    fetchVideos,
    fetchFolders,
    setActiveItem,
    isDeletedVideos,
    isFolderCreating,
    setIsNewVideoModalOpen,
    setIsOpenNewFolderModal,
  } = props

  const handleCreateVideo = () => setIsNewVideoModalOpen(true)
  const handleCreateFolder = () => setIsOpenNewFolderModal(true)

  const fetchFoldersData = async () => await fetchFolders(filterInfo?.condition)
  const fetchVideosData = async () => await fetchVideos(filterInfo?.condition)

  return (
    <Row
      gutter={[32, 24]}
      className="folders-row"
      style={{ marginBottom: isDeletedVideos && !folders?.length ? 0 : 40 }}
    >
      {!isDeletedVideos && !openFolder?.deleted && (
        <>
          {folderId ? (
            <>
              <Col className="create-card-col">
                <Card className="create-card">
                  <p>New video</p>
                  <Button type="primary" icon={addVideoIcon} onClick={handleCreateVideo}>
                    Create
                  </Button>
                </Card>
              </Col>
              <Col className="create-card-col">
                <Card className="create-card dashed">
                  <p>New folder</p>
                  <Button type="default" loading={isFolderCreating} icon={addFolderIcon} onClick={handleCreateFolder}>
                    Create
                  </Button>
                </Card>
              </Col>
            </>
          ) : (
            <Col className="create-card-col">
              <Card className="create-card">
                <p>New folder</p>
                <Button loading={isFolderCreating} icon={addFolderIcon} onClick={handleCreateFolder}>
                  Add folder
                </Button>
              </Card>
            </Col>
          )}
        </>
      )}
      {openFolder?.deleted && <Empty image={deleteIcon} description={deletedFolderDescription} />}
      {folders ? (
        folders.map((folder) => (
          <Col key={folder._id}>
            <FolderItem
              folder={folder}
              folderId={folderId}
              isDeletedVideos={isDeletedVideos}
              activeItem={activeItem}
              setActiveItem={setActiveItem}
              fetchFolders={fetchFoldersData}
              fetchVideos={fetchVideosData}
            />
          </Col>
        ))
      ) : (
        <Col style={{ display: 'flex', alignItems: 'center', marginBottom: isDeletedVideos ? 40 : 0 }}>
          <Spin />
        </Col>
      )}
    </Row>
  )
}
