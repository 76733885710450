import { SearchOutlined } from '@ant-design/icons'

import Icon from '../../../../components/Icon'

export const portraitIcon = <Icon name="portrait" />
export const searchOutlinedIcon = <SearchOutlined />
export const plusIcon = <Icon name="plus" className="plus-icon" />
export const arrowDownIcon = <Icon name="down_arrow" className="arrow-down-icon" />
export const USER_ICON = <Icon name="user" />
export const USER_CIRCLE_ICON = <Icon name="user_circle" />
export const MIC_ICON = <Icon name="mic" />

const style = {
  marginTop16: { marginTop: 16 },
  marginTop24: { marginTop: 24 },
  marginRight7: { marginRight: '7px' },
  margin0: { margin: 0 },
  positionRel: { position: 'relative' },
  requestAvatar: { fontWeight: 700, marginTop: 6 },
  strokeColor: {
    '0%': '#4868FF',
    '100%': '#87d068',
  },
  fontWeight700: { fontWeight: 700 },
  headerStyle: { padding: '10px', textAlign: 'right' },
  bodyStyle: { padding: '10px 0' },
  rootStyle: { zIndex: 2, outline: 'none' },
  minHeight500: { minHeight: 500 },
  flex: { flex: '1 1 auto' },
  flexCol: { flexDirection: 'column' },
  displayFlex: { display: 'flex' },
  width100: { width: '100%' },
}

export const OPTIONS = [
  {
    label: (
      <span>
        {USER_ICON}
        Transparent
      </span>
    ),
    value: 'transparent',
    title: 'Transparent',
    placement: 'bottomLeft',
  },
  {
    label: (
      <span>
        {USER_CIRCLE_ICON}
        In circle
      </span>
    ),
    value: 'circle',
    title: 'In circle',
    placement: 'bottom',
  },
  {
    label: (
      <span>
        {MIC_ICON}
        No avatar
      </span>
    ),
    value: 'voiceover',
    title: 'No avatar',
    placement: 'bottomRight',
  },
]

export const TAB_VALUES = [
  { value: 'general', label: 'Avatars library' },
  { value: 'custom', label: 'Custom avatars' },
]

export { style }
