import { Button, Typography } from 'antd'
import Icon from '../../../../../components/Icon'

const { Text } = Typography

const InteractivityEmulationControls = ({
  interactiveQuestion,
  interactiveElements,
  player,
  setPlayer,
  playNextSlide,
}) => {
  const onImitateClick = (interactivity) => {
    const { type, link, slide } = interactivity
    if (type === 'slide') playNextSlide(slide)
    else if (type === 'link') {
      setPlayer((p) => ({ ...p, status: 'idle' }))
      window.open(link, '_blank')
    }
  }

  return (
    (interactiveQuestion || !!interactiveElements?.length) &&
    player.activePreview &&
    player.status === 'idle' && (
      <div className="interactivity-buttons-container">
        {interactiveQuestion ? (
          <>
            <Button
              className="btn-emulate-interactivity"
              type="default"
              icon={<Icon className="own-colors" name="check_circle_color" />}
              onClick={() => playNextSlide(interactiveQuestion.onCorrect?.slide)}
            >
              Emulate correct answer
            </Button>
            <Button
              className="btn-emulate-interactivity"
              type="default"
              icon={<Icon className="own-colors" name="cancel_circle_color" />}
              onClick={() => playNextSlide(interactiveQuestion.onWrong?.slide)}
            >
              Emulate incorrect answer
            </Button>
          </>
        ) : (
          interactiveElements.map((element) => (
            <Button
              key={element.id}
              className="btn-emulate-interactivity"
              type="default"
              icon={<Icon name="click" />}
              onClick={() => onImitateClick(element.interactivity)}
            >
              {element.src ? (
                <img height={22} src={element.src} alt="Interactive element thumbnail" />
              ) : (
                <Text ellipsis>{element.text}</Text>
              )}
            </Button>
          ))
        )}
      </div>
    )
  )
}

export default InteractivityEmulationControls
