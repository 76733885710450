import { memo } from 'react'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Row, Col, Switch, InputNumber, Tooltip, Space } from 'antd'

import { Trim } from './components'
import LazySlider from '../../../../../components/LazySlider'
import { syncVideoState } from '../../../slide/canvas/videos'

import { formatVolume, useVideoControls } from './useVideoControls'

import { STYLE } from './constants'

export const VideoControls = (props) => {
  const { duration, canvasActiveObject, activeObjectProps, setActiveObjectModifier } = props

  const {
    handleLoop,
    onStep,
    onTrimChange,
    handleFitAudio,
    onVolumeChange,
    onTrimEndChange,
    onTrimStartChange,
    onSpeedRateChange,
  } = useVideoControls({
    duration,
    syncVideoState,
    canvasActiveObject,
    activeObjectProps,
    setActiveObjectModifier,
    volume: activeObjectProps.volume,
  })

  const VolumeSlider = memo(
    () => (
      <Row style={STYLE.marginTop14} align="middle">
        <Col span={9}>
          <Space>
            <h4>Volume</h4>
            <Tooltip title="Use this to change volume of your video (works with default speed rate only)">
              <QuestionCircleOutlined />
            </Tooltip>
          </Space>
        </Col>
        <Col span={15}>
          <LazySlider
            min={0}
            max={1}
            step={0.01}
            linkedElementKey="volume"
            onChange={onVolumeChange}
            value={activeObjectProps.volume}
            linkedElement={activeObjectProps}
            tooltip={{ formatter: formatVolume }}
            disabled={activeObjectProps.speedRate !== 1}
          />
        </Col>
      </Row>
    ),
    [formatVolume, activeObjectProps, onVolumeChange],
  )

  const canvasVideoObject = canvasActiveObject?.getElement
    ? canvasActiveObject
    : canvasActiveObject?._objects?.length === 1 && canvasActiveObject._objects[0].type === 'video'
    ? canvasActiveObject._objects[0]
    : null

  return (
    <>
      <Row style={STYLE.marginTop14} align="middle">
        <Col span={9}>
          <h4>Loop Video</h4>
        </Col>
        <Col span={15}>
          <Switch checked={activeObjectProps.loop} onChange={handleLoop} />
        </Col>
      </Row>
      {
        // active when video is not in a group selection
        canvasVideoObject && (
          <Trim
            canvasActiveObject={canvasVideoObject}
            onStep={onStep}
            onTrimChange={onTrimChange}
            onTrimStartChange={onTrimStartChange}
            onTrimEndChange={onTrimEndChange}
          />
        )
      }
      <Row style={STYLE.marginTop14} align="middle">
        <Col span={9}>
          <Space>
            <h4>Fit Audio</h4>
            <Tooltip title="Trimmed video will be automatically speeded up to a duration of generated audio. Very useful for screencasts.">
              <QuestionCircleOutlined />
            </Tooltip>
          </Space>
        </Col>
        <Col span={15}>
          <Switch checked={activeObjectProps.durationFitAudio} onChange={handleFitAudio} />
        </Col>
      </Row>
      <Row style={STYLE.marginTop14} align="middle">
        <Col span={9}>
          <Space>
            <h4>Speed Rate</h4>
            <Tooltip title="Use this to speed up or slow down your video">
              <QuestionCircleOutlined />
            </Tooltip>
          </Space>
        </Col>
        <Col span={15}>
          <InputNumber
            value={activeObjectProps.speedRate}
            min={0.3}
            max={16}
            disabled={activeObjectProps.durationFitAudio}
            onChange={onSpeedRateChange}
          />
        </Col>
      </Row>
      {activeObjectProps.hasAudio && <VolumeSlider />}
    </>
  )
}
