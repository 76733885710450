import { request } from '../../../../../../utils/api'
import { mediaStockProvidersPathMap } from './content/media-provider.config'
import { defaultIcons } from './content/icons'

const { REACT_APP_API_URL } = process.env

const supportedTypes = ['jpg', 'png', 'gif', 'svg']

const buildDownloadUrl = (object, filesType) => {
  let ext = ''
  if (object.free_svg !== undefined) {
    ext = 'svg'
  }
  const availableTypes = object.meta?.available_formats
  if (availableTypes) {
    // as of 21.11.24 Freepik sometime responses with tiny size available_formats[format].items[].size of image (12-15KB). It indicates that image potentially broken. So use most weighty image for download
    const getWithLargestSize = Object.entries(availableTypes)
      .flatMap(
        ([format, { items }]) => supportedTypes.includes(format) && items.map((item) => ({ format, size: item.size })),
      )
      .reduce((a, b) => (a.size > b.size ? a : b))
    if (getWithLargestSize?.format) ext = getWithLargestSize.format
  }
  return `${REACT_APP_API_URL}/public/freepik/cache/${filesType}/${object.id}.${ext}`
}

const normalizeContent = (data, filesType) => {
  if (filesType === 'image')
    return data
      ?.filter(({ active }) => active)
      .map((item) => ({
        type: item.image ? 'image' : 'video',
        thumbnail: item.image?.source?.url ?? item.preview?.url ?? item.thumbnails[0].url,
        url: buildDownloadUrl(item, filesType),
      }))
  if (filesType === 'icon')
    return data?.map((item) => ({
      type: 'icon',
      thumbnail: item.thumbnails[0].url,
      url: buildDownloadUrl(item, filesType),
    }))
}

const normalizeMetaData = (data) => {
  if (!data) return {}
  const { pagination } = data
  const meta = pagination ?? data
  const { current_page, last_page, total } = meta
  const nextPage = current_page < last_page ? current_page + 1 : undefined
  return {
    nextPage,
    totalPages: total,
  }
}

const fetchMedia = async ({ url, params, data, method = 'get' }) => {
  try {
    const responseData = await request({
      method,
      url,
      params,
      data,
    })
    return responseData
  } catch (error) {
    return { error: error.message }
  }
}

const normalizeData = (result, filesType) => {
  const { data, meta } = result
  return { content: normalizeContent(data, filesType), meta: normalizeMetaData(meta) }
}

const pathPrefix = (filesType) => (filesType === 'image' ? 'resources' : mediaStockProvidersPathMap[filesType])

const getPaginationLimit = (filesType, limit = 30) => {
  return { [filesType === 'icon' ? 'per_page' : 'limit']: limit }
}

export const freepikGetContent = async ({ nextPage = 1, filter = {}, filesType }) => {
  const isFiltering = !!Object.keys(filter).length
  if (filter.color) {
    filter = { ...filter, filters: { ...filter.filters, color: filter.color } }
    delete filter.color
  }
  if (filesType === 'image') filter = { 'filters[content_type][photo]': 1, ...filter }
  if (isFiltering && !filter.term?.length) filter = { ...filter, term: 'Most popular on freepik' }
  filter = { ...filter, page: nextPage, ...getPaginationLimit(filesType) }
  const data = await fetchMedia({ url: `/freepik/${pathPrefix(filesType)}`, params: filter })
  if (data.error) return data
  return normalizeData(data, filesType)
}

export const freepikGetDefaultContent = async (filesType) => {
  if (filesType === 'icon') {
    return { content: defaultIcons }
  }
  let params = { page: 1, ...getPaginationLimit(filesType) }
  if (filesType === 'image') params = { 'filters[content_type][photo]': 1, ...params }
  const result = await fetchMedia({
    url: `/freepik/${pathPrefix(filesType)}`,
    params,
  })
  if (result.error) return result
  return normalizeData(result, filesType)
}

export const freepikGetAiContent = async (filter) => {
  const url = '/freepik/text-to-image'
  const data = await fetchMedia({ url, method: 'get', params: filter })
  return data
}
