let initialPath = window.location.pathname

const goBack = (fallbackHandler) => {
  if (initialPath?.startsWith('/video/')) {
    initialPath = null
    return fallbackHandler?.()
  }
  if (window.history.length > 2) {
    const pathname = window.location.pathname

    window.addEventListener(
      'popstate',
      () => {
        if (pathname === window.location.pathname) fallbackHandler?.()
      },
      { once: true },
    )

    window.history.back()
  } else fallbackHandler?.()
}

const getDefaultRedirectUrl = ({ accountRole }) => (accountRole === 'locked' ? '/404' : '/')

export { goBack, getDefaultRedirectUrl }
