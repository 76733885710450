import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useState, useRef, useEffect, useMemo } from 'react'

import { useStore } from '../../store'
import { useElaiNotification } from '../../hooks/useElaiNotification'

import { CATEGORIES, maxTTVGenerationTimeSec } from './constants'
import { OPTIONS } from './components/createStory/constants'
import { isTemplateApplicable } from '../../utils/videos'
import { defaultBlankVideoName } from '../../utils/constants'

import { requestCreateVideo, requestDuplicateVideo } from '../../utils/videoCreation/videoCreation'
import { track } from '../../utils/analytics'

const initialFilterState = { tags: [], format: 'all' }

/**
 * TODO: looks like it can be optimized to use only 1 templates array
 */
const useCreateVideoModal = (props) => {
  const { defaultOpenedTemplate, isNewVideoModalOpen, setIsNewVideoModalOpen } = props

  // hooks
  const location = useLocation()
  const navigate = useNavigate()
  const isJustRegistered = localStorage.getItem('isJustRegistered') ?? false
  const { folderId } = useParams()
  const notification = useElaiNotification()

  // store
  const user = useStore((stores) => stores.authStore.user)
  const { templates: storeTemplates, templateTags } = useStore((stores) => stores.videosStore)

  // refs
  const progressTimeout = useRef(null)
  const videoFromTextRef = useRef(null)
  const startGenerationTime = useRef(null)

  // state
  const [pdfFile, setPdfFile] = useState(null)
  const [gptValue, setGPTValue] = useState(null)
  const [isGenerationProgressVisible, setIsGenerationProgressVisible] = useState(false)
  const [htmlToVideo, setHtmlToVideo] = useState(null)
  const [storyOption, setStoryOption] = useState(OPTIONS.topic)
  const [openedTemplate, setOpenedTemplate] = useState(null)
  const [isVideoCreating, setIsVideoCreating] = useState(false)
  const [isSetDefaultTags, setIsSetDefaultTags] = useState(true)
  const [templatesListData, setTemplatesListData] = useState([])
  const [generationProgress, setGenerationProgress] = useState(0)
  const [searchedTemplates, setSearchedTemplates] = useState(null)
  const [templatesFilter, setTemplatesFilter] = useState(initialFilterState)
  const [pastedContent, setPastedContent] = useState({ content: '', type: null })
  const [visibleCategory, setVisibleCategory] = useState(CATEGORIES.regular)

  // values
  const templates = useMemo(() => storeTemplates?.filter(isTemplateApplicable), [storeTemplates])

  const templatesForSelect = useMemo(() => {
    const selectedTemplates = templates ? [...templates] : []
    if (visibleCategory === 'regular') {
      selectedTemplates.unshift({ _id: 1, name: defaultBlankVideoName })
    }
    return selectedTemplates
  }, [templates, visibleCategory])

  const containsPrivateTemplates = useMemo(
    () => templatesForSelect.some((t) => t.template?.private),
    [templatesForSelect],
  )

  // functions
  const updateGenerationProgress = () => {
    const timeDiff = Date.now() - startGenerationTime.current
    if (timeDiff > maxTTVGenerationTimeSec * 1000) {
      notification.info({
        message: 'Sorry for the excessive waiting',
        description:
          'The generation process took longer than usual. As soon as the video is ready you will be automatically redirected. Please keep this window open.',
        duration: 0,
      })
      setIsVideoCreating(false)
      setIsNewVideoModalOpen(false)
      setOpenedTemplate(null)
      return clearTimeout(progressTimeout.current)
    }
    const progress = Math.min(99, Math.round((timeDiff / (maxTTVGenerationTimeSec * 1000)) * 100))
    setGenerationProgress(progress)
    if (isVideoCreating) {
      progressTimeout.current = setTimeout(updateGenerationProgress, 2000)
    } else {
      clearTimeout(progressTimeout.current)
    }
  }

  const setDefaultTags = () => {
    if (!isJustRegistered) return
    switch (user.account.additionalInfo?.userType) {
      case 'Learning & Development':
      case 'L&D Agency':
        setTemplatesFilter({ tags: ['Learning and Development'], format: 'all' })
        break
      case 'HR':
        setTemplatesFilter({ tags: ['Corporate communications'], format: 'all' })
        break
      case 'Marketing':
        setTemplatesFilter({ tags: ['Marketing'], format: 'all' })
        break
      case 'Sales':
        setTemplatesFilter({ tags: ['Sales'], format: 'all' })
        break
      case 'News':
        setTemplatesFilter({ tags: ['News'], format: 'all' })
        break
      default: {
        setTemplatesFilter(initialFilterState)
        break
      }
    }
    setIsSetDefaultTags(false)
  }

  const handleAfterCreateVideo = () => {
    setIsNewVideoModalOpen(false)
    setGPTValue('')
    setIsVideoCreating(false)
    setOpenedTemplate(null)
    setHtmlToVideo(null)
    setIsGenerationProgressVisible(false)
    setDefaultTags()
  }

  const createBlankVideo = async () => {
    setIsVideoCreating(true)
    const videoId = await requestCreateVideo({ folderId })
    if (videoId) {
      handleAfterCreateVideo()
      window.location.hash = ''
      navigate(`/video/${videoId}`)
    }
    setIsVideoCreating(false)
  }

  const createVideoFromTemplate = async (template = {}) => {
    setIsVideoCreating(true)
    const videoId = await requestDuplicateVideo({ sourceId: template._id, folderId })
    if (videoId) {
      if (user.account.status === 'trial') {
        track('video_trial_autocut', { id: videoId })
        notification.warning({
          key: 'trial-warning',
          message: (
            <p>
              Trial users can render only 3 slides. Please{' '}
              <a href="#subscription" onClick={() => notification.destroy('trial-warning')}>
                upgrade
              </a>{' '}
              if you need more
            </p>
          ),
        })
      }
      handleAfterCreateVideo()
      window.location.hash = ''
      navigate(`/video/${videoId}`)
    }
    setIsVideoCreating(false)
  }

  const handleModalOpen = () => {
    if (isNewVideoModalOpen) {
      if (!defaultOpenedTemplate && !window.location.hash) {
        navigate({ ...location, hash: 'regular' }, { state: { ...location.state } })
      }

      if (isSetDefaultTags && templates?.length > 1) {
        setDefaultTags()
      }
      setVisibleCategory(window.location.hash.replace('#', ''))
      setPastedContent({ content: '', type: null })
    } else {
      setGPTValue('')
      setTemplatesFilter(initialFilterState)
    }
  }

  useEffect(() => {
    handleModalOpen()
  }, [isNewVideoModalOpen])

  useEffect(() => {
    setDefaultTags()
  }, [user.account])

  useEffect(() => {
    setSearchedTemplates(() => {
      if (templatesFilter.format === 'all' && templatesFilter.tags.length === 0) {
        return null
      }
      let filteredTemplates = templates?.length ? [...templates] : []
      if (templatesFilter.format === 'private') {
        filteredTemplates = filteredTemplates.filter((t) => t.template.private && t.template.enabled)
      } else if (templatesFilter.format === '16_9') {
        filteredTemplates = filteredTemplates.filter((t) => !t.data?.format || t.data.format === '16_9')
      } else if (templatesFilter.format !== 'all') {
        filteredTemplates = filteredTemplates.filter((t) => t.data?.format === templatesFilter.format)
      }
      if (templatesFilter.tags.length > 0) {
        filteredTemplates = filteredTemplates.filter((t) =>
          t.template.tags?.some((tag) => templatesFilter.tags?.includes(tag)),
        )
      }
      if (filteredTemplates.length && window.location.hash === '#regular') {
        filteredTemplates.unshift({ _id: 1, name: defaultBlankVideoName })
      }
      return filteredTemplates
    })
  }, [templatesFilter, templates])

  /**
   * reset templates data for list if main templates data changed or filters was applied
   */
  useEffect(() => {
    showMoreTemplatesData(true)
  }, [templatesForSelect, searchedTemplates])

  useEffect(() => {
    if (isVideoCreating && isGenerationProgressVisible) {
      if (progressTimeout.current) {
        clearTimeout(progressTimeout.current)
      }
      setGenerationProgress(0)
      startGenerationTime.current = Date.now()
      progressTimeout.current = setTimeout(updateGenerationProgress, 2000)
    } else if (progressTimeout.current) {
      clearTimeout(progressTimeout.current)
      progressTimeout.current = null
    }
  }, [isVideoCreating])

  useEffect(() => {
    setOpenedTemplate(false)
    setIsGenerationProgressVisible(false)
  }, [window.location.hash])

  useEffect(() => {
    if (defaultOpenedTemplate && isNewVideoModalOpen) {
      setOpenedTemplate(defaultOpenedTemplate)
    }
  }, [defaultOpenedTemplate, isNewVideoModalOpen])

  const handleCategoryClick = (category) => {
    setTemplatesFilter(initialFilterState)
    switch (category) {
      case CATEGORIES.regular:
        setDefaultTags()
        window.location.hash = CATEGORIES.regular
        setVisibleCategory(CATEGORIES.regular)
        break
      case CATEGORIES.story:
        window.location.hash = CATEGORIES.story
        setVisibleCategory(CATEGORIES.story)
        break
      case CATEGORIES.pdf:
        window.location.hash = CATEGORIES.pdf
        setVisibleCategory(CATEGORIES.pdf)
        break

      case CATEGORIES.avatars:
        setIsNewVideoModalOpen(false)
        // Reset all filters and tags when selecting avatars
        setDefaultTags()
        setTemplatesFilter(initialFilterState)
        navigate('/avatars')
        break
      default:
        setTemplatesFilter(initialFilterState)
        break
    }
  }

  const showMoreTemplatesData = (resetData) => {
    const templates = searchedTemplates || templatesForSelect
    if (templatesListData?.length === templates.length) return
    const step = 20
    const currentIndex = resetData ? 0 : templatesListData?.length || 0
    const data = templates.slice(0, currentIndex + step)
    setTemplatesListData(data)
  }

  const handleClose = () => {
    if (isVideoCreating && isGenerationProgressVisible) {
      return false
    }
    window.location.hash = ''
    setDefaultTags()

    setSearchedTemplates(null)
    setOpenedTemplate(null)
    setIsGenerationProgressVisible(false)
    setPdfFile(null)
    setHtmlToVideo(null)
    setPastedContent({ content: '', type: null })
    setIsNewVideoModalOpen(false)
  }

  const handleNavigateBackToTemplates = () => {
    if (isVideoCreating) return
    window.location.hash = CATEGORIES.regular
    setVisibleCategory(CATEGORIES.regular)
    setOpenedTemplate(null)
  }

  return {
    pdfFile,
    setPdfFile,
    handleClose,
    htmlToVideo,
    templateTags,
    isGenerationProgressVisible,
    setIsGenerationProgressVisible,
    setHtmlToVideo,
    gptValue,
    setGPTValue,
    storyOption,
    setStoryOption,
    pastedContent,
    setPastedContent,
    openedTemplate,
    isVideoCreating,
    templatesFilter,
    videoFromTextRef,
    templatesListData,
    setOpenedTemplate,
    searchedTemplates,
    setTemplatesFilter,
    generationProgress,
    setIsVideoCreating,
    handleCategoryClick,
    showMoreTemplatesData,
    containsPrivateTemplates,
    createVideoFromTemplate,
    visibleCategory,
    setVisibleCategory,
    createBlankVideo,
    handleNavigateBackToTemplates,
    handleAfterCreateVideo,
  }
}

export default useCreateVideoModal
