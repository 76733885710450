import { Form, notification } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { request } from '../../../../utils/api'

import { LESSONS_URL } from '../constants'

export const useAdminLessonsState = () => {
  const { id } = useParams()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editableLesson, setEditableLesson] = useState({})
  const [lessonsData, setLessonsData] = useState({ course: {}, lessons: [] })

  useEffect(() => {
    const fetchLessons = async () => {
      const result = await request({ method: 'get', url: `${LESSONS_URL}/${id}` })
      setLessonsData(result)
    }

    fetchLessons()
  }, [])

  const [lessonForm] = Form.useForm()

  const handleCreateLesson = () => {
    lessonForm.resetFields()
    setIsModalOpen(true)
  }

  const deleteLesson = (record) => async () => {
    const result = await request({ method: 'delete', url: `${LESSONS_URL}/${record._id}` })
    if (result === false) return
    notification.success({ message: 'Lesson was successfully removed ', duration: 4 })
    // update state
    setLessonsData((prevState) => ({
      ...prevState,
      lessons: prevState.lessons.filter((lesson) => lesson._id !== record._id),
    }))
  }

  const editLesson = (record) => () => {
    setEditableLesson(record)
    setIsModalOpen(true)
  }

  return {
    id,
    editLesson,
    lessonForm,
    lessonsData,
    isModalOpen,
    deleteLesson,
    setIsModalOpen,
    editableLesson,
    setLessonsData,
    setEditableLesson,
    handleCreateLesson,
  }
}
