import { useState } from 'react'
import { Select, Tooltip, Row, Popconfirm, Button } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

import { request } from '../../utils/api'

import { ROLES, useUserState } from './constants'
import Icon from '../../components/Icon'
import { useElaiNotification } from '../../hooks/useElaiNotification'

const { Option } = Select

const SelectComponent = (props) => {
  const { text, record } = props
  const notification = useElaiNotification()
  const { authStore } = useUserState()
  const [selected, setSelected] = useState(text)
  const { user } = authStore

  const onChange = async (value) => {
    const res = await request({ method: 'patch', url: `/users/${record._id}`, data: { accountRole: value } })
    if (res === false) return
    notification.success({
      message: 'You changes was successfully saved',
      duration: 3,
    })
    setSelected(value)
  }

  if (user?.id !== record._id && user.canManageWorkspace) {
    return (
      <Select value={selected} style={{ width: 115 }} onChange={onChange} optionLabelProp="children">
        {ROLES.map(
          (role) =>
            role !== selected && (
              <Option value={role.role} key={role.role}>
                <Row justify="space-between">
                  {role.role}
                  <Tooltip placement="topLeft" title={role.tooltip}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Row>
              </Option>
            ),
        )}
      </Select>
    )
  } else {
    return <>{text}</>
  }
}

/**
 *
 * @param {*} user - user object
 * @param {function:void} deleteUser - onCLick event
 * @returns
 */
export const generateColumns = (user, deleteUser) => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Account Role',
      dataIndex: 'accountRole',
      render: (text, record) => <SelectComponent text={text} record={record} />,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (s) => (s === 'register' ? 'invited' : s),
    },
    {
      title: 'Last Login',
      dataIndex: 'lastLoginAt',
      render: (d) => d && new Date(d).toLocaleString(),
    },
  ]
  if (user.canManageWorkspace) {
    columns.push({
      title: 'Actions',
      fixed: 'right',
      align: 'center',
      render: (record) =>
        record._id !== user?.id && (
          <Popconfirm
            title="Are you sure you want to delete this user?"
            onConfirm={() => deleteUser(record._id)}
            okText="Delete"
            placement="topRight"
          >
            <Button type="ghost" icon={<Icon name="delete" />} />
          </Popconfirm>
        ),
    })
  }
  return columns
}
