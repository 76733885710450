import { Col, Button } from 'antd'
import ButtonGroup from 'antd/lib/button/button-group'

import { boldIcon, italicIcon, underlineIcon } from '../constants'

export const FontDecoration = (props) => {
  const { userFonts, getTextSelectionStyle, getTextSelectionFontFamily, activeObjectProps, setActiveObjectModifier } =
    props

  const isBoldDisabled = userFonts.find(({ _id }) => _id === getTextSelectionFontFamily(activeObjectProps))
  const isItalicDisabled = userFonts.find(({ _id }) => _id === getTextSelectionFontFamily(activeObjectProps))
  const isUnderlineDisabled = userFonts.find(
    ({ _id }) => _id === getTextSelectionFontFamily(activeObjectProps, 'fontFamily'),
  )

  // NOTE: it's not the same as used on onClick events
  const isUnderlineText = getTextSelectionStyle(activeObjectProps, 'underline') ? 'primary' : 'default'
  const isBoldText = getTextSelectionStyle(activeObjectProps, 'fontWeight') === 'bold' ? 'primary' : 'default'
  const isItalicText = getTextSelectionStyle(activeObjectProps, 'fontStyle') === 'italic' ? 'primary' : 'default'

  const onBoldClick = () => {
    setActiveObjectModifier({
      change: 'fontWeight',
      value: getTextSelectionStyle(activeObjectProps, 'fontWeight') === 'bold' ? 'normal' : 'bold',
    })
  }

  const onItalicClick = () => {
    setActiveObjectModifier({
      change: 'fontStyle',
      value: getTextSelectionStyle(activeObjectProps, 'fontStyle') === 'italic' ? 'normal' : 'italic',
    })
  }

  const onUnderlineClick = () => {
    setActiveObjectModifier({
      change: 'underline',
      value: !getTextSelectionStyle(activeObjectProps, 'underline'),
    })
  }

  return (
    <Col>
      <ButtonGroup>
        <Button icon={boldIcon} disabled={isBoldDisabled} type={isBoldText} onClick={onBoldClick} />
        <Button icon={italicIcon} disabled={isItalicDisabled} type={isItalicText} onClick={onItalicClick} />
        <Button icon={underlineIcon} type={isUnderlineText} onClick={onUnderlineClick} disabled={isUnderlineDisabled} />
      </ButtonGroup>
    </Col>
  )
}
