import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Popconfirm, Input, List, Spin, Space, Empty, Tooltip, Button } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { request } from '../../utils/api'
import { useStore } from '../../store'
import Icon from '../../components/Icon'
import Scrollbars from 'react-custom-scrollbars'
import { useElaiNotification } from '../../hooks/useElaiNotification'
import { requestDuplicateVideo } from '../../utils/videoCreation/videoCreation'

const ItemPopconfirm = ({
  item,
  itemComponent,
  isDeletedVideos,
  popconfirmAction,
  setPopconfirmAction,
  setActiveItem,
  setListItemButtonVisible,
  saveItemName,
  deleteItem,
  moveItem,
  filterInfo,
  fetchFolders,
}) => {
  const notification = useElaiNotification()
  const navigate = useNavigate()
  const { fetchTemplates } = useStore((stores) => stores.videosStore)
  const [openFolder, setOpenFolder] = useState(false)
  const [activeFolderId, setActiveFolderId] = useState()
  const [editingItem, setEditingItem] = useState({ id: item._id, name: item.name })
  const [isOpenNewFolderInput, setIsOpenNewFolderInput] = useState(false)
  const [isFolderCreating, setIsFolderCreating] = useState(false)
  const inputNewFolderNameRef = useRef(null)

  const fetchOpenFolder = async (id) => {
    setOpenFolder(false)
    if (activeFolderId) setActiveFolderId(false)
    if (isOpenNewFolderInput) setIsOpenNewFolderInput(false)
    if (id) {
      const f = await request({ method: 'post', url: `/folders/folder/${id}`, data: { match: { deleted: false } } })
      setOpenFolder(f)
    } else {
      const folders = await request({
        method: 'post',
        url: `/folders/lookup`,
        data: { match: { deleted: false } },
      })
      setOpenFolder({ name: 'Videos', children: folders })
    }
  }

  const createFolder = async () => {
    const name = inputNewFolderNameRef.current.input.value
    if (!name) {
      inputNewFolderNameRef.current.focus()
      return notification.error({ message: 'Please input folder name' })
    }
    setIsFolderCreating(true)
    const newFolder = await request({
      method: 'post',
      url: '/folders',
      data: { name, parentFolderId: openFolder._id },
    })
    setIsFolderCreating(false)
    setOpenFolder(newFolder)
    setIsOpenNewFolderInput(false)
    fetchFolders(filterInfo.condition)
  }

  const convertToTemplate = async () => {
    const res = await requestDuplicateVideo({ sourceId: item._id, createTemplate: true })
    if (!res) return
    await fetchTemplates()
    notification.success({ message: 'Your video was successfully converted to a template' })
    navigate('/templates')
  }

  useEffect(() => {
    const handlePopconfirmAction = async () => {
      if (popconfirmAction === 'move') await fetchOpenFolder(item.parentFolderId || item.folderId)
      setActiveItem(!!popconfirmAction)
    }
    handlePopconfirmAction()
  }, [popconfirmAction])

  useEffect(() => {
    if (isOpenNewFolderInput) inputNewFolderNameRef.current.focus({ cursor: 'all' })
  }, [isOpenNewFolderInput])

  const popconfirmProps = {
    rename: {
      title: <h4 style={{ width: 200 }}>Edit {item.slides ? 'video' : 'folder'} name</h4>,
      icon: <Icon name="edit" style={{ color: '#999999' }} />,
      description: (
        <Input
          style={{ marginBottom: 10 }}
          value={editingItem.name}
          onChange={(e) => setEditingItem({ ...editingItem, name: e.target.value })}
        />
      ),
      okText: 'Save',
      onConfirm: () => saveItemName(editingItem),
      onCancel: () => {
        setEditingItem({ id: item._id, name: item.name })
      },
    },
    delete: {
      title: isDeletedVideos
        ? `Are you sure you want to delete this ${
            item.slides ? 'video' : 'folder'
          } permanently? This action is irreversible`
        : `Are you sure you want to delete this ${item.slides ? 'video' : 'folder'}?`,
      icon: <ExclamationCircleOutlined />,
      description: null,
      okText: 'Delete',
      onConfirm: () => deleteItem(),
    },
    move: {
      title: isOpenNewFolderInput ? (
        <Space.Compact block style={{ paddingRight: 10 }}>
          <Input ref={inputNewFolderNameRef} defaultValue="New folder" />
          <Button type="primary" loading={isFolderCreating} icon={<Icon name="check" />} onClick={createFolder} />
        </Space.Compact>
      ) : openFolder ? (
        <div className="folder-name" style={{ marginLeft: openFolder._id ? 0 : -8 }}>
          <h3>{openFolder.name}</h3>
          <Tooltip placement="top" title="Create folder">
            <Icon name="add_folder" onClick={() => setIsOpenNewFolderInput(true)} className="add-folder-icon" />
          </Tooltip>
        </div>
      ) : null,
      icon: (
        <Icon
          name="left_arrow"
          style={{ visibility: openFolder._id || isOpenNewFolderInput ? 'visible' : 'hidden' }}
          className="arrow-back"
          onClick={() => fetchOpenFolder(openFolder.parentFolderId)}
        />
      ),
      description: (
        <Scrollbars style={{ height: 250, width: 300 }}>
          {!openFolder ? (
            <Spin style={{ width: '100%', marginTop: 20 }} />
          ) : openFolder.children?.length > 0 ? (
            <List
              size="small"
              dataSource={openFolder.children}
              className="popconfirm-folders-list"
              renderItem={(folder) => (
                <List.Item
                  className={`${activeFolderId === folder._id ? 'active' : ''} ${
                    folder._id === item._id ? 'disabled' : ''
                  }`}
                  onClick={() => setActiveFolderId(activeFolderId === folder._id ? false : folder._id)}
                >
                  <span className="list-item-title">
                    <Icon name="folder" />
                    <span>{folder.name}</span>
                  </span>
                  <Icon
                    name="right_arrow"
                    onClick={(e) => {
                      e.stopPropagation()
                      fetchOpenFolder(folder._id)
                    }}
                  />
                </List.Item>
              )}
            />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="There are no folders here" />
          )}
        </Scrollbars>
      ),
      okText: activeFolderId ? 'Move' : 'Move here',
      okButtonProps: {
        disabled:
          (!openFolder && !activeFolderId) ||
          (!activeFolderId && openFolder._id === (item.parentFolderId || item.folderId)) ||
          (activeFolderId && activeFolderId === (item.parentFolderId || item.folderId)),
      },
      onConfirm: () => moveItem(activeFolderId || openFolder._id),
      onCancel: async () => {
        await fetchFolders(filterInfo.condition)
      },
    },
    convertToTemplate: {
      title: 'Are you sure you want to turn this video into a template?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'Cancel',
      onConfirm: convertToTemplate,
    },
  }

  return (
    <Popconfirm
      title={popconfirmProps[popconfirmAction]?.title}
      description={popconfirmProps[popconfirmAction]?.description}
      placement="top"
      overlayClassName="videos-item-popconfirm"
      icon={popconfirmProps[popconfirmAction]?.icon || false}
      open={popconfirmAction}
      onConfirm={() => {
        setListItemButtonVisible(false)
        return popconfirmProps[popconfirmAction]?.onConfirm()
      }}
      onOpenChange={() => setPopconfirmAction(false)}
      onCancel={() => {
        setPopconfirmAction(false)
        setListItemButtonVisible(false)
        popconfirmProps[popconfirmAction]?.onCancel?.()
      }}
      okButtonProps={popconfirmProps[popconfirmAction]?.okButtonProps}
      okText={popconfirmProps[popconfirmAction]?.okText}
      cancelText="Cancel"
    >
      {itemComponent}
    </Popconfirm>
  )
}

export default ItemPopconfirm
