import { Button } from 'antd'
import { useEffect, useState } from 'react'
import { SketchPicker } from 'react-color'

const SketchPickerWithControls = ({ color, onChangeComplete, onCancel, ...props }) => {
  const [currentColor, setCurrentColor] = useState(color)
  const [tempColor, setTempColor] = useState(color)

  const handleChange = (color) => {
    setTempColor(color)
  }

  const handleOk = () => {
    setCurrentColor(tempColor)
    onChangeComplete(tempColor)
  }

  const handleCancel = () => {
    setTempColor(currentColor)
    onCancel?.()
  }

  useEffect(() => {
    const abortController = new AbortController()
    document.addEventListener(
      'keydown',
      (e) => {
        if (e.key === 'Escape') handleCancel()
      },
      { signal: abortController.signal },
    )
    return () => abortController.abort()
  }, [currentColor])

  return (
    <>
      <SketchPicker color={tempColor} onChange={handleChange} {...props} />
      <div className="sketchpicker-controls">
        <Button size="small" type="default" onClick={handleCancel}>
          Cancel
        </Button>
        <Button size="small" type="primary" onClick={handleOk}>
          OK
        </Button>
      </div>
    </>
  )
}

export default SketchPickerWithControls
