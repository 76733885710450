import { SearchOutlined } from '@ant-design/icons'

export const searchOutlinedIcon = <SearchOutlined />

export const sorterMenuItems = [
  { label: 'Last videos', key: 'updatedAt_descend' },
  { label: 'From old to new', key: 'updatedAt_ascend' },
  { label: 'From A to Z', key: 'name_ascend' },
  { label: 'From Z to A', key: 'name_descend' },
]

export const actionsWithConfirmation = ['delete', 'rename', 'move', 'convertToTemplate']
