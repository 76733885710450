import { useState } from 'react'
import { Alert, Form, Input, Button, Progress, Spin, Row, Col, Layout } from 'antd'
import { RightOutlined, LinkOutlined, UserOutlined } from '@ant-design/icons'
import { request } from '../../utils/api'
import { useStore } from '../../store'
import AgreementCheckbox from './components/agreementCheckbox'
import steps_shape from '../../assets/images/steps-shape.png'
import { useElaiNotification } from '../../hooks/useElaiNotification'

const Voice = () => {
  const notification = useElaiNotification()
  const [loaderVoice, setLoaderVoice] = useState(false)
  const { user, refreshSession } = useStore((stores) => stores.authStore)

  const voice =
    user.account.voices?.find((voice) => voice.status === 1) || user.account.voices[user.account.voices.length - 1]

  const onFinishVoice = async (data) => {
    setLoaderVoice(true)
    const res = await request({
      method: 'patch',
      url: '/voices/custom',
      data: { ...data, id: voice._id },
    })
    if (res === false) return setLoaderVoice(false)
    await refreshSession()
    setLoaderVoice(false)
    notification.success({
      message: `Thank you for providing your voice recording. Your voice is currently being processed and will be ready in 1-2 days.`,
    })
  }

  return voice ? (
    <Layout.Content>
      <div className="container">
        <div className="avatar-card voice">
          <div className="title">
            <h2>Voice cloning</h2>
          </div>
          <div className="main">
            {loaderVoice ? (
              <Spin size="large" style={{ width: '100%' }} />
            ) : (
              <>
                {voice.status === 2 && (
                  <>
                    <h2>Processing...</h2>
                    <Progress
                      percent={100}
                      status="active"
                      showInfo={false}
                      className="progress"
                      strokeColor={{
                        '0%': '#4868FF',
                        '100%': '#87d068',
                      }}
                    />
                  </>
                )}
                {voice.status === 1 ? (
                  <Form layout="vertical" requiredMark={false} onFinish={onFinishVoice}>
                    <Row gutter={[100, 30]}>
                      <Col md={12} sm={24} xs={24}>
                        <div>
                          <h3>Record your voice</h3>

                          <ul className="instruction-list">
                            <li>Record 1-5 minutes of your voice. 1 is ok but for best quality 5 is better.</li>
                            <li>
                              Save your recoridng into any audio format (mp3, wav) and upload to cloud (for ex. Google
                              Drive).
                            </li>
                            <li>
                              Share your footage with <b>avatars@elai.io</b>.
                            </li>
                            <li>Copy the link to your folder/file and paste it here.</li>
                          </ul>
                        </div>
                      </Col>
                      <Col md={12} sm={24} xs={24}>
                        <Alert
                          type="info"
                          style={{ marginBottom: 20 }}
                          message={
                            <>
                              Sample quality is more important than quantity. Noisy samples may give bad results.
                              Providing more than 5 minutes of audio in total brings little improvement.
                            </>
                          }
                        />
                        <Form.Item
                          name="name"
                          label="Voice name"
                          validateTrigger="onSubmit"
                          rules={[
                            {
                              required: true,
                              message: 'Voice name is required',
                            },
                          ]}
                        >
                          <Input
                            placeholder="Your official name that we can use as Voice ID"
                            prefix={<UserOutlined />}
                          />
                        </Form.Item>
                        <Form.Item
                          name="footageLink"
                          label="Link to your voice recording"
                          validateTrigger="onSubmit"
                          rules={[
                            {
                              required: true,
                              type: 'url',
                              message: 'Link is required',
                            },
                          ]}
                        >
                          <Input placeholder="Link" prefix={<LinkOutlined />} />
                        </Form.Item>
                        <AgreementCheckbox isVoice={true} />
                      </Col>
                    </Row>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        icon={<RightOutlined />}
                        className="btn-login btn-arrow"
                        size="large"
                      >
                        Send
                      </Button>
                    </Form.Item>
                  </Form>
                ) : (
                  <Alert
                    message={
                      voice.status === 2
                        ? 'Your voice is being prepared.'
                        : voice.status === 0
                        ? 'Voice is not paid. Please update your subscription for the voice before proceeding.'
                        : voice.status === 3
                        ? 'Your voice is ready. You can find it in the voice list when editing videos.'
                        : 'Your voice is unavailable. Please contact support.'
                    }
                    type="info"
                    showIcon
                  />
                )}
              </>
            )}
          </div>
          {voice.status === 1 && <img src={steps_shape} className="steps-shape-img" />}
        </div>
      </div>
    </Layout.Content>
  ) : null
}

export default Voice
