import { useState, useEffect, useMemo, useRef } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { Layout, Spin, Menu, Button, Collapse } from 'antd'
import configService from '../../../../utils/config'
import { RightOutlined } from '@ant-design/icons'
import { request } from '../../../../utils/api'
import { CreateVideoModal } from '../../../../components/CreateVideoModal'
import Icon from '../../../../components/Icon'
import { CourseBreadcrumb } from '../../components/courseBreadcrumb'
import logoElaiAcademy from '../../../../assets/images/academy/logo.png'
import './course.less'

const { Header } = Layout
const { Panel } = Collapse

const Course = () => {
  const { id, lessonId } = useParams()
  const navigate = useNavigate()
  const lessonContentContainer = useRef(null)
  const [course, setCourse] = useState(null)
  const [loadingCourse, setLoadingCourse] = useState(false)
  const [activeLesson, setActiveLesson] = useState()
  const [isNewVideoModalOpen, setIsNewVideoModalOpen] = useState(false)

  const { playerUrl } = configService.get().urls

  useEffect(() => {
    const fetchCourse = async () => {
      setLoadingCourse(true)
      const courseRes = await request({ method: 'get', url: `courses/${id}` })
      if (courseRes) setCourse(courseRes)
      setLoadingCourse(false)
    }
    fetchCourse()
  }, [])

  useEffect(() => {
    if (course && course.lessons.length && !lessonId) {
      navigate(`/academy/course/${id}/${course.lessons[0].id}`, { replace: true })
    }
  }, [lessonId, course])

  useEffect(() => {
    if (!course || loadingCourse) return
    const lesson = course.lessons.find((lesson) => lesson.id === lessonId)
    if (!lesson) return
    setActiveLesson(lesson)
    if (lessonContentContainer.current) lessonContentContainer.current.innerHTML = lesson.content
  }, [lessonId, loadingCourse])

  const onSelectLesson = ({ key }) => {
    navigate(`/academy/course/${id}/${key}`)
  }

  const onClickCreateVideo = () => {
    setIsNewVideoModalOpen(true)
  }

  const courseMenuItems = useMemo(
    () =>
      course?.lessons?.map((lesson) => ({
        key: lesson.id,
        label: lesson.name,
      })),
    [course],
  )

  return (
    <Layout.Content className="elai-academy course">
      <Header className="academy-header">
        <div>
          <Link to="/academy">
            <img className="academy-logo" src={logoElaiAcademy} alt="Elai academy Logo" />
          </Link>
        </div>
      </Header>
      {loadingCourse ? (
        <Spin className="spin-centered" />
      ) : (
        course && (
          <div className="academy-content-wrapper">
            <CourseBreadcrumb course={course} lesson={activeLesson} />
            <div className="academy-content">
              <div className="course-menu-container">
                <div className="course-menu-wrapper desktop-menu">
                  <span className="course-label">Course</span>
                  <h3 className="course-name">{course.name}</h3>
                  {!courseMenuItems.length ? (
                    <p className="empty-course-message">There are no lessons</p>
                  ) : (
                    <Menu
                      selectedKeys={[lessonId]}
                      className="course-menu"
                      mode="inline"
                      items={courseMenuItems}
                      selectable
                      onSelect={onSelectLesson}
                    />
                  )}
                </div>
                <div className="course-menu-wrapper mobile-menu">
                  <Collapse
                    defaultActiveKey={['1']}
                    ghost
                    expandIcon={({ isActive }) => (isActive ? <Icon name="up_arrow" /> : <Icon name="down_arrow" />)}
                    expandIconPosition="end"
                  >
                    <Panel
                      header={
                        <>
                          <span className="course-label">Course</span>
                          <h3 className="course-name">{course.name}</h3>
                        </>
                      }
                      key="1"
                    >
                      {!courseMenuItems.length ? (
                        <p className="empty-course-message">There are no lessons</p>
                      ) : (
                        <Menu
                          selectedKeys={[lessonId]}
                          className="course-menu"
                          mode="inline"
                          items={courseMenuItems}
                          selectable
                          onSelect={onSelectLesson}
                        />
                      )}
                    </Panel>
                  </Collapse>
                </div>
              </div>
              <div className="lesson-content-wrapper">
                {activeLesson?.interactivityId && (
                  <iframe
                    src={`${playerUrl}/${activeLesson.interactivityId}`}
                    title="Elai.io video player"
                    allowFullScreen
                    className="lesson-player"
                    scrolling="no"
                  ></iframe>
                )}
                <div ref={lessonContentContainer} className="lesson-content"></div>
                {activeLesson?.template && (
                  <Button
                    type="primary"
                    icon={<RightOutlined />}
                    className="btn-arrow"
                    size="large"
                    onClick={onClickCreateVideo}
                  >
                    Create video from template
                  </Button>
                )}
              </div>
            </div>
          </div>
        )
      )}
      <CreateVideoModal
        isNewVideoModalOpen={isNewVideoModalOpen}
        setIsNewVideoModalOpen={setIsNewVideoModalOpen}
        defaultOpenedTemplate={activeLesson?.template}
      />
    </Layout.Content>
  )
}

export default Course
