import { Badge } from 'antd'
import { useMemo } from 'react'

export const CommentsBadge = (props) => {
  const { children, comments, userId } = props

  const unviewedCommentsCount = useMemo(
    () =>
      comments?.reduce((accumulator, comment) => {
        return comment.viewed.includes(userId) ? accumulator : accumulator + 1
      }, 0),
    [comments, userId],
  )

  return (
    <Badge count={unviewedCommentsCount} color="#4868ff" size="small" className="comments-badge" offset={[7, -3]}>
      {children}
    </Badge>
  )
}
