import { Button, Card, Col, Popconfirm, Row, Space, Tag } from 'antd'

import Icon from '../../components/Icon'

import { UPLOAD_ICON } from './constants'

export const AccountAvatars = (props) => {
  const { accountAvatars, avatarStatuses, onConfirmDelete, isVideoCreating, onClickCreateVideo } = props

  return (
    <>
      <h2 className="title">My avatars</h2>
      <Row gutter={[20, 30]} className="avatar-cards-row">
        {accountAvatars?.map((avatar) => (
          <Col key={avatar.id}>
            <Card className="avatar-card">
              <Tag color={avatarStatuses[avatar.status]?.color} className="card-tag positioned-absolute">
                {avatarStatuses[avatar.status]?.text || 'Disabled'}
              </Tag>
              {avatar.status === 2 ? (
                <img
                  src={avatar.variants[0]?.thumbnail || avatar.variants[0]?.canvas || avatar.thumbnail || avatar.canvas}
                  alt="Avatar thumbnail"
                />
              ) : (
                <Icon name="user" className="default-avatar-thumbnail" />
              )}
              {avatar.status === 2 || avatar.status === 5 ? (
                <div className="avatar-name-wrapper">
                  <p>{avatar.name}</p>
                  <Space>
                    {avatar.type === 'photo' && (
                      <Popconfirm
                        title="Are you sure you want to delete this avatar?"
                        onConfirm={onConfirmDelete(avatar.id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="text" size="small" icon={<Icon name="delete" />} className="btn-delete-avatar" />
                      </Popconfirm>
                    )}
                    <Button
                      type="primary"
                      size="small"
                      loading={isVideoCreating === avatar.code}
                      disabled={avatar.status === 5}
                      icon={<Icon name="plus" />}
                      className="btn-create-video"
                      onClick={onClickCreateVideo(avatar)}
                    />
                  </Space>
                </div>
              ) : avatar.status === 4 ? (
                <Button type="primary" href={`/avatar/${avatar.id}`} icon={UPLOAD_ICON} className="btn-add-data">
                  Add data
                </Button>
              ) : null}
            </Card>
          </Col>
        ))}
      </Row>
    </>
  )
}
