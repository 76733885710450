import { Row, Tooltip } from 'antd'

import Icon from '../../../components/Icon'

import { useStore } from '../../../store'

/**
 * Component to display panel which on click navigate user to Brandbook
 * @param {{setActiveTab, setAssetActiveTab, text: string, secondText: string, marginBottom: number, marginTop: number }} props
 * @returns JSX element
 */
export const BrandbookLink = ({
  setActiveTab,
  setAssetActiveTab,
  text,
  secondText,
  marginTop,
  marginBottom,
  setColorPickerVisible,
  setCanvasActiveObject,
  style = {},
}) => {
  const userPlan = useStore((stores) => stores.authStore.user.account.plan)

  // Check if user have access to brandkit
  const isBrandkitAvaliable = userPlan !== 'basic'

  const handleClick = () => {
    if (!isBrandkitAvaliable) return

    setColorPickerVisible?.(false)
    setCanvasActiveObject?.(null)
    setActiveTab('assets')
    setAssetActiveTab('brandbook')
  }

  return (
    <Row className="brandbook-link" style={style}>
      <Tooltip title={isBrandkitAvaliable ? null : 'Upgrade to Advanced plan'}>
        <div
          className={`custom-fonts-link ${!isBrandkitAvaliable && 'fonts-link-disabled'}`}
          onClick={handleClick}
          style={{ marginTop: `${marginTop}px`, marginBottom: `${marginBottom}px` }}
          // style={{ marginTop: `${marginTop}px;`, marginBottom: `${marginBottom}px` }}
        >
          {text} <span className="highlight-word">{secondText}</span>
          <Icon name="link" className="custom-font-link-icon" />
        </div>
      </Tooltip>
    </Row>
  )
}
