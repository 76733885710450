import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { queueMicrotask } from '../../../../utils/helpers'
import { setTimeOnObjectsWithMarkers } from '../../../../utils/canvas/canvas'
import { track } from '../../../../utils/analytics'

import { useStore } from '../../../../store'

import userIcon from '../../../../assets/images/user-icon.svg'
import { PLAYER_STATUSES, FILE_TYPES, DEFAULT_SILENCE_DURATION, FLAGS_URL } from './constants'
import { equal } from '../../../../store/helpers'
import { useElaiNotification } from '../../../../hooks/useElaiNotification'
import { flagsUrl } from './voiceModal/constants'

const mapDictionaryPairs = (dictionary) =>
  dictionary.map((ph) => ({ word: ph.word.toLowerCase(), pronunciation: ph.pronunciation }))

const waitSeeked = async (audio) =>
  await new Promise((resolve) => audio.addEventListener('seeked', resolve, { once: true }))

export const useVoiceState = (props) => {
  const { player, setPlayer, checkObjectsAnimationTime, video, data, audioCache, updateSlide, updateVideo } = props

  const notification = useElaiNotification()
  const playerActiveRef = useRef()

  const [activeVoice, setActiveVoice] = useState({})
  const [isVoiceUploading, setIsVoiceUploading] = useState(false)
  const [visibleVoiceModal, setVisibleVoiceModal] = useState(false)
  const [previousDictionaryPairs, setPreviousDictionaryPairs] = useState(null)

  const voices = useStore((stores) => stores.voicesStore.voices)
  const { voices: userVoices, status: userStatus, dictionary } = useStore((stores) => stores.authStore.user.account)

  const objectsWithoutAnimationTime = useMemo(() => checkObjectsAnimationTime(data.canvas.objects), [data.canvas])

  const resetAudio = () => {
    audioCache.dropCacheForSlide(data)
    setPlayer((prevPlayer) => Object.assign({}, prevPlayer, { voiceAudio: null, audioReady: false, status: 'idle' }))
  }

  useEffect(() => setActiveVoice(getActiveVoice()), [voices, data.language, data.voice, data.avatar.gender])

  useEffect(() => {
    if (!previousDictionaryPairs) {
      setPreviousDictionaryPairs(mapDictionaryPairs(dictionary))
      return
    }
    const dictionaryPairs = mapDictionaryPairs(dictionary)
    if (!equal(previousDictionaryPairs, dictionaryPairs)) {
      setPreviousDictionaryPairs(dictionaryPairs)

      let isEdited = false
      for (const slide of video.slides) {
        if (!slide.speech || slide.voiceType !== 'text') continue

        const speechWords = slide.speech.split(/\b/).map((word) => word.toLowerCase())
        if (!dictionaryPairs.some((ph) => speechWords.includes(ph.word))) continue

        slide.status = 'edited'

        if (slide.id === data.id) resetAudio()
        else audioCache.dropCacheForSlide(slide)

        isEdited = true
      }
      if (isEdited) updateVideo({ slides: [...video.slides], status: 'draft' })
    }
  }, [dictionary])

  /**
   * drop audio cache to resend request for markers time
   */
  useEffect(() => {
    if (objectsWithoutAnimationTime) {
      resetAudio()
    }
  }, [objectsWithoutAnimationTime])

  useEffect(() => {
    if (data.voiceType === 'file' && data.audioUrl) loadAudio()
  }, [data.audioUrl, data.voiceType])

  useEffect(() => {
    if (player.status === 'loading' && !player.voiceAudio) loadAudio()
  }, [player.status])

  useEffect(() => {
    playerActiveRef.current = player.activePreview
  }, [player.activePreview])

  const getSpeechAudioFromCache = async () => {
    const speechData = await audioCache.getVoiceForSlideSpeech(
      data,
      player.activePreview,
      onChangeAudioGenerationStatus,
    )
    return speechData.audio
  }

  const getVoiceForSlideSpeech = async () => {
    const speechData = await audioCache.getVoiceForSlideSpeech(
      data,
      player.activePreview,
      onChangeAudioGenerationStatus,
    )
    if (speechData.markers) {
      const objects = setTimeOnObjectsWithMarkers(data.canvas.objects, speechData.markers)
      await updateSlide(
        {
          canvas: Object.assign({}, data.canvas, { objects }),
          updateCanvas: true,
        },
        { ignoreHistory: true },
      )
    }
    return speechData.audio
  }

  const loadAudio = async () => {
    try {
      let audio
      if (data.voiceType === 'file') {
        if (!data.audioUrl) {
          notification.error({ message: 'Audio file is required' })
          return stopPlaying()
        }

        audio = await getSpeechAudioFromCache()
      } else if (data.voiceType === 'silence') {
        audio = new Audio(audioCache.createSilentAudio(data.duration))
      } else {
        if (!data.voice || !data.speech) {
          notification.error({ message: 'Speech text is required' })
          return stopPlaying()
        }

        audio = await getVoiceForSlideSpeech()
        if (!audio) return stopPlaying()
      }
      if (player.seekTimeForResume) {
        const shouldSeek = audio.currentTime !== player.seekTimeForResume
        audio.currentTime = player.seekTimeForResume
        if (shouldSeek) await waitSeeked(audio)
      } else {
        const shouldSeek = audio.currentTime > 0
        audio.currentTime = 0
        if (shouldSeek) await waitSeeked(audio)
      }
      audio.onended = () => {
        if (playerActiveRef.current) {
          setPlayer((prevState) => ({
            ...prevState,
            status: 'switching',
            currentTime: prevState.currentTime,
          }))
        } else {
          setPlayer((prevState) => ({
            ...prevState,
            status: 'idle',
            currentTime: 0,
          }))
        }
      }

      const onloadedMetadata = () => {
        if (playerActiveRef.current)
          setPlayer((p) => ({ ...p, status: 'loading', voiceAudio: audio, audioReady: true }))
        else setPlayer((p) => ({ ...p, voiceAudio: audio, audioReady: true }))

        if (audio.duration === Infinity || !audio.duration || isNaN(audio.duration)) {
          updateSlide({ duration: data.approxDuration })
        } else if (audio.duration !== data.duration)
          updateSlide({ duration: audio.duration }, { ignoreStateRestoreWarning: true })

        audio.onloadedmetadata = null
      }

      if (audio.readyState < 1) audio.onloadedmetadata = onloadedMetadata
      else queueMicrotask(onloadedMetadata)

      setPlayer((p) => ({ ...p, seekTimeForResume: 0 }))
      return audio
    } catch (e) {
      setPlayer((p) => ({ ...p, status: 'idle' }))
    }
  }

  const getActiveVoice = () => {
    if (userVoices?.length) {
      const voice = userVoices.find((voice) => voice.id === data.voice)
      if (voice) {
        const { id, provider } = voice
        // TODO: maybe, need an updeted API to avoid copying objects into themselfs
        return Object.assign({}, voice, { voice: id, voiceProvider: provider, style: 'default' })
      }
    }
    const lang = voices.length > 0 ? voices.find((language) => language.name === data.language)[data.avatar.gender] : []
    if (data.voice?.includes(':')) {
      const [voiceId, style] = data.voice.split(':')
      const v = lang.find((v) => v.voice?.startsWith(voiceId))
      return Object.assign({}, v, { id: voiceId, style })
    } else {
      // const v = lang.find((v) => v.voice === data.voice || v.playedTags?.some((t) => t.id === data.voice)) || lang[0]
      const v =
        lang.find((v) => v.voice === data.voice || (v.playedTags && v.playedTags.some((t) => t.id === data.voice))) ||
        lang[0]

      return { ...v, style: 'default' }
    }
  }

  const onChangeAudioGenerationStatus = useCallback(
    (inProgress) => setPlayer((p) => ({ ...p, audioReady: !inProgress })),
    [],
  )

  const stopPlaying = useCallback(() => {
    setPlayer((prevState) =>
      Object.assign({}, prevState, { activePreview: false, status: PLAYER_STATUSES.idle, currentTime: 0 }),
    )
  }, [])

  const changeSilenceDuration = (duration) => {
    if (!duration) duration = 1
    updateSlide({ duration, language: data.language })
    setPlayer((p) => ({
      ...p,
      voiceAudio: null,
      currentTime: 0,
    }))
  }

  const beforeUpload = (file) => {
    if (file.size / 1024 / 1024 > 20) {
      notification.error({ message: 'File must be smaller than 20MB' })
      return false
    }
    if (!FILE_TYPES.includes(file.type.split('/')[0])) {
      notification.error({ message: 'Invalid file format' })
      return false
    }
    return true
  }

  const onChangeVoiceType = (type) => {
    const update = { voiceType: type, duration: null, status: 'edited', language: data.language }
    if (type === 'silence') {
      const objects = data.canvas.objects
      const currentAvatar = data.canvas.objects.find((obj) => obj.type === 'avatar')
      if (currentAvatar) currentAvatar.avatarType = 'voiceover'
      update.canvas = { ...data.canvas, objects }
      update.updateCanvas = true
      update.duration = update.duration || DEFAULT_SILENCE_DURATION
    }
    updateSlide(update)
  }

  const handleUploadingVoice = (info) => {
    const { status, error, response } = info.file
    setIsVoiceUploading(status === 'uploading')
    if (status === 'done') {
      track('editor_voice_file_uploaded')
      audioCache.dropCacheForSlide(data)
      updateSlide({
        audioUrl: response.url,
        status: 'edited',
        language: data.language,
      })
    } else if (status === 'error') {
      notification.error({ message: error.toString() })
    }
  }

  const openModal = () => setVisibleVoiceModal(true)

  const flagIcon = activeVoice?.icon ? `${FLAGS_URL}${activeVoice.icon}.svg` : userIcon

  // Contain all languages and voices in it
  const languages = useMemo(
    () => voices.filter((lang, idx) => voices.findIndex((v) => v.name === lang.name) === idx),
    [voices],
  )

  const languageOptions = useMemo(
    () =>
      languages.map((language) => ({
        value: language.name,
        label: (
          <div key={language.name} className="language-select-option" style={{ display: 'flex' }}>
            <img
              src={`${flagsUrl}${language.male[0].icon}.svg`}
              width="20"
              className="flag-icon margin-right-10px"
              alt={language.name}
            />
            <span>{language.name}</span>
          </div>
        ),
      })),
    [languages],
  )

  const handleChangeLanguageForUploadVoice = (value) => updateSlide({ language: value })

  return {
    voices,
    flagIcon,
    openModal,
    languages,
    userStatus,
    activeVoice,
    beforeUpload,
    getActiveVoice,
    isVoiceUploading,
    visibleVoiceModal,
    onChangeVoiceType,
    handleUploadingVoice,
    setVisibleVoiceModal,
    changeSilenceDuration,
    resetAudio,
    languageOptions,
    handleChangeLanguageForUploadVoice,
  }
}
