import React, { useEffect, useState, useRef, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Table, Layout, Tag, Spin, Form, InputNumber, Space, Tooltip, Popconfirm } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { formatSeconds } from '../../utils/videos'
import { request } from '../../utils/api'
import PageHeader from '../../components/PageHeader/pageHeader'
import Icon from '../../components/Icon'
import { useStore } from '../../store'
import './templates.less'

const EditableContext = React.createContext(null)

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}
const EditableOrderCell = ({ editable, children, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false)
  const inputRef = useRef(null)
  const form = useContext(EditableContext)
  const dataIndexName = 'order'

  useEffect(() => {
    if (editing) {
      inputRef.current.focus()
    }
  }, [editing])

  const toggleEdit = () => {
    setEditing(!editing)
    form.setFieldsValue({
      [dataIndexName]: record.template.order,
    })
  }

  const save = async () => {
    const values = await form.validateFields()
    toggleEdit()
    if (record.template.order !== values.order) {
      await handleSave({
        ...record,
        template: {
          ...record.template,
          order: values.order,
        },
      })
    }
  }

  let childNode = children
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndexName}
        rules={[
          {
            required: true,
            type: 'number',
            min: 1,
          },
        ]}
      >
        <InputNumber ref={inputRef} min={1} max={99999} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    )
  }
  return <td {...restProps}>{childNode}</td>
}

const Videos = () => {
  const [videos, setVideos] = useState()
  const [isVideoCreating, setIsVideoCreating] = useState()
  const { fetchTemplates } = useStore((stores) => stores.videosStore)

  const fetchVideos = async () => {
    const videos = await request({ method: 'get', url: 'admin/videos/templates' })
    setVideos(videos.filter((v) => v.template))
  }

  const copyTemplate = async (id) => {
    setIsVideoCreating(id)
    const video = await request({ method: 'post', url: `/templates/copy`, data: { sourceId: id } })
    setIsVideoCreating(false)
    if (video) await fetchVideos()
  }

  useEffect(() => {
    fetchVideos()
  }, [])

  const deleteVideo = async (id) => {
    await request({ method: 'patch', url: `/videos/${id}?from=delete`, data: { deleted: true } })
    await fetchVideos()
    await fetchTemplates()
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableOrderCell,
    },
  }

  const defaultColumns = [
    {
      dataIndex: 'thumbnail',
      render: (url, record) => (
        <img
          src={record.slides[0]?.screenshot}
          width={record.data?.format === '9_16' ? 30 : 80}
          style={{ display: 'block', margin: '0 auto' }}
        ></img>
      ),
      responsive: ['xl'],
    },
    {
      title: 'Name',
      dataIndex: 'name',
      render: (name, record) => <Link to={`/video/${record._id}`}>{name}</Link>,
    },
    {
      title: 'Type',
      filters: [
        {
          text: 'Vertical',
          value: 'vertical',
        },
      ],
      filterMultiple: false,
      onFilter: (v, record) => {
        if (v === 'vertical' && record.data?.format === '9_16') return true
        else return false
      },
      render: (v, record) => (record.data?.format === '9_16' ? <Tag>Vertical</Tag> : null),
    },
    {
      title: 'Status',
      filters: [
        {
          text: 'Enabled',
          value: 'enabled',
        },
        {
          text: 'Disabled',
          value: 'disabled',
        },
      ],
      filterMultiple: false,
      onFilter: (v, record) => {
        if (v === 'enabled' && record.template.enabled) return true
        else if (v === 'disabled' && !record.template.enabled) return true
        else return false
      },
      render: (v, record) => (record.template.enabled ? <Tag color="success">Enabled</Tag> : ''),
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      render: (duration) => formatSeconds(duration),
    },
    {
      title: 'Tags',
      render: (record) => record.template.tags?.length ?? 0,
    },
    {
      title: 'Order',
      render: (record) => record.template.order,
      editable: true,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render: (d) => new Date(d).toLocaleString(),
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      render: (d) => new Date(d).toLocaleString(),
    },
    {
      key: 'action',
      colSpan: 1,
      render: (text, record) => (
        <Space align="center">
          <Tooltip title="Duplicate/copy template">
            {isVideoCreating === record._id ? (
              <LoadingOutlined />
            ) : (
              <Icon name="copy" onClick={() => copyTemplate(record._id)} />
            )}
          </Tooltip>
          <Popconfirm
            title="Are you sure you want to delete this template?"
            onConfirm={() => deleteVideo(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Icon name="delete" />
          </Popconfirm>
        </Space>
      ),
    },
  ]

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        title: col.title,
        handleSave: async (row) => {
          await request({ method: 'patch', url: `/videos/${row._id}`, data: { template: row.template } })
          await fetchVideos()
        },
      }),
    }
  })

  return (
    <Layout.Content className="admin-content">
      <div className="content">
        <PageHeader title="Templates" style={{ padding: 0 }} />
        {videos ? (
          <Table
            columns={columns}
            components={components}
            rowClassName={() => 'editable-row'}
            dataSource={videos}
            rowKey="_id"
            pagination={false}
          />
        ) : (
          <Spin />
        )}
      </div>
    </Layout.Content>
  )
}

export default Videos
