import React, { useEffect, useMemo, useState } from 'react'
import Icon from '../../../../../../components/Icon'
import { Button, Row, Select, Tooltip } from 'antd'
import { mediaStockProvidersMap } from './content/media-provider.config'
import { useStore } from '../../../../../../store'
import DefaultColors from '../../../../colorsEditor/defaultColors'

const STYLES = {
  selectDownIcon: { pointerEvents: 'none' },
}

const LibraryFilter = (props) => {
  const { filesType, onApplyFilter, filter, updateFilter } = props

  const [color, setColor] = useState('')
  const [colorPalette, setColorPalette] = useState([])
  const { user } = useStore((stores) => stores.authStore)
  const plan = user?.account?.plan

  const { mediaStockProvider, updateMediaStockProvider } = useStore((stores) => stores.videosStore)

  const provider = mediaStockProvidersMap[mediaStockProvider[filesType]]
  const { name, label, colorsMap, isCustomColorEnabled, colorEnabled } = provider
  const filters = useMemo(() => [...provider?.filters?.[filesType]], [provider, filesType])
  const isColorEnabled = colorEnabled?.includes(filesType)

  const selectProviderValues = useMemo(
    () =>
      Object.values(mediaStockProvidersMap)
        .filter(({ availableTypes }) => availableTypes.includes(filesType))
        .map(({ name, label, restrictedPlans }) => {
          const disabled = restrictedPlans?.[filesType]?.plans?.includes(plan)
          const shouldShowIcon = restrictedPlans?.[filesType]?.plans?.length
          return {
            value: name,
            label: shouldShowIcon ? (
              <Tooltip title={disabled && restrictedPlans?.[filesType]?.message} placement="bottom">
                <Row justify="space-between" align="middle">
                  <span>{label}</span>
                  <Icon name="diamond" className="own-colors" />
                </Row>
              </Tooltip>
            ) : (
              label
            ),
            disabled,
          }
        }),
    [filesType],
  )

  const addColorToPalette = (color) => {
    if (colorPalette.find((value) => value.toLowerCase() === color)) return
    setColorPalette((prev) => [color, ...prev])
  }

  const onSelectProvider = (provider) => {
    const propsToDisable = filters
      .flatMap(({ values }) => values.flatMap(({ enabledProps }) => enabledProps))
      .filter(Boolean)
    propsToDisable.forEach((prop) => turnPropVisibility(prop, true))
    updateMediaStockProvider(filesType, provider)
  }

  const onChangeColor = (newColor) => {
    if (color === newColor) {
      setColor('')
      updateFilter({ color: undefined })
      return
    }
    setColor(newColor)
    addColorToPalette(newColor)
    const foundColor = Object.entries(colorsMap).find(([, value]) => value.toLowerCase() === newColor)
    const [updatedColor] = foundColor ?? [newColor]
    updateFilter({ color: updatedColor })
  }

  const turnPropVisibility = (key, disabled) => {
    const foundFilter = filters.find((filter) => filter.key === key)
    if (foundFilter) foundFilter.disabled = disabled
    if (disabled && filter[key]) updateFilter({ [key]: undefined })
  }

  const filterOptionHandler = ({ key, value, subKey, disabledProps = [], enabledProps = [] }) => {
    if (isKeyActive({ key, value, subKey })) value = undefined
    if (subKey) {
      const currentFilterKey = filters.find((item) => item.key === key)
      currentFilterKey.values.forEach((item) => {
        if (!filter[key + item.subKey]) return
        updateFilter({ [key + item.subKey]: undefined })
      })
      key += subKey
    }
    updateFilter({ [key]: value })
    disabledProps.forEach((prop) => turnPropVisibility(prop, true))
    enabledProps.forEach((prop) => turnPropVisibility(prop, false))
    if (value === undefined) {
      enabledProps.forEach((prop) => turnPropVisibility(prop, true))
    }
  }

  const isKeyActive = ({ key, value, subKey }) => {
    if (filter[key + subKey]) return filter[key + subKey] === value
    return filter[key] === value
  }

  useEffect(() => {
    // drop color if provider changed
    setColor('')
  }, [name])

  useEffect(() => {
    if (!colorsMap) return
    setColorPalette([...Object.values(colorsMap)])
  }, [colorsMap])

  return (
    <div
      className="search-media-filter"
      id={`search-media-filter-${filesType}`}
      style={{ maxHeight: 'calc(100vh - 80px)' }}
    >
      <div className="search-media-filter-entries">
        {selectProviderValues?.length > 1 && (
          <>
            <h4>Stock</h4>
            <Select
              defaultValue={{ value: name, label }}
              className="provider-select"
              popupClassName="filter-media-provider-select-dropdown"
              suffixIcon={<Icon name="down_arrow" style={STYLES.selectDownIcon} />}
              getPopupContainer={() => document.getElementById(`search-media-tooltip-${filesType}`)}
              onChange={onSelectProvider}
              options={selectProviderValues}
            />
          </>
        )}
      </div>
      <div className="search-media-filter-entries">
        {isColorEnabled && (
          <div className="colors-editor">
            <DefaultColors
              title="Color"
              color={color}
              customColor={isCustomColorEnabled}
              colorPalette={colorPalette}
              onChangeColor={onChangeColor}
            />
          </div>
        )}
        {filters
          ?.filter(({ disabled }) => !disabled)
          .map(({ key, label, values }) => (
            <div key={key} className="search-media-filter-entries-item">
              <h4 className="search-media-filter-entries-item-header">{label}</h4>
              <div className="search-media-filter-entries-item-content">
                {values.map(({ subKey, icon, label: valuesLabel, value, disabledProps, enabledProps }) => (
                  <Button
                    icon={icon}
                    key={key + subKey + value}
                    className="search-media-filter-entries-item-button"
                    type={isKeyActive({ key, value, subKey }) ? 'primary' : 'default'}
                    onClick={() => filterOptionHandler({ key, value, subKey, disabledProps, enabledProps })}
                  >
                    {valuesLabel}
                    {isKeyActive({ key, value, subKey }) ? <Icon name="close_simple" /> : null}
                  </Button>
                ))}
              </div>
            </div>
          ))}
      </div>
      <Button type="primary" className="search-media-filter-apply-button" onClick={onApplyFilter}>
        Apply Filters
      </Button>
    </div>
  )
}

export default LibraryFilter
