import axios from 'axios'
import { Scrollbars } from 'react-custom-scrollbars'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { Input, Button, Spin } from 'antd'

import { ElementItem } from './elementItem'
import giphyLogo from '../../../../../assets/images/powered-by-giphy.png'
import { useElaiNotification } from '../../../../../hooks/useElaiNotification'
import configService from '../../../../../utils/config'

const { Search } = Input

const { giphy: apiGiphyKey } = configService.get().keys

const STYLE = {
  width100: { width: '100%' },
  minHeight500: { minHeight: 500 },
  text: { textAlign: 'center', marginTop: 20 },
  img: { display: 'block', margin: '14px auto 10px' },
}

export const Giphy = (props) => {
  const notification = useElaiNotification()
  const { type, onChooseFile } = props

  const [loader, setLoader] = useState()
  const [filesData, setFilesData] = useState()
  const [searchValue, setSearchValue] = useState()
  const [filesTotalCount, setFilesTotalCount] = useState()

  const giphyUrl = `https://api.giphy.com/v1/${type}`

  const fetchTrendingStickers = useCallback(async () => {
    setLoader(true)
    try {
      const { data } = await axios.get(`${giphyUrl}/trending?api_key=${apiGiphyKey}&limit=30&offset=0&rating=g&lang=en`)
      setFilesData(data.data)
      setFilesTotalCount(data.pagination.total_count)
    } catch (error) {
      notification.error({ message: error.message, duration: null })
    }
    setLoader(false)
  }, [])

  useEffect(() => {
    fetchTrendingStickers()
  }, [fetchTrendingStickers])

  const onSearch = async (value) => {
    if (!value) return fetchTrendingStickers()
    setFilesData(null)
    setSearchValue(value)
    setLoader(true)
    try {
      const { data } = await axios.get(
        `${giphyUrl}/search?api_key=${apiGiphyKey}&q=${value}&limit=30&offset=0&rating=g&lang=en`,
      )
      setFilesData(data.data)
      setFilesTotalCount(data.pagination.total_count)
    } catch (error) {
      notification.error({ message: error.message, duration: null })
    }
    setLoader(false)
  }

  const showMore = async () => {
    const offset = filesData.length
    setLoader(true)
    try {
      const { data } = await axios.get(
        `${giphyUrl}/${searchValue ? 'search' : 'trending'}?api_key=${apiGiphyKey}${
          searchValue ? `&q=${searchValue}` : ''
        }&limit=30&offset=${offset}&rating=g&lang=en`,
      )
      setFilesData((previousData) => [...previousData, ...data.data])
    } catch (error) {
      notification.error({ message: error.message, duration: null })
    }
    setLoader(false)
  }

  return (
    <div className="search-media">
      <Search placeholder={`Search GIPHY`} className="media-items-controls" onSearch={onSearch} enterButton />
      <div className="search-media-wrapper">
        <Scrollbars style={STYLE.minHeight500}>
          <div className="search-items-container">
            {filesData?.map((file, index) => {
              return (
                <Fragment key={index}>
                  <ElementItem
                    thumbnail={file.images.preview_gif.url}
                    isVisibleActions={false}
                    onClickItem={() => onChooseFile(file.images.original.url, file.images.downsized.url)}
                  />
                </Fragment>
              )
            })}
          </div>
          <div className="media-items-controls">
            {!loader && filesData && filesData.length !== filesTotalCount && (
              <Button type="primary" ghost block onClick={showMore}>
                Show more
              </Button>
            )}
            {loader && <Spin style={STYLE.width100} />}
            {filesData?.length ? (
              <a href="https://giphy.com/" target="_blank" rel="noreferrer">
                <img src={giphyLogo} width="130" alt="Powered by GIPHY" style={STYLE.img} />
              </a>
            ) : null}
            {filesData && !filesData?.length && <p style={STYLE.text}>Nothing found</p>}
          </div>
        </Scrollbars>
      </div>
    </div>
  )
}
