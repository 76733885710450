import { Scrollbars } from 'react-custom-scrollbars'
import { Card, Upload, Row, Col, Tooltip, Typography, Select, Spin, Segmented, InputNumber } from 'antd'
import { SoundOutlined, SearchOutlined, CheckOutlined, QuestionCircleOutlined } from '@ant-design/icons'

import music from '../../../../data/music'
import Icon from '../../../../components/Icon'
import { useMusicTabState } from './useMusicTabState'
import LazySlider from '../../../../components/LazySlider'
import { customUploadRequest } from '../../../../utils/api'
import { CustomMusicScrollBar } from './customMusicScrollBar'

import { style } from './constants'
import configService from '../../../../utils/config'

const { Dragger } = Upload

const saveIcon = <Icon name="save" />
const headphoneIcon = <Icon name="headphone" />

const musicURL = 'https://d3u63mhbhkevz8.cloudfront.net/music/'
const defaultVolume = 0.18

export const Music = (props) => {
  const { video, slide, updateVideo, player, stopVideo, activeSlide } = props

  const {
    shift,
    musicTab,
    endShift,
    userMusic,
    musicData,
    musicAudio,
    applyMusic,
    pauseMusic,
    deleteMusic,
    uploadMusic,
    onPlayClick,
    createAudio,
    validateShift,
    setUserMusic,
    beforeUpload,
    musicFilters,
    playingTrack,
    setMusicFilters,
    setPlayingTrack,
    validateEndShift,
    isMusicUploading,
    handleShiftInput,
    handlePauseClick,
    isUploadAvailable,
    handleDraggerClick,
    handleEndShiftInput,
    handleChangeMusicTab,
  } = useMusicTabState({ video, slide, stopVideo, player, updateVideo, activeSlide })

  const url = video?.data?.musicUrl ? new URL(video.data.musicUrl).pathname : null
  const { apiUrl } = configService.get().urls

  return (
    <div className="music tab-content">
      {video.data.musicUrl && (
        <>
          <Row align="middle" style={style.marginBottom16}>
            <Col span={6}>Volume</Col>
            <Col span={18}>
              <LazySlider
                min={0.03}
                max={0.45}
                step={0.01}
                tooltip={{ formatter: (v) => Math.round(v * 100) }}
                value={video.data?.musicVolume === undefined ? defaultVolume : video.data.musicVolume}
                onStep={(v) => {
                  if (musicAudio.current) musicAudio.current.volume = v
                }}
                onChange={(v) => {
                  if (musicAudio.current) musicAudio.current.volume = v
                  updateVideo({ data: { ...video.data, musicVolume: v } })
                }}
              />
            </Col>
          </Row>
          <Row align="middle" style={style.marginBottom16}>
            <Col span={10}>
              Beginning Shift{' '}
              <Tooltip title="Positive value means music will start in X seconds after video starts (starts with delay), negative value - music will be trimmed for X seconds in the beginning.">
                <QuestionCircleOutlined />
              </Tooltip>
            </Col>
            <Col span={14}>
              {validateShift ? (
                <Tooltip title="Your speech is shorter than inputed value">
                  <InputNumber
                    value={shift}
                    onChange={handleShiftInput}
                    style={{ borderColor: `${validateShift ? '#cf5d60' : '#bdbdbd'}` }}
                  />
                </Tooltip>
              ) : (
                <InputNumber value={shift} onChange={handleShiftInput} />
              )}
            </Col>
          </Row>
          <Row align="middle" style={style.marginBottom16}>
            <Col span={10}>
              End Shift{' '}
              <Tooltip title="Music will stop playing in X seconds before end of the video.">
                <QuestionCircleOutlined />
              </Tooltip>
            </Col>
            <Col span={14}>
              {validateEndShift ? (
                <Tooltip title="Your speech is shorter than inputed value">
                  <InputNumber
                    min={0}
                    value={endShift}
                    onChange={handleEndShiftInput}
                    style={{ borderColor: `${validateEndShift ? '#cf5d60' : '#bdbdbd'}` }}
                  />
                </Tooltip>
              ) : (
                <InputNumber min={0} value={endShift} onChange={handleEndShiftInput} />
              )}
            </Col>
          </Row>
        </>
      )}
      <Segmented
        value={musicTab}
        className="segmented-default"
        block
        options={[
          {
            label: (
              <span>
                {headphoneIcon}
                Stock Music
              </span>
            ),
            value: 'tracks',
          },
          {
            label: (
              <Tooltip
                overlayStyle={{ visibility: isUploadAvailable ? 'hidden' : 'visible' }}
                title="Please upgrade to paid plan to add your own music"
              >
                <span>
                  {saveIcon}
                  Upload
                </span>
              </Tooltip>
            ),
            value: 'upload',
          },
        ]}
        onChange={handleChangeMusicTab}
      />
      <div className="music-tracks" style={{ display: musicTab === 'tracks' ? 'flex' : 'none' }}>
        <Row justify="space-between" wrap={false} style={{ width: '99%', margin: '0 auto 10px' }}>
          <Col flex="33%">
            <Select
              style={style.width100P}
              defaultValue="All genres"
              size="small"
              onChange={(v) => setMusicFilters({ ...musicFilters, genre: v })}
            >
              <Select.Option>All genres</Select.Option>
              {music
                .map((obj) => obj.genre)
                .filter((g, i, self) => self.indexOf(g) === i)
                .map((genre) => (
                  <Select.Option key={genre}>{genre}</Select.Option>
                ))}
            </Select>
          </Col>
          <Col flex="65%">
            <Select
              mode="multiple"
              style={style.width100P}
              placeholder="Search by tags"
              suffixIcon={<SearchOutlined />}
              showSearch={false}
              showArrow="true"
              maxTagCount="responsive"
              size="small"
              onChange={(values) => setMusicFilters({ ...musicFilters, tags: values })}
            >
              {music
                .map((obj) => obj.tags)
                .flat()
                .filter((g, i, self) => self.indexOf(g) === i)
                .map((t) => (
                  <Select.Option key={t}>{t}</Select.Option>
                ))}
            </Select>
          </Col>
        </Row>
        <div className="music-tracks-wrapper">
          <Scrollbars style={{ minHeight: 400 }}>
            {musicData.length ? (
              musicData.map(({ name, file, genre, tags, shift }, i) => (
                <Card
                  key={i}
                  size="small"
                  className={`item ${video.data && url === '/music/' + file ? 'active' : ''}`}
                  title={
                    <>
                      <p>{name}</p>
                      <Typography.Text ellipsis type="secondary" style={{ fontSize: 11 }}>
                        <span className="genre">{genre}</span>
                        {tags.map((t) => (
                          <span key={t}>{t} </span>
                        ))}
                      </Typography.Text>
                      <div className="active-icon">
                        <CheckOutlined />
                      </div>
                    </>
                  }
                  extra={
                    player.activePreview && video.data?.musicUrl === musicURL + file ? null : playingTrack ===
                      musicURL + file ? (
                      <Icon name="pause" onClick={handlePauseClick} />
                    ) : (
                      <Icon name="play" onClick={() => onPlayClick(file)} />
                    )
                  }
                  onClick={(e) => {
                    if (!e.target.matches(['svg', 'path'])) {
                      if (video.data?.musicUrl?.includes(musicURL + file)) {
                        deleteMusic()
                      } else applyMusic({ name, url: musicURL + file })
                    }
                  }}
                />
              ))
            ) : (
              <p style={style.nothingFound}>Nothing found</p>
            )}
          </Scrollbars>
        </div>
      </div>
      <div className="music-upload" style={{ display: musicTab === 'upload' ? 'flex' : 'none' }}>
        <Dragger
          name="file"
          action={`${apiUrl}/uploads/music/${video._id}`}
          accept=".mp3, .wav, .ogg, .aac, .m4a"
          multiple={false}
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={uploadMusic}
          disabled={!isUploadAvailable}
          customRequest={customUploadRequest}
          style={style.marginBottom16}
          onClick={handleDraggerClick}
        >
          <p className="ant-upload-drag-icon">
            <SoundOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">We are currently supporting most audio formats (mp3, wav, ogg, aac, m4a).</p>
        </Dragger>
        {isMusicUploading && <Spin size="large" />}
        <CustomMusicScrollBar
          musicUrl={video.data.musicUrl}
          customMusic={userMusic}
          data={video.data}
          player={player}
          playingTrack={playingTrack}
          handlePauseClick={handlePauseClick}
          pauseMusic={pauseMusic}
          createAudio={createAudio}
          musicShift={video.data.musicShift}
          setPlayingTrack={setPlayingTrack}
          deleteMusic={deleteMusic}
          applyMusic={applyMusic}
          setCustomMusic={setUserMusic}
          musicAudio={musicAudio}
        />
      </div>
    </div>
  )
}
