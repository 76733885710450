import { debounce } from 'throttle-debounce'
import { useMemo, useState, useCallback } from 'react'

import { useStore } from '../../../../../store'

/**
 *
 * @param {*} activeObject
 * @param {String} style
 * @returns {String}
 */
const getTextSelectionStyle = (activeObject, style) => {
  if (!activeObject) return
  const selectionStyles = activeObject?.selectionStyles
  if (
    activeObject &&
    selectionStyles?.length &&
    selectionStyles[0][style] &&
    selectionStyles.every((char) => char[style] === selectionStyles[0][style])
  )
    return selectionStyles[0][style]
  return activeObject[style]
}

export const useTextControlsState = (props) => {
  const { canvasActiveObject, setActiveObjectModifier } = props

  const { fonts: userFonts, fetchFonts, deleteFont } = useStore((stores) => stores.videosStore)

  const [manualFontSize, setManualFontSize] = useState(false)

  const activeObjectProps = useMemo(
    () => (canvasActiveObject?.type === 'activeSelection' ? canvasActiveObject.getObjects()[0] : canvasActiveObject),
    [canvasActiveObject],
  )

  const getTextSelectionFontFamily = (activeObject) => {
    if (activeObject?.fontId) return activeObject.fontId
    const fontFamily = getTextSelectionStyle(activeObject, 'fontFamily')
    return fontFamily?.split(',')[0]
  }

  const handleColorChange = (color) => {
    setActiveObjectModifier({ change: 'fill', value: color ? color : '#fffffff' })
  }

  const colorEditButton = getTextSelectionStyle(activeObjectProps, 'fill')

  const onFontSizeChange = (v) => {
    setManualFontSize(v)
    debounceFontSizeChange(v)
  }

  const saveFontSize = (v) => {
    if (v) {
      const value = v > 300 ? 300 : v < 3 ? 3 : v
      setActiveObjectModifier({ change: 'fontSize', value })
      setManualFontSize(false)
    }
  }

  const debounceFontSizeChange = useCallback(
    debounce(800, (v) => saveFontSize(v)),
    [],
  )

  const onBlurFontSizeInput = () => {
    setManualFontSize(false)
  }

  const onStep = (v) => setManualFontSize(v)

  const onSliderChange = (v) => {
    setManualFontSize(false)
    setActiveObjectModifier({ change: 'fontSize', value: v })
  }

  const handleLineHeightChange = (v) => {
    setActiveObjectModifier({ change: 'lineHeight', value: v })
  }

  const customFontsForSelect = useMemo(
    () =>
      userFonts.map((font) => ({
        _id: font._id,
        displayName: font.displayName || font.name,
        name: font.name,
        subfamily: font.subfamily,
      })),
    [userFonts],
  )

  return {
    onStep,
    userFonts,
    fetchFonts,
    deleteFont,
    onSliderChange,
    manualFontSize,
    colorEditButton,
    onFontSizeChange,
    activeObjectProps,
    handleColorChange,
    onBlurFontSizeInput,
    customFontsForSelect,
    getTextSelectionStyle,
    handleLineHeightChange,
    getTextSelectionFontFamily,
  }
}
