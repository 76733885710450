export const ElementItem = (props) => {
  const { thumbnail, isVisibleActions, onClickItem, onChooseFile, type } = props

  const chooseFile = (e) => {
    e.stopPropagation()
    onChooseFile()
  }

  return (
    <div className="element-item" onClick={onClickItem}>
      <img src={thumbnail} className={`element-item-img element-item-img-${type}`} crossOrigin="anonymous" />
      {isVisibleActions && (
        <div className="element-item-actions-bg">
          <div className="element-item-actions">
            <span role="img" aria-label="plus" className="anticon anticon-eye">
              <svg
                viewBox="64 64 896 896"
                focusable="false"
                data-icon="plus"
                width="1em"
                height="1em"
                aria-hidden="true"
                onClick={chooseFile}
              >
                <path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"></path>
                <path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z"></path>
              </svg>
            </span>
            <span role="img" aria-label="eye" className="anticon anticon-eye">
              <svg
                viewBox="64 64 896 896"
                focusable="false"
                data-icon="eye"
                width="1em"
                height="1em"
                aria-hidden="true"
              >
                <path d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"></path>
              </svg>
            </span>
          </div>
        </div>
      )}
    </div>
  )
}
