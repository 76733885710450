import { useNavigate } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'

import Icon from '../../components/Icon'

import { useStore } from '../../store'
import { request } from '../../utils/api'
import { requestCreateVideo, createVideoSlidesByAvatar } from '../../utils/videoCreation/videoCreation'
import { useFilterAvatars } from '../../hooks/useFilterAvatars'
import { MASCOT_PHOTO_AVATARS } from './constants'

export const useAvatarsState = () => {
  const navigate = useNavigate()

  const user = useStore((stores) => stores.authStore.user)
  const { setHeader } = useStore((stores) => stores.domStore)
  const { avatars, fetchAvatars } = useStore((stores) => stores.videosStore)

  const [isVideoCreating, setIsVideoCreating] = useState(false)
  const [isOpenFiltersPopup, setIsOpenFiltersPopup] = useState(false)

  const voices = useStore((stores) => stores.voicesStore.voices)

  const account = user.account
  // no need to filter all this time just once per avatars change or account.id
  const accountAvatars = useMemo(
    () => avatars?.filter((avatar) => avatar.accountId === account.id),
    [avatars, account.id],
  )

  const publicAvatars = useMemo(() => {
    if (!avatars) return
    return avatars
      .filter((avatar) => !avatar.accountId && !MASCOT_PHOTO_AVATARS.includes(avatar.type))
      .map((avatar) => {
        if (avatar.intro) return avatar
        return avatar.variants
          .filter((v) => v.intro)
          .map((v) => {
            const mergedAvatar = {
              ...avatar,
              ...v,
              code: `${avatar.code}.${v.code}`,
              status: avatar.status,
              name: v.name,
              previewName: `${avatar.name} ${v.name}`,
            }
            delete mergedAvatar.id
            delete mergedAvatar.variants
            delete mergedAvatar.listeningAvatar
            return mergedAvatar
          })
      })
      .flat()
  }, [avatars])

  const { filteredAvatars, filters, setFilters } = useFilterAvatars({
    avatars: publicAvatars,
    avatarsDataWithoutVariants: true,
  })

  const onClickCreateVideo = (avatar) => async () => {
    setIsVideoCreating(avatar.code)
    const data = createVideoSlidesByAvatar(avatar, voices)
    const videoId = await requestCreateVideo(data)
    if (!videoId) return
    setIsVideoCreating(false)
    navigate(`/video/${videoId}`)
  }

  const onConfirmDelete = (id) => async () => {
    await request({ method: 'delete', url: `avatars/${id}` })
    fetchAvatars()
  }

  useEffect(() => {
    setHeader({
      title: 'Avatars',
      icon: <Icon name="users" />,
      link: '/avatars',
    })
  }, [])

  return {
    user,
    account,
    publicAvatars,
    accountAvatars,
    isVideoCreating,
    filteredAvatars,
    filters,
    setFilters,
    isOpenFiltersPopup,
    setIsOpenFiltersPopup,
    onConfirmDelete,
    onClickCreateVideo,
  }
}
