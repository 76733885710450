import { useState } from 'react'

const initialState = {
  visible: false,
  color: '',
  onChange: null,
  onShow: null,
}

export default () => {
  const [colorEditor, setColorEditor] = useState(initialState)

  const showEditor = (color, isBackground = false, onChange) => {
    setColorEditor((ce) => ({ ...ce, color, visible: true, onChange }))
    if (colorEditor.onShow) colorEditor.onShow(isBackground)
  }

  const setColor = (color) => setColorEditor((ce) => ({ ...ce, color }))

  const setVisible = (visible) => setColorEditor((ce) => ({ ...ce, visible }))

  const setOnShowCallback = (onShow) => setColorEditor((ce) => ({ ...ce, onShow }))

  const resetState = () => setColorEditor(initialState)

  return { colorEditor, showEditor, setColor, setVisible, setOnShowCallback, resetState }
}
