import Icon from '../../../../components/Icon'
import question_horizontal_light from '../../../../assets/images/interactive_elements/question-horizontal-light.png'
import question_vertical_light from '../../../../assets/images/interactive_elements/question-vertical-light.png'
import question_horizontal_dark from '../../../../assets/images/interactive_elements/question-horizontal-dark.png'
import question_vertical_dark from '../../../../assets/images/interactive_elements/question-vertical-dark.png'
import question_horizontal_transparent_light from '../../../../assets/images/interactive_elements/question-horizontal-transparent-light.png'
import question_vertical_transparent_light from '../../../../assets/images/interactive_elements/question-vertical-transparent-light.png'
import question_horizontal_transparent_dark from '../../../../assets/images/interactive_elements/question-horizontal-transparent-dark.png'
import question_vertical_transparent_dark from '../../../../assets/images/interactive_elements/question-vertical-transparent-dark.png'
import button_gradient from '../../../../assets/images/interactive_elements/button-gradient.png'
import button_primary from '../../../../assets/images/interactive_elements/button-primary.png'
import button_youtube from '../../../../assets/images/interactive_elements/button-youtube.png'
import button_web from '../../../../assets/images/interactive_elements/button-web.png'
import button_link_arrow from '../../../../assets/images/interactive_elements/button-link-arrow.png'
import button_link from '../../../../assets/images/interactive_elements/button-link.png'

const arrowDownIcon = <Icon name="down_arrow" className="arrow-down-icon" />

const questions = [
  { id: 'horizontal-light', thumbnail: question_horizontal_light },
  { id: 'vertical-light', thumbnail: question_vertical_light },
  { id: 'horizontal-dark', thumbnail: question_horizontal_dark },
  { id: 'vertical-dark', thumbnail: question_vertical_dark },
  { id: 'horizontal-transparent-dark', thumbnail: question_horizontal_transparent_dark },
  { id: 'vertical-transparent-dark', thumbnail: question_vertical_transparent_dark },
  { id: 'horizontal-transparent-light', thumbnail: question_horizontal_transparent_light },
  { id: 'vertical-transparent-light', thumbnail: question_vertical_transparent_light },
]

const elements = [
  { id: 'button-gradient', thumbnail: button_gradient },
  { id: 'button-primary', thumbnail: button_primary },
  { id: 'button-youtube', thumbnail: button_youtube },
  { id: 'button-web', thumbnail: button_web },
  { id: 'button-link-arrow', thumbnail: button_link_arrow },
  { id: 'button-link', thumbnail: button_link },
]

const MIN_ANSWERS_COUNT = 2
const MAX_ANSWERS_COUNT = 6

const ANSWER_OBJECT_PLACEHOLDER = 'Provide an answer'
const QUESTION_OBJECT_PLACEHOLDER = 'Create a question with correct answer'
const QUESTIONS_BLOCKED_TOOLTIP =
  'Reminder: "Questions" cannot be added to a slide that already contains other interactive elements '

export {
  questions,
  elements,
  arrowDownIcon,
  MIN_ANSWERS_COUNT,
  MAX_ANSWERS_COUNT,
  ANSWER_OBJECT_PLACEHOLDER,
  QUESTION_OBJECT_PLACEHOLDER,
  QUESTIONS_BLOCKED_TOOLTIP,
}
