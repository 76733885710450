import { useRef } from 'react'
import { createContext, useContextSelector } from 'use-context-selector'
import { equal } from './helpers'
import store from './store'

export const Context = createContext(null)

export const StoreProvider = ({ children }) => {
  return <Context.Provider value={store()}>{children}</Context.Provider>
}

export const useStore = (selector) => {
  const prevValue = useRef(null)
  const patchedSelector = (state) => {
    const nextValue = selector(state)
    if (equal(prevValue.current, nextValue)) {
      return prevValue.current
    }
    return (prevValue.current = nextValue)
  }
  return useContextSelector(Context, patchedSelector)
}
