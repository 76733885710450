import Icon from '../Icon'

const TagOptionFilter = ({ options, title, filterValue, setFilterValue }) => {
  const handleChangeFilter = (value) => {
    setFilterValue((prevState) => {
      if (prevState.includes(value)) {
        const updatedOptions = prevState.filter((option) => option !== value)
        return updatedOptions
      } else {
        return [...prevState, value]
      }
    })
  }

  return (
    <div>
      <h4 className="filter-title">{title}</h4>
      <div className="options-container">
        {options.map((option) => (
          <div
            key={option}
            className={`option ${filterValue.includes(option) ? 'active' : ''}`}
            onClick={() => handleChangeFilter(option)}
          >
            <span>{option}</span>
            <Icon name="close_simple" />
          </div>
        ))}
      </div>
    </div>
  )
}

export { TagOptionFilter }
