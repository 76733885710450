import { Modal, Row, Col, Checkbox, Button } from 'antd'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Scrollbars from 'react-custom-scrollbars'
import { useStore } from '../../../../../store'
import { createCheckoutSession } from '../../../../../utils/payment'
import Icon from '../../../../../components/Icon'
import './purchaseModal.less'
import { useElaiNotification } from '../../../../../hooks/useElaiNotification'

const PurchaseModal = ({ plan, avatarData, avatar, canBuy, isOpenPurchaseModal, setIsOpenPurchaseModal }) => {
  const notification = useElaiNotification()
  const navigate = useNavigate()
  const user = useStore((stores) => stores.authStore.user)
  const [isCheckedAgreementCheckbox, setIsCheckedAgreementCheckbox] = useState()
  const [loading, setLoading] = useState(false)

  const checkout = async () => {
    if (!canBuy) return
    if (!isCheckedAgreementCheckbox)
      return notification.error({ message: 'Please accept our Custom Avatars and Voices Terms & Conditions' })

    setLoading(true)
    if (avatar) await checkoutAvatar()
    else await checkoutVoice()
    setTimeout(() => setLoading(false), 1000)
  }

  const checkoutAvatar = async () => {
    if (user) await createCheckoutSession({ voice: plan === 'with-voice', avatar })
    else {
      localStorage.setItem('purchase', JSON.stringify({ voice: plan === 'with-voice', avatar }))
      navigate('/signup')
    }
  }

  const checkoutVoice = async () => {
    if (user) await createCheckoutSession({ voice: true })
    else {
      localStorage.setItem('purchase', JSON.stringify({ voice: true }))
      navigate('/signup')
    }
  }

  return (
    <Modal
      centered
      open={isOpenPurchaseModal}
      width={plan === 'with-voice' ? 920 : 600}
      className="purchase-modal"
      footer={null}
      closable={!loading}
      onCancel={() => {
        setIsOpenPurchaseModal(false)
        setIsCheckedAgreementCheckbox(false)
      }}
    >
      <Icon name="chat_info" className="chat-icon" />
      <Scrollbars
        className="scrollbar purchase-modal-scrollbar"
        autoHeight={true}
        autoHeightMin={avatar ? 300 : 200}
        autoHeightMax={450}
      >
        <Row gutter={[56, 24]} style={{ paddingBottom: 8 }}>
          {avatar && (
            <Col lg={plan === 'with-voice' ? 12 : 24} md={plan === 'with-voice' ? 12 : 24} sm={24} xs={24}>
              <h3>Important information about {avatarData.title}:</h3>
              <ul className="key-points-list">
                {avatarData.keyPoints.map((point, index) => (
                  <li key={index}>{point}</li>
                ))}
              </ul>
            </Col>
          )}
          {(!avatar || plan === 'with-voice') && (
            <Col lg={avatar ? 12 : 24} md={avatar ? 12 : 24} sm={24} xs={24}>
              <h3>Important information about voice cloning:</h3>
              <ul className="key-points-list">
                <li>
                  If your voice recording does not meet our{' '}
                  <a
                    href="https://d3u63mhbhkevz8.cloudfront.net/docs/Voice+-+Terms+%26+Conditions.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    requirements
                  </a>
                  , we might not be able to clone your voice
                </li>
                <li>Your voice should remain consistent during the recording and should have no background noise</li>
                <li>
                  Your new voice <b>might not be an exact copy</b> of your real voice. While we use state-of-the-art
                  technology, it might not always replicate the accents, tone, or dynamics of your voice
                </li>
              </ul>
            </Col>
          )}
        </Row>
      </Scrollbars>
      <Checkbox
        checked={isCheckedAgreementCheckbox}
        className="agreement-checkbox"
        onChange={(e) => setIsCheckedAgreementCheckbox(e.target.checked)}
      >
        By purchasing {avatar ? 'an avatar' : 'a voice'}, I confirm and accept the{' '}
        <a href="https://elai.io/ai-avatar-policy" target="_blank" rel="noreferrer">
          Terms & Conditions for Custom Avatars and Voices
        </a>
      </Checkbox>
      <Button type="primary" size="large" loading={loading} className="btn-payment" onClick={checkout}>
        Proceed to payment
      </Button>
    </Modal>
  )
}

export default PurchaseModal
