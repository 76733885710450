import Icon from '../../../../components/Icon'

export const MARKER_REGEX = /<mark name=['"](\d+)['"] \/>/g
export const ANIMATION_TYPES = {
  enter: 'Enter',
  exit: 'Exit',
}

export const FADES = ['fade_in', 'fade_out']

export const DOWN_ARROW_ICON = <Icon name="down_arrow" />
