import { useEffect } from 'react'

import { useStore } from '../../../store'
import { normalizeColor } from './helpers'

import backgroundIcon from '../../../assets/images/color-wheel.png'

const style = {
  img: { position: 'absolute', top: 4, left: 4 },
}

const ColorEditorButton = (props) => {
  const { color, isBackgroundPicker, onChangeColor } = props
  const colorEditorStore = useStore((stores) => stores.colorEditorStore)

  useEffect(() => {
    if (color && typeof color === 'string' && color !== colorEditorStore.colorEditor.color)
      colorEditorStore.setColor(normalizeColor(color))
  }, [color])

  const pickColor = () => {
    colorEditorStore.showEditor(normalizeColor(color), isBackgroundPicker, (color) => {
      colorEditorStore.setColor(color)
      onChangeColor(color)
    })
  }
  const gradient = {
    backgroundColor: isBackgroundPicker ? '#ffffff' : color,
  }
  return (
    <div>
      <div className="gradient-picker" onClick={pickColor}>
        {isBackgroundPicker && <img src={backgroundIcon} width="20" style={style.img} />}
        <div className={`gradient-color ${color === 'rgba(0, 0, 0, 0)' ? 'transparent' : ''}`} style={gradient}></div>
      </div>
    </div>
  )
}

export default ColorEditorButton
