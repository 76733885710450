import { useNavigate } from 'react-router-dom'
import { Row, Col, Button } from 'antd'
import { useStore } from '../../../../store'

export default ({ activeTab, setIsOpenPhotoAvatarModal }) => {
  const navigate = useNavigate()
  const authStore = useStore((stores) => stores.authStore)

  return (
    <div className="tabs-content-wrapper">
      <Row className="main-description" gutter={[22, 22]} justify="center">
        <Col lg={{ span: 7, order: 1 }} xs={{ span: 24, order: 1 }}>
          <Row className="cards-container" gutter={[16, 16]}>
            {avatarsDescription[activeTab].cards_description.map((card, index) => (
              <Col key={index} lg={24} md={8} sm={24} xs={24}>
                <div className="card">
                  <h3>{card.title}</h3>
                  <p>{card.text}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
        <Col lg={{ span: 16, order: 1 }} xs={{ span: 24, order: -1 }}>
          <div className={`card-title ${activeTab}`}>
            <h2>{avatarsDescription[activeTab].title}</h2>
            <p>{avatarsDescription[activeTab].title_description}</p>
            <div className="video-container">{avatarsDescription[activeTab].video}</div>
          </div>
        </Col>
      </Row>
      {activeTab === 'photo' && (
        <Button
          type="primary"
          size="large"
          className="btn-get-started"
          onClick={() => (authStore.user ? setIsOpenPhotoAvatarModal(true) : navigate('/signup'))}
        >
          Create now
        </Button>
      )}
    </div>
  )
}

const avatarsDescription = {
  studio: {
    title: 'Studio Avatar',
    title_description:
      'Have you ever dreamed of having your Digital Avatar that looks the same as an actual human? Create Avatar from the studio footage!',
    cards_description: [
      {
        title: 'Highest quality',
        text: 'Our team of professionals will take care of proper color correction, remove a greenscreen background, enhance the quality and get the maximum out of your footage!',
      },
      {
        title: 'Requirements for studio footage',
        text: (
          <>
            Find more helpful tips and instructions on how to make a perfect video snippet by following the link{' '}
            <a target="_blank" href="https://elai.io/custom-avatar" rel="noreferrer">
              here
            </a>
            .
          </>
        ),
      },
      {
        title: '7-10 Business days',
        text: 'Studio Avatar creation usually requires manual work to make a good-looking talking presenter.',
      },
    ],
    video: (
      <iframe
        className="youtube-video"
        src="https://www.youtube.com/embed/mhgNuEErfIA"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    ),
  },
  selfie: {
    title: 'Selfie Avatar',
    title_description:
      'Have no opportunity to shoot the video footage for avatar in a studio? We’ve got a solution for you!',
    cards_description: [
      {
        title: 'No professional equipment needed',
        text: 'Generate your own Custom Avatar based only on the video snippet recorded on your smartphone or web camera.',
      },
      {
        title: 'Avatar in a few minutes',
        text: 'Create avatar right at your workplace or home just with 3 minutes footage.',
      },
      {
        title: 'Change your background',
        text: 'We will use AI to remove background from your video but you can still use your original video background.',
      },
    ],
    video: (
      <iframe
        className="youtube-video"
        src="https://www.youtube.com/embed/ZicEFFTikWk"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    ),
  },
  mascot: {
    title: 'Cartoon Mascot',
    title_description:
      'Do you have a drawn mascot of a fictional character? Let’s make it animated with Elai.io! This is exactly what you need to promote your business, grow its popularity and build a successful video marketing strategy.',
    cards_description: [
      {
        title: 'Animated character',
        text: 'With Elai.io, you can easily turn your best-drawn mascot into Talking Cartoon Avatar and use it for video creation.',
      },
      {
        title: '4 different looks',
        text: 'Upload an image of your mascot and our team will provide you with 4 different animated bodies that will be converted into fictional Avatars as well.',
      },
      {
        title: 'Full customization',
        text: 'We will customize your Cartoon Avatar according to your requirements before embedding it into your Avatar’s library.',
      },
    ],
    video: (
      <iframe
        className="youtube-video"
        src="https://www.youtube.com/embed/g_QOnmBGg5g"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    ),
  },
  photo: {
    title: 'Photo Avatar',
    title_description:
      'This is the best option for those who are keen on making videos with cartoon-looking animated avatars without any significant effort!',
    cards_description: [
      {
        title: 'Avatar from a photo',
        text: 'Photo Avatar allows you to get the Avatar of yourself just from a single photo without shooting in a studio. Just upload the portrait you want to bring to life.',
      },
      {
        title: 'Personalize your video',
        text: 'Enhance your video with visuals, colors, transitions, stickers, media, and more. Create a personalized touch for your video! Simply insert text to generate a video.',
      },
      {
        title: 'Select the voice you like or upload your audio',
        text: `Finally, once the voice-over and animated elements are added, it's time to hit the "Render" button and let the magic happen!`,
      },
    ],
    video: (
      <iframe
        className="youtube-video"
        src="https://www.youtube.com/embed/afeZWUNuS_c?si=pPL4eJTXtaLFHqyn"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    ),
  },
}
