import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout, Button, Carousel, Row, Col } from 'antd'
import { RightOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import './index.less'
import studio_avatar from '../../../../assets/images/public_custom_avatar/studio-avatar.png'
import animated_mascot from '../../../../assets/images/public_custom_avatar/animated-mascot.png'
import webcam_avatar from '../../../../assets/images/public_custom_avatar/webcam-avatar.png'
import personal_cartoon from '../../../../assets/images/public_custom_avatar/personal-cartoon.png'
import MainDescription from './description'
// import Instruction from './instruction'
import Voice from './voice'
import Purchase from './purchase'
import FAQ from '../components/faq/faq'
import ContactForm from '../components/form/form'
import CreatePhotoAvatarModal from '../../../../components/CreatePhotoAvatarModal/createPhotoAvatarModal'
import Examples from './examples'
import { useElaiNotification } from '../../../../hooks/useElaiNotification'

const tabsCards = [
  {
    id: 'studio',
    title: 'Studio Avatar',
    description:
      'Get a professional-looking Talking presenter that will be available only for you. It only requires high-quality studio video footage! ',
    image: studio_avatar,
  },
  {
    id: 'selfie',
    title: 'Selfie Avatar',
    description: 'Use a smartphone or web camera to create an animated Avatar of yourself in no time',
    image: webcam_avatar,
  },
  {
    id: 'mascot',
    title: 'Animated Mascot',
    description: 'Get your fully customized animated talking character',
    image: animated_mascot,
  },
  {
    id: 'photo',
    title: 'Photo Avatar',
    description:
      'Turn your ordinary photos into extraordinary talking avatars. Just upload your photo and bring it to life.',
    image: personal_cartoon,
  },
]

export default () => {
  const notification = useElaiNotification()
  const navigate = useNavigate()
  const [isOpenPhotoAvatarModal, setIsOpenPhotoAvatarModal] = useState(false)
  const isMobileView = document.body.clientWidth < 576

  const activeTab = useMemo(() => {
    const type = window.location.hash.replace('#', '')
    if (type && tabsCards.some((tab) => tab.id === type)) return type
    else {
      if (isMobileView) return false
      window.location.hash = '#selfie'
      return 'selfie'
    }
  }, [window.location.hash])

  const setActiveTab = (type) => {
    if (window.location.hash.replace('#', '') !== type) window.location.hash = type || ''
  }

  const navigateToAvatarsPage = () => {
    notification.destroy('photo-avatar-success')
    navigate('/avatars')
  }

  const activeCard = tabsCards.find((card) => card.id === activeTab)

  return (
    <Layout.Content className="public-avatars">
      <div className="content">
        <div className="header">
          {activeTab && <ArrowLeftOutlined onClick={() => setActiveTab(false)} />}
          <h1>Create your avatar</h1>
          <p style={{ textAlign: 'center', margin: '0 auto' }}>
            Elai has 4 different types of avatars. Choose the one that fits your needs best.
          </p>
        </div>
        <Row gutter={[18, 18]} className="tabs-container">
          {tabsCards.map((card) => (
            <Col key={card.id} lg={6} md={12} sm={12}>
              <div
                className={`tab-card ${card.id} ${activeTab === card.id ? 'active' : ''}`}
                onClick={() => !isMobileView && setActiveTab(card.id)}
              >
                <h3>{card.title}</h3>
                <p>{card.description}</p>
                <img src={card.image} className="card-image" alt={card.title}></img>
                <Button
                  type="primary"
                  icon={<RightOutlined />}
                  size="large"
                  className="btn-arrow"
                  onClick={() => setActiveTab(card.id)}
                >
                  See more
                </Button>
              </div>
            </Col>
          ))}
        </Row>
        <div className="tabs-container tabs-container-carousel">
          {activeTab ? (
            <div
              className={`tab-card ${activeCard.id} active`}
              onClick={() => !isMobileView && setActiveTab(activeCard.id)}
            >
              <h3>{activeCard.title}</h3>
              <p>{activeCard.description}</p>
              <img src={activeCard.image} className="card-image" alt={activeCard.title}></img>
              <Button
                type="primary"
                icon={<RightOutlined />}
                size="large"
                className="btn-arrow"
                onClick={() => setActiveTab(activeCard.id)}
              >
                See more
              </Button>
            </div>
          ) : (
            <Carousel>
              {tabsCards.map((card) => (
                <div
                  key={card.id}
                  className={`tab-card ${card.id}`}
                  onClick={() => !isMobileView && setActiveTab(card.id)}
                >
                  <h3>{card.title}</h3>
                  <p>{card.description}</p>
                  <img src={card.image} className="card-image" alt={card.title}></img>
                  <Button
                    type="primary"
                    icon={<RightOutlined />}
                    size="large"
                    className="btn-arrow"
                    onClick={() => setActiveTab(card.id)}
                  >
                    See more
                  </Button>
                </div>
              ))}
            </Carousel>
          )}
        </div>
      </div>
      {activeTab && (
        <div className="tabs-content">
          <MainDescription activeTab={activeTab} setIsOpenPhotoAvatarModal={setIsOpenPhotoAvatarModal} />
          <Examples activeTab={activeTab} />
          {/* <Instruction activeTab={activeTab} /> */}
          <Voice activeTab={activeTab} setIsOpenPhotoAvatarModal={setIsOpenPhotoAvatarModal} />
          {activeTab !== 'photo' && <Purchase avatar={activeTab} />}
          <FAQ activeTab={activeTab} />
          <ContactForm title="Custom Avatars" />
        </div>
      )}
      <CreatePhotoAvatarModal
        successMessage={
          <span>
            Your avatar is ready! Find it in your <a onClick={navigateToAvatarsPage}>avatars library</a> or just use it
            in existing video.
          </span>
        }
        isOpenPhotoAvatarModal={isOpenPhotoAvatarModal}
        setIsOpenPhotoAvatarModal={setIsOpenPhotoAvatarModal}
      />
    </Layout.Content>
  )
}
