import { useSlideDuration } from '../../../../../hooks/useSlideDuration'

export const useAnimationControlsState = (props) => {
  const { activeObjectProps, data, setActiveObjectModifier } = props

  const { getApproxDuration } = useSlideDuration(data)

  const onChangeTime = (v, type, marker) => {
    const value = { ...activeObjectProps.animation }
    const markerType = type === 'Enter' ? 'startMarker' : 'endMarker'
    const timeType = type === 'Enter' ? 'startTime' : 'endTime'
    const sceneType = type === 'Enter' ? 'startScene' : 'endScene'
    const durationType = type === 'Enter' ? 'duration' : 'exitDuration'
    if (marker) {
      if (v === 'start') {
        value[timeType] = 0
        value[markerType] = false
        value[sceneType] = v
      } else if (v === 'end') {
        value[timeType] = (data.duration || getApproxDuration(data)) - (value[durationType] || 0.4)
        value[markerType] = false
        value[sceneType] = v
      } else if (value[markerType] !== v) {
        value[timeType] = false
        value[markerType] = v
        value[sceneType] = false
        //set status of slide to 'edited' to drop audio cache
        setActiveObjectModifier({
          change: 'animation',
          value,
          linkedSlideChanges: { status: 'edited' },
        })
        return
      }
    } else {
      value[timeType] = v
      value[markerType] = false
      value[sceneType] = false
    }
    setActiveObjectModifier({
      change: 'animation',
      value,
    })
  }

  const onChangeDuration = (v, type) => {
    const durationType = [type === 'Enter' ? 'duration' : 'exitDuration']
    const sceneType = [type === 'Enter' ? 'startScene' : 'endScene']
    const timeType = [type === 'Enter' ? 'startTime' : 'endTime']
    const value = { ...activeObjectProps.animation, [durationType]: v }
    if (activeObjectProps.animation[sceneType] === 'end')
      value[timeType] = (data.duration || getApproxDuration(data)) - v
    setActiveObjectModifier({
      change: 'animation',
      value,
    })
  }

  const onCustomAnimationTurn = (checked) => {
    const endAnimation = activeObjectProps.meta?.interactivity ? { endTime: -1, exitType: 'fade_out' } : {}
    setActiveObjectModifier({
      change: 'animation',
      value: checked ? { type: data.animation || 'fade_in', startTime: 0, ...endAnimation } : {},
    })
  }

  const handleUpdateSlide = (animationId, type) => {
    const value = { ...activeObjectProps.animation, [type === 'Enter' ? 'type' : 'exitType']: animationId }
    if (type === 'Exit' && !value.endTime) value.endTime = -1
    setActiveObjectModifier({
      change: 'animation',
      value,
    })
  }
  const onChangeEasing = (v, type) => {
    const animation = activeObjectProps.animation
    const key = type === 'Enter' ? 'easing' : 'exitEasing'
    animation[key] = v
    setActiveObjectModifier({
      change: 'animation',
      value: animation,
    })
  }

  return {
    onChangeTime,
    onChangeEasing,
    onChangeDuration,
    handleUpdateSlide,
    onCustomAnimationTurn,
  }
}
