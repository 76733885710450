import { useState, useEffect, useRef, useMemo } from 'react'
import { Input, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import Lottie from 'lottie-web'
import { Scrollbars } from 'react-custom-scrollbars'
import { useElaiNotification } from '../../../../../hooks/useElaiNotification'

const LottieUploader = ({ lottieUrl, setLottieUrl, setActiveObjectModifier, video, slideCanvas }) => {
  const notification = useElaiNotification()
  const [lottieObjects, setLottieObjects] = useState([])
  const lottieContainer = useRef([])

  const getAllLottiesFromVideo = (video) =>
    video.slides
      .map((slide) => {
        if (slide.canvas) return slide.canvas.objects.filter((obj) => obj.type === 'lottie')
        else return []
      })
      .flat()
      .filter((obj, index, objects) => objects.findIndex((duplicate) => duplicate.src === obj.src) === index)

  const lottieNumber = useMemo(() => getAllLottiesFromVideo(video).length, [slideCanvas])

  useEffect(() => setLottieObjects(getAllLottiesFromVideo(video)), [lottieNumber])

  useEffect(() => {
    lottieObjects.forEach((lottie, index) => {
      const lottieDivContainer = lottieContainer.current[index]
      if (lottieDivContainer) lottieDivContainer.innerHTML = ''
      Lottie.loadAnimation({
        container: lottieDivContainer,
        path: lottie.src,
        autoplay: true,
      })
    })
  }, [lottieObjects])

  return (
    <div className="search-media">
      <div className="media-items-controls">
        <p>
          Upload free animations from{' '}
          <a href="https://lottiefiles.com/featured" target="_blank" rel="noreferrer">
            Lottiefiles
          </a>
          . Please <b>sign up</b> to see JSON urls.
        </p>
        <Input.Group compact>
          <Input
            placeholder="Lottie JSON URL"
            style={{ width: '73%' }}
            value={lottieUrl}
            onChange={(e) => setLottieUrl(e.target.value)}
          />
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              if (!lottieUrl) return notification.error({ message: 'Lottie URL is required' })
              if (!lottieUrl.endsWith('.json'))
                return notification.error({ message: `Lottie URL must end with '.json'` })
              setActiveObjectModifier({ newObject: 'lottie', url: lottieUrl })
              setLottieUrl(null)
            }}
          >
            Add
          </Button>
        </Input.Group>
      </div>
      <div className="search-media-wrapper">
        <Scrollbars className="scrollbar">
          {lottieObjects.length > 0 && (
            <div className="search-items-container">
              {lottieObjects.map((obj, i) => (
                <div
                  className="element-item"
                  ref={(el) => (lottieContainer.current[i] = el)}
                  onClick={() => setActiveObjectModifier({ newObject: 'lottie', url: obj.src })}
                />
              ))}
            </div>
          )}
        </Scrollbars>
      </div>
    </div>
  )
}

export default LottieUploader
