import { Button } from 'antd'
import React, { memo } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Scrollbars } from 'react-custom-scrollbars'

import SlideItem from './components/item'
import { useSlideListState } from './useSlideList'
import { TransitionModal } from './components/transitionModal'
import ShortcutTooltip from '../shortcutTooltip'

import './slideList.less'
import { useStore } from '../../../../store'
import { MAX_SLIDES_COUNT_PAID, MAX_SLIDES_COUNT_TRIAL } from '../../constants'

const plusIcon = <PlusOutlined />

const minHeight400 = { minHeight: 400 }

export const SlideList = (props) => {
  const {
    video,
    canvasRegistry,
    activeSlide,
    canvasActiveObject,
    updateVideo,
    updateActiveSlide,
    player,
    addSlide,
    deleteSlide,
    duplicateSlide,
  } = props

  const {
    moveSlide,
    transitionModal,
    activeTransition,
    setTransitionModal,
    setActiveTransition,
    handleSetTransition,
    applyTransitionToAllSlides,
    closeTransitionModal,
  } = useSlideListState({ video, updateVideo, updateActiveSlide, canvasActiveObject, activeSlide })

  const { user } = useStore((stores) => stores.authStore)
  const slidesLimit = user.account.status === 'trial' ? MAX_SLIDES_COUNT_TRIAL : MAX_SLIDES_COUNT_PAID

  const AddButton = memo(() => (
    <ShortcutTooltip title="Add slide" keyName="N" placement="top" showMetaKey={false}>
      <Button
        type="dashed"
        icon={plusIcon}
        size="large"
        style={{ width: '185px' }}
        className="btn-add"
        onClick={addSlide}
        disabled={video?.slides.length >= slidesLimit || !player.canvasReady}
        title={video?.slides.length >= slidesLimit ? 'Maximum slides limit reached' : ''}
      >
        Add slide
      </Button>
    </ShortcutTooltip>
  ))

  return (
    <>
      <Scrollbars className="slides-scrollbar scrollbar" style={minHeight400}>
        <div className="slides-container">
          {video.slides &&
            video.slides.map((slide, i) => (
              <SlideItem
                index={i}
                key={slide.id}
                slides={video.slides}
                slidesLimit={slidesLimit}
                duplicateSlide={duplicateSlide}
                moveSlide={moveSlide}
                activeSlide={activeSlide}
                deleteSlide={deleteSlide}
                videoFormat={video.data?.format}
                updateActiveSlide={updateActiveSlide}
                player={player}
                setTransitionModal={setTransitionModal}
                canvasRegistry={canvasRegistry}
              />
            ))}
        </div>
      </Scrollbars>
      {!player.activePreview && video.status !== 'validating' && <AddButton />}
      <TransitionModal
        transitionModal={transitionModal}
        activeTransition={activeTransition}
        handleSetTransition={handleSetTransition}
        applyTransitionToAllSlides={applyTransitionToAllSlides}
        setActiveTransition={setActiveTransition}
        closeTransitionModal={closeTransitionModal}
      />
    </>
  )
}
