import { useMemo } from 'react'
import { Row, Col, Select } from 'antd'
import Icon from '../../../../../components/Icon'

const SlideSelect = ({ slides, activeSlide, title, value, disabled, handleChangeSlide }) => {
  const isLastSlide = activeSlide === slides.length - 1

  const selectSlideOptions = useMemo(() => {
    let options = slides.map((slide, index) => ({
      value: slide.id,
      label: `Slide ${index + 1}`,
    }))
    if (!isLastSlide) {
      options = [{ value: 'next', label: 'Next slide' }, ...options]
    } else {
      options = [...options, { value: 'end', label: 'End video' }]
    }
    return options
  }, [slides.length, activeSlide])
  const defaultLabel = isLastSlide ? 'End video' : 'Next slide'
  const defaultValue = isLastSlide ? 'end' : 'next'
  const currentSlideOption = selectSlideOptions.find((option) => option.value === value) ?? {}
  const currentValue = value ?? defaultValue
  const currentLabel = currentSlideOption.label ?? defaultLabel

  return (
    <Row align="middle" justify="space-between" className="slide-select-wrapper">
      <Col>
        <span className="slide-select-title">{title}</span>
      </Col>
      <Col>
        <Select
          value={{
            value: currentValue,
            label: (
              <span className="label-wrapper">
                <Icon name="envelope" className="label-icon" />
                <span>{currentLabel}</span>
              </span>
            ),
          }}
          disabled={disabled}
          labelInValue={true}
          className="slide-select"
          suffixIcon={<Icon name="down_arrow" />}
          getPopupContainer={(triggerNode) => triggerNode}
          onChange={(e) => handleChangeSlide(['next', 'end'].includes(e.value) ? null : e.value)}
          options={selectSlideOptions}
        />
      </Col>
    </Row>
  )
}

export default SlideSelect
