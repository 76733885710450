import { useNavigate } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { useStore } from '../../../../store'

const excludeFromRedirect = ['/poll', '/login', '/signup', '/auth']

const useNavigateToAnotherPage = ({ isVideoSaved, navigateUrl, videoId }) => {
  const navigate = useNavigate()

  const [isLeavingPopconfirmOpen, setIsLeavingPopconfirmOpen] = useState(false)

  const { navigationHistory } = useStore((stores) => stores.navigationStore)

  /**
   * Get the latest navigation entry that does not include URL parameter, e.g., videoId
   * @type {function(*): *}
   */
  const getLastLocationBeforeExistingUrlParam = useCallback(
    (urlParam) =>
      navigationHistory.findLast(
        (element) => !element.includes(`/${urlParam}`) && excludeFromRedirect.every((excl) => !element.includes(excl)),
      ),
    [navigationHistory],
  )

  const handleNavigate = (confirm, shouldNavigateToUrl) => {
    if (!isVideoSaved && !confirm) {
      setIsLeavingPopconfirmOpen(true)
      return
    }
    window.removeEventListener('beforeunload', promptForUnsavedChanges)
    if (shouldNavigateToUrl) navigate(navigateUrl)
    else navigate(getLastLocationBeforeExistingUrlParam(videoId) ?? navigateUrl)
  }

  const promptForUnsavedChanges = useCallback((event) => {
    // Safari requires returnValue to be set to display the prompt
    return (event.returnValue = 'You have unsaved changes. Are you sure you want to leave?')
  }, [])

  useEffect(() => {
    if (!isVideoSaved) window.addEventListener('beforeunload', promptForUnsavedChanges)
    else window.removeEventListener('beforeunload', promptForUnsavedChanges)
  }, [isVideoSaved])

  return {
    isLeavingPopconfirmOpen,
    setIsLeavingPopconfirmOpen,
    handleNavigate,
  }
}

export { useNavigateToAnotherPage }
