import Icon from '../../../Icon'
import { OPTIONS } from './constants'

export const CreateOptions = (props) => {
  const { currentOption, setOption } = props

  const onTopicSelect = () => {
    setOption(OPTIONS.topic)
  }
  const onURLSelect = () => {
    setOption(OPTIONS.url)
  }
  const onTextSelect = () => {
    setOption(OPTIONS.text)
  }
  const onScratchSelect = () => {
    setOption(OPTIONS.scratch)
  }

  return (
    <div className="story-row">
      <div
        className={`option-card-story ${currentOption === OPTIONS.topic ? 'story-active' : ''}`}
        onClick={onTopicSelect}
      >
        <div className="icon-container">
          <Icon name="edit_chat" />
        </div>
        <p>From topic</p>
      </div>
      <div className={`option-card-story ${currentOption === OPTIONS.url ? 'story-active' : ''}`} onClick={onURLSelect}>
        <div>
          <Icon name="link" />
        </div>
        <p>From URL</p>
      </div>
      <div
        className={`option-card-story ${currentOption === OPTIONS.text ? 'story-active' : ''}`}
        onClick={onTextSelect}
      >
        <div>
          <Icon name="document" />
        </div>
        <p>From text</p>
      </div>
      <div
        className={`option-card-story ${currentOption === OPTIONS.scratch ? 'story-active' : ''}`}
        onClick={onScratchSelect}
      >
        <div>
          <Icon name="document_layout" />
        </div>
        <p>From scratch</p>
      </div>
    </div>
  )
}
