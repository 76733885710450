import { useNavigate } from 'react-router-dom'
import { useState, useMemo } from 'react'
import { Row, Col, Modal, Button, Select } from 'antd'

import { useStore } from '../store'

import { useElaiNotification } from '../hooks/useElaiNotification'
import { requestTranslateVideo } from '../utils/videoCreation/videoCreation'

const flagsUrl = 'https://d3u63mhbhkevz8.cloudfront.net/flags/'

export default (props) => {
  const { video, isOpen, setIsOpen } = props

  const [loading, setLoading] = useState(false)
  const [language, setLanguage] = useState({ name: null, code: null, voices: null })

  const navigate = useNavigate()
  const notification = useElaiNotification()

  const voices = useStore((stores) => stores.voicesStore.voices)

  const translate = async () => {
    setLoading(true)
    const translated = await requestTranslateVideo({
      videoId: video._id,
      data: { language },
    })
    if (translated?.status === 206) {
      notification.success({
        message:
          'Seems like video translation is taking longer time than expected. Please check your videos page in 1-2 minutes - your video should appear there.',
        duration: 0,
      })
    } else if (translated?._id) {
      navigate(`/video/${translated._id}`)
    }
    setLoading(false)
    setIsOpen(false)
  }

  const relevantVoices = useMemo(() => {
    if (!video) {
      return []
    }
    const videoLanguages = [...new Set(video.slides.filter((s) => s.voiceType === 'text').map((s) => s.language))]
    return voices.filter(
      (language, idx) =>
        videoLanguages.indexOf(language.name) === -1 && voices.findIndex((v) => v.name === language.name) === idx,
    )
  }, [video])

  const onChangeLanguage = (lang) => {
    const voice = voices.find((voice) => voice.name === lang)
    setLanguage({
      name: lang,
      code: voice['male'][0].locale.split('-').slice(0, 2).join('-'),
      voices: { male: voice['male'][0].voice, female: voice['female'][0].voice },
    })
  }
  const handleClose = () => {
    setIsOpen(false)
  }

  if (!video) return null
  return (
    <Modal
      title={`Translate ${video.name}`}
      centered
      forceRender
      open={isOpen}
      width={500}
      bodyStyle={{ padding: 15 }}
      footer={
        <Button
          type="primary"
          key="translate"
          onClick={translate}
          disabled={loading || !language.name}
          loading={loading}
        >
          Translate
        </Button>
      }
      onCancel={handleClose}
    >
      <Row gutter={12}>
        <Col xs={24}>
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Select a language"
            className="language-select"
            value={language.name}
            onChange={onChangeLanguage}
            filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {relevantVoices.map((language) => (
              <Select.Option key={language.name.toLowerCase()} value={language.name} className="language-select-option">
                <img
                  src={`${flagsUrl}${language.male[0].icon}.svg`}
                  width="20"
                  className="flag-icon"
                  style={{ marginRight: 10 }}
                  alt={language.name}
                />
                <span>{language.name}</span>
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
    </Modal>
  )
}
