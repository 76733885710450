import { useEffect, useRef, useState } from 'react'
import Icon from '../../../../components/Icon'
import { request } from '../../../../utils/api'
import { useStore } from '../../../../store'
import { requestCopyVideoToAdmin } from '../../../../utils/videoCreation/videoCreation'

export const ratioOptions = [
  {
    label: '16:9',
    value: '16_9',
    icon: <Icon name="monitor" />,
  },
  {
    label: '9:16',
    value: '9_16',
    icon: <Icon name="mobile" />,
  },
  {
    label: '1:1',
    value: '1_1',
    icon: <Icon name="focus" />,
  },
]

export const INLINE_STYLES = {
  infoIcon: { marginLeft: 20, fontSize: 18, color: '#f3f3f3', display: 'inline-flex' },
  marginTop10: { marginTop: 10 },
  icons: { color: '#f3f3f3', fontSize: 18 },
}

export const useVideoHeaderState = (props) => {
  const { id, video, navigate, fetchVideo, editingName, updateVideo, saveVideoName, setEditingName } = props

  const [videoNameState, setVideonameState] = useState(video.name)
  const inputRef = useRef()

  const authStore = useStore((stores) => stores.authStore)

  const inputKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSaveName()
    }
  }

  const handleSaveName = () => {
    saveVideoName(videoNameState)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      // contains crash the app
      if (editingName) {
        if (event.target.id !== inputRef.current.input.id && event.target.className !== 'video-name-text') {
          handleSaveName()
        }
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [editingName, handleSaveName])

  // as we don't have input before we allow to edit name
  useEffect(() => {
    if (editingName) {
      inputRef.current.focus()
    }
  }, [editingName])

  const handleChange = (event) => {
    setVideonameState(event.target.value)
  }

  const allowEditName = () => {
    setEditingName(true)
  }

  const handleUpdateVideo = (event) => {
    updateVideo({
      data: {
        ...video.data,
        format: event,
      },
    })
  }

  const handleCopyVideoToAdmin = () => {
    requestCopyVideoToAdmin(video, authStore.user, authStore.logout)
  }

  const userEmailOrAdmin = video.userEmail || authStore.checkAdminLoginedAsUser()

  const loginAsUser = async () => {
    await authStore.adminLoginAs({ accountId: video.accountId })
    navigate('/')
  }

  const approveVideo = async () => {
    await request({ method: 'post', url: `/admin/videos/approve/${id}` })
    await fetchVideo()
  }

  const declineVideo = async () => {
    await request({ method: 'post', url: `/admin/videos/decline/${id}` })
    await fetchVideo()
  }

  return {
    handleUpdateVideo,
    allowEditName,
    handleCopyVideoToAdmin,
    userEmailOrAdmin,
    loginAsUser,
    approveVideo,
    declineVideo,
    videoNameState,
    handleChange,
    handleSaveName,
    authStore,
    inputKeyPress,
    inputRef,
  }
}
