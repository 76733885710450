import { useRef } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { Row, Empty, Col, Card, Spin } from 'antd'

import SlidesCarousel from '../../SlidesCarousel'
import useIntersection from '../../../hooks/useIntersection'

import { defaultBlankVideoName } from '../../../utils/constants'

import blank_icon from '../../../assets/images/video_creation_modal/neyson_thumbnail.jpg'

const { Meta } = Card

const STYLES = {
  padding0: { padding: 0 },
  flex: { display: 'flex' },
  height10: { height: '10px' },
  emptyImage: { margin: '20% auto' },
  link: { display: 'block', width: '100%', height: '100%' },
}

export const TemplatesList = (props) => {
  const {
    isVideoCreating,
    templatesListData,
    setOpenedTemplate,
    searchedTemplates,
    showMoreTemplatesData,
    createBlankVideo,
  } = props

  const observerTarget = useRef(null)
  useIntersection(observerTarget, showMoreTemplatesData)

  return (
    <Scrollbars
      id="templatesScrollbar"
      className={`template-scrollbar ${window.location.hash === '#regular' ? 'regular' : ''}`}
    >
      <Row gutter={[16, 16]} className="templates-container">
        {searchedTemplates?.length === 0 ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={STYLES.emptyImage} />
        ) : (
          <>
            {templatesListData.map((v) => (
              <Col key={v._id} style={STYLES.flex} xs={12} sm={8} md={6}>
                <Card
                  className={`template-card ${v.name === defaultBlankVideoName ? 'blank' : ''} ${
                    v.data?.format === '9_16' ? 'vertical' : ''
                  } ${v.data?.format === '1_1' ? 'square' : ''}`}
                  bodyStyle={STYLES.padding0}
                  onClick={() => (v.template ? setOpenedTemplate(v) : createBlankVideo())}
                >
                  <>
                    {window.location.hash === '#regular' && <Meta title={v.name} className="card-head" />}
                    {v.template ? (
                      <SlidesCarousel
                        collection={{
                          ...v,
                          items: v.slides.map((slide) => ({ ...slide, src: slide.screenshot })),
                        }}
                        index={v._id}
                      />
                    ) : isVideoCreating ? (
                      <Spin />
                    ) : (
                      <img src={blank_icon} alt="create video from scratch icon" />
                    )}
                  </>
                </Card>
              </Col>
            ))}
          </>
        )}
        <Col ref={observerTarget} style={STYLES.height10}></Col>
      </Row>
    </Scrollbars>
  )
}
