import { Button, Space, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

// TODO: better move css files i guess
const STYLE = {
  input: { marginBottom: 8, display: 'block' },
  width90: { width: 90 },
}

/**
 *
 * @param {string} dataIndex pass field presented in object for search in column
 * @returns Object for antd.table column to filter by provided field
 */
export const getColumnSearchProps = (dataIndex) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
    const onChange = (event) => {
      const result = event.target.value ? [event.target.value] : []
      setSelectedKeys(result)
    }
    const onClick = () => {
      clearFilters()
      confirm()
    }
    return (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={onChange}
          onPressEnter={confirm}
          style={STYLE.input}
        />
        <Space>
          <Button type="primary" onClick={confirm} icon={<SearchOutlined />} size="small" style={STYLE.width90}>
            Search
          </Button>
          <Button onClick={onClick} size="small" style={STYLE.width90}>
            Reset
          </Button>
        </Space>
      </div>
    )
  },
  filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) => record[dataIndex] && record[dataIndex].toLowerCase().includes(value.toLowerCase()),
})
