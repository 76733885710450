import Scrollbars from 'react-custom-scrollbars'
import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { UploadOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Upload, Button, Select, Checkbox, Tooltip, Alert } from 'antd'

import { useStore } from '../../../../store'
import PptxFontsPromptModal from './pptxFontsPromptModal'
import { requestUploadPptx } from '../../../../utils/videoCreation/videoCreation'
import { useElaiNotification } from '../../../../hooks/useElaiNotification'
import Icon from '../../../Icon'
import './createVideoFromPresentation.less'

const { Dragger } = Upload

const isDevelopment = process.env.NODE_ENV === 'development'

const style = {
  createButton: { marginBottom: 30 },
}

const allowedMimeTypes = [
  'application/pdf',
  'image/svg+xml',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  // 'application/msword',
]

const allowedExtensions = ['pptx', 'ppt', 'pdf']
if (isDevelopment) allowedExtensions.push('svg')

export const CreateVideoFromPresentation = (props) => {
  const { pdfFile, setPdfFile, setIsVideoCreating, isVideoCreating, setIsNewVideoModalOpen } = props
  const notification = useElaiNotification()

  const navigate = useNavigate()
  const { folderId } = useParams()
  const [showFontPrompt, setShowFontPrompt] = useState(false)
  const [pptxCreationResult, setPptxCreationResult] = useState(null)
  const [showPptxWarning, setShowPptxWarning] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState(localStorage.getItem('pptxCreationTemplatedId'))
  const [staticMode, setStaticMode] = useState(null)
  const scrollbarsRef = useRef(null)
  const [privateTemplates, setPrivateTemplates] = useState([{ name: 'Create new template...', _id: 'new' }])

  const { templates } = useStore((stores) => stores.videosStore)

  const closeVideoModal = () => {
    setIsVideoCreating(false)
    setIsNewVideoModalOpen(false)
  }

  const handleErrors = (error) => {
    if (error.response.data?.context?.missedFonts) {
      setPptxCreationResult(error.response.data.context)
      setShowFontPrompt(true)
      return
    }
    if (error.response?.data?.message) notification.error({ message: error.response?.data?.message, duration: 0 })
    else if (error.message) notification.error({ message: error.message, duration: 0 })
    setIsVideoCreating(false)
  }

  const createVideoFromPresentation = async (mode) => {
    const fileExt = pdfFile.name.split('.').pop()
    setIsVideoCreating(true)
    setShowFontPrompt(false)

    // if (fileExt.toLowerCase() === 'pdf') convertImagesFromPdf(pdfFile, setConvertedImages, setIsVideoCreating)
    if (allowedExtensions.includes(fileExt.toLowerCase()) || mode === 'ignoreFonts') {
      if (fileExt.toLowerCase().includes('ppt') && !mode && !staticMode) {
        notification.warning({
          key: 'ppt-limitations',
          message:
            'Please note that the PPT/PPTX converter has limitations. If you encounter any issues in your video, try converting the slides to static images.',
          duration: 0,
        })
      }
      const result = await requestUploadPptx({
        mode: staticMode ?? mode,
        folderId,
        file: pdfFile,
        urlPath: mode === 'ignoreFonts' ? pptxCreationResult?.urlPath : undefined,
        templateId: localStorage.getItem('pptxCreationTemplateId') ?? selectedTemplate,
        customErrorHandler: handleErrors,
      })
      notification.destroy('ppt-limitations')
      if (result?.status === 206) {
        notification.success({
          message:
            'Seems like file conversion is taking longer time than expected. Please check your videos page in 1-2 minutes - your video should appear there.',
          duration: 0,
        })
        window.location.hash = ''
        closeVideoModal()
      } else if (typeof result === 'string') {
        setPdfFile(null)
        setPptxCreationResult(null)
        navigate(`/video/${result}`)
        closeVideoModal()
      }
    }
  }

  const handleSelectTemplate = (value) => {
    if (value === 'new') {
      setIsNewVideoModalOpen(false)
      navigate('/templates')
      return
    } else if (value === 'remove') value = undefined

    if (value) {
      localStorage.setItem('pptxCreationTemplateId', value)
    } else {
      localStorage.removeItem('pptxCreationTemplateId')
    }

    setSelectedTemplate(value)
  }

  const handleStaticModeChange = (e) => setStaticMode(e.target.checked ? 'static' : null)

  const beforeUpload = (file) => {
    if (file.size / 1024 / 1024 > 30) return notification.error({ message: 'File must be smaller than 30MB' })
    if (!allowedMimeTypes.includes(file.type)) {
      return notification.error({ message: 'File should be in PDF, PPT or PPTX format.' })
    }
    setPdfFile(file)
    return false
  }

  useEffect(() => {
    const privateTemplates = templates?.filter((t) => t?.template?.private === true)
    if (privateTemplates && privateTemplates.length) {
      setPrivateTemplates([{ name: 'Remove template...', _id: 'remove' }, ...privateTemplates])
    } else {
      // hack to show link when no templates
      setPrivateTemplates([{ name: 'Create template...', _id: 'new' }])
    }
  }, [templates])

  useEffect(() => {
    if (pdfFile?.name.endsWith('.pptx') && !staticMode) {
      setShowPptxWarning(true)
    } else {
      setShowPptxWarning(false)
    }
    let timerId
    // eslint-disable-next-line no-unused-vars
    if (pdfFile) timerId = setTimeout(() => scrollbarsRef.current.scrollToBottom(), 500)
    return () => {
      timerId = null
    }
  }, [pdfFile, staticMode])

  return (
    <Scrollbars className="template-scrollbar from-pdf" ref={scrollbarsRef}>
      <div className="story-card">
        <div className="text-container">
          <h2>Create video from presentation in a few clicks</h2>
          <ul>
            <li>
              For PPTX/PPT we will convert each presentation object into dynamic object on your video. Notes will be
              converted to speech text.
            </li>
            <li>
              Please note that PPTX/PPT converter can't guarantee proper convertion of any slide structure. If you
              encounter any issues in your video, try converting the slides to static images.
            </li>
            <li>For PDF each slide will be converted to video slide background as a static image.</li>
          </ul>
          <Dragger
            name="file"
            accept={allowedExtensions.map((ext) => `.${ext}`).join(',')}
            multiple={false}
            fileList={pdfFile ? [pdfFile] : []}
            showUploadList={{ showRemoveIcon: false }}
            beforeUpload={beforeUpload}
            className="pdf-uploader"
          >
            <p className="ant-upload-drag-icon">
              <UploadOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload.
              <br />
              File must be smaller than 30MB
            </p>
          </Dragger>
          {showPptxWarning && (
            <Alert
              closable
              showIcon
              type="warning"
              message="PPTX conversion is not fully supported. We recommend converting to PPT before proceeding or converting the slides to static images."
            />
          )}
          <div>
            <div className="select-template-label">
              Select template
              <Tooltip
                title={
                  <>
                    You can use your own template to adjust your PPT-based videos at scale. Please{' '}
                    <a
                      href="https://elai.freshdesk.com/support/solutions/articles/73000639566-create-video-from-template"
                      target="_blank"
                      rel="noreferrer"
                    >
                      read here
                    </a>{' '}
                    to see how it works.
                  </>
                }
              >
                <InfoCircleOutlined />
              </Tooltip>
            </div>
            <div className="controls-wrapper">
              <Select
                allowClear
                placeholder={
                  <span style={{ color: '#31353B' }}>
                    <Icon name="vector" /> No template{' '}
                  </span>
                }
                value={selectedTemplate}
                options={privateTemplates.map((v) => ({ label: v.name, value: v._id }))}
                onChange={handleSelectTemplate}
              />
              {pdfFile && pdfFile.type !== 'application/pdf' && (
                <div>
                  <Checkbox onChange={handleStaticModeChange}>Convert to static images</Checkbox>
                  <Tooltip title="When enabled, PPTX slides will be converted to static images, similar to PDFs. Use this option if you encounter issues with object conversion (e.g., missing elements or formatting problems). Please note that individual slide elements cannot be modified after conversion.">
                    <InfoCircleOutlined />
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
          <Button
            type="primary"
            style={style.createButton}
            disabled={!pdfFile}
            loading={isVideoCreating}
            onClick={() => createVideoFromPresentation()}
          >
            Create video
          </Button>
        </div>
      </div>
      <PptxFontsPromptModal
        showFontPrompt={showFontPrompt}
        setShowFontPrompt={setShowFontPrompt}
        setIsVideoCreating={setIsVideoCreating}
        createVideoFromPresentation={createVideoFromPresentation}
        missedFonts={pptxCreationResult?.missedFonts}
      />
    </Scrollbars>
  )
}
