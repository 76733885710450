import { Button, Popover } from 'antd'
import Icon from '../../../../../components/Icon'
import { os } from '../../../constants'
import { osMap } from '../../../../../utils/constants'
import './shortcutPopover.less'

const ALIGN = {
  points: ['bl', 'bl'],
  offset: [60, 0],
}

const ShortcutsPopover = ({
  children,
  shortcutsPopoverContentRef,
  isOpenShortcutsPopover,
  onCloseShortcutsPopover,
}) => {
  const modifierKey = os === osMap.MAC ? <Icon name="mac_command" /> : 'Ctrl'

  return (
    <Popover
      open={isOpenShortcutsPopover}
      placement="leftBottom"
      arrow={false}
      align={ALIGN}
      overlayClassName="shortcut-popover"
      destroyTooltipOnHide
      content={
        <div
          ref={shortcutsPopoverContentRef}
          className={`popover-content-wrapper ${os === osMap.MAC ? 'mac' : 'windows'}`}
        >
          <div className="shortcut-popover-title">
            <h3>Shortcuts</h3>
            <Button type="text" size="small" className="btn-close" onClick={onCloseShortcutsPopover}>
              <Icon name="close_simple" />
            </Button>
          </div>
          <div className="shortcuts-section">
            <h4>General</h4>
            <div className="shortcuts-wrapper">
              <div className="shortcut-item">
                <span className="title-text">Copy slide or object</span>
                <span className="key-wrapper">
                  <span>{modifierKey}</span>
                </span>
                <span className="key-wrapper">
                  <span>C</span>
                </span>
              </div>
              <div className="shortcut-item">
                <span className="title-text">Paste slide or object</span>
                <span className="key-wrapper">
                  <span>{modifierKey}</span>
                </span>
                <span className="key-wrapper">
                  <span>V</span>
                </span>
              </div>
              <div className="shortcut-item">
                <span className="title-text">Delete slide or object</span>
                <span className="key-wrapper full-width">
                  <span>{os === osMap.MAC ? 'DEL' : 'BCKSP'}</span>
                </span>
              </div>
              <div className="shortcut-item">
                <span className="title-text">Undo</span>
                <span className="key-wrapper">
                  <span>{modifierKey}</span>
                </span>
                <span className="key-wrapper">
                  <span>Z</span>
                </span>
              </div>
            </div>
          </div>
          <div className="shortcuts-section">
            <h4>Editor</h4>
            <div className="shortcuts-wrapper">
              <div className="shortcut-item">
                <span className="title-text">Choose multiple objects</span>
                <span className="key-wrapper full-width">
                  <span>SHIFT</span>
                </span>
              </div>
              <div className="shortcut-item">
                <span className="title-text">Group objects</span>
                <span className="key-wrapper">
                  <span>{modifierKey}</span>
                </span>
                <span className="key-wrapper">
                  <span>G</span>
                </span>
              </div>
              <div className="shortcut-item">
                <span className="title-text">Ungroup objects</span>
                <span className="key-wrapper">
                  <span>{modifierKey}</span>
                </span>
                <span className="key-wrapper full-width">
                  <span>SHIFT</span>
                </span>
                <span className="key-wrapper">
                  <span>G</span>
                </span>
              </div>
              <div className="shortcut-item">
                <span className="title-text">Disable object snapping</span>
                <span className="key-wrapper full-width">
                  <span>SHIFT</span>
                </span>
              </div>
              <div className="shortcut-item">
                <span className="title-text">Move objects</span>
                <span className="key-wrapper">
                  <span>
                    <Icon name="key_arrow_left" />
                  </span>
                </span>
                <span className="key-wrapper">
                  <span>
                    <Icon name="key_arrow_bottom" />
                  </span>
                </span>
                <span className="key-wrapper">
                  <span>
                    <Icon name="key_arrow_top" />
                  </span>
                </span>
                <span className="key-wrapper">
                  <span>
                    <Icon name="key_arrow_right" />
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div className="shortcuts-section">
            <h4>Slide</h4>
            <div className="shortcuts-wrapper">
              <div className="shortcut-item">
                <span className="title-text">Add slide</span>
                <span className="key-wrapper full-width">
                  <span>N</span>
                </span>
              </div>
              <div className="shortcut-item">
                <span className="title-text">Duplicate slide</span>
                <span className="key-wrapper">
                  <span>{modifierKey}</span>
                </span>
                <span className="key-wrapper">
                  <span>D</span>
                </span>
              </div>
            </div>
          </div>
          <div className="shortcuts-section">
            <h4>Other</h4>
            <div className="shortcuts-wrapper">
              <div className="shortcut-item">
                <span className="title-text">Play / Pause slide preview</span>
                <span className="key-wrapper full-width">
                  <span>SPACE</span>
                </span>
              </div>
              <div className="shortcut-item">
                <span className="title-text">Text line break</span>
                <span className="key-wrapper full-width">
                  <span>ENTER</span>
                </span>
              </div>
              <div className="shortcut-item">
                <span className="title-text">Cut speech text</span>
                <span className="key-wrapper">
                  <span>{modifierKey}</span>
                </span>
                <span className="key-wrapper">
                  <span>X</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      }
    >
      {children}
    </Popover>
  )
}

export default ShortcutsPopover
