import { SaveOutlined, LoadingOutlined } from '@ant-design/icons'
import Icon from '../../components/Icon'
import { getOS } from '../../utils/helpers'

export const HEADER_STYLE = {
  marginRight20: { marginRight: 20 },
  checkIcon: { fontSize: '14px', color: '#3bad5f', paddingRight: 8 },
}

export const MAIN_AREA_STYLE = { minWidth: '660px', width: '100%', padding: '0 10px' }

export const MASK_STYLE = { backgroundColor: 'rgba(0,0,0,0.9)' }
export const WARNING_MODAL_BUTTON_STYLE = { float: 'left', margin: '16px 0 0 34px' }

export const UNSAVED_VIDEO_STATUSES = ['validating', 'rendering']

export const SHAPES_TYPES = ['rect', 'circle', 'triangle', 'ellipse']

export const PLAY_ICON = <Icon name="play" />
export const PAUSE_ICON = <Icon name="pause" />
export const SAVE_OUTLINED_ICON = <SaveOutlined />
export const ADD_VIDEO_ICON = <Icon name="add_video" />
export const LOADING_OUTLINED_ICON = <LoadingOutlined />
export const VIDEO_CAMERA_ICON = <Icon name="video_camera" />

export const os = getOS()

export const keyMap = { DELETE: 46, BACKSPACE: 8 }

export const THUMBNAIL_MODES = {
  REGULAR: 'regular',
  TEMPLATE: 'template',
}

export const MAX_SLIDES_COUNT_TRIAL = 3
export const MAX_SLIDES_COUNT_PAID = 100
