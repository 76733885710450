export const fontFamilies = [
  'Anek Bangla',
  'Archivo',
  'Arimo',
  'Arial',
  'Arial Black',
  'Arial Narrow',
  'Arial Unicode MS',
  'Averia Serif Libre',
  'Azeret Mono',
  'Balsamiq Sans',
  'Bellota',
  'Bodoni Moda',
  'Black Han Sans',
  'Brush Script MT',
  'Catamaran',
  'Caveat',
  'Comic Neue',
  'Comic Sans MS',
  'Cormorant Infant',
  'Courier',
  'Courier New',
  'Cousine',
  'Cuprum',
  'DM Mono',
  'DM Sans',
  'Epilogue',
  'Exo',
  'Expletus Sans',
  'Fahkwang',
  'Galada',
  'Genos',
  'Georgia',
  'Glory',
  'Gluten',
  'Grandstander',
  'Great Vibes',
  'Hi Melody',
  'Hind Madurai',
  'Impact',
  'Inter',
  'JetBrains Mono',
  'Josefin Sans',
  'Josefin Slab',
  'Kufam',
  'Lato',
  'Lobster Two',
  'Mada',
  'Microsoft Sans Serif',
  'Mohave',
  'Montserrat',
  'Montserrat Alternates',
  'Noto Serif',
  'Noto Serif Display',
  'Nunito',
  'PT Sans',
  'PT Serif',
  'Papyrus',
  'Poppins',
  'Prompt',
  'Raleway',
  'Roboto',
  'Rockwell',
  'Sansita Swashed',
  'Source Serif Pro',
  'Sora',
  'Spectral SC',
  'Syne',
  'Tahoma',
  'Times New Roman',
  'Trebuchet MS',
  'Varela',
  'Varela Round',
  'Verdana',
  'Work Sans',
]
