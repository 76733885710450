import React, { memo } from 'react'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Input, Button, Tooltip, Space, Segmented } from 'antd'

import { ratioOptions, useVideoHeaderState, INLINE_STYLES } from './useVideoHeader'
import Icon from '../../../../components/Icon'
import VideoBackButton from './videoBackButton'
import EditAsStoryButton from './editAsStoryButton'

// jsx as a props equal to creating new component instead just passing it down
const checkedOutline = <CheckOutlined />
const closedOutline = <CloseOutlined />
const logoutIcon = <Icon name="logout" style={INLINE_STYLES.icons} />
const iconCopy = <Icon name="copy" style={INLINE_STYLES.icons} />

export const VideoHeader = (props) => {
  const {
    video,
    editingName,
    updateVideo,
    setEditingName,
    saveVideoName,
    id,
    navigate,
    fetchVideo,
    headerExtra,
    playing,
    isVideoSaved,
  } = props

  // Keep all busines logic out of component
  const {
    handleUpdateVideo,
    allowEditName,
    handleCopyVideoToAdmin,
    userEmailOrAdmin,
    loginAsUser,
    approveVideo,
    declineVideo,
    videoNameState,
    handleChange,
    authStore,
    inputKeyPress,
    inputRef,
  } = useVideoHeaderState({
    id,
    video,
    navigate,
    fetchVideo,
    updateVideo,
    editingName,
    saveVideoName,
    setEditingName,
  })

  const RatioComponent = memo(
    () => (
      <div className={`header-center-wrapper ${video.template ? 'template' : ''}`}>
        {!playing && (
          <Segmented value={video.data?.format || '16_9'} options={ratioOptions} onChange={handleUpdateVideo} />
        )}
      </div>
    ),
    [video.data?.format],
  )

  return (
    <div className="video-header">
      <div className="video-name-wrapper" style={{ width: playing ? 'auto' : '30%' }}>
        <div className="video-name">
          <VideoBackButton video={video} isVideoSaved={isVideoSaved} />
          {editingName ? (
            <Input
              id="header-name"
              ref={inputRef}
              className="video-name-input"
              maxLength={100}
              value={videoNameState}
              onChange={handleChange}
              onKeyDown={inputKeyPress}
            />
          ) : (
            <span className="video-name-text" onClick={allowEditName}>
              {video.name}
            </span>
          )}
          <EditAsStoryButton video={video} isVideoSaved={isVideoSaved} />
        </div>
        {userEmailOrAdmin && (
          <Space size="small" style={INLINE_STYLES.marginTop10}>
            <Tooltip title="Login as user">
              <Button type="ghost" icon={logoutIcon} onClick={loginAsUser} />
            </Tooltip>
            <Tooltip title="Copy video to admin account">
              <Button type="ghost" icon={iconCopy} onClick={handleCopyVideoToAdmin} />
            </Tooltip>
            <h3 className="user-email-admin">{video.userEmail || authStore.user.email}</h3>
          </Space>
        )}
        {authStore.user.role === 'admin' && video.status === 'moderation' && (
          <Space style={INLINE_STYLES.marginTop10}>
            <Button key="approve" icon={checkedOutline} onClick={approveVideo}>
              Approve
            </Button>
            <Button key="decline" icon={closedOutline} danger onClick={declineVideo}>
              Decline
            </Button>
          </Space>
        )}
      </div>
      <RatioComponent />
      <Space className="header-extra">{headerExtra}</Space>
    </div>
  )
}
