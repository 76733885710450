import { Modal, Typography, Button } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import successIcon from '../../../assets/images/success-icon.svg'

const SuccessPaymentModal = ({ avatarId, isOpenSuccessModal, setIsOpenSuccessModal }) => {
  const navigate = useNavigate()

  const handleClickCreate = () => {
    navigate(avatarId ? `/avatar/${avatarId}` : '/add-voice-data')
  }

  const handleClickSkip = () => {
    setIsOpenSuccessModal(false)
    navigate('/')
  }

  return (
    <Modal centered open={isOpenSuccessModal} closable={false} width={600} className="success-modal" footer={null}>
      <img src={successIcon} alt="Success icon" />
      <h3>
        Thank you for your
        <br />
        purchase!
      </h3>
      <Typography.Text className="subtitle" type="secondary">
        {avatarId
          ? 'Your avatar will appear in the sidebar when editing videos. You can proceed to create avatar now or get back to it later from the video sidebar.'
          : 'Your voice will appear in the voices modal when editing videos. Please proceed to create voice.'}
      </Typography.Text>
      <div className="btns-container">
        <Button
          type="primary"
          htmlType="submit"
          icon={<RightOutlined />}
          size="large"
          className="btn-arrow"
          onClick={handleClickCreate}
        >
          Create {avatarId ? 'avatar now' : 'voice'}
        </Button>
        {avatarId && (
          <Button type="link" onClick={handleClickSkip}>
            Skip
          </Button>
        )}
      </div>
    </Modal>
  )
}

export default SuccessPaymentModal
