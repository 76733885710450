import { useMemo } from 'react'
import { PauseOutlined, LoadingOutlined } from '@ant-design/icons'
import { Row, Col, Button, Slider, Tooltip } from 'antd'

import rotateIcon from '../../../assets/images/play.svg'
import ColorEditorButton from '../colorsEditor/colorEditorButton'
import Icon from '../../../components/Icon'
import { useObjectVisibilityHandler } from './hooks/useObjectVisibilityHandler'
import { DEFAULT_SLIDE_DURATION } from './canvas/constans'
import { useSlideDuration } from '../../../hooks/useSlideDuration'

const style = {
  topM2: { top: -2 },
  handleStyle: { top: 3 },
  handleStyleHidden: { display: 'none' },
  rotateIcon: { position: 'relative', left: 1, top: 1 },
  railStyle: { backgroundColor: 'rgba(189, 189, 189, 0.1)', height: 8, borderRadius: 8 },
  trackStyle: { backgroundColor: 'rgba(72, 104, 255, 0.2)', height: 8, borderRadius: 8 },
  trackStyleInactive: { backgroundColor: 'rgba(72, 104, 255, 0)', height: 8, borderRadius: 8 },
  trackStyleRange: { backgroundColor: '#D9D9D9', height: 8, borderRadius: 8 },
  playButton: { width: '32px', display: 'flex', justifyContent: 'center', alignItems: 'center' },
  animationToggleIcon: { width: 24, height: 24, fontSize: 20, marginTop: 6 },
}

const animationOnIcon = <Icon name="animation_on" style={style.animationToggleIcon} />
const animationOffIcon = <Icon name="animation_off" style={style.animationToggleIcon} />

const formatter = { formatter: (v) => `${v?.toFixed(1) ?? 0}s` }

const PlayerManager = (props) => {
  const {
    slide,
    slides,
    player,
    setPlayer,
    togglePlay,
    manualChangingTime,
    activeSlide,
    canvasRegistry,
    updateActiveSlide,
    onChangeSlideTime,
    changeCanvasBackground,
  } = props

  const { objectMarks, toggleObjectsVisibilityMode } = useObjectVisibilityHandler(
    slide,
    player,
    setPlayer,
    style.handleStyle,
  )

  const { getApproxDuration } = useSlideDuration({ slide })

  const durationOffset = useMemo(() => {
    return slides
      .slice(0, activeSlide)
      .reduce((duration, slide) => duration + (slide.duration || getApproxDuration(slide) || DEFAULT_SLIDE_DURATION), 0)
  }, [activeSlide, player.activePreview])

  const changePlayerTime = (time, slideChanged) => {
    if (slideChanged) {
      setPlayer((p) => ({ ...p, seekTimeForResume: time }))
      player.voiceAudio?.pause()
    } else {
      manualChangingTime.current = true
      if (player.voiceAudio) player.voiceAudio.currentTime = time
      setPlayer((p) => ({ ...p, currentTime: time }))
      onChangeSlideTime(time)
    }
  }

  const onSliderChanged = (currentTime) => {
    if (!player.activePreview) return changePlayerTime(currentTime, false)

    let slideIndex = 0
    let offset = 0
    for (const slide of slides) {
      if (currentTime < offset + (slide.duration || getApproxDuration(slide) || DEFAULT_SLIDE_DURATION)) break
      offset += slide.duration || getApproxDuration(slide) || DEFAULT_SLIDE_DURATION
      slideIndex++
    }
    changePlayerTime(currentTime - offset, slideIndex !== activeSlide)
    if (slideIndex !== activeSlide) updateActiveSlide(slideIndex, true)
  }

  const time =
    (player.activePreview && player.status !== 'playing' ? 0 : player.currentTime) +
    (player.activePreview ? durationOffset : 0)

  const setPlayButtonIcon = () => {
    if (!canvasRegistry.ready || !player.canvasReady || player.status === 'loading') {
      return <LoadingOutlined />
    }
    if (player.status === 'idle') {
      return <img src={rotateIcon} style={style.rotateIcon} />
    }
    return <PauseOutlined />
  }

  const sliderIcon = setPlayButtonIcon()

  return (
    <div className="player-controls">
      <Row gutter={16} align="middle">
        <Col span={2}>
          <Button
            type="primary"
            disabled={player.status === 'loading'}
            onClickCapture={togglePlay}
            style={style.playButton}
          >
            {sliderIcon}
          </Button>
        </Col>
        <Col span={19} style={{ overflow: 'hidden' }}>
          <Slider
            value={time}
            min={0}
            max={
              player.activePreview
                ? player.duration
                : slide.duration || getApproxDuration(slide) || DEFAULT_SLIDE_DURATION
            }
            step={0.01}
            tooltip={formatter}
            onChange={onSliderChanged}
            disabled={player.status === 'loading'}
            style={style.topM2}
            railStyle={style.railStyle}
            trackStyle={player.status === 'playing' ? style.trackStyle : style.trackStyleInactive}
            handleStyle={style.handleStyle}
            marks={objectMarks}
          />
        </Col>
        <Col span={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {player.activePreview ? (
            time.toFixed(1)
          ) : player.status === 'playing' ? (
            <span>{player.currentTime.toFixed(1)}s</span>
          ) : (
            <>
              <Tooltip
                title={
                  player.objectsVisibilityMode === 'all'
                    ? 'Show elements only when they appear on a slide. Move the timeline to see elements.'
                    : 'Always show all elements.'
                }
              >
                <Button
                  type="ghost"
                  shape="circle"
                  icon={player.objectsVisibilityMode === 'all' ? animationOffIcon : animationOnIcon}
                  style={style.toggleAnimations}
                  className="btn-toggle-animations-mode"
                  onClick={toggleObjectsVisibilityMode}
                />
              </Tooltip>
              <Tooltip title="Change slide background color">
                <div>
                  <ColorEditorButton
                    color={slide.canvas.background}
                    isBackgroundPicker={true}
                    onChangeColor={changeCanvasBackground}
                  />
                </div>
              </Tooltip>
            </>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default PlayerManager
