import { Row, Col, Space, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

import { useStore } from '../../../store'
import { BrandbookLink } from '../components/brandbookLink'

const CorporateColors = ({ color, onChangeColor, setActiveTab, setAssetActiveTab, setColorPickerVisible }) => {
  const {
    user: { account },
  } = useStore((stores) => stores.authStore)

  account.corporateColors = account.corporateColors || []

  return (
    <>
      <Row style={{ marginTop: 14 }} align="top">
        <Col>
          <Space>
            <h4>Brand Colors</h4>
            <Tooltip title="Store colors available for all users in your account and apply to any video">
              <QuestionCircleOutlined />
            </Tooltip>
          </Space>
        </Col>
      </Row>
      <Row style={{ marginTop: 14 }} align="top">
        {account.corporateColors.length > 0 ? (
          <Space wrap={true}>
            {account.corporateColors.map((c) => {
              const itemClass = `color-item ${c === color ? 'active' : 'inactive'}`
              return (
                <button type="button" key={c} className={itemClass} onClick={() => onChangeColor(c)}>
                  <div className="color-value" style={{ backgroundColor: c }}></div>
                </button>
              )
            })}
          </Space>
        ) : (
          <BrandbookLink
            setActiveTab={setActiveTab}
            setAssetActiveTab={setAssetActiveTab}
            setColorPickerVisible={setColorPickerVisible}
            text="Manage colors in"
            secondText="My Assets"
            className="corporate-colors-link"
          />
        )}
      </Row>
      {account.corporateColors.length > 0 && (
        <Row style={{ marginTop: 14 }} align="top">
          <BrandbookLink
            setActiveTab={setActiveTab}
            setAssetActiveTab={setAssetActiveTab}
            setColorPickerVisible={setColorPickerVisible}
            text="Manage colors in"
            secondText="My Assets"
            className="corporate-colors-link"
          />
        </Row>
      )}
    </>
  )
}

export default CorporateColors
