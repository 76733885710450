const createSniperLink = (email) => {
  if (['@outlook', '@hotmail', '@live'].some((domain) => email.includes(domain)))
    return `https://outlook.live.com/mail/?login_hint=${email}`
  else if (['@proton', '@pm'].some((domain) => email.includes(domain)))
    return `https://mail.proton.me/u/0/all-mail#from=noreply@mailgun.elai.io`
  else if (['@yahoo', '@myyahoo'].some((domain) => email.includes(domain)))
    return `https://mail.yahoo.com/d/search/keyword=from%253Anoreply@mailgun.elai.io`
  else if (email.includes('@icloud')) return 'https://www.icloud.com/mail/'
  else return `https://mail.google.com/mail/u/${email}/#search/from%3Anoreply@mailgun.elai.io+in%3Aanywhere`
}

export { createSniperLink }
