import { Button, Form, Input, Steps } from 'antd'
import { Fragment, useEffect, useState } from 'react'
import { CheckCircleFilled } from '@ant-design/icons'
import ReactGA from 'react-ga4'

import { REACT_APP_CHAT_URL, rightOutlinedIcon, stepsQuestions } from '../constants'

import { request } from '../../../../utils/api'
import { track } from '../../../../utils/analytics'

export const StepsComponent = (props) => {
  const { currentStep, name, isPersonal, isPublicEmail, setCurrentStep, redirectUrl } = props

  const [answers, setAnswers] = useState({})
  const [inputValue, setInputValue] = useState('')
  const [answerError, setAnswerErrors] = useState([])
  const [currentAnswers, setCurrentAnswers] = useState({})

  const [stepsForm] = Form.useForm()

  // for personal we exclude some b2b steps, for b2b usecases we show some fields only for gmails and some - only for corporate domains
  const steps = isPersonal
    ? stepsQuestions.filter((questions) => questions.some((q) => !('show' in q)))
    : stepsQuestions.map((questions) =>
        questions.filter(
          (q) => (isPublicEmail && q.show !== 'corporate-domain') || (!isPublicEmail && q.show !== 'public-email'),
        ),
      )

  useEffect(() => {
    if (redirectUrl === REACT_APP_CHAT_URL) {
      steps.splice(0, 1)
      setAnswers({ userType: 'Real-time Avatars' })
    }
  }, [redirectUrl])

  const onFinishStep = async (formData) => {
    // check if there is no an answered button questions (custom validation)
    const errors = []
    steps[currentStep].forEach((field) => {
      if ((field.component === 'button' || field.requiredMessage) && !formData[field.name]) {
        errors.push(field.name)
      }
    })
    // otherwise stop function
    if (errors.length > 0) {
      setAnswerErrors(errors)
      return
    }
    const step = currentStep + 1
    const isFinalStep = step > steps.length - 1
    const data = { ...answers, ...formData }
    setAnswers(data)
    if (isFinalStep) {
      await request({ method: 'patch', url: 'users/additional-info', data })
      ReactGA.gtag('set', 'user_properties', {
        usage_type: data.useType,
        use_case: data.userType,
        company_size: data.companySize,
        company_name: data.companyName,
        role: data.role,
        awarness_channel: data.source,
        usage_frequency: data.usage,
      })
      track('account_info_submitted')
    }
    setCurrentStep(isFinalStep ? 'final' : step)
    stepsForm.resetFields()
  }

  const goToPreviousSteps = (step) => {
    if (step > currentStep) {
      return
    }
    setCurrentStep(step)
    steps[step].forEach((item) => {
      if (answers[item.name]) {
        stepsForm.setFieldsValue({ [item.name]: answers[item.name] })
      }
    })
  }

  useEffect(() => {
    steps[currentStep]?.forEach((item) => {
      if (answers[item.name]) {
        stepsForm.setFieldsValue({ [item.name]: answers[item.name] })
      }
    })
  }, [currentStep])

  const handleClick = () => onFinishStep(currentAnswers)

  return (
    <>
      <div className="card-title">
        <h3>Let's get to know you</h3>
        <p>We are eager to learn more about you to help you have the best experience with Elai</p>
      </div>
      <div className="steps-wrapper">
        <Steps
          className="poll-steps"
          current={currentStep}
          responsive={false}
          progressDot={(iconDot, { status }) => {
            if (status === 'wait') return <span className="wait-icon"></span>
            if (status === 'process')
              return (
                <span className="progress-icon">
                  <span className="progress-icon-point"></span>
                </span>
              )
            if (status === 'finish') return <CheckCircleFilled />
          }}
          items={Array.from({ length: steps.length }, (_, step) => ({
            key: step,
            onClick: () => goToPreviousSteps(step),
          }))}
        />
      </div>
      <p className="greeting">Hello, {name}!</p>
      <Form
        form={stepsForm}
        autoComplete="off"
        className="steps-form"
        requiredMark={false}
        layout="vertical"
        validateTrigger="onSubmit"
      >
        {steps[currentStep].map(({ question, answers, name, show, requiredMessage, component, validation }) => (
          <Fragment key={name}>
            {component === 'button' ? (
              <Form.Item label={question} className="poll-label">
                <div
                  className="answer-grid"
                  style={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(${answers.length > 2 ? '3' : '2'}, 0fr)`,
                  }}
                >
                  {answers.map((answer, index) => (
                    <div key={index}>
                      <Button
                        className={`poll-answer ${currentAnswers[name] === answer ? 'active' : ''}`}
                        onClick={() => {
                          setCurrentAnswers((prevState) => ({ ...prevState, [name]: answer }))
                          const result = answerError.filter((error) => error !== name)
                          setAnswerErrors(result)
                        }}
                      >
                        {answer}
                      </Button>
                    </div>
                  ))}
                </div>
                {answerError.includes(name) && <p className="poll-error">Please select an answer</p>}
              </Form.Item>
            ) : (
              <Form.Item name={name} className="poll-label form-item-textarea" label={question}>
                <Input
                  placeholder="Please, write your answer here"
                  value={inputValue}
                  rules={
                    requiredMessage
                      ? [
                          {
                            required: true,
                            message: requiredMessage,
                          },
                        ]
                      : null
                  }
                  onChange={(event) => {
                    if (validation) {
                      const newValue = event.target.value
                      const regex = new RegExp(validation)
                      if (regex.test(newValue)) {
                        setInputValue(newValue)
                        setCurrentAnswers((prevState) => ({ ...prevState, [name]: newValue }))
                      }
                      return
                    } else {
                      setInputValue(event.target.value)
                      setCurrentAnswers((prevState) => ({ ...prevState, [name]: event.target.value }))
                    }
                  }}
                />
                {answerError.includes(name) && <p className="poll-error">{requiredMessage}</p>}
              </Form.Item>
            )}
          </Fragment>
        ))}
        <Form.Item style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            type="primary"
            htmlType="submit"
            onClick={handleClick}
            icon={rightOutlinedIcon}
            size="large"
            className="btn-login btn-arrow continue-poll-button"
          >
            {currentStep === steps.length - 1 ? 'Finish setup' : 'Continue'}
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}
