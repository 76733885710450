import { useEffect, useRef } from 'react'
import { Input, Button, Modal, Form, Checkbox, notification } from 'antd'

import { Editor } from './editor'
import { useStore } from '../../../../store'
import { request } from '../../../../utils/api'

import { COURSE_URL, LESSON_EDITOR_PLUGINS } from '../constants'
import { equal } from '../../../../store/helpers'

export const CreateCourseModal = (props) => {
  const { isModalOpen, setIsModalOpen, setCourses, editableCourse, setEditableCourse, courseForm } = props
  const editorRef = useRef(null)

  const isEditMode = editableCourse && Object.keys(editableCourse).length > 0

  const clearModal = () => {
    setEditableCourse({})
    courseForm.resetFields()
    setIsModalOpen(false)
  }

  const onCancelModal = () => {
    if (isEditMode) {
      const activeCourse = {}
      activeCourse.description = editableCourse.description
      activeCourse.duration = editableCourse.duration
      activeCourse.enabled = editableCourse.enabled
      activeCourse.name = editableCourse.name
      activeCourse.order = editableCourse.order
      activeCourse.teaserId = editableCourse.teaserId
      activeCourse.whatYouLearn = editableCourse.whatYouLearn.join(',')
      activeCourse.materials = editableCourse.materials

      const form = courseForm.getFieldsValue()
      form.materials = editorRef.current.getContent()
      if (isEditMode && !equal(form, activeCourse)) {
        return Modal.confirm({
          title: `Do you want to save your changes?`,
          onOk: onFinish,
          onCancel: clearModal,
          okText: 'Save',
          cancelText: 'Close',
        })
      } else {
        clearModal()
      }
    } else {
      clearModal()
    }
  }

  const onFinish = async () => {
    const { whatYouLearn, ...formData } = courseForm.getFieldsValue()
    const result = { ...formData }
    // should be array of skills
    result.whatYouLearn = whatYouLearn.split(',')
    // assign course to user and hes account
    if (editorRef.current) {
      result.materials = editorRef.current.getContent()
    }
    if (!isEditMode) {
      result.userId = user.id
      result.accountId = user.account.id
      const response = await request({ method: 'post', url: COURSE_URL, data: result })
      if (response) {
        setCourses((prevState) => [...prevState, response])
        notification.success({ message: 'Course created successfully', duration: 4 })
        clearModal()
      }
    } else {
      const response = await request({ method: 'patch', url: `${COURSE_URL}/${editableCourse._id}`, data: result })
      if (response) {
        setCourses((prevState) => {
          const index = prevState.findIndex((course) => course._id === response._id)
          const updated = [...prevState]
          updated[index] = response
          return [...updated]
        })
        notification.success({ message: 'Course updated successfully', duration: 4 })
        clearModal()
      }
    }
  }

  const user = useStore((stores) => stores.authStore.user)

  useEffect(() => {
    if (!isEditMode) {
      courseForm.setFieldsValue({
        userId: user.id,
        accountId: user.account.id,
      })
    } else {
      courseForm.setFieldsValue({
        ...editableCourse,
        whatYouLearn: editableCourse.whatYouLearn.join(','),
      })
    }
  }, [isEditMode, editableCourse])

  return (
    <Modal
      title={`${isEditMode ? 'Edit' : 'Create'} course`}
      open={isModalOpen}
      width={500}
      footer={null}
      centered
      onCancel={onCancelModal}
    >
      <Form form={courseForm} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="name"
          label="Course name"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: 'Course name is required',
            },
          ]}
        >
          <Input placeholder="Course name" />
        </Form.Item>
        <Form.Item
          name="order"
          label="Order"
          tooltip="Order should be unique for every course"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: 'Order is required',
            },
          ]}
        >
          <Input placeholder="Order" type="number" />
        </Form.Item>
        <Form.Item name="enabled" label="Enabled" validateTrigger="onBlur" valuePropName="checked">
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: 'Description is required',
            },
          ]}
        >
          <Input placeholder="Description" />
        </Form.Item>
        <>
          <label>Materials</label>
          <Editor
            editorRef={editorRef}
            plugins={LESSON_EDITOR_PLUGINS}
            value={isEditMode ? editableCourse.materials : ''}
          />
        </>
        <Form.Item
          name="duration"
          label="Duration"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: 'Duration is required',
            },
          ]}
        >
          <Input placeholder="Duration" type="number" />
        </Form.Item>
        <Form.Item
          name="whatYouLearn"
          label="What you learn"
          validateTrigger="onBlur"
          tooltip="Write Coma separated items"
          rules={[
            {
              required: true,
              message: 'What you learn is required',
            },
          ]}
        >
          <Input placeholder="What you learn" />
        </Form.Item>
        <Form.Item
          name="teaserId"
          label="Teaser"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: 'Video teaser is required',
            },
          ]}
        >
          <Input placeholder="Video ID (get from URL)" />
        </Form.Item>

        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>
    </Modal>
  )
}
