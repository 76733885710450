import axios from 'axios'
import { backgrounds } from '../../../../../../data/backgrounds'
import configService from '../../../../../../utils/config'

const { pexels } = configService.get().keys

const api = axios.create({ baseURL: 'https://api.pexels.com', headers: { Authorization: pexels } })

backgrounds.forEach((bg) => (bg.thumbnail += '?nocache'))

const normalizeContent = (data) => {
  const { photos, videos } = data
  const type = photos ? 'image' : 'video'
  const source = photos ?? videos
  return source.map((item) => ({
    ...item,
    type,
    thumbnail: item.src?.tiny ?? item.image,
    url: item.src?.large ?? item.video_files[0].link,
  }))
}

const normalizeMetaData = (data) => ({
  nextPage: data.next_page,
})

const pexelsUrl = (filesType) => (filesType === 'image' ? 'https://api.pexels.com/v1' : 'https://api.pexels.com/videos')

const fetchMedia = async (url, params) => {
  try {
    const request = await api.get(url, { params })
    return request.data
  } catch (error) {
    return { error: error.message }
  }
}

export const normalizeData = (data) => {
  return { content: normalizeContent(data), meta: normalizeMetaData(data) }
}

export const pexelsGetContent = async ({ nextPage, filter = {}, filesType }) => {
  const { query } = filter
  if (!nextPage && !Object.keys(filter).length && !query?.length) return pexelsGetDefaultContent(filesType)
  let url = pexelsUrl(filesType)
  const isFiltering = Object.keys(filter).length
  if (nextPage) url = nextPage
  if (query?.length || isFiltering) url += '/search/'
  if (isFiltering && !query?.length) filter = { ...filter, query: 'Any content in image' }
  const data = await fetchMedia(url, { ...filter, per_page: 30 })
  if (data.error) return data
  return normalizeData(data)
}

export const pexelsGetDefaultContent = async (filesType) => {
  if (filesType === 'video') {
    const data = await fetchMedia(`${pexelsUrl(filesType)}/popular`, { per_page: 30 })
    if (data.error) return data
    return normalizeData(data)
  } else {
    return { content: backgrounds, meta: {} }
  }
}
