import { useEffect, useRef, useState, useMemo } from 'react'
import * as storage from 'idb-keyval'
import { useStore } from '../../store'
import fabric, { checkObjectExists } from '../../utils/fabric/fabric'
import { promiseAllWithLimit, requestIdleCallback, sleep } from '../../utils/helpers'
import { CANVAS_HEIGHT, CANVAS_WIDTH } from './slide/canvas/constans'
import { THUMBNAIL_MODES } from './constants'
import { useVideoFormat } from './useVideoFormat'
import { toggleCanvasVisibility } from './slide/canvas/helpers'
import configService from '../../utils/config'

const REQUEST_IDLE_TIMEOUT = 300
const THUMBNAIL_PENDING_CACHE_KEY = 'thumbnail-pending-cache'
const TEMPLATE_THUMBMAIL_MAX_OBJECT = 5
const CONTROLS_VISIBILITY_DELAY_MS = 10

const thumbnailCacheEnabled = window.indexedDB !== undefined

/**
 * The CanvasRenderingContext2D is primarily CPU-related, but the rendering process can benefit from GPU acceleration in modern web browsers
 */
const checkIsGPUIntegrated = () => {
  try {
    const canvas = document.createElement('canvas')
    const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl')
    const debugInfo = gl.getExtension('WEBGL_debug_renderer_info')

    return /intel|legacy|mali|google/i.test(gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL))
  } catch {
    return false
  }
}

const isLowEndDevice =
  (!!navigator.deviceMemory && navigator.deviceMemory < 8) ||
  navigator.hardwareConcurrency < 8 ||
  checkIsGPUIntegrated()

const MAX_SIMULTANEOUSLY_SLIDES = isLowEndDevice ? 2 : 5

const getStorageKey = (videoId, slideId) => `canvas-thumbnail-${videoId}-${slideId}`

const noop = () => {}

const idle = () => new Promise((resolve) => requestIdleCallback(resolve, { timeout: REQUEST_IDLE_TIMEOUT }))

const createImage = (srcBase64) => {
  const img = new Image()
  img.src = srcBase64
  return img
}

const updatedThumbnails = []

export const createThumbnailFromFabricCanvas = (canvas, { cacheKey, forceCache } = {}) => {
  try {
    const imgSrc = canvas.toDataURL({
      format: 'jpeg',
      quality: 0.8,
    })
    if (!cacheKey) return createImage(imgSrc)

    if (thumbnailCacheEnabled) {
      if (forceCache) storage.set(cacheKey, imgSrc).catch(noop)
      else {
        updatedThumbnails.push({ cacheKey, imgSrc })

        const pendingCache = localStorage.getItem(THUMBNAIL_PENDING_CACHE_KEY) || ''
        localStorage.setItem(THUMBNAIL_PENDING_CACHE_KEY, pendingCache + (pendingCache ? ';' : '') + cacheKey)
      }
    }
    return createImage(imgSrc)
  } catch {
    // canvas contains CORS issues, so we can't export it, just use it as is
    return canvas.lowerCanvasEl
  }
}

const updateVideoVersionInCache = (video) => localStorage.setItem('cache_video_version_' + video._id, video.version)

const dropThumbnailCache = async (video) => {
  if (!thumbnailCacheEnabled) return
  if (video?.slides?.length) await storage.delMany(video.slides.map((slide) => getStorageKey(video._id, slide.id)))
  else await storage.clear()
}

const flushThumbnailsCache = (video) => {
  if (!video || !thumbnailCacheEnabled) return
  updateVideoVersionInCache(video)
  updatedThumbnails.forEach(({ cacheKey, imgSrc }) => storage.set(cacheKey, imgSrc).catch(noop))
  updatedThumbnails.length = 0
  localStorage.removeItem(THUMBNAIL_PENDING_CACHE_KEY)
}

const createAndAttachCanvas = (parent, slideId) => {
  const el = document.createElement('canvas')
  el.id = `canvas-${slideId}`
  el.style.position = 'absolute'
  parent.appendChild(el)
}

const checkForPreviousCanvasInstance = (slide) => {
  const canvas = document.querySelector('#canvas-' + slide.id)
  if (canvas && canvas.parentNode.classList.contains('canvas-container')) {
    const root = canvas.parentNode.parentElement
    root.removeChild(canvas.parentNode)

    createAndAttachCanvas(root, slide.id)
  }
}

export const useCanvasRegistry = (
  video,
  videoRef,
  selectedSlideId,
  workingAreaRef,
  canvasesContainerRef,
  isOpenSidebar,
  activeSlide,
  handleCanvasSelected,
  handleMissingMedia,
) => {
  const fontsReady = useStore((stores) => stores.videosStore.fontsReady)
  const canvasRegistry = useRef({})
  const currentCanvas = useRef()
  const missingMedia = useRef([])
  const [thumbnails, setThumbnails] = useState({})
  const [templateThumbnails, setTemplateThumbnails] = useState({})
  const [ready, setReady] = useState(false)
  const [readyInitialSlide, setReadyInitialSlide] = useState(false)

  const { isTesting } = configService.get().env

  const { calculateZoom, resizeFormatLimiters, clearFormatLimiters } = useVideoFormat(
    video,
    canvasRegistry,
    workingAreaRef,
    canvasesContainerRef,
    isOpenSidebar,
  )

  const thumbnailsReady = useMemo(
    () => video && Object.keys(thumbnails).length === video.slides.length,
    [thumbnails, video?.slides],
  )
  const templateThumbnailsReady = useMemo(
    () => video && Object.keys(templateThumbnails).length === video.slides.length,
    [templateThumbnails, video?.slides],
  )

  const syncThumbnailsWithSlides = () => {
    const updatedThumbnails = Object.fromEntries(
      Object.entries(thumbnails).filter(([slideId]) => videoRef.current.slides.find((s) => s.id === Number(slideId))),
    )
    if (Object.keys(updatedThumbnails).length !== Object.keys(thumbnails)) {
      setThumbnails(updatedThumbnails)
    }
  }

  const handleCanvasReady = (missingMedia) => {
    if (missingMedia.length) handleMissingMedia?.(missingMedia, selectedSlideId)
  }

  const createCanvas = (slide, initial = false) => {
    checkForPreviousCanvasInstance(slide)
    const zoom = calculateZoom()
    const canvas = new fabric.Canvas('canvas-' + slide.id, {
      height: Math.round(CANVAS_HEIGHT * zoom),
      width: Math.round(CANVAS_WIDTH * zoom),
      preserveObjectStacking: true,
      /**
       * prevent fabric canvas from rerendering whenever an object is added or removed
       */
      renderOnAddRemove: false,
      // avoid cache generation during scaling
      noScaleCache: true,
      // by default it's true, just to make it explicit
      objectCache: true,
      maxCacheSideLimit: 1280,
      minCacheSideLimit: 320,
      perfLimitSizeTotal: 7340032,
    })
    canvas.setZoom(zoom)
    canvas.wrapperEl.style.display = 'none'
    if (!initial) {
      canvas.loadFromJSON(slide.canvas, (missingMedia) => {
        handleCanvasReady(missingMedia)
        handleCanvasSelected?.()
      })
      canvas.initialized = true
    }
    return canvas
  }

  const createCanvasAsync = async (slide) => {
    const canvas = createCanvas(slide, true)

    if (!slide.canvas) return canvas

    await canvas.loadFromJSON(slide.canvas, handleCanvasReady)
    await updateThumbnail(slide, { canvas, showControlsWithDelay: false, forceCache: !ready })

    return canvas
  }

  const preloadSlideCanvas = async (slide, { immediate = false } = {}) => {
    if (thumbnailCacheEnabled && !thumbnails[slide.id]) {
      const cachedThumbnail = await storage.get(getStorageKey(video._id, slide.id)).catch(() => null)
      if (cachedThumbnail) {
        setThumbnails((t) => ({ ...t, [slide.id]: createImage(cachedThumbnail) }))
        return
      }
    }

    if (!immediate) await idle()

    if (!canvasRegistry.current[slide.id]) {
      // no need to iterate over all objects if we can find one object
      const canvas = await createCanvasAsync(slide)
      if (slide.id !== selectedSlideId) {
        // if it's not the selected slide we should deselect canvas to free memory
        disposeCanvas(canvas)
      } else {
        canvasRegistry.current[slide.id] = canvas
      }
    } else {
      updateThumbnail(slide, { showControlsWithDelay: false, forceCache: !ready })
    }
  }

  const preloadCanvases = async (slides) => {
    await promiseAllWithLimit(slides, preloadSlideCanvas, MAX_SIMULTANEOUSLY_SLIDES)
    if (!ready) updateVideoVersionInCache(video)
  }

  const updateCanvases = async (slides, { activeObject, thumbnailMode = THUMBNAIL_MODES.REGULAR } = {}) => {
    let activeObjectUpdated = null
    for (const slide of slides) {
      let canvas = canvasRegistry.current[slide.id]
      if (!activeObject && slide.id === selectedSlideId)
        await updateThumbnail(slide, { showControlsWithDelay: true, thumbnailMode })
      else {
        if (!canvas || canvas._disposed) canvas = selectCanvasInternal(slide, true)
        const updated = await canvas
          ?.loadFromJSON(slide.canvas)
          .then(() => updateThumbnail(slide, { thumbnailMode }))
          .then(() => {
            if (activeObject && typeof activeObject !== 'boolean' && slide.id === selectedSlideId) {
              const ao = canvas.getObjects().find((obj) => obj.id === activeObject.id)
              if (ao) {
                canvas.setActiveObject(activeObject).renderAllSafe()
                return ao
              }
            }
            return null
          })
        activeObjectUpdated = activeObjectUpdated || updated
      }
      await idle()
    }
    return activeObjectUpdated
  }

  const updateCanvasesInStoryMode = async (slides) => {
    setThumbnails({})
    await promiseAllWithLimit(slides, updateCanvasInStoryMode, MAX_SIMULTANEOUSLY_SLIDES)
  }

  const updateCanvasInStoryMode = async (slide) => {
    let canvas = canvasRegistry.current[slide.id]
    if (!canvas || canvas._disposed) canvas = selectCanvasInternal(slide, true)
    await canvas?.loadFromJSON(slide.canvas)
    updateThumbnail(slide)
  }

  const updateThumbnail = async (
    slide,
    { canvas, showControlsWithDelay, forceCache, thumbnailMode = THUMBNAIL_MODES.REGULAR } = {},
  ) => {
    canvas = canvas || canvasRegistry.current[slide.id]

    if (!canvas?.lowerCanvasEl || !canvas.getObjects().length) {
      return
    }

    if (canvas.activeLoading) {
      await canvas.activeLoading
    }

    showControls(canvas, false, thumbnailMode)

    const thumbnailCanvas = createThumbnailFromFabricCanvas(canvas, {
      cacheKey: getStorageKey(video._id, slide.id),
      forceCache,
    })

    const thumbnailsSetter = thumbnailMode === THUMBNAIL_MODES.REGULAR ? setThumbnails : setTemplateThumbnails
    thumbnailsSetter((t) => ({
      ...t,
      [slide.id]: thumbnailCanvas,
    }))

    if (showControlsWithDelay) await sleep(CONTROLS_VISIBILITY_DELAY_MS)
    showControls(canvas, true, thumbnailMode)
  }

  const updateThumbnailImmediately = (slide) =>
    updateThumbnail(slide, { showControlsWithDelay: false, forceCache: true })

  const deleteThumbnailsCache = () => {
    if (!ready && thumbnailCacheEnabled) {
      const pendingCache = localStorage.getItem(THUMBNAIL_PENDING_CACHE_KEY)
      if (pendingCache) {
        storage.delMany(pendingCache.split(';'))
        localStorage.removeItem(THUMBNAIL_PENDING_CACHE_KEY)
      }
    }
  }

  const toggleCanvasesReadOnly = (readOnly) => {
    Object.values(canvasRegistry.current).forEach((canvas) => {
      canvas.wrapperEl?.classList.toggle('read-only', readOnly)
    })
  }

  const checkObjectVisibilityForTemplate = (canvas, obj) => {
    if (!obj.animation?.startTime) return true
    // NOTE: in can be improved to check if overlapping with other objects etc.
    return canvas.getObjects().filter((o) => o.type !== 'avatar' && !o.bg).length <= TEMPLATE_THUMBMAIL_MAX_OBJECT
  }

  const showControls = (canvas, visibility, thumbnailMode = THUMBNAIL_MODES.REGULAR) => {
    canvas.getObjects().forEach((obj) => {
      obj.forEachControl((control) => (control.visible = visibility))
      obj.borderColor = visibility ? 'rgba(72, 104, 255, 1)' : 'rgba(72, 104, 255, 0)'
      if (visibility && Object.hasOwn(obj, 'visiblePrev')) {
        obj.visible = obj.visiblePrev
        delete obj.visiblePrev
      } else {
        obj.visiblePrev = obj.visible
        // if it's a template mode, we should force it to show only the objects at the beginning of the slide
        // otherwise it will show all objects and it looks messy
        obj.visible =
          visibility || thumbnailMode === THUMBNAIL_MODES.REGULAR ? true : checkObjectVisibilityForTemplate(canvas, obj)
      }
    })
    canvas.renderAllSafe()
  }

  useEffect(() => {
    if (video && video.version !== Number(localStorage.getItem('cache_video_version_' + video._id)))
      dropThumbnailCache(video)
  }, [video?._id])

  useEffect(() => {
    const handleError = () => {
      // for ensure that thumbnails cache will be consistent we should clear it if any error occurred
      dropThumbnailCache(video)
    }
    window.addEventListener('error', handleError)
    return () => {
      window.removeEventListener('error', handleError)
      updatedThumbnails.length = 0
    }
  }, [])

  /**
   * For preloading initial opened slide before preloading other slides
   */
  useEffect(() => {
    if (!fontsReady || !video?.slides.length || readyInitialSlide) return
    //only for story mode, because there isn't active slide in story mode
    if (activeSlide === undefined) return setReadyInitialSlide(true)
    resizeFormatLimiters()
    deleteThumbnailsCache()

    const preload = async () => {
      await preloadSlideCanvas(video.slides[activeSlide], { immediate: true })
      setReadyInitialSlide(video.slides[activeSlide].id)
    }
    preload()
  }, [video?.slides.length, fontsReady, readyInitialSlide, activeSlide])

  /**
   * For syncing canvas registry
   */
  useEffect(() => {
    if (!fontsReady || !video?.slides.length || !readyInitialSlide) return
    preloadCanvases(video.slides).then(() => setReady(true))
  }, [video?.slides.length, fontsReady, readyInitialSlide])

  useEffect(() => {
    if (!video?.slides || !handleMissingMedia) return

    syncThumbnailsWithSlides()
  }, [video?.slides])

  useEffect(() => {
    if (isTesting && thumbnailsReady) localStorage.setItem('__thumbnailsReady', 'true')
  }, [thumbnailsReady])

  const disposeCanvas = (canvas) => {
    if (!canvas || canvas._disposed) return
    canvas.stopAnimations()
    canvas.dispose()
    canvas._disposed = true
  }

  const deselectCanvas = (canvas) => {
    if (!canvas) return

    toggleCanvasVisibility(canvas, false)
    canvas.discardActiveObject()

    canvas.stopAnimations()
  }

  const hideCanvases = () =>
    Object.values(canvasRegistry.current).forEach((canvas) => toggleCanvasVisibility(canvas, false))

  const selectCanvasInternal = (slide, initial = false) => {
    let canvas = canvasRegistry.current[slide.id]
    if (canvas) {
      // check that it presents in DOM
      const el = document.querySelector('#canvas-' + slide.id)
      if (el && !el.classList.contains('lower-canvas')) {
        disposeCanvas(canvas)
        canvas = createCanvas(slide, initial)
      } else if (canvas._disposed) {
        canvas = createCanvas(slide, initial)
      }
      // re-check for missing media after returning from another slide
      const missingMedia = canvas.getObjects().filter((obj) => !checkObjectExists(obj))
      if (missingMedia.length) handleMissingMedia?.(missingMedia, selectedSlideId)
    } else {
      canvas = createCanvas(slide, initial)
      const shouldUpdateThumbnail = !thumbnailCacheEnabled || slide.newlyAdded || !slide.status
      if (!initial && shouldUpdateThumbnail) {
        setTimeout(() => updateThumbnail(slide), 500)
      }
    }
    canvasRegistry.current[slide.id] = canvas
    canvas.slideId = slide.id
    return canvas
  }

  const selectCanvas = (slide) => {
    if (currentCanvas.current?.slideId === slide.id) return false
    if (currentCanvas.current) deselectCanvas(currentCanvas.current)
    return (currentCanvas.current = selectCanvasInternal(slide))
  }

  const deleteCanvas = (slide) => {
    const canvas = canvasRegistry.current[slide.id]
    if (canvas) disposeCanvas(canvas)
    delete canvasRegistry.current[slide.id]
    setTimeout(syncThumbnailsWithSlides, 1500)
  }

  const clearRegistry = () => {
    Object.values(canvasRegistry.current).forEach(disposeCanvas)
    canvasRegistry.current = {}
    missingMedia.current = []
    setThumbnails({})
    fabric.dropCache()
    clearFormatLimiters()
  }

  const clearTemplateThumbnails = () => {
    Object.values(templateThumbnails).forEach((thumbnail) => {
      if (thumbnail.src) thumbnail.src = '' // clear src to prevent memory leaks
    })
    setTemplateThumbnails({})
  }

  return {
    ready,
    thumbnails,
    thumbnailsReady,
    templateThumbnails,
    templateThumbnailsReady,
    readyInitialSlide,
    selectCanvas,
    deleteCanvas,
    toggleCanvasesReadOnly,
    updateCanvases,
    updateCanvasesInStoryMode,
    updateCanvasInStoryMode,
    updateThumbnail,
    updateThumbnailImmediately,
    flushThumbnailsCache,
    dropThumbnailCache,
    clearRegistry,
    hideCanvases,
    clearTemplateThumbnails,
  }
}
