import { memo } from 'react'
import { Row, Col, Switch } from 'antd'

import LazySlider from '../../../../../../components/LazySlider'
import { ColorEditorButtonComponent } from './colorEditorButtonComponent'
import ColorEditorButton from '../../../../colorsEditor/colorEditorButton'

import { useShapeState } from './useShapeState'

const STYLE = {
  marginTop10: { marginTop: '10px' },
  gradientContainer: { display: 'flex', flexWrap: 'wrap', position: 'relative' },
}

/**
 * Gradient controls for shape
 */
export const ShapeControls = (props) => {
  const { activeObjectProps, setActiveObjectModifier, isOpenGradient, setIsOpenGradient } = props

  const { switchShapeBackground, handleChangeColor, handleCoordsChange } = useShapeState({
    activeObjectProps,
    setIsOpenGradient,
    setActiveObjectModifier,
  })

  /**
   * Do not wont to re-render it whenever something updates
   * and Switch is not relying on it
   */
  const MemoizeSwitch = memo(() => (
    <Switch
      className="gradient-switch"
      checkedChildren="Gradient"
      unCheckedChildren="Gradient"
      checked={activeObjectProps.fill?.type}
      onChange={switchShapeBackground}
    />
  ))

  return (
    <>
      <Row align="middle">
        <Col span={8}>
          <MemoizeSwitch />
        </Col>
        <Col span={14}>
          {!isOpenGradient && <ColorEditorButton color={activeObjectProps.fill} onChangeColor={handleChangeColor} />}
          {isOpenGradient && (
            <div style={STYLE.gradientContainer}>
              {Array.from({ length: 2 }, (_, index) => (
                <ColorEditorButtonComponent
                  key={index}
                  index={index}
                  activeObjectProps={activeObjectProps}
                  setActiveObjectModifier={setActiveObjectModifier}
                />
              ))}
            </div>
          )}
        </Col>
      </Row>
      {isOpenGradient && (
        <>
          <Row align="middle" style={STYLE.marginTop10}>
            <Col span={6}>
              <h4>Horizontal</h4>
            </Col>
            <Col span={18}>
              <LazySlider
                range
                value={[
                  activeObjectProps.fill.coords ? activeObjectProps.fill.coords.x1 : 0,
                  activeObjectProps.fill.coords ? activeObjectProps.fill.coords.x2 : 0.4,
                ]}
                min={0}
                max={1}
                step={0.01}
                onChange={(value) => handleCoordsChange(value, 'horizontal')}
              />
            </Col>
          </Row>
          <Row align="middle" gutter={20} style={STYLE.marginTop10}>
            <Col span={6}>
              <h4>Vertical</h4>
            </Col>
            <Col span={18}>
              <LazySlider
                range
                value={[
                  activeObjectProps.fill.coords ? activeObjectProps.fill.coords.y1 : 0,
                  activeObjectProps.fill.coords ? activeObjectProps.fill.coords.y2 : 0.4,
                ]}
                min={0}
                max={1}
                step={0.01}
                onChange={(value) => handleCoordsChange(value, 'vertical')}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  )
}
