import { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Tag,
  List,
  Input,
  Space,
  Radio,
  Empty,
  Table,
  Modal,
  Select,
  Layout,
  Button,
  Tooltip,
  Progress,
  Dropdown,
  Breadcrumb,
} from 'antd'

import VideoItem from './videoItem'
import Icon from '../../components/Icon'
import VideoItemActionMenu from './videoItemActionMenu'
import PageHeader from '../../components/PageHeader/pageHeader'

import { useVideosState } from './useVideosState'
import { videoStatuses, getRenderingTime, formatSeconds } from '../../utils/videos'

import { sorterMenuItems } from './constants'
import video_chat from '../../assets/images/video-chat.svg'

import './videos.less'
import { VideosHeader } from './components'

/**
 * TODO:
 * Breadcrumbs, make standalone component and use template, just pass some dictionary and it will auto generate the rest
 * refactor and improve UI part and check state for DIY and some optimization
 */
const Videos = () => {
  const {
    folders,
    navigate,
    folderId,
    filterInfo,
    videosData,
    openFolder,
    onSelectTag,
    fetchVideos,
    fetchFolders,
    allVideoTags,
    createFolder,
    onDeselectTag,
    setFilterInfo,
    selectedVideos,
    isVideoCreating,
    isDeletedVideos,
    handleSortItems,
    videosListFormat,
    navigateToVideos,
    isFolderCreating,
    handleTableChange,
    setSelectedVideos,
    setIsVideoCreating,
    setVideosListFormat,
    showDeletingConfirm,
    showRestoringConfirm,
    isOpenNewFolderModal,
    getColumnSearchProps,
    inputNewFolderNameRef,
    setIsNewVideoModalOpen,
    setIsOpenNewFolderModal,
  } = useVideosState()

  const [activeItem, setActiveItem] = useState(false)

  const columns = [
    {
      dataIndex: 'thumbnail',
      render: (url, record) =>
        record.url && (
          <Link to={`/preview/${record._id}`}>
            <img
              src={url}
              width={record.data?.format === '9_16' ? 30 : 80}
              style={{ display: 'block', margin: '0 auto' }}
            ></img>
          </Link>
        ),
      responsive: ['xl'],
    },
    {
      title: 'Name',
      dataIndex: 'name',
      filteredValue: filterInfo.filters?.name || null,
      sorter: true,
      sortOrder: filterInfo.sorter?.field === 'name' && filterInfo.sorter.order,
      ...getColumnSearchProps('name'),
      render: (name, record) => (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
          {isDeletedVideos ? (
            <Space>
              <a onClick={() => showRestoringConfirm(record._id)}>{name}</a>
              {record.template && <Tag color="green">Template</Tag>}
            </Space>
          ) : (
            <Link to={`/video/${record._id}`}>{name}</Link>
          )}
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      filterMultiple: false,
      filters: [
        {
          text: 'draft',
          value: 'draft',
        },
        {
          text: 'rendering',
          value: 'rendering',
        },
        {
          text: 'ready',
          value: 'ready',
        },
        {
          text: 'error',
          value: 'error',
        },
      ],
      filteredValue: filterInfo.filters?.status || null,
      render: (status, record) => {
        let style = {
          color: videoStatuses[status]?.color || videoStatuses.draft,
          icon: videoStatuses[status]?.icon ?? videoStatuses.draft.icon,
        }
        if (record.data?.appError)
          return (
            <Tooltip title={record.data.appError}>
              <Tag size="large" {...style}>
                {status}
              </Tag>
            </Tooltip>
          )
        if (status === 'moderation')
          return (
            <Tooltip title="Your video is awaiting moderation. Please check back later.">
              <Tag size="large" {...style}>
                {status}
              </Tag>
            </Tooltip>
          )
        return (
          <Tag size="large" {...style}>
            {status}
          </Tag>
        )
      },
    },
    {
      title: 'Duration',
      align: 'center',
      dataIndex: 'duration',
      render: (duration, video) => {
        if (video.status === 'rendering') {
          const { minutesLeftFormatted, percent } = getRenderingTime(video)
          return (
            <Tooltip title={`${minutesLeftFormatted} left`}>
              <Progress percent={percent} showInfo={false} status="active" size="small" strokeColor="#4868FF" />
            </Tooltip>
          )
        }
        return formatSeconds(duration)
      },
      responsive: ['sm'],
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      responsive: ['lg'],
      filteredValue: filterInfo.filters?.tags || null,
      filters: allVideoTags
        ?.map((t) => ({
          text: t,
          value: t,
        }))
        .filter((t) => t.value),
      render: (tags, video) => {
        return (
          <Select
            defaultValue={tags}
            mode="tags"
            placeholder="Add tags"
            bordered={false}
            size="small"
            style={{ width: '100%', maxWidth: 200 }}
            suffixIcon={<Icon name="down_arrow" />}
            onSelect={(tag) => onSelectTag(tag, video)}
            onDeselect={(tag) => onDeselectTag(tag, video)}
          >
            {allVideoTags?.map((t) => {
              if (t) return <Select.Option key={t}>{t}</Select.Option>
              else return null
            })}
          </Select>
        )
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render: (d) => new Date(d).toLocaleString(),
      responsive: ['md'],
      sorter: true,
      className: 'date-column',
      sortOrder: filterInfo.sorter?.field === 'createdAt' && filterInfo.sorter.order,
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      className: 'date-column',
      render: (d) => new Date(d).toLocaleString(),
      responsive: ['md'],
      sorter: true,
      align: 'left',
      sortOrder: filterInfo.sorter?.field === 'updatedAt' && filterInfo.sorter.order,
    },
    {
      title:
        selectedVideos.length > 0 ? (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <Icon name="delete" onClick={showDeletingConfirm} />
            <span style={{ marginLeft: '6px' }}>{selectedVideos.length}</span>
          </span>
        ) : null,
      key: 'action',
      colSpan: 1,
      render: (text, record) => (
        <VideoItemActionMenu
          video={record}
          isDeletedVideos={isDeletedVideos}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          fetchFolders={fetchFolders}
          fetchVideos={fetchVideos}
          filterInfo={filterInfo}
          isVideoCreating={isVideoCreating}
          setIsVideoCreating={setIsVideoCreating}
          isAlwaysVisible={true}
        />
      ),
    },
    Table.SELECTION_COLUMN,
  ]

  return (
    <Layout.Content className="videos-content layout-container">
      {openFolder && (
        <Breadcrumb separator={<Icon name="right_arrow" />}>
          <Breadcrumb.Item onClick={navigateToVideos} className="custom-breadcrumbs-item">
            Videos
          </Breadcrumb.Item>
          {[...openFolder?.parentFolders, openFolder].map((folder, index) => (
            <Breadcrumb.Item key={index}>
              {folder._id === folderId ? folder.name : <Link to={`/videos/${folder._id}`}>{folder.name}</Link>}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      )}
      <PageHeader
        title={openFolder ? openFolder.name : !folderId && !isDeletedVideos ? 'My videos' : ''}
        onBack={
          openFolder
            ? () => navigate(openFolder.parentFolderId ? `/videos/${openFolder.parentFolderId}` : '/videos')
            : false
        }
        extra={
          (folders?.length > 0 || openFolder?.children?.length > 0 || videosData?.videos?.length > 0) && [
            <Radio.Group
              key="format-btns"
              className="format-radio-btns"
              value={videosListFormat}
              onChange={(e) => {
                setVideosListFormat(e.target.value)
                localStorage.setItem('videosListFormat', e.target.value)
              }}
            >
              <Radio.Button
                size="small"
                value="grid"
                className={`format-radio-btn ${videosListFormat === 'grid' ? 'active' : ''}`}
              >
                <Icon name="grid" />
              </Radio.Button>
              <Radio.Button
                size="small"
                value="list"
                className={`format-radio-btn ${videosListFormat === 'list' ? 'active' : ''}`}
              >
                <Icon name="list" />
              </Radio.Button>
            </Radio.Group>,
            <Dropdown
              key="sorter-dropdown"
              menu={{
                items: sorterMenuItems,
                selectedKeys: [
                  filterInfo?.sorter?.field && filterInfo?.sorter?.order
                    ? `${filterInfo.sorter.field}_${filterInfo?.sorter?.order}`
                    : 'updatedAt_descend',
                ],
                onClick: handleSortItems,
              }}
              trigger={['click']}
            >
              <div className="sorter-dropdown-title">
                {sorterMenuItems.find(
                  (item) =>
                    item.key ===
                    (filterInfo?.sorter?.field && filterInfo?.sorter?.order
                      ? `${filterInfo.sorter.field}_${filterInfo?.sorter?.order}`
                      : 'updatedAt_descend'),
                )?.label || sorterMenuItems[0].label}
                <>
                  <Icon name="down_arrow" className="down_icon" />
                  <Icon name="up_arrow" className="up_icon" />
                </>
              </div>
            </Dropdown>,
          ]
        }
      />
      <VideosHeader
        folders={folders}
        folderId={folderId}
        openFolder={openFolder}
        filterInfo={filterInfo}
        activeItem={activeItem}
        fetchVideos={fetchVideos}
        fetchFolders={fetchFolders}
        setActiveItem={setActiveItem}
        isDeletedVideos={isDeletedVideos}
        isFolderCreating={isFolderCreating}
        setIsNewVideoModalOpen={setIsNewVideoModalOpen}
        setIsOpenNewFolderModal={setIsOpenNewFolderModal}
      />
      {videosData?.videos?.length === 0 &&
        !isDeletedVideos &&
        !(Object.keys(filterInfo?.condition?.match).length > 2) &&
        !folderId && (
          <div className="content">
            <Empty
              description={<p style={{ marginBottom: 30 }}>Create your first video by clicking a button below.</p>}
              image={<img src={video_chat} />}
              imageStyle={{ marginBottom: 30, height: 70 }}
            >
              <Button
                icon={<Icon name="add_video" />}
                style={{ margin: '0 auto' }}
                type="primary"
                onClick={() => setIsNewVideoModalOpen(true)}
              >
                Create Now
              </Button>
            </Empty>
          </div>
        )}{' '}
      {(!videosData ||
        videosData?.videos?.length > 0 ||
        Object.keys(filterInfo?.condition?.match).length > 2 ||
        (isDeletedVideos && videosData?.videos?.length === 0 && folders?.length === 0)) && (
        <>
          {videosListFormat === 'grid' ? (
            <List
              grid={{
                gutter: 0,
                xs: 2,
                sm: 2,
                md: 3,
                lg: 4,
                xl: 5,
                xxl: 5,
              }}
              className="videos-grid"
              dataSource={videosData?.videos}
              loading={!videosData}
              renderItem={(video) => (
                <VideoItem
                  video={video}
                  isDeletedVideos={isDeletedVideos}
                  isVideoCreating={isVideoCreating}
                  setIsVideoCreating={setIsVideoCreating}
                  activeItem={activeItem}
                  filterInfo={filterInfo}
                  setActiveItem={setActiveItem}
                  fetchVideos={() => fetchVideos(filterInfo.condition)}
                  fetchFolders={async () => await fetchFolders(filterInfo?.condition)}
                  showRestoringConfirm={showRestoringConfirm}
                />
              )}
              pagination={{
                current: videosData?.page,
                pageSize: 50,
                align: 'center',
                total: videosData?.total,
                showSizeChanger: false,
                onChange: (page) => {
                  localStorage.setItem('page', page)
                  setFilterInfo({
                    ...filterInfo,
                    condition: { ...filterInfo.condition, page },
                  })
                },
              }}
            />
          ) : (
            <Table
              columns={columns}
              dataSource={videosData?.videos}
              rowKey="_id"
              className="videos-table"
              loading={!videosData}
              pagination={{
                current: videosData?.page,
                pageSize: 50,
                total: videosData?.total,
                showSizeChanger: false,
                position: ['bottomCenter'],
                hideOnSinglePage: true,
              }}
              rowSelection={{
                selectedRowKeys: selectedVideos.map((v) => v._id),
                onChange: (ids, videos) => setSelectedVideos(videos),
              }}
              locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> }}
              onChange={handleTableChange}
            />
          )}
        </>
      )}
      <Modal
        title="Create folder"
        open={isOpenNewFolderModal}
        width={350}
        destroyOnClose
        okText="Create"
        confirmLoading={isFolderCreating}
        onOk={async () => await createFolder()}
        onCancel={() => setIsOpenNewFolderModal(false)}
      >
        <Input defaultValue="New folder" ref={inputNewFolderNameRef} />
      </Modal>
    </Layout.Content>
  )
}

export default Videos
