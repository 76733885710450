import { debounce } from 'throttle-debounce'
import { useCallback, useMemo, useState } from 'react'

import Icon from '../../../../components/Icon'
import { buildEasing, getAnimationTimeSelectValue } from './components'

import { useStore } from '../../../../store'
import { animations } from '../../../../data/animations'
import { DEFAULT_ANIMATION_EASING } from '../../slide/canvas/animations/easings'

import { ANIMATION_TYPES, MARKER_REGEX } from './constants'

/**
 *
 * @param {{activeObjectProps: any, type: string, data: currentSlide}} props
 * @returns {string}
 */
const getAnimationType = ({ activeObjectProps, type, data }) => {
  if (activeObjectProps) {
    if (type === ANIMATION_TYPES.enter) {
      return activeObjectProps?.animation?.type
    } else {
      return activeObjectProps?.animation?.exitType
    }
  }
  if (type === ANIMATION_TYPES.enter) {
    return data.animation
  } else {
    return data.exitAnimation
  }
}

/**
 *
 * @param {{activeObjectProps, data, onChangeTime}} props
 * @returns {{type, options, animationType, animationsArray, handleChangeTime, easingSelectData, animationTimeValue, easingSelectOptions, changeAnimationType, onInputAnimationTimeChange}}
 */
export const useAnimationsState = (props) => {
  const { activeObjectProps, data, onChangeTime } = props

  const markMap = useStore((stores) => stores.videosStore.markMap)

  const [type, setType] = useState(ANIMATION_TYPES.enter)

  const animationType = getAnimationType({ activeObjectProps, type, data })

  const markers = useMemo(() => {
    const matches = [...data.speech.matchAll(MARKER_REGEX)]
    const array = []
    for (let index = 0; index < matches.length; index++) {
      const match = matches[index][1]
      const mark = markMap?.find((mark) => Object.values(mark)[0] === match)
      if (mark) {
        const newValue = {
          value: match,
          label: (
            <span>
              <span className="marker-tag-wrapper">
                <span>{Object.keys(mark)[0]}</span>
              </span>
              Mark
            </span>
          ),
        }
        array.push(newValue)
      }
    }
    return array
  }, [data.speech, markMap])

  const options = useMemo(
    () => [
      {
        value: 'start',
        label: (
          <span>
            <Icon name="scene_start" />
            Scene start
          </span>
        ),
      },
      ...markers,
      {
        value: 'end',
        label: (
          <span>
            <Icon name="scene_end" />
            Scene end
          </span>
        ),
      },
    ],
    [markers],
  )

  const changeAnimationType = (v) => setType(v)

  const animationsArray = [animations.default].concat(animations[type.toLowerCase()])

  const findCurrentAnimationValue = useCallback(() => {
    const value = getAnimationTimeSelectValue(activeObjectProps?.animation, type)
    const result = markers.find((element) => element.value === value)
    if (!result) {
      return value
    }
    return result
  }, [activeObjectProps?.animation, type, markers])

  const handleChangeTime = (v) => {
    onChangeTime(v, type, true)
  }

  const animationTimeValue = useMemo(() => findCurrentAnimationValue(), [findCurrentAnimationValue])

  const onInputAnimationTimeChange = useCallback(
    debounce(500, (v, type) => onChangeTime(v, type)),
    [onChangeTime],
  )

  // as it's always static we can memoize it once and forget
  const easingSelectOptions = useMemo(() => buildEasing(), [])

  /**
   * create object for Select depending on different type
   * @returns {{isDisabled: boolean, value: string}}
   */
  const generateEasingSelectData = () => {
    if (type === ANIMATION_TYPES.enter) {
      return {
        value: activeObjectProps?.animation?.easing || DEFAULT_ANIMATION_EASING,
        isDisabled: !activeObjectProps?.animation?.type,
      }
    }
    return {
      value: activeObjectProps?.animation?.exitEasing || DEFAULT_ANIMATION_EASING,
      isDisabled: !activeObjectProps?.animation?.exitType,
    }
  }

  const easingSelectData = generateEasingSelectData()

  const disabledAnimationTooltipTitle = activeObjectProps?.meta?.interactivity
    ? 'Animations are disabled for interactive elements. If animations were applied earlier they would be changed to default "Fade In" and "Fade Out"'
    : ''

  return {
    type,
    options,
    animationType,
    animationsArray,
    handleChangeTime,
    easingSelectData,
    animationTimeValue,
    easingSelectOptions,
    changeAnimationType,
    onInputAnimationTimeChange,
    disabledAnimationTooltipTitle,
  }
}
