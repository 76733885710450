import { useState } from 'react'
import { Form, Input, Button, Card, Checkbox, Row, Col, Select } from 'antd'
import { UserOutlined, LockOutlined, MailOutlined, RightOutlined } from '@ant-design/icons'
import axios from 'axios'
import { useStore } from '../../store'
import pageroLogo from '../../assets/images/pagero-logo.png'
import shieldAmericasPerf from '../../assets/images/shields/Presentation_HighPerformer_Americas_HighPerformer.png'
import shieldEMEAPerf from '../../assets/images/shields/VideoEditing_HighPerformer_EMEA_HighPerformer.png'
import shieldEasy2Use from '../../assets/images/shields/Presentation_EasiestToUse_EaseOfUse.png'
import shieldMostLike from '../../assets/images/shields/Presentation_UsersMostLikelyToRecommend_Small-Business_Nps.png'
import shieldHighPerf from '../../assets/images/shields/SyntheticMedia_HighPerformer_HighPerformer.png'

import './auth.less'
import { useElaiNotification } from '../../hooks/useElaiNotification'
import { Navigate } from 'react-router-dom'
import configService from '../../utils/config'

const { Option } = Select

const Signup = () => {
  const notification = useElaiNotification()
  const authStore = useStore(({ authStore }) => authStore)
  const [form] = Form.useForm()
  const [requesting, setRequesting] = useState(false)
  const { apiUrl } = configService.get().urls

  if (authStore.user && !requesting) return <Navigate replace to="/" />

  const onFinish = async (signupData) => {
    try {
      setRequesting(true)
      const { data } = await axios.post(apiUrl + '/auth/signup', signupData)

      localStorage.setItem('isJustRegistered', 'true')
      await authStore.login(data, true)

      window.location.replace('/poll')
    } catch (error) {
      const message = error.response?.data?.message || error.message
      notification.error({ message })
    } finally {
      setRequesting(false)
    }
  }

  return (
    <div className="signup-content-wrapper">
      <Row justify="center" className="signup-content">
        <Col lg={11} md={11}>
          <div className="signup-description">
            <h1>Leading AI Video Generation Tool for Corporate Learning.</h1>
            <p className="signup-content-quote">
              “Elai has helped us streamline video production via an easy to use platform and great customer service”.
            </p>
            <p className="signup-content-signature">Pagero Team</p>
            <img alt="Pagero logotype" src={pageroLogo} className="signup-content-logo" />
            <div className="signup-content-shields">
              <img
                alt="High performer Americas Winter 2024 shield"
                src={shieldAmericasPerf}
                className="signup-content-shield"
              />
              <img
                alt="High performer EMEA Winter 2024 shield"
                src={shieldEMEAPerf}
                className="signup-content-shield"
              />
              <img alt="Easiest to use Winter 2024 shield" src={shieldEasy2Use} className="signup-content-shield" />
              <img
                alt="Users most likely to recomend Small Business Winter 2024 shield"
                src={shieldMostLike}
                className="signup-content-shield"
              />
              <img alt="High performer Winter 2024 shield" src={shieldHighPerf} className="signup-content-shield" />
            </div>
          </div>
        </Col>
        <Col lg={13} md={13}>
          <Card className="login signup">
            <h2>Create your account</h2>
            <Form form={form} onFinish={onFinish} layout="vertical" requiredMark={false}>
              <Form.Item
                name="name"
                label="Name"
                validateTrigger="onBlur"
                rules={[{ required: true, min: 2, message: 'Name should be longer than 2 chars' }]}
              >
                <Input prefix={<UserOutlined />} placeholder="First and last name" size="large" />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                validateTrigger="onBlur"
                rules={[{ required: true, type: 'email', message: 'Please, enter your email' }]}
              >
                <Input prefix={<MailOutlined />} placeholder="you@company.com" size="large" />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                validateTrigger="onBlur"
                rules={[{ max: 128, min: 8, pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/ }]}
                help={
                  <p className="form-password-hint">
                    Password should be at least 8 characters long, must contain a lowercase letter, an uppercase letter,
                    and a number
                  </p>
                }
              >
                <Input.Password prefix={<LockOutlined />} placeholder="Create a strong password" size="large" />
              </Form.Item>
              <Form.Item
                name="useType"
                label="What will you use videos for?"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Please answer this short question' }]}
              >
                <Select prefix={<UserOutlined />} size="large">
                  <Option value="Personal">Personal use</Option>
                  <Option value="Work">Work</Option>
                  <Option value="Education">Education</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="accept"
                valuePropName="checked"
                style={{ marginTop: 20 }}
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(new Error('Please accept our Terms & Conditions and Privacy Policy')),
                  },
                ]}
              >
                <Checkbox>
                  Please accept our{' '}
                  <a href="https://elai.io/terms-and-conditions" target="_blank" rel="noreferrer">
                    Terms & Conditions
                  </a>{' '}
                  and{' '}
                  <a href="https://elai.io/privacy-policy" target="_blank" rel="noreferrer">
                    Privacy Policy
                  </a>
                </Checkbox>
              </Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                icon={<RightOutlined />}
                className="btn-login btn-arrow"
                loading={requesting}
                size="large"
              >
                Create account
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Signup
