import { useState, useRef, useCallback, useEffect, useMemo, Fragment } from 'react'
import { Typography, Button, Dropdown, Tag, Input, Checkbox, Tooltip } from 'antd'
import { request } from '../../utils/api'
import Icon from '../Icon'
import useIntersection from '../../hooks/useIntersection'
import useClickOutside from '../../hooks/useClickOutside'
import { STYLE, onlyNewLinesRegex, formatDate } from './constants'
import { useElaiNotification } from '../../hooks/useElaiNotification'

// TODO: refactor this too
const CommentCard = ({
  comment,
  video,
  userId,
  isEnabledSwitchingSlide,
  editingComment,
  setEditingComment,
  loadingComment,
  setLoadingComment,
  updateVideo,
  updateActiveSlide,
  unviewedComments,
  setUnviewedComments,
}) => {
  const [commentText, setCommentText] = useState('')
  const [isInView, setIsInView] = useState(false)
  const commentCardRef = useRef()
  const commentTextAreaRef = useRef()
  const commentMenuRef = useRef()
  const notification = useElaiNotification()

  const callback = useCallback(() => {
    setIsInView(true)
  }, [setIsInView])

  useIntersection(commentCardRef, callback)

  const handleClickOutside = useCallback(() => {
    if (editingComment === comment._id) setEditingComment(false)
  }, [editingComment, comment._id])

  useClickOutside([commentTextAreaRef, commentMenuRef], handleClickOutside)

  const slideIndex = useMemo(
    () => video.slides.findIndex((slide) => slide.id === comment.slideId),
    [video.slides.length, comment.slideId],
  )

  const commentMenuItems = useMemo(() => {
    const items = [
      {
        key: 'delete',
        icon: <Icon name="delete" />,
        label: 'Delete',
      },
    ]
    if (userId === comment.userId && (!comment.slideId || slideIndex !== -1))
      items.push({
        key: 'edit',
        icon: <Icon name="edit" />,
        label: 'Edit',
      })
    return items
  }, [userId, comment, slideIndex])

  /**
   * If comment is in view, delete it from array of unviewed comments
   */
  useEffect(() => {
    if (isInView && unviewedComments.includes(comment._id)) {
      const timerId = setTimeout(() => {
        setUnviewedComments((unviewedCommentsState) => unviewedCommentsState.filter((id) => id !== comment._id))
      }, 1000)
      return () => clearTimeout(timerId)
    }
  }, [isInView])

  const handleDeleteComment = async () => {
    setLoadingComment(true)
    const comments = await request({
      method: 'delete',
      url: `/videos/comments/${video._id}`,
      data: { comment: { _id: comment._id } },
    })
    if (!comments) return setLoadingComment(false)
    updateVideo({ comments }, { ignoreHistory: true })
    setLoadingComment(false)
  }

  const handleEditComment = async (e) => {
    if (e.shiftKey) return
    if (!commentText || onlyNewLinesRegex.test(commentText))
      return notification.error({ message: 'Please input comment text' })
    setLoadingComment(true)
    const comments = await request({
      method: 'patch',
      url: `/videos/comments/${video._id}`,
      data: { comment: { _id: comment._id, text: commentText } },
    })
    if (!comments) return setLoadingComment(false)
    updateVideo({ comments }, { ignoreHistory: true })
    setLoadingComment(false)
    setEditingComment(false)
  }

  const handleClickCommentMenu = async ({ key }) => {
    if (key === 'delete') await handleDeleteComment()
    else if (key === 'edit') onClickEditComment()
  }

  const handleChangeCommentText = (e) => {
    setCommentText(e.target.value)
  }

  const onClickEditComment = () => {
    if (userId !== comment.userId || loadingComment) return
    setCommentText(comment.text)
    setEditingComment(comment._id)
  }

  const onClickCommentCard = (e) => {
    const isCardClicked = !e.target.closest('.btn-menu') && !e.target.closest('.ant-checkbox')
    if (comment.slideId && isEnabledSwitchingSlide && slideIndex !== -1 && isCardClicked) {
      updateActiveSlide?.(slideIndex)
    }
  }

  const onChangeCommentResolved = async (e) => {
    setLoadingComment(true)
    const comments = await request({
      method: 'patch',
      url: `/videos/comments/${video._id}`,
      data: { comment: { _id: comment._id, resolved: e.target.checked } },
    })
    if (!comments) return setLoadingComment(false)
    updateVideo({ comments }, { ignoreHistory: true })
    setLoadingComment(false)
  }

  return (
    <div
      ref={commentCardRef}
      className={`comment-card ${unviewedComments.includes(comment._id) ? 'unviewed' : ''}`}
      style={comment.slideId && isEnabledSwitchingSlide && slideIndex !== -1 ? STYLE.cursorPointer : null}
      onClick={onClickCommentCard}
    >
      <div className="comment-card-title">
        <h4 className="comment-user-name" title={comment.userName}>
          {comment.userName}
        </h4>
        <Typography.Text type="secondary" className="comment-time" title={formatDate(comment.createdAt)}>
          {formatDate(comment.createdAt)}
        </Typography.Text>
        <div className="comment-controls">
          <Tooltip
            title={
              comment.deleted || (comment.slideId && slideIndex === -1)
                ? ''
                : `Mark as ${comment.resolved ? 'Open' : 'Resolved'}`
            }
          >
            <span>
              <Checkbox
                checked={comment.resolved || (comment.slideId && slideIndex === -1)}
                disabled={loadingComment}
                style={{ pointerEvents: comment.deleted ? 'none' : 'all' }}
                onChange={onChangeCommentResolved}
              />
            </span>
          </Tooltip>
          <Dropdown
            menu={{ items: commentMenuItems, onClick: handleClickCommentMenu }}
            placement="bottomRight"
            disabled={loadingComment}
            dropdownRender={(menu) => <div ref={commentMenuRef}>{menu}</div>}
          >
            <Button type="text" size="small" icon={<Icon name="menu" />} className="btn-menu" />
          </Dropdown>
        </div>
      </div>
      <div className="comment-info">
        {comment.slideId &&
          (comment.deleted || (comment.slideId && slideIndex === -1) ? (
            <Tag color="#cf5d60" className="comment-slide-tag">
              Deleted slide
            </Tag>
          ) : (
            <Tag color="#31353b" className="comment-slide-tag">
              Slide {slideIndex + 1}
            </Tag>
          ))}
        {editingComment === comment._id ? (
          <div ref={commentTextAreaRef} className="textarea-container">
            <Input.TextArea
              value={commentText}
              placeholder="Write your comment here"
              className="comment-textarea"
              disabled={loadingComment}
              rows={3}
              style={STYLE.resizeNone}
              onChange={handleChangeCommentText}
              onPressEnter={handleEditComment}
            />
            <Button
              className="btn-send"
              disabled={!commentText || loadingComment}
              type="primary"
              shape="circle"
              size="small"
              icon={<Icon name="check" />}
              onClick={handleEditComment}
            />
          </div>
        ) : (
          <p className="comment-text" onDoubleClick={onClickEditComment}>
            {comment.text.split('\n').map((line, index) => (
              <Fragment key={index}>
                {line}
                <br />
              </Fragment>
            ))}
          </p>
        )}
      </div>
    </div>
  )
}

export default CommentCard
