import { Tag } from 'antd'

export const VoiceItemTag = (props) => {
  const {
    item,
    voice,
    activeVoice,
    isVoiceActive,
    setActiveVoice,
    pauseVoicePreview,
    voicePreviewPlayer,
    setVoicePreviewPlayer,
  } = props

  const handleItemClick = (e) => {
    e.stopPropagation()
    if (voicePreviewPlayer.state === 'playing') {
      setVoicePreviewPlayer({ ...voicePreviewPlayer, state: 'idle' })
      pauseVoicePreview()
    }
    setActiveVoice({ ...activeVoice, voice: { ...voice, id: item.id, style: 'default' } })
  }

  return (
    <Tag
      key={item.id}
      className={`voice-item-tag ${
        item.id === activeVoice.voice.id ||
        (voicePreviewPlayer.state === 'playing' && voicePreviewPlayer.id === item.id && !isVoiceActive)
          ? 'active'
          : ''
      }`}
      onClick={handleItemClick}
    >
      {item.name}
    </Tag>
  )
}
