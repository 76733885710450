/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Row, Statistic, Card, Spin, Table, Layout, Space, Popconfirm, Tag, Tooltip, Progress, Button } from 'antd'
import React, { useEffect, useState } from 'react'
import {
  YoutubeOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
  DeleteOutlined,
  DollarOutlined,
  FieldTimeOutlined,
} from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { request } from '../../utils/api'
import { getRenderingTime, formatSeconds } from '../../utils/videos'
import Icon from '../../components/Icon'

const Videos = () => {
  const [videos, setVideos] = useState(null)
  const [summary, setSummary] = useState(null)

  const fetchVideos = async () => {
    setVideos(null)
    const videos = await request({ method: 'get', url: 'admin/videos' })
    setVideos(videos)
  }

  const fetchSummary = async () => {
    setSummary('loading')
    const { summary, videos } = await request({ method: 'get', url: 'admin/videos/summary' })
    setVideos((currentVideos) => [...currentVideos, ...videos])
    setSummary(summary)
  }

  const deleteVideo = async (id) => {
    await request({ method: 'patch', url: `/videos/${id}?from=delete`, data: { deleted: true } })
    await fetchVideos()
    if (summary.total) await fetchSummary()
  }

  useEffect(() => {
    fetchVideos()
    //await fetchSummary()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = [
    {
      dataIndex: 'thumbnail',
      width: 100,
      align: 'center',
      render: (url, record) =>
        record.url && (
          <Link to={`/preview/${record._id}`}>
            <img src={url} width={record.data?.format === '9_16' ? 30 : 80}></img>
          </Link>
        ),
      responsive: ['xl'],
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: 230,
      render: (name, record) => {
        // if updatedAT more than 3 hours
        const ifStuck = record.status === 'rendering' && (new Date() - new Date(record.updatedAt)) / 36e5 > 3
        return (
          <Link to={`/video/${record._id}`} target="_blank" rel="noopener noreferrer">
            {name}
            {record.data?.resolution === '4K' && <Tag color="blue">4K</Tag>}
            {record.status === 'moderation' && <Tag color="red">Need Moderation</Tag>}
            {record.data?.internalError && <Tag color="error">Need fix</Tag>}
            {record.flagged && <Tag>Flagged</Tag>}
            {record.deleted && <Tag color="warning">Deleted</Tag>}
            {ifStuck && <Tag color="error">Stuck</Tag>}
          </Link>
        )
      },
    },
    {
      title: 'Status',
      width: 120,
      dataIndex: 'status',
      render: (status, record) => {
        const avatar = record.slides[0]?.avatar
        let style = { color: 'default', icon: null }
        if (status === 'ready') style = { color: 'success', icon: <CheckCircleOutlined /> }
        else if (status === 'error') style = { color: 'error', icon: <CloseCircleOutlined /> }
        else if (status === 'rendering') style = { color: 'processing', icon: <SyncOutlined spin /> }
        let tags = [
          record.data?.internalError || record.data?.appError ? (
            <Tooltip title={record.data?.internalError || record.data?.appError}>
              <Tag size="large" {...style}>
                {status}
              </Tag>
            </Tooltip>
          ) : (
            <Tag size="large" {...style}>
              {status}
            </Tag>
          ),
          <Tag size="large">{['mascot', 'photo'].includes(avatar?.type) ? avatar.type : avatar.code}</Tag>,
        ]
        if (record.tags.length) tags.push(<Tag>{record.tags[0].toString()}</Tag>)
        if (record.deleted)
          tags.push(
            <Tag color="orange">
              <Icon name="delete" />
              deleted
            </Tag>,
          )

        return tags.map((tag, index) => <React.Fragment key={record._id + 'tag' + index}>{tag}</React.Fragment>)
      },
    },
    {
      title: 'Duration',
      width: 120,
      dataIndex: 'duration',
      render: (duration, video) => {
        if (video.status === 'rendering') {
          const { minutesLeftFormatted, percent } = getRenderingTime(video)
          return (
            <Tooltip title={`${minutesLeftFormatted} left`}>
              <Progress percent={percent} showInfo={false} status="active" size="small" strokeColor="#4868FF" />
            </Tooltip>
          )
        }
        return formatSeconds(duration)
      },
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      render: (d) => new Date(d).toLocaleString(),
      width: 180,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render: (d) => new Date(d).toLocaleString(),
      width: 180,
    },
    {
      title: 'Account',
      dataIndex: 'accountStatus',
      width: 100,
      render: (d, r) => (
        <span>
          {d} {r?.parentAccountStatus && <Tag>{r.parentAccountStatus}</Tag>}
        </span>
      ),
    },
    {
      title: 'Plan',
      dataIndex: 'plan',
      width: 100,
      render: (d, r) => (
        <span>
          {d} {r?.parentAccountPlan && <Tag>{r.parentAccountPlan}</Tag>}
        </span>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'userEmail',
    },
    {
      key: 'action',
      fixed: 'right',
      width: 60,
      render: (text, record) => (
        <Space style={{ display: 'flex', justifyContent: 'center' }}>
          {record.url && (
            <Link to={`/preview/${record._id}`}>
              <YoutubeOutlined />
            </Link>
          )}
          <Popconfirm
            title="Are you sure you want to delete this video?"
            onConfirm={() => deleteVideo(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Icon name="delete" />
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <Layout.Content className="admin-content">
      <div className="content">
        <h2>Recent Videos (24H)</h2>
        {summary?.total ? (
          <Card>
            <Row gutter={16}>
              <Col span={3}>
                <Statistic
                  title="Minutes Trial"
                  value={Math.round(summary.minutesTrial)}
                  prefix={<FieldTimeOutlined />}
                />
              </Col>
              <Col span={3}>
                <Statistic title="Minutes Paid" value={Math.round(summary.minutesPaid)} prefix={<DollarOutlined />} />
              </Col>
              <Col span={3}>
                <Statistic title="Rendered Trial" value={summary.renderedTrial} />
              </Col>
              <Col span={3}>
                <Statistic title="Rendered Paid" value={summary.renderedPaid} />
              </Col>
              <Col span={3}>
                <Statistic title="Deleted" value={summary.deleted} prefix={<DeleteOutlined />} />
              </Col>
            </Row>
          </Card>
        ) : summary === 'loading' ? (
          <Spin size="large" />
        ) : (
          <Button onClick={fetchSummary}>Fetch Summary and all videos</Button>
        )}
        {videos === null ? (
          <Spin size="large" />
        ) : (
          <Table sticky scroll={{ x: 1600 }} columns={columns} dataSource={videos} rowKey="_id" pagination={false} />
        )}
      </div>
    </Layout.Content>
  )
}

export default Videos
