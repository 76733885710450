import {
  Button,
  Space,
  Table,
  Layout,
  Statistic,
  Tag,
  Modal,
  Tooltip,
  Input,
  InputNumber,
  Switch,
  DatePicker,
  Popconfirm,
  Divider,
  Dropdown,
  Select,
} from 'antd'
import React, { useState, useEffect } from 'react'
import {
  LoginOutlined,
  DollarOutlined,
  EditOutlined,
  SearchOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons'
import Icon from '../../components/Icon'
import { useStore } from '../../store'
import { request } from '../../utils/api'
import { escapeRegExp } from '../../utils/helpers'
import { useElaiNotification } from '../../hooks/useElaiNotification'

const officeBagIcon = <Icon name="office_bag" />
const mortarboardIcon = <Icon name="mortarboard" />

const Users = () => {
  const notification = useElaiNotification()
  const [data, setData] = useState({})
  const [modalEditAccount, setModalEditAccount] = useState()
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [filterInfo, setFilterInfo] = useState({ sorter: {}, filters: {}, condition: null, additionalFilters: {} })
  const [loader, setLoader] = useState(false)
  const authStore = useStore((stores) => stores.authStore)
  const { refreshSession, user } = authStore

  const fetchAccountsData = async () => {
    setLoader(true)
    const accountsData = await request({ method: 'post', url: 'admin/accounts/lookup', data: filterInfo.condition })
    setData(accountsData)
    setLoader(false)
  }

  useEffect(() => {
    if (filterInfo.condition) fetchAccountsData()
  }, [filterInfo.condition])

  const updateSelectedUserRole = ({ accountId, userId, role }) => {
    const accountIndex = data.accounts.findIndex((account) => account._id === accountId)
    let updatedAccount = { ...data.accounts[accountIndex] }
    const updatedUserIndex = updatedAccount.users.findIndex((user) => user._id === userId)
    const updatedUser = { ...updatedAccount.users[updatedUserIndex], role }
    const updatedUsers = [
      ...updatedAccount.users.slice(0, updatedUserIndex),
      updatedUser,
      ...updatedAccount.users.slice(updatedUserIndex + 1),
    ]
    updatedAccount = { ...updatedAccount, users: updatedUsers }
    setSelectedAccount(updatedAccount)

    const updatedAccounts = [
      ...data.accounts.slice(0, accountIndex),
      updatedAccount,
      ...data.accounts.slice(accountIndex + 1),
    ]
    setData({ ...data, accounts: updatedAccounts })
  }

  const handleTableChange = async (pagination, filters, sorter) => {
    const condition = { page: pagination.current }
    condition.match = {}
    condition.userMatch = {}

    if (sorter.order) condition.sort = { [sorter.field]: sorter.order === 'ascend' ? 1 : -1 }
    for (const key in filters)
      if (filters[key]) {
        if (key === 'name') condition.match.name = { $regex: escapeRegExp(filters.name[0]), $options: 'i' }
        else if (key === 'provider') condition.match['source.provider'] = filters.provider[0]
        else if (key === 'email') condition.userMatch.email = { $regex: escapeRegExp(filters.email[0]), $options: 'i' }
        else if (key === 'jobTitle')
          condition.userMatch.jobTitle = { $regex: escapeRegExp(filters.jobTitle[0]), $options: 'i' }
        else if (key === 'userStatus') {
          condition.userMatch.status = filters.userStatus[0]
          condition.userMatch.accountRole = 'admin'
        } else condition.match[key] = filters[key][0]
      }

    if (filterInfo.additionalFilters.subscribers) {
      condition.match.status = filterInfo.condition?.match.status
      condition.match.billingCycle = filterInfo.condition?.match.billingCycle
    }
    if (filterInfo.additionalFilters.createdAt) {
      condition.match.createdAt = filterInfo.condition?.match.createdAt
    }

    if (Object.keys(condition.match).length === 0) delete condition.match
    if (Object.keys(condition.userMatch).length === 0) delete condition.userMatch

    setFilterInfo({ sorter, filters, condition, additionalFilters: filterInfo.additionalFilters })
  }

  const showSubscribers = (checked) => {
    if (checked)
      setFilterInfo({
        ...filterInfo,
        filters: { ...filterInfo.filters, status: null },
        condition: {
          ...filterInfo.condition,
          match: { ...filterInfo.condition?.match, status: 'paid', billingCycle: { $in: ['monthly', 'annual'] } },
        },
        additionalFilters: { ...filterInfo.additionalFilters, subscribers: true },
      })
    else {
      setFilterInfo((info) => {
        delete info.condition.match.status
        delete info.condition.match.billingCycle
        return {
          ...info,
          filters: { ...filterInfo.filters, status: null },
          condition: { ...info.condition, match: { ...info.condition.match } },
          additionalFilters: { ...filterInfo.additionalFilters, subscribers: false },
        }
      })
    }
  }

  const getAccountsFromPeriod = (dates) => {
    if (dates) {
      setFilterInfo({
        ...filterInfo,
        condition: {
          ...filterInfo.condition,
          match: {
            ...filterInfo.condition?.match,
            createdAt: { startDate: dates[0].$d, endDate: dates[1].$d },
          },
        },
        additionalFilters: { ...filterInfo.additionalFilters, createdAt: true },
      })
    } else {
      setFilterInfo((info) => {
        delete info.condition.match.createdAt
        return {
          ...info,
          condition: { ...info.condition, match: { ...info.condition.match } },
          additionalFilters: { ...filterInfo.additionalFilters, createdAt: false },
        }
      })
    }
  }

  const onChangeDatePicker = (dates) => {
    getAccountsFromPeriod(dates)
  }

  const downloadReport = (data, fileName) => {
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${fileName}.csv`)
    document.body.appendChild(link)
    link.click()
  }

  const getThinkificReport = async () => {
    const data = await request({ method: 'get', url: 'admin/accounts/thinkific-report' })
    downloadReport(data, 'thinkific')
  }

  const exportCsv = async () => {
    const accountsData = await request({
      method: 'post',
      url: 'admin/accounts/lookup',
      data: { ...filterInfo.condition, csv: true },
    })
    downloadReport(accountsData, 'accounts')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={confirm}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters()
              confirm()
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  })

  const setEditParentAccount = () => {
    setModalEditAccount(data.accounts.find((a) => a._id === modalEditAccount?.parentAccountId))
  }

  const deleteAccount = async (id) => {
    const res = await request({ method: 'delete', url: `/accounts/${id}` })
    if (res === false) return
    notification.success({ message: 'Account was successfully removed with all data', duration: 4 })
    if (user?.accounts.find((a) => a._id === id)) {
      const subAccountId = localStorage.getItem('subAccountId')
      if (subAccountId && subAccountId === id) localStorage.removeItem('subAccountId')
      refreshSession()
    }
    fetchAccountsData()
  }

  const editAccount = async (data) => {
    await request({
      method: 'patch',
      url: `admin/accounts/${modalEditAccount._id}`,
      data,
    })
    setModalEditAccount((account) => ({ ...account, ...data }))
    notification.success({ message: 'Changes saved' })
  }

  const onClickManageRolesButton = async (record) => {
    setSelectedAccount(record)
  }

  const manageRoleColumns = [
    { title: 'User Id', dataIndex: '_id' },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    { title: 'Email', dataIndex: 'email' },
    {
      title: 'Last Login At',
      dataIndex: 'lastLoginAt',
      render: (d) => d && new Date(d).toLocaleString(),
    },
    {
      title: 'Role',
      render: (_, record) => {
        const currentRole = record?.role
        const userId = record?._id
        const currentUserData = selectedAccount.users.find((usr) => usr._id === authStore.user.id)
        const accountId = record?.accountId[0]
        const isSameUser = userId === authStore.user.id
        return (
          <Select
            value={currentRole}
            placement="bottomRight"
            dropdownStyle={{ minWidth: 200 }}
            disabled={(!!currentUserData && currentUserData.role !== 'admin') || isSameUser}
            defaultValue={'user'}
            options={[
              { value: 'admin', label: 'Admin' },
              { value: 'user', label: 'User' },
              { value: 'designer', label: 'Designer' },
              { value: 'developer', label: 'Developer' },
              { value: 'avatarsAdmin', label: 'Avatars Admin' },
            ]}
            onChange={async (role) => {
              try {
                await request({ method: 'patch', url: `admin/accounts/user/${userId}`, data: { role } })
                updateSelectedUserRole({ accountId, userId, role })
                notification.success({ message: 'You changed role succesfully', duration: 4 })
              } catch (err) {
                notification.error({
                  message: err.message,
                  duration: 4,
                })
              }
            }}
          />
        )
      },
    },
    { title: 'Account Role', dataIndex: 'accountRole' },
    {
      title: 'Login',
      render: (_, record) => {
        const selectedUserID = record?._id
        const isSameUser = selectedUserID === authStore.user.id

        const accountId = record.accountId[0]
        return (
          <Button
            disabled={isSameUser}
            onClick={async () => {
              try {
                await authStore.adminLoginAs({ accountId, userId: selectedUserID })
                window.open('/profile', '_blank').focus()
              } catch (err) {
                notification.error({
                  message: err.message,
                  duration: 4,
                })
              }
            }}
            type="primary"
          >
            Login
          </Button>
        )
      },
    },
  ]

  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
      width: 80,
      ellipsis: {
        showTitle: false,
      },
      filteredValue: filterInfo.filters?._id || null,
      render: (id) => (
        <Tooltip placement="topLeft" title={id}>
          {id}
        </Tooltip>
      ),
      ...getColumnSearchProps('id'),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      sortOrder: filterInfo.sorter?.field === 'name' && filterInfo.sorter.order,
      filteredValue: filterInfo.filters?.name || null,
      ...getColumnSearchProps('name'),
      render: (text, record) => (
        <>
          {text}
          {record.source?.provider && <Tag color="processing">{record.source?.provider}</Tag>}
          {record.parentAccountId && <Tag>Sub account</Tag>}
        </>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 180,
      filteredValue: filterInfo.filters?.email || null,
      ...getColumnSearchProps('email'),
      render: (text, record) => record?.users?.length && record.users[0].email,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filterMultiple: false,
      filters: [
        {
          text: 'trial',
          value: 'trial',
        },
        {
          text: 'paid',
          value: 'paid',
        },
        {
          text: 'canceled',
          value: 'canceled',
        },
      ],
      filteredValue: filterInfo.filters?.status || null,
    },
    {
      title: 'User status',
      dataIndex: 'userStatus',
      filterMultiple: false,
      filters: [
        {
          text: 'register',
          value: 'register',
        },
        {
          text: 'verified',
          value: 'verified',
        },
      ],
      filteredValue: filterInfo.filters?.userStatus || null,
      render: (text, record) => record?.users?.length && record.users[0].status,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      sorter: true,
      sortOrder: filterInfo.sorter?.field === 'createdAt' && filterInfo.sorter.order,
      render: (d) => new Date(d).toLocaleString(),
    },
    {
      title: 'Plan',
      dataIndex: 'plan',
      editable: true,
      filterMultiple: false,
      filters: ['basic', 'advanced', 'custom', 'unlimited'].map((plan) => ({
        text: plan,
        value: plan,
      })),
      filteredValue: filterInfo.filters?.plan || null,
    },
    {
      title: 'Billing Cycle',
      dataIndex: 'billingCycle',
      filterMultiple: false,
      filters: ['trial7', 'trial14', 'trial30', 'trial60', 'lifetime', 'monthly', 'annual', 'enterprise'].map(
        (plan) => ({
          text: plan,
          value: plan,
        }),
      ),
      filteredValue: filterInfo.filters?.billingCycle || null,
    },
    {
      title: 'Provider',
      dataIndex: 'provider',
      filterMultiple: false,
      filters: [
        {
          text: 'RocketHub',
          value: 'RocketHub',
        },
        {
          text: 'Thinkific',
          value: 'thinkific',
        },
        {
          text: 'Admin/Manual',
          value: 'Admin/Manual',
        },
      ],
      filteredValue: filterInfo.filters?.provider || null,
      render: (text, record) => record.source?.provider,
    },
    {
      title: 'Minutes Used',
      dataIndex: 'lifetimeMinutesUsed',
      sorter: true,
      sortOrder: filterInfo.sorter?.field === 'lifetimeMinutesUsed' && filterInfo.sorter.order,
      render: (text) => text && text.toFixed(2),
    },
    {
      title: 'Minutes Available',
      dataIndex: 'minutesAvailable',
      sorter: true,
      sortOrder: filterInfo.sorter?.field === 'minutesAvailable' && filterInfo.sorter.order,
    },
    {
      title: 'Number of users',
      dataIndex: 'usersNumber',
      render: (text, record) => record?.users?.length,
    },
    {
      title: 'User Type',
      render: (text, record) => record.additionalInfo?.userType,
    },
    {
      title: 'Company Name',
      render: (text, record) => record.additionalInfo?.companyName,
    },
    {
      title: 'Company Size',
      render: (text, record) => record.additionalInfo?.companySize,
    },
    {
      title: 'Last Login At',
      dataIndex: 'lastLoginAt',
      sorter: true,
      sortOrder: filterInfo.sorter?.field === 'lastLoginAt' && filterInfo.sorter.order,
      render: (text, record) => record?.users?.length && new Date(record.users[0].lastLoginAt).toLocaleString(),
    },
    {
      title: 'Actions',
      fixed: 'right',
      width: 175,
      render: (record) => (
        <Space>
          <Button
            icon={<UserSwitchOutlined />}
            type="primary"
            onClick={() => onClickManageRolesButton(record)}
          ></Button>
          <Button
            type="ghost"
            icon={<LoginOutlined />}
            onClick={async () => {
              await authStore.adminLoginAs({ accountId: record._id })
              window.open('/profile', '_blank').focus()
            }}
          />
          <Button type="ghost" icon={<EditOutlined />} onClick={() => setModalEditAccount(record)} />
          <Popconfirm
            title="Are you sure to delete the entire account? All account data, users, all videos will be removed permanently!!!"
            onConfirm={() => deleteAccount(record._id)}
            okText="Delete"
          >
            <Icon name="delete" />
          </Popconfirm>
        </Space>
      ),
    },
  ]
  return (
    <Layout.Content className="admin-content">
      <div className="content">
        <h2>Users</h2>
        <Table
          sticky
          scroll={{ x: 2000 }}
          dataSource={data.accounts}
          loading={loader}
          columns={columns}
          rowKey="_id"
          className="users-table"
          rowClassName={() => 'editable-row'}
          pagination={{
            current: data.page,
            pageSize: 50,
            total: data.total,
            showSizeChanger: false,
          }}
          title={() => (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Statistic value={data.total} prefix={<UserOutlined />} valueStyle={{ fontSize: '16px' }} />
              <Space>
                <Dropdown
                  trigger="click"
                  placement="bottomRight"
                  menu={{
                    items: [
                      {
                        key: 'thinkific',
                        icon: mortarboardIcon,
                        label: 'Thinkific',
                        onClick: getThinkificReport,
                      },
                      {
                        key: 'corporate-users',
                        icon: officeBagIcon,
                        label: 'Export as CSV',
                        onClick: exportCsv,
                      },
                    ],
                  }}
                >
                  <Button type="primary" key="create-video-btn" className="btn-create-video">
                    Reports
                    <Icon name="down_arrow" className="down_icon" />
                  </Button>
                </Dropdown>
                <DatePicker.RangePicker showTime={true} format="YYYY-MM-DD HH:mm" onChange={onChangeDatePicker} />
                <DollarOutlined style={{ fontSize: '16px' }} />
                Only subscribers
                <Switch onChange={showSubscribers} />
              </Space>
            </div>
          )}
          onChange={handleTableChange}
        />
        <Modal
          title="Users in account"
          open={!!selectedAccount}
          width={1200}
          footer={null}
          onCancel={() => setSelectedAccount(null)}
        >
          {selectedAccount && (
            <Table
              sticky
              dataSource={selectedAccount.users}
              loading={loader}
              columns={manageRoleColumns}
              rowKey="_id"
              className="users-table"
              rowClassName={() => 'editable-row'}
              pagination={{
                current: data.page,
                pageSize: 10,
                total: selectedAccount.users.length,
                showSizeChanger: false,
              }}
            />
          )}
        </Modal>
        <Modal
          title={`${modalEditAccount?.name} - Edit`}
          open={!!modalEditAccount}
          width={700}
          footer={null}
          onCancel={() => setModalEditAccount(null)}
        >
          {modalEditAccount?.parentAccountId && (
            <p>
              <span style={{ color: 'red' }}>
                <Tag color={'red'}>
                  <b>Warning!</b>
                </Tag>
              </span>{' '}
              This is sub account. You can{' '}
              <b>
                <a onClick={setEditParentAccount}>switch</a>
              </b>{' '}
              to primary account of this sub account.
            </p>
          )}
          <Space>
            <Button onClick={() => editAccount({ plan: 'reset' })}>Cancel plan</Button>
            <Button type="dashed" danger onClick={() => editAccount({ plan: 'advanced', billingCycle: 'lifetime' })}>
              Advanced (Lifetime)
            </Button>
            <Button danger onClick={() => editAccount({ plan: 'custom', billingCycle: 'enterprise' })}>
              Custom (Manual Billing)
            </Button>
            <Button danger onClick={() => editAccount({ plan: 'unlimited', billingCycle: 'enterprise' })}>
              Unlimited (Manual Billing)
            </Button>
          </Space>
          <Space style={{ margin: '15px 0' }}>
            <Button type="primary" onClick={() => editAccount({ plan: 'unlimited', billingCycle: 'trial7' })}>
              Unlim 7 Days
            </Button>
            <Button type="primary" onClick={() => editAccount({ plan: 'unlimited', billingCycle: 'trial14' })}>
              Unlim 14 Days
            </Button>
            <Button type="primary" onClick={() => editAccount({ plan: 'unlimited', billingCycle: 'trial30' })}>
              Unlim 30 Days
            </Button>
            <Button type="primary" onClick={() => editAccount({ plan: 'unlimited', billingCycle: 'trial60' })}>
              Unlim 60 Days
            </Button>
          </Space>
          <Space style={{ margin: '15px 0' }}>
            <Button type="primary" onClick={() => editAccount({ plan: 'custom', billingCycle: 'trial7' })}>
              API 7 Days
            </Button>
            <Button type="primary" onClick={() => editAccount({ plan: 'custom', billingCycle: 'trial14' })}>
              API 14 Days
            </Button>
            <Button type="primary" onClick={() => editAccount({ plan: 'custom', billingCycle: 'trial30' })}>
              API 30 Days
            </Button>
            <Button type="primary" onClick={() => editAccount({ plan: 'custom', billingCycle: 'trial60' })}>
              API 60 Days
            </Button>
          </Space>
          <Space style={{ marginBottom: 15 }}>
            <b>Set Stripe ID</b>
            <Input
              style={{ width: 200 }}
              value={modalEditAccount?.stripeCustomerId || modalEditAccount?.stripe?.customerId}
              onChange={(e) => setModalEditAccount({ ...modalEditAccount, stripeCustomerId: e.target.value })}
            />
            <Button
              type="primary"
              onClick={() => editAccount({ stripeCustomerId: modalEditAccount?.stripeCustomerId })}
            >
              Set Stripe ID
            </Button>
          </Space>
          <Space>
            <b>Top up minutes</b>
            <InputNumber
              style={{ width: 100 }}
              value={modalEditAccount?.additionalMinutes}
              onChange={(v) => setModalEditAccount({ ...modalEditAccount, additionalMinutes: v })}
            />
            <Button
              type="primary"
              onClick={() => editAccount({ additionalMinutes: modalEditAccount?.additionalMinutes })}
            >
              Top up minutes
            </Button>
          </Space>
          <br />
          <Space style={{ margin: '15px 0' }}>
            <b>Skip Moderation</b>
            <Switch
              checked={modalEditAccount?.skipModeration}
              onChange={(checked) => editAccount({ skipModeration: checked })}
            />
          </Space>
          <br />
          <Space>
            <Input
              style={{ width: 150 }}
              value={modalEditAccount?.maxSeats}
              placeholder="Max Seats"
              onChange={(e) => setModalEditAccount({ ...modalEditAccount, maxSeats: e.target.value })}
            />
            <Button type="primary" onClick={() => editAccount({ maxSeats: modalEditAccount?.maxSeats })}>
              Set Max Seats
            </Button>
            <Input
              style={{ width: 150 }}
              value={modalEditAccount?.maxReviewers}
              placeholder="Max Reviewers"
              onChange={(e) => setModalEditAccount({ ...modalEditAccount, maxReviewers: e.target.value })}
            />
            <Button type="primary" onClick={() => editAccount({ maxReviewers: modalEditAccount?.maxReviewers })}>
              Set Max Reviewers
            </Button>
          </Space>
          <Space style={{ margin: '15px 0' }}>
            <b>Allow Overlimit (Uncappable):</b>
            <Switch
              checked={modalEditAccount?.allowOverlimit}
              onChange={(checked) => editAccount({ allowOverlimit: checked })}
            />
          </Space>
          <Space style={{ marginLeft: 30 }}>
            <b>Allow Premium Avatars:</b>
            <Switch
              checked={modalEditAccount?.allowPremiumAvatars}
              onChange={(checked) => editAccount({ allowPremiumAvatars: checked })}
            />
          </Space>
          {modalEditAccount?.billingCycle === 'enterprise' && (
            <>
              <Divider />
              <h1>Enterprise Billing (not stripe)</h1>
              <br />
              <Space>
                <b>Reset minutes</b>
                <InputNumber
                  style={{ width: 100 }}
                  value={modalEditAccount?.minutesAvailable}
                  onChange={(v) => setModalEditAccount({ ...modalEditAccount, minutesAvailable: v })}
                />
                <Button
                  type="primary"
                  onClick={() => editAccount({ minutesAvailable: modalEditAccount?.minutesAvailable })}
                >
                  Reset minutes
                </Button>
              </Space>
            </>
          )}
        </Modal>
      </div>
    </Layout.Content>
  )
}

export default Users
