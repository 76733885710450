import { useEffect, useState, useRef } from 'react'
import { DEFAULT_SLIDE_DURATION } from '../canvas/constans'
import { useSlideDuration } from '../../../../hooks/useSlideDuration'

const slideWidth = 480
const zeroRange = { from: 0, to: 0 }
const railBorderRadius = 8

const checkIsAllObjectMode = (player) => player.status !== 'idle' || player.objectsVisibilityMode === 'all'

export const useObjectVisibilityHandler = (slide, player, setPlayer, handleStyle) => {
  const [objectMarks, setObjectMarks] = useState({})
  const linkedObjectRange = useRef(zeroRange)
  const sliderRailRef = useRef(null)

  const { getApproxDuration } = useSlideDuration({ slide })

  const buildObjectMarks = (linkedObject = null) => {
    const marks = {}
    slide.canvas?.objects?.forEach((obj) => {
      if (linkedObject && obj.id === linkedObject.id) obj = linkedObject
      if (obj.type !== 'avatar' && !obj.bg && typeof obj.animation?.startTime === 'number') {
        marks[obj.animation?.startTime.toFixed(1)] = {
          label: <></>,
          style: handleStyle,
        }
      }
    })
    return marks
  }

  const getSliderRailElement = () => {
    let sliderRail = sliderRailRef.current?.deref()
    if (!sliderRail) {
      const el = document.querySelector('.player-controls .ant-slider-rail')
      // eslint-disable-next-line no-undef
      sliderRailRef.current = new WeakRef(el)
      sliderRail = el
    }
    return sliderRail
  }

  const updateLinkedObjectRange = () => {
    const { style } = getSliderRailElement()
    const { from, to } = linkedObjectRange.current
    style.setProperty('--rail-after-left', from + 'px')
    style.setProperty('--rail-after-width', to + 'px')
    style.setProperty('--rail-after-border-radius-start', (from === 0 ? railBorderRadius : 0) + 'px')
    style.setProperty(
      '--rail-after-border-radius-end',
      (Math.round(from + to) === slideWidth ? railBorderRadius : 0) + 'px',
    )
  }

  const calculateObjectAnimationRange = (obj) => {
    const duration = slide.duration || getApproxDuration(slide) || DEFAULT_SLIDE_DURATION
    const {
      animation: { startTime = 0, endTime = duration },
    } = obj
    const startFraction = startTime / duration
    const endFraction = Math.min(endTime < 0 ? duration + endTime : endTime, duration) / duration
    return { from: startFraction * slideWidth, to: ((endFraction || 1) - startFraction) * slideWidth }
  }

  useEffect(() => {
    const showAllObjects = checkIsAllObjectMode(player)
    if (showAllObjects && player.linkedCanvasObject) {
      setPlayer((p) => ({ ...p, linkedCanvasObject: null }))
    }
    setObjectMarks(showAllObjects ? {} : buildObjectMarks())
  }, [slide.id, slide.canvas?.objects, player.status, player.objectsVisibilityMode])

  useEffect(() => {
    linkedObjectRange.current = player.linkedCanvasObject
      ? calculateObjectAnimationRange(player.linkedCanvasObject)
      : zeroRange

    if (!player.linkedCanvasObject) updateLinkedObjectRange()

    const showAllObjects = checkIsAllObjectMode(player)
    setObjectMarks(showAllObjects ? {} : buildObjectMarks(player.linkedCanvasObject))
  }, [
    player.linkedCanvasObject,
    player.linkedCanvasObject?.animation?.startTime,
    player.linkedCanvasObject?.animation?.endTime,
    player.linkedCanvasObject?.animation?.startMarker,
    player.linkedCanvasObject?.animation?.endMarker,
  ])

  // it's hack to customize instance of antd slider and make sure it will be synced on each re-render
  useEffect(() => {
    if (!player.linkedCanvasObject) return

    updateLinkedObjectRange()
  })

  const toggleObjectsVisibilityMode = () => {
    const mode = player.objectsVisibilityMode === 'all' ? 'animated' : 'all'
    localStorage.setItem('objectsVisibilityMode', mode)
    setPlayer((p) => ({ ...p, objectsVisibilityMode: mode }))
  }

  return {
    objectMarks,
    toggleObjectsVisibilityMode,
  }
}
