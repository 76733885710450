/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Form, Input, Button, Card, Tooltip } from 'antd'
import { LockOutlined, MailOutlined, RightOutlined } from '@ant-design/icons'
import { useStore } from '../../store'
import { Link, Navigate } from 'react-router-dom'
import Turnstile from 'react-turnstile'
import { useElaiNotification } from '../../hooks/useElaiNotification'
import useAppRedirect from '../../hooks/useAppRedirect'
import configService from '../../utils/config'

const isCaptchaEnabled = process.env.REACT_APP_TURNSTILE_SITE_KEY?.length > 0

const Login = () => {
  const notification = useElaiNotification()
  const authStore = useStore((stores) => stores.authStore)
  const [form] = Form.useForm()
  const [turnstileToken, setTurnstileToken] = useState()
  const [loadingLogin, setLoadingLogin] = useState(false)
  const [loginAttempts, setLoginAttempts] = useState(0)

  const { handleRedirect } = useAppRedirect()
  const { apiUrl } = configService.get().urls

  if (authStore.user && !loadingLogin) return <Navigate replace to="/" />

  useEffect(() => {
    if (authStore.user) handleRedirect()
  }, [authStore.user])

  const onFinish = async (form) => {
    try {
      setLoadingLogin(true)
      const { data } = await axios.post(apiUrl + '/auth/login', { ...form, turnstileToken })
      // don't save to authStore before redirecting as it moves to main page and then redirect. So we send tokens to a function
      // checkAndRedirectToExtApp(redirectUrl, data)
      await authStore.login(data)
    } catch (error) {
      const message = error.response?.data?.message || error.message
      notification.error({ message })
      setLoadingLogin(false)
      setLoginAttempts((attempts) => attempts + 1)
    }
  }

  return (
    <Card className="login">
      <h2>Sign in to your account</h2>
      <Form form={form} onFinish={onFinish} layout="vertical" requiredMark={false}>
        <Form.Item
          name="email"
          label="Email"
          validateTrigger="onBlur"
          rules={[{ required: true, type: 'email', message: 'Please, enter your email' }]}
        >
          <Input prefix={<MailOutlined />} placeholder="you@company.com" size="large" />
        </Form.Item>
        <Form.Item
          name="password"
          label={
            <div className="password-label" style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <span>Password</span>
              <div>
                <Link replace={true} to="/forgot-password">
                  Forgot password?
                </Link>
              </div>
            </div>
          }
          validateTrigger="onBlur"
          rules={[{ required: true, message: 'Please, enter your password' }]}
        >
          <Input.Password prefix={<LockOutlined />} placeholder="Password" size="large" />
        </Form.Item>
        {isCaptchaEnabled && (
          <>
            <Form.Item key={loginAttempts} noStyle>
              <Turnstile
                sitekey={process.env.REACT_APP_TURNSTILE_SITE_KEY}
                autoResetOnExpire={true}
                action="login"
                appearance="interaction-only"
                className="turnstile-captcha"
                onVerify={(token) => setTurnstileToken(token)}
                onError={() => setLoginAttempts((attempts) => attempts + 1)}
              />
            </Form.Item>
          </>
        )}
        <Form.Item>
          <Tooltip
            title="We are applying captcha. Please wait a second…"
            overlayStyle={{
              visibility: turnstileToken ? 'hidden' : 'visible',
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              loading={(!turnstileToken && isCaptchaEnabled) || loadingLogin}
              icon={<RightOutlined />}
              className="btn-login btn-arrow"
              size="large"
            >
              Continue
            </Button>
          </Tooltip>
        </Form.Item>
      </Form>
    </Card>
  )
}

export default Login
