import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Input, Layout } from 'antd'
import PageHeader from '../../../components/PageHeader/pageHeader'
import { request } from '../../../utils/api'
import { useParams } from 'react-router-dom'
import { useStore } from '../../../store'
import { useElaiNotification } from '../../../hooks/useElaiNotification'

const Avatar = () => {
  const notification = useElaiNotification()
  const { fetchAvatars } = useStore((stores) => stores.videosStore)

  const [avatar, setAvatar] = useState()
  const [editableJson, setEditableJson] = useState('')
  const [jsonValid, setJsonValid] = useState(true)
  const { id } = useParams()
  const navigate = useNavigate()
  const textArea = useRef(null)

  useEffect(() => {
    const fetchAvatar = async () => {
      const avatar = await request({ method: 'get', url: `admin/avatars/${id}` })
      setAvatar(avatar)
      setEditableJson(JSON.stringify(getEditVersion(avatar), null, 2))
    }
    fetchAvatar()

    const insertTab = (e) => {
      if (e.key === 'Tab') {
        const _this = textArea.current.resizableTextArea.textArea
        e.preventDefault()
        var start = _this.selectionStart
        var end = _this.selectionEnd
        _this.value = _this.value.substring(0, start) + '  ' + _this.value.substring(end)
        _this.selectionStart = _this.selectionEnd = start + 2
      }
    }

    textArea.current.resizableTextArea.textArea.addEventListener('keydown', insertTab)
    return () => textArea.current?.resizableTextArea.textArea.removeEventListener('keydown', insertTab)
  }, [])

  const updateSelectedAvatar = async () => {
    if (!jsonValid) return notification.error({ message: 'JSON is not valid' })

    const res = await request({ method: 'patch', url: `admin/avatars/${id}`, data: avatar })
    if (res === false) return
    notification.success({ message: 'Сhanges saved successfully' })
    setAvatar(res)
    setEditableJson(JSON.stringify(getEditVersion(res), null, 2))
    fetchAvatars()
  }

  const onChangeJson = (e) => {
    try {
      const newVersion = JSON.parse(e.target.value)
      setAvatar(newVersion)
      setJsonValid(true)
    } catch {
      setJsonValid(false)
    }
    setEditableJson(e.target.value)
  }

  const getEditVersion = (avatar) => {
    if (!avatar) return null
    const { __v, _id, ...fields } = avatar
    return fields
  }

  return (
    <Layout.Content className="admin-content">
      <div className="content" style={{ overflow: 'auto' }}>
        <PageHeader
          title={avatar?.name || ' '}
          subTitle={avatar?.id}
          onBack={() => navigate(`/admin/avatars`)}
          style={{ padding: '0 0 16px 0' }}
        />
        <p>
          {jsonValid ? (
            <span style={{ color: 'green' }}>Correct JSON</span>
          ) : (
            <span style={{ color: 'red' }}>Invalid JSON</span>
          )}
        </p>
        <Input.TextArea
          ref={textArea}
          style={{ width: '100%', fontFamily: 'monospace', marginBottom: 30 }}
          rows={25}
          value={editableJson}
          placeholder="Avatar JSON"
          onChange={onChangeJson}
        />
        <Button type="primary" key="update" onClick={updateSelectedAvatar}>
          Update
        </Button>
      </div>
    </Layout.Content>
  )
}

export default Avatar
