import videosStore from './videosStore'
import { authStore } from './authStore'
import domStore from './domStore'
import colorEditorStore from './colorEditorStore'
import voicesStore from './voicesStore'
import { navigationStore } from './navigationStore'

export default () => {
  return {
    videosStore: videosStore(),
    authStore: authStore(),
    domStore: domStore(),
    colorEditorStore: colorEditorStore(),
    voicesStore: voicesStore(),
    navigationStore: navigationStore(),
  }
}
