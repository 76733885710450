import React, { useMemo } from 'react'
import Icon from '../../../../../../components/Icon'
import { useStore } from '../../../../../../store'
import { Tooltip } from 'antd'
import { aiConfig } from './content/ai.config'

const GenerateWithAiButton = (props) => {
  const { setIsAiGenerateDrawerVisible } = props

  const { user } = useStore((stores) => stores.authStore)
  const plan = user?.account?.plan

  const { restrictedPlans, restrictedMessage } = aiConfig
  const isDisabled = useMemo(() => restrictedPlans.includes(plan), [plan, restrictedPlans])
  const clickHandler = () => {
    if (isDisabled) return
    setIsAiGenerateDrawerVisible(true)
  }

  return (
    <Tooltip title={`${isDisabled ? restrictedMessage : ''}`} placement="bottom">
      <div
        style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
        className={`generate-with-ai-button${isDisabled ? ' generate-with-ai-button-disabled' : ''}`}
        onClick={clickHandler}
      >
        <Icon name="magic_wand" className="generate-with-ai-button-icon" />
        <span className="generate-with-ai-button-text">Generate with AI</span>
      </div>
    </Tooltip>
  )
}

export default GenerateWithAiButton
