import { useEffect, useState, useMemo, useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  Table,
  Layout,
  Tag,
  Button,
  Space,
  Popconfirm,
  Modal,
  Form,
  Input,
  Select,
  Spin,
  Switch,
  Tooltip,
  Statistic,
  Row,
  Col,
} from 'antd'
import {
  PlusOutlined,
  EditOutlined,
  SearchOutlined,
  CheckSquareOutlined,
  DoubleRightOutlined,
  SyncOutlined,
} from '@ant-design/icons'
import { useStore } from '../../../store'
import { request } from '../../../utils/api'
import PageHeader from '../../../components/PageHeader/pageHeader'
import Icon from '../../../components/Icon'
import { videoStatuses } from '../../../utils/videos'
import { formatSeconds } from '../../../utils/videos'
import { voiceStatuses, avatarStatuses, avatarTypes } from '../constants'
import { useElaiNotification } from '../../../hooks/useElaiNotification'

const Avatars = () => {
  const notification = useElaiNotification()
  const [avatars, setAvatars] = useState()
  const [isAvatarAddModalVisible, setIsAvatarAddModalVisible] = useState(false)
  const [showCustomAvatars, setShowCustomAvatars] = useState(false)
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false)
  const [videos, setVideos] = useState([])
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const adminLoginAs = useStore((stores) => stores.authStore.adminLoginAs)

  const fetchAvatars = useCallback(async () => {
    const avatars = await request({ method: 'get', url: 'admin/avatars' })
    setAvatars(avatars)
  }, [])

  useEffect(() => {
    fetchAvatars()
  }, [])

  const createAvatarFromFormFields = (data) => {
    const avatar = {
      code: data.code,
      name: data.name,
      type: data.type,
      accountId: data.accountId,
      frontendConfig: {
        gender: data.gender,
      },
    }
    if (avatar.type === 'mascot') {
      avatar.avatarConfig = {
        head: {
          imageS3: `s3://elai-media/cartoons/${data.code}/head.png`,
          markupS3: `s3://elai-media/cartoons/${data.code}/${data.code}.json`,
        },
      }
      avatar.variants = [
        {
          code: data.code,
          name: data.name.split(' ')[0],
          frontendConfig: {
            canvas: `https://d3u63mhbhkevz8.cloudfront.net/cartoons/${data.code}/${data.code}.png`,
            thumbnail: `https://d3u63mhbhkevz8.cloudfront.net/cartoons/${data.code}/${data.code}.jpg`,
          },
          renderConfig: {
            body: `https://d3u63mhbhkevz8.cloudfront.net/cartoons/${data.code}/${data.code}_bg.png`,
            headPosition: {
              top: 0,
              left: 0,
              height: 0,
              rotate: 0,
            },
          },
        },
      ]
    } else {
      const baseName = data.code.replace(/^custom_/, '')
      avatar.frontendConfig.thumbnail = `https://d3u63mhbhkevz8.cloudfront.net/avatars/custom/${baseName}.jpg`
      avatar.frontendConfig.canvas = `https://d3u63mhbhkevz8.cloudfront.net/avatars/custom/${baseName}.png`
    }
    return avatar
  }

  const addAvatar = async (data) => {
    const res = await request({ method: 'post', url: `admin/avatars`, data: createAvatarFromFormFields(data) })
    if (res === false) return

    fetchAvatars()
    setIsAvatarAddModalVisible(false)
    form.resetFields()
  }

  const deleteAvatar = useCallback(
    async (id) => {
      await request({ method: 'delete', url: `admin/avatars/${id}` })
      await fetchAvatars()
    },
    [fetchAvatars],
  )

  const addAvatarLooks = useCallback((videos) => {
    return videos?.map((video) => {
      video.avatarLooks = video.slides
        .map((slide) => (slide.avatar.code ? slide.avatar.code.split('.')[1] : false))
        .filter((look) => look)
        .filter((look, index, looks) => looks.indexOf(look) === index)
      return video
    })
  }, [])

  const fetchVideos = useCallback(
    async (avatarId) => {
      setVideos(null)
      setIsVideoModalOpen(true)
      const data = await request({ method: 'get', url: `admin/avatars/videos/${avatarId}` })
      setVideos(addAvatarLooks(data))
    },
    [addAvatarLooks],
  )

  const runTest = async (avatarId, variantCode) => {
    notification.success({
      key: 'validating',
      duration: false,
      icon: <SyncOutlined spin />,
      message: 'Test is creating...',
    })

    const data = await request({
      method: 'post',
      url: `/admin/avatars/test`,
      data: { avatarId, variantCode },
    })

    notification.destroy('validating')

    if (!data) return

    navigate(`/video/${data.videoId}`)
  }

  const testAvatarButton = useCallback(
    (name, videoId, avatarId, variantCode) => (
      <Tooltip
        key={variantCode}
        placement="left"
        title={
          videoId ? (
            <>
              {name}. <a onClick={() => runTest(avatarId, variantCode)}>Run new test</a>
            </>
          ) : (
            `Run test for ${name}`
          )
        }
      >
        {videoId ? (
          <Link to={`/video/${videoId}`}>
            <CheckSquareOutlined />
          </Link>
        ) : (
          <DoubleRightOutlined onClick={() => runTest(avatarId, variantCode)} />
        )}
      </Tooltip>
    ),
    [],
  )

  const getColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={confirm}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters()
                confirm()
              }}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) => record[dataIndex] && record[dataIndex].toLowerCase().includes(value.toLowerCase()),
    }),
    [],
  )

  const columns = useMemo(() => {
    const cols = [
      {
        title: '',
        render: (record) => (
          <Link to={`/admin/avatar/${record._id}`}>
            <img
              src={
                record.variants[0]?.frontendConfig?.thumbnail ||
                record.frontendConfig?.thumbnail ||
                record.frontendConfig?.canvas
              }
              width="80"
              style={{ border: '1px solid #f0f0f0', borderRadius: 4 }}
            />
          </Link>
        ),
      },
      {
        title: 'Code',
        dataIndex: 'code',
        ...getColumnSearchProps('code'),
      },
      {
        title: 'Account ID',
        dataIndex: 'accountId',
        ...getColumnSearchProps('accountId'),
        render: (accountId) => {
          return (
            <a
              onClick={async () => {
                await adminLoginAs({ accountId })
                window.open('/profile', '_blank').focus()
              }}
            >
              {accountId}
            </a>
          )
        },
      },
      {
        title: 'Name',
        render: (record) => <Link to={`/admin/avatar/${record._id}`}>{record.name}</Link>,
        ...getColumnSearchProps('name'),
      },
      {
        title: 'Gender',
        dataIndex: ['frontendConfig', 'gender'],
      },
      {
        title: 'Status',
        filters: avatarStatuses.map(({ text }, index) => ({ text, value: index })),
        filterMultiple: false,
        onFilter: (v, record) => record.status === v,
        render: (v, record) => {
          if (record.status === undefined) return null
          const status = avatarStatuses[record.status]
          return record.status === 4 ? (
            <Link to={`/admin/avatar/${record._id}`}>
              <Tag color={status.color}>{status.text}</Tag>
            </Link>
          ) : (
            <Tag color={status.color}>{status.text}</Tag>
          )
        },
      },
      {
        title: 'Type',
        filters: avatarTypes.map((type) => ({
          text: type.name,
          value: type.name.toLowerCase(),
        })),
        filterMultiple: false,
        onFilter: (v, record) => {
          if (v === 'human' && !record.type) return true
          else if (v === record.type) return true
          else return false
        },
        render: (record) => (
          <Tag
            color={
              avatarTypes.find(
                (type) =>
                  (!record.type && type.name.toLowerCase() === 'human') || record.type === type.name.toLowerCase(),
              )?.color
            }
          >
            {record.type || 'human'}
          </Tag>
        ),
      },
      {
        title: 'Order',
        dataIndex: 'order',
        sorter: (a, b) => a.order - b.order,
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        render: (value) => value && new Date(value).toLocaleString(),
        sorter: (a, b) => {
          return new Date(a.createdAt) - new Date(b.createdAt)
        },
      },
      {
        title: 'Updated At',
        dataIndex: 'updatedAt',
        render: (value) => value && new Date(value).toLocaleString(),
        sorter: (a, b) => {
          return new Date(a.updatedAt) - new Date(b.updatedAt)
        },
      },
      {
        title: 'Actions',
        key: 'action',
        align: 'center',
        render: (record) => (
          <Space>
            <Link to={`/admin/avatar/${record._id}`}>
              <EditOutlined />
            </Link>
            <Popconfirm
              title="Are you sure you want to delete this avatar? This action is irreversible!"
              onConfirm={() => deleteAvatar(record._id)}
              okText="Yes"
              cancelText="No"
            >
              <Icon name="delete" />
            </Popconfirm>

            <Icon name="video_camera" onClick={() => fetchVideos(record._id)} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {record.variants.length
                ? record.variants.map((v) =>
                    testAvatarButton(v.name, v.test, record._id, v.code || v.name.toLowerCase()),
                  )
                : testAvatarButton(record.name, record.test, record._id)}
            </div>
          </Space>
        ),
      },
    ]

    if (showCustomAvatars)
      cols.splice(7, 0, {
        title: 'Voice',
        filters: voiceStatuses.map(({ text }, index) => ({ text, value: index })),
        filterMultiple: false,
        onFilter: (v, record) => record.voices?.some((voice) => voice.status === v),
        render: (v, record) =>
          record.voices?.map((voice) => {
            if (voice.status === undefined) return null
            const status = voiceStatuses.find((_, index) => voice.status === index)
            return (
              <Tag key={voice._id} color={status.color}>
                {status.text}
              </Tag>
            )
          }),
      })

    return cols
  }, [avatars, showCustomAvatars, adminLoginAs, getColumnSearchProps, deleteAvatar, fetchVideos, testAvatarButton])

  const columnsVideos = useMemo(
    () => [
      {
        title: '',
        render: (record) => (
          <Link to={`/preview/${record._id}`} target="_blank">
            <img src={record.thumbnail} width="80" style={{ border: '1px solid #f0f0f0', borderRadius: 4 }} />
          </Link>
        ),
      },
      {
        title: 'Name',
        dataIndex: 'name',
        render: (name, record) => (
          <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
            <Link to={`/video/${record._id}`} target="_blank">
              {name}
            </Link>
            {record.data?.resolution === '4K' && (
              <Tag color="blue" style={{ marginLeft: '4px' }}>
                4K
              </Tag>
            )}
          </div>
        ),
      },
      {
        title: 'Looks',
        dataIndex: 'avatarLooks',
        render: (looks) => looks.map((look) => <Tag color="green">{look}</Tag>),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (record) => <Tag color={videoStatuses[record]?.color}>{videoStatuses[record]?.title}</Tag>,
      },
      {
        title: 'Duration',
        dataIndex: 'duration',
        render: (duration) => formatSeconds(duration),
      },
      {
        title: 'Updated At',
        dataIndex: 'updatedAt',
        render: (d) => new Date(d).toLocaleString(),
      },
    ],
    [],
  )

  return (
    <>
      <Layout.Content className="admin-content">
        <div className="content">
          <PageHeader
            title="Avatars"
            style={{ padding: 0 }}
            extra={[
              <div key="switch-custom-avatars" style={{ marginRight: 16 }}>
                <span style={{ marginRight: 8 }}>Show custom avatars</span>
                <Switch
                  checked={showCustomAvatars}
                  disabled={!avatars?.length}
                  onChange={(v) => setShowCustomAvatars(v)}
                />
              </div>,
              <Button
                key="create-avatar-btn"
                icon={<PlusOutlined />}
                type="primary"
                onClick={() => setIsAvatarAddModalVisible(true)}
              >
                New Avatar
              </Button>,
            ]}
          />
          {showCustomAvatars && (
            <Row className="statistic-row">
              <Col>
                <h3>Avatars</h3>
                <Space size="large">
                  <Statistic title="Total" value={avatars.filter((a) => a.accountId).length} />
                  <Statistic title="In progress" value={avatars.filter((a) => a.accountId && a.status === 5).length} />
                  <Statistic title="Need data" value={avatars.filter((a) => a.accountId && a.status === 4).length} />
                </Space>
              </Col>
            </Row>
          )}
          {avatars ? (
            <Table
              sticky
              columns={columns}
              dataSource={showCustomAvatars ? avatars.filter((a) => a.accountId) : avatars.filter((a) => !a.accountId)}
              rowKey="_id"
              pagination={false}
            />
          ) : (
            <Spin size="large" />
          )}
        </div>
      </Layout.Content>
      <Modal
        title="Create avatar"
        open={isAvatarAddModalVisible}
        width={400}
        footer={null}
        onCancel={() => {
          setIsAvatarAddModalVisible(false)
          form.resetFields()
        }}
      >
        <Form form={form} onFinish={addAvatar} layout="vertical" requiredMark={true}>
          <Form.Item
            name="code"
            label="Code"
            validateTrigger="onBlur"
            rules={[
              {
                required: true,
                message: 'Unique code is required',
              },
            ]}
          >
            <Input placeholder="Code" />
          </Form.Item>
          <Form.Item
            name="name"
            label="Name"
            validateTrigger="onBlur"
            rules={[
              {
                required: true,
                message: 'Name is required',
              },
            ]}
          >
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item name="gender" label="Gender" initialValue="male">
            <Select>
              <Select.Option value="male">Male</Select.Option>
              <Select.Option value="female">Female</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="type" label="Type">
            <Select>
              <Select.Option>Human</Select.Option>
              <Select.Option value="cartoon">Cartoon</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="accountId" label="Account ID">
            <Input placeholder="For example: 6189ce84b1b63c0563b815bd" />
          </Form.Item>
          <Form.Item style={{ margin: 0 }}>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
              Create
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Videos with selected avatar"
        width={1000}
        open={isVideoModalOpen}
        footer={null}
        onCancel={() => setIsVideoModalOpen(false)}
      >
        <Table sticky columns={columnsVideos} loading={!videos} dataSource={videos} rowKey="_id" pagination={false} />
      </Modal>
    </>
  )
}

export default Avatars
