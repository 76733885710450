import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { useStore } from '../../../store'
import { requestDuplicateVideo } from '../../../utils/videoCreation/videoCreation'
import { ONBOARDING_TEMPLATE_ID } from '../../../utils/constants'

const templatesPageSize = 16

const initialFilterState = { tags: [], format: 'all' }

export const useCreateTemplateState = (props) => {
  const { templates, onTemplateCreated, isNewTemplateModalOpen, setIsNewTemplateModalOpen } = props
  const location = useLocation()

  const { templateTags, fetchTemplates } = useStore((stores) => stores.videosStore)

  const [isCreatingTemplate, setIsCreatingTemplate] = useState(false)
  const [openedTemplate, setOpenedTemplate] = useState(null)
  const [templatesFilters, setTemplatesFilter] = useState(initialFilterState)
  const [searchedTemplates, setSearchedTemplates] = useState([])
  const [templatesToShow, setTemplatesToShow] = useState([])

  useEffect(() => {
    setTimeout(() => localStorage.removeItem('isNewTemplateModalOpen'), 100)
  }, [])

  useEffect(() => {
    setSearchedTemplates(() => {
      let filteredTemplates = [
        ...(templates?.filter((t) => t.template.enabled && t._id !== ONBOARDING_TEMPLATE_ID) || []),
      ]
      if (templatesFilters.format === '16_9')
        filteredTemplates = filteredTemplates.filter((t) => !t.data?.format || t.data.format === '16_9')
      else if (templatesFilters.format !== 'all')
        filteredTemplates = filteredTemplates.filter((t) => t.data?.format === templatesFilters.format)
      if (templatesFilters.tags.length > 0)
        filteredTemplates = filteredTemplates.filter((t) =>
          t.template.tags?.some((tag) => templatesFilters.tags?.includes(tag)),
        )
      return [...filteredTemplates]
    })
  }, [templatesFilters, templates])

  /**
   * reset modal state when navigating to video page
   */
  useEffect(() => {
    setOpenedTemplate(null)
    setTemplatesFilter(initialFilterState)
    setSearchedTemplates([])
  }, [location.pathname])

  const createTemplate = async (source) => {
    setIsCreatingTemplate(true)
    const res = await requestDuplicateVideo({ sourceId: source._id, createTemplate: true })
    setIsCreatingTemplate(false)
    setIsNewTemplateModalOpen(false)
    if (res) {
      fetchTemplates()
      onTemplateCreated?.(res)
    }
  }

  const handleCreateClick = () => {
    createTemplate(openedTemplate)
  }

  const handleCancel = () => {
    setIsNewTemplateModalOpen(false)
  }

  const onFilterChange = (e) => setTemplatesFilter((f) => ({ ...f, format: e.target.value }))
  const onTagsCHange = (tags) => setTemplatesFilter((f) => ({ ...f, tags }))

  const handleOnBack = () => setOpenedTemplate(null)

  const updateTemplatesToShow = (count) => {
    setTemplatesToShow(searchedTemplates.slice(0, count))
  }

  useEffect(() => {
    updateTemplatesToShow(templatesPageSize)
  }, [searchedTemplates, templatesFilters])

  useEffect(() => {
    if (!isNewTemplateModalOpen && searchedTemplates?.length) {
      updateTemplatesToShow(templatesPageSize)
      setTemplatesFilter(initialFilterState)
    }
  }, [isNewTemplateModalOpen])

  const handleScrollToBottom = () => {
    const newCount = templatesToShow.length + templatesPageSize
    updateTemplatesToShow(newCount)
  }

  return {
    templateTags,
    onTagsCHange,
    handleCancel,
    handleOnBack,
    openedTemplate,
    onFilterChange,
    templatesFilters,
    templatesToShow,
    setOpenedTemplate,
    handleCreateClick,
    isCreatingTemplate,
    isNewTemplateModalOpen,
    handleScrollToBottom,
  }
}
