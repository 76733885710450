import { useEffect, useMemo, useState } from 'react'

import { useStore } from '../../store'
import { request } from '../../utils/api'

import { onlyNewLinesRegex } from './constants'
import { useElaiNotification } from '../../hooks/useElaiNotification'
import { useLocation } from 'react-router-dom'

export const useCommentsState = (props) => {
  const { activeSlide, video, defaultAnchorToSlides, updateVideo } = props
  const notification = useElaiNotification()

  const userId = useStore((stores) => stores.authStore.user.id)

  // if comments on preview page then up 20px to avoid help button overlap
  const { pathname } = useLocation()
  const isPreviewPage = pathname.includes('preview')

  const [selectedSlide, setSelectedSlide] = useState(activeSlide || video.slides[0].id)
  const [slidesCheckboxValue, setSlidesCheckboxValue] = useState(defaultAnchorToSlides)
  const [loadingComment, setLoadingComment] = useState(false)
  const [commentTextAreaValue, setCommentTextAreaValue] = useState('')
  const [editingComment, setEditingComment] = useState(false)
  const [commentsFilter, setCommentsFilter] = useState({ sort: 'slides', filter: 'all' })
  const [unviewedComments, setUnviewedComments] = useState([])

  const comments = useMemo(() => {
    let filteredComments
    if (commentsFilter.filter === 'all') {
      filteredComments = video.comments
    } else if (commentsFilter.filter === 'open') {
      filteredComments = video.comments.filter((comment) => {
        const deletedSlideComment = comment.slideId && !video.slides.some((slide) => slide.id === comment.slideId)
        return !comment.deleted && !comment.resolved && !deletedSlideComment
      })
    } else if (commentsFilter.filter === 'resolved') {
      filteredComments = video.comments.filter((comment) => comment.resolved)
    }

    const sortedComments = filteredComments.sort((a, b) => {
      if (commentsFilter.sort === 'slides') {
        if (a.deleted && !b.deleted) return 1
        if (!a.deleted && b.deleted) return -1
        if (a.slideId && b.slideId) {
          const indexA = video.slides.findIndex((slide) => slide.id === a.slideId)
          const indexB = video.slides.findIndex((slide) => slide.id === b.slideId)
          if (indexA === -1 && indexB !== -1) return 1
          if (indexA !== -1 && indexB === -1) return -1
          return indexA - indexB
        }
        if (a.slideId) return -1
        if (b.slideId) return 1
        return 0
      } else {
        const dateA = new Date(a.createdAt)
        const dateB = new Date(b.createdAt)
        return dateB - dateA
      }
    })

    return sortedComments
  }, [video.slides.length, video.comments, commentsFilter])

  const handleSortComments = (value) => {
    setCommentsFilter({ ...commentsFilter, sort: value })
  }

  const handleFilterComments = (e) => {
    console.log('value', e)
    setCommentsFilter({ ...commentsFilter, filter: e.target.value })
  }

  const handleChangeSelectedSlide = (value) => {
    setSelectedSlide(value)
  }

  const createComment = async (e) => {
    if (e.shiftKey) {
      return
    }
    if (!commentTextAreaValue || onlyNewLinesRegex.test(commentTextAreaValue))
      return notification.error({ message: 'Please input comment text' })
    const comment = {
      text: commentTextAreaValue,
      slideId: slidesCheckboxValue ? selectedSlide : null,
    }
    setLoadingComment(true)
    const comments = await request({ method: 'post', url: `/videos/comments/${video._id}`, data: { comment } })
    if (!comments) {
      return setLoadingComment(false)
    }
    updateVideo({ comments }, { ignoreHistory: true })
    setCommentTextAreaValue('')
    setLoadingComment(false)
  }

  const handleChangeSlidesCheckbox = (e) => {
    setSlidesCheckboxValue(e.target.checked)
  }

  const handleChangeCommentText = (e) => {
    setCommentTextAreaValue(e.target.value)
  }

  /**
   * Get unviewed comments to highlight them
   */
  useEffect(() => {
    const unviewed = video.comments.filter((comment) => !comment.viewed.includes(userId)).map((comment) => comment._id)
    setUnviewedComments(unviewed)
  }, [video.comments])

  /**
   * After user viewing comments, save to main state that comments were viewed
   */
  useEffect(() => {
    const timerId = setTimeout(async () => {
      const viewedComments = []
      video.comments.forEach((comment) => {
        if (!unviewedComments.includes(comment._id) && !comment.viewed.includes(userId)) {
          viewedComments.push({ ...comment, viewed: [...comment.viewed, userId] })
        }
      })
      const unviewed = video.comments.filter((comment) => !comment.viewed.includes(userId))
      if (unviewedComments.length === unviewed.length) return
      const v = await request({ method: 'patch', url: `/videos/${video._id}`, data: { comments: viewedComments } })
      updateVideo({ comments: v.comments }, { ignoreHistory: true })
    }, 800)
    return () => clearTimeout(timerId)
  }, [unviewedComments])

  useEffect(() => {
    if (!activeSlide) {
      const isAvailableSlide = video.slides.some((slide) => slide.id === selectedSlide)
      if (!isAvailableSlide) {
        setSelectedSlide(video.slides[0].id)
      }
    }
  }, [video.slides.length, selectedSlide, activeSlide])

  useEffect(() => {
    if (activeSlide) {
      setSelectedSlide(activeSlide)
    }
  }, [activeSlide])

  return {
    userId,
    comments,
    createComment,
    isPreviewPage,
    selectedSlide,
    loadingComment,
    editingComment,
    commentsFilter,
    unviewedComments,
    setEditingComment,
    setLoadingComment,
    handleSortComments,
    handleFilterComments,
    setUnviewedComments,
    slidesCheckboxValue,
    commentTextAreaValue,
    handleChangeCommentText,
    handleChangeSelectedSlide,
    handleChangeSlidesCheckbox,
  }
}
