export const animations = {
  default: {
    id: null,
    name: 'No Animation',
    thumbnail: {
      image: 'https://d3u63mhbhkevz8.cloudfront.net/animations/no.png',
    },
  },
  enter: [
    {
      id: 'fade_in',
      name: 'Fade In',
      thumbnail: {
        image: 'https://d3u63mhbhkevz8.cloudfront.net/animations/fade_in.png',
        video: 'https://d3u63mhbhkevz8.cloudfront.net/animations/fade_in.mp4',
      },
    },
    {
      id: 'fade_left',
      name: 'Fade From Left',
      thumbnail: {
        image: 'https://d3u63mhbhkevz8.cloudfront.net/animations/fade_left.png',
        video: 'https://d3u63mhbhkevz8.cloudfront.net/animations/fade_left.mp4',
      },
    },
    {
      id: 'fade_right',
      name: 'Fade From Right',
      thumbnail: {
        image: 'https://d3u63mhbhkevz8.cloudfront.net/animations/fade_right.png',
        video: 'https://d3u63mhbhkevz8.cloudfront.net/animations/fade_right.mp4',
      },
    },
    {
      id: 'fade_top',
      name: 'Fade From Top',
      thumbnail: {
        image: 'https://d3u63mhbhkevz8.cloudfront.net/animations/fade_from_top.png',
        video: 'https://d3u63mhbhkevz8.cloudfront.net/animations/fade_from_top.mp4',
      },
    },
    {
      id: 'fade_bottom',
      name: 'Fade From Bottom',
      thumbnail: {
        image: 'https://d3u63mhbhkevz8.cloudfront.net/animations/fade_from_bottom.png',
        video: 'https://d3u63mhbhkevz8.cloudfront.net/animations/fade_from_bottom.mp4',
      },
    },
  ],
  exit: [
    {
      id: 'fade_out',
      name: 'Fade Out',
      thumbnail: {
        image: 'https://d3u63mhbhkevz8.cloudfront.net/animations/out_animation/fade_out.png',
        video: 'https://d3u63mhbhkevz8.cloudfront.net/animations/out_animation/fade_out.mp4',
      },
    },
    {
      id: 'fade_to_left',
      name: 'Fade To Left',
      thumbnail: {
        image: 'https://d3u63mhbhkevz8.cloudfront.net/animations/out_animation/fade_to_left.png',
        video: 'https://d3u63mhbhkevz8.cloudfront.net/animations/out_animation/fade_to_left.mp4',
      },
    },
    {
      id: 'fade_to_right',
      name: 'Fade To Right',
      thumbnail: {
        image: 'https://d3u63mhbhkevz8.cloudfront.net/animations/out_animation/fade_to_right.png',
        video: 'https://d3u63mhbhkevz8.cloudfront.net/animations/out_animation/fade_to_right.mp4',
      },
    },
    {
      id: 'fade_to_top',
      name: 'Fade To Top',
      thumbnail: {
        image: 'https://d3u63mhbhkevz8.cloudfront.net/animations/out_animation/fade_to_top.png',
        video: 'https://d3u63mhbhkevz8.cloudfront.net/animations/out_animation/fade_to_top.mp4',
      },
    },
    {
      id: 'fade_to_bottom',
      name: 'Fade To Bottom',
      thumbnail: {
        image: 'https://d3u63mhbhkevz8.cloudfront.net/animations/out_animation/fade_to_bottom.png',
        video: 'https://d3u63mhbhkevz8.cloudfront.net/animations/out_animation/fade_to_bottom.mp4',
      },
    },
  ],
}

/**
 * additional commented animations
{
  id: 'move',
  name: 'Move',
  thumbnail: false,
},
{
  id: 'waves',
  name: 'Waves',
  thumbnail: false,
},
{
  id: 'move_down',
  name: 'Move Down',
  thumbnail: false,
  imageOnly: true,
},
{
  id: 'move_up',
  name: 'Move Up',
  thumbnail: false,
  imageOnly: true,
},
 */
