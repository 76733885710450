import { useState } from 'react'
import { Layout } from 'antd'
import { useStore } from '../../../../store'
import Voice from '../components/voice/voice'
import Languages from './languages'
import Avatars from './avatars'
import './index.less'
import Purchase from './purchase'
import FAQ from '../components/faq/faq'
import ContactForm from '../components/form/form'
import PurchaseModal from '../components/purchaseModal/purchaseModal'
import { track } from '../../../../utils/analytics'

export default () => {
  const user = useStore((stores) => stores.authStore.user)
  const [isOpenPurchaseModal, setIsOpenPurchaseModal] = useState(false)

  const canBuyVoice = !user || user?.canManageWorkspace

  const onClickBuyNow = () => {
    track('buy_now_voice')
    setIsOpenPurchaseModal(true)
  }

  return (
    <Layout.Content className="public-voice">
      <div className="public-voice-content">
        <div className="header">
          <h1>Get a digital copy of your voice</h1>
          <p style={{ textAlign: 'center', margin: '0 auto' }}>
            Clone your own voice and use it to turn all your ideas into reality.
          </p>
        </div>
        <Voice canBuyVoice={canBuyVoice} setIsOpenPurchaseModal={setIsOpenPurchaseModal} />
        <Languages canBuyVoice={canBuyVoice} onClickBuyNow={onClickBuyNow} />
        <Avatars />
        <Purchase canBuyVoice={canBuyVoice} onClickBuyNow={onClickBuyNow} />
        <FAQ activeTab="voice" />
        <ContactForm title="Voice Cloning" />
        <PurchaseModal
          canBuy={canBuyVoice}
          isOpenPurchaseModal={isOpenPurchaseModal}
          setIsOpenPurchaseModal={setIsOpenPurchaseModal}
        />
      </div>
    </Layout.Content>
  )
}
