import { useState } from 'react'
import { Modal, Button, Form, Upload, Checkbox, Radio, Tooltip } from 'antd'
import Icon from '../Icon'
import { useStore } from '../../store'
import { request } from '../../utils/api'
import './createPhotoAvatarModal.less'
import { elaiNotification as notification } from '../../utils/helpers'

const { Dragger } = Upload

const beforeUpload = (file) => {
  if (file.size / 1024 / 1024 > 10) {
    notification.error({
      message: 'File must be smaller than 10MB',
    })
    return false
  }
  return true
}

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e
  }
  return e?.fileList
}

const CreatePhotoAvatarModal = ({ successMessage, isOpenPhotoAvatarModal, setIsOpenPhotoAvatarModal }) => {
  const [loadingAvatar, setLoadingAvatar] = useState(false)
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false)
  const [form] = Form.useForm()
  const { fetchAvatars } = useStore((stores) => stores.videosStore)
  const { user } = useStore((stores) => stores.authStore)

  const onFinish = async ({ gender, files }) => {
    setLoadingAvatar(true)
    const { name, data } = files[0]
    const res = await request({
      method: 'post',
      url: `/avatars`,
      data: { gender, image: { name, data } },
    })
    if (res === false) return setLoadingAvatar(false)
    notification.success({
      key: 'photo-avatar-success',
      message: successMessage,
    })
    fetchAvatars()
    setLoadingAvatar(false)
    form.resetFields()
    setIsOpenPhotoAvatarModal(false)
  }

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = reject
    })

  const onChangeUpload = async ({ file }) => {
    setIsSubmitButtonDisabled(true)
    try {
      file.data = await toBase64(file.originFileObj)
      file.status = 'done'
    } catch (e) {
      file.status = 'error'
    } finally {
      setIsSubmitButtonDisabled(false)
    }
  }

  const uploadProps = {
    name: 'file',
    accept: 'image/jpeg, image/png',
    maxCount: 1,
    beforeUpload,
    onChange: onChangeUpload,
    customRequest: () => {},
  }

  const agreementCheckbox = () => (
    <Form.Item
      name="accept"
      valuePropName="checked"
      style={{ marginTop: 20 }}
      rules={[
        {
          validator: (_, value) =>
            value
              ? Promise.resolve()
              : Promise.reject(new Error('Please confirm that you allow us to create your avatar')),
        },
      ]}
    >
      <Checkbox>
        I accept{' '}
        <a href="https://elai.io/terms-and-conditions" target="_blank" rel="noreferrer">
          Terms & Conditions
        </a>{' '}
        and{' '}
        <a href="https://elai.io/privacy-policy" target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
        . I confirm that I am the owner of this footage and allow Elai to create a digital avatar.
      </Checkbox>
    </Form.Item>
  )

  return (
    <Modal
      centered
      open={isOpenPhotoAvatarModal}
      width={700}
      className="photo-avatar-modal"
      footer={null}
      onCancel={() => {
        form.resetFields()
        setIsOpenPhotoAvatarModal(false)
      }}
    >
      {user?.account?.status === 'paid' ? (
        <>
          <h2>Create your photo avatar</h2>
          <div className="subheader">
            <p>Please make sure your photo follows these requirements:</p>
            <ul>
              <li>Avoid smiles; neutral expressions are preferred.</li>
              <li>
                Only humans or human-like creatures are allowed. For animals or non-human-like beings, please use{' '}
                <a href="https://app.elai.io/buy-avatar#mascot" target="_blank" rel="noreferrer">
                  mascot avatars
                </a>
                .
              </li>
              <li>Note: Lip sync might not work properly for large lips; we are working on it.</li>
            </ul>
          </div>
          <Form layout="vertical" form={form} requiredMark={false} onFinish={onFinish}>
            <Form.Item
              label="Avatar's photo"
              name="files"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[
                {
                  validator: (_, value) =>
                    !value[0] || value[0].status === 'error' ? Promise.reject(new Error()) : Promise.resolve(),
                  message: "Please add avatar's photo",
                },
              ]}
            >
              <Dragger {...uploadProps}>
                <p className="ant-upload-drag-icon">
                  <Icon name="upload" />
                </p>
                <p className="ant-upload-text">Click or drag photo to this area.</p>
              </Dragger>
            </Form.Item>
            <Form.Item
              label={
                <span className="gender-label">
                  Gender{' '}
                  <Tooltip title="This will be used to filter available voices for this avatar">
                    <Icon name="info" />
                  </Tooltip>
                </span>
              }
              name="gender"
              rules={[
                {
                  required: true,
                  message: 'Gender is required',
                },
              ]}
            >
              <Radio.Group>
                <Radio value="male">Male</Radio>
                <Radio value="female">Female</Radio>
              </Radio.Group>
            </Form.Item>
            {agreementCheckbox()}
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="btn-submit"
                disabled={isSubmitButtonDisabled}
                loading={loadingAvatar}
                size="large"
              >
                Create now
              </Button>
            </Form.Item>
          </Form>
        </>
      ) : (
        <>
          <h3>Photo avatars in Beta</h3>
          <p>
            This feature is in Public Beta and only available for <b>paid customers</b> now. Please Upgrade or check
            back later.
          </p>
        </>
      )}
    </Modal>
  )
}

export default CreatePhotoAvatarModal
