import { useEffect } from 'react'

const listenerCallbacks = new WeakMap()

const handleIntersections = (entries) => {
  entries.forEach((entry) => {
    if (!listenerCallbacks.has(entry.target) || !entry.isIntersecting) return

    const callback = listenerCallbacks.get(entry.target)
    observer.unobserve(entry.target)
    listenerCallbacks.delete(entry.target)
    callback()
  })
}

const observer = new IntersectionObserver(handleIntersections)

const useIntersection = (ref, callback) => {
  useEffect(() => {
    if (ref.current) {
      listenerCallbacks.set(ref.current, callback)
      observer.observe(ref.current)

      return () => {
        listenerCallbacks.delete(ref.current)
        if (ref.current) observer.unobserve(ref.current)
      }
    }
  }, [ref, callback])
}

export default useIntersection
