import { Col } from 'antd'
import { useState } from 'react'

// TODO: check why renders so much times
export const AnimationListComponent = (props) => {
  const { animation, updateSlide, type, animationType } = props

  const [isPlayingVideoPreview, setIsPlayingVideoPreview] = useState(false)

  const handleCLick = () => updateSlide(animation.id, type)

  const onMouseOver = () => setIsPlayingVideoPreview(animation.id)
  const onMouseOut = () => setIsPlayingVideoPreview(false)

  return (
    <Col span={12} key={animation.id} onClick={handleCLick} className="animation-list-col">
      <div
        className={`effect-preview-container ${
          (!animationType && !animation.id) || animationType === animation.id ? 'active' : ''
        }`}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      >
        {animation.thumbnail ? (
          <>
            {isPlayingVideoPreview === animation.id && animation.thumbnail.video ? (
              <video className="effect-preview-thumbnail" src={animation.thumbnail.video} autoPlay={true} loop muted />
            ) : (
              <img src={animation.thumbnail.image} className="effect-preview-thumbnail" />
            )}
          </>
        ) : (
          <p className="animation-name">{animation.name}</p>
        )}
      </div>
    </Col>
  )
}
