import { debounce } from 'throttle-debounce'
import { useState, useCallback, useEffect } from 'react'
import { Collapse, Switch, Input, Row, Col, Tooltip } from 'antd'

import SlideSelect from './slideSelect'
import Icon from '../../../../../components/Icon'

import { tutorialVideos } from '../../../../../utils/constants'
import { arrowDownIcon, questions, elements, QUESTIONS_BLOCKED_TOOLTIP } from '../constants'
import { track } from '../../../../../utils/analytics'
import configService from '../../../../../utils/config'

const { Panel } = Collapse

export const GeneralControls = (props) => {
  const { data, video, activeSlide, updateSlide, setActiveObjectModifier } = props

  const [chapterTitle, setChapterTitle] = useState()
  const [isQuestionsAdded, setIsQuestionsAdded] = useState(false)
  const [isInteractiveElementAdded, setIsInteractiveElementAdded] = useState(false)
  const { hideElaiMentions } = configService.get().features

  const handleChangeNextSlide = (value) => {
    updateSlide({ interactivity: { ...data.interactivity, nextSlide: value } })
  }

  const handleChangeChapterEnabled = (checked) => {
    updateSlide({ interactivity: { ...data.interactivity, chapterEnabled: checked, chapterTitle: null } })
    setChapterTitle(null)
  }

  const saveChapterTitle = (value) => {
    if (value === data.interactivity?.chapterTitle) return
    updateSlide({ interactivity: { ...data.interactivity, chapterTitle: value } })
  }

  const debounceSaveChapterTitle = useCallback(
    debounce(500, (value) => saveChapterTitle(value)),
    [data.interactivity],
  )

  const onChangeChapterTitle = (event) => {
    const value = event.target.value
    setChapterTitle(value)
    debounceSaveChapterTitle(value)
  }

  const addButton = (id) => () => {
    // do nothing when question added
    if (isQuestionsAdded) return
    // transition is not supported with interactive
    updateSlide({ transition: null, interactivity: { ...data.interactivity, nextSlide: null } })
    setActiveObjectModifier({ newObject: 'interactive_element', id })
    track('interactivity_button_added', { id: video._id })
  }

  const addQuestion = (id) => () => {
    // transition is not supported with interactive
    if (isInteractiveElementAdded) return
    updateSlide({ transition: null, interactivity: { ...data.interactivity, nextSlide: null } })
    setActiveObjectModifier({ newObject: 'question', id })
    track('interactivity_question_added', { id: video._id })
  }

  useEffect(() => {
    setChapterTitle(data.interactivity?.chapterTitle)
  }, [data.id])

  useEffect(() => {
    setIsQuestionsAdded(false)
    setIsInteractiveElementAdded(false)

    // find interactive element on slide
    const interactiveObjects = video.slides[activeSlide].canvas.objects.filter(
      (element) =>
        (element?.meta?.interactivity && Object.keys(element.meta.interactivity).length > 0) ||
        element.type === 'question',
    )
    const question = interactiveObjects.some((element) => element.type === 'question')

    // if we find it
    if (interactiveObjects.length > 0) {
      //   // check is it's question
      if (question) {
        setIsQuestionsAdded(true)
      }

      // and any other interactivity object
      if (interactiveObjects.some((element) => Object.keys(element?.meta?.interactivity ?? []).length > 0)) {
        setIsInteractiveElementAdded(true)
      }
      //   // reset state if there is no any interactivity elements on slide
    }
  }, [activeSlide, video])

  const buttonsTooltip = isQuestionsAdded
    ? 'Reminder: Additional interactive elements cannot be added to a slide with "Questions"'
    : "Clicking on a button can redirect viewers to another section of the video or open a link in a new tab. After the avatar's speech, the video pauses until the viewer interacts with the button. You can customize the button's name and color by ungrouping the button element, making adjustments, and regrouping it. Once customized, please ensure to add interactivity to the button."

  const questionsTooltip = isInteractiveElementAdded
    ? QUESTIONS_BLOCKED_TOOLTIP
    : "Add quizzes with clickable options to your video. The quiz will appear after the avatar finishes speaking. You can customize which slide will be shown next based on whether the user's answer was correct or incorrect."

  return (
    <div className="interactivity-general-controls">
      <Collapse
        className="sidebar-collapse-transparent"
        defaultActiveKey={['1']}
        expandIconPosition="end"
        expandIcon={() => arrowDownIcon}
        ghost
      >
        <Panel header={<h4 className="sidebar-collapse-title">Slide settings</h4>} key="1">
          {video.slides.length > 1 && (
            <Tooltip
              title={
                isQuestionsAdded || isInteractiveElementAdded
                  ? 'The current slide has interactions configured, allowing navigation to other slides upon interaction.'
                  : ''
              }
              placement="bottomRight"
            >
              <>
                <SlideSelect
                  slides={video.slides}
                  activeSlide={activeSlide}
                  disabled={isQuestionsAdded || isInteractiveElementAdded}
                  title={
                    <div className="title-info-wrapper">
                      <span>Next slide</span>
                      <Tooltip title="You can choose which slide to show after this one. Change slides order to create branching scenarios. This is usually used together with Questions interactions.">
                        <Icon name="info" />
                      </Tooltip>
                    </div>
                  }
                  value={data.interactivity?.nextSlide || null}
                  handleChangeSlide={handleChangeNextSlide}
                />
              </>
            </Tooltip>
          )}
          <Row align="middle" justify="space-between">
            <Col className="title-info-wrapper">
              <span className="control-label-bolder">Slide chapter</span>
              <Tooltip title="Add chapter for each slide so your audience can see what particular slide is about and jump to relevant sections while watching. This can be used without any interactive elements.">
                <Icon name="info" />
              </Tooltip>
            </Col>
            <Col>
              <Switch checked={data.interactivity?.chapterEnabled} onChange={handleChangeChapterEnabled} />
            </Col>
          </Row>
          {data.interactivity?.chapterEnabled && (
            <Input
              placeholder="Add chapter name here"
              value={chapterTitle}
              maxLength={82}
              allowClear
              onChange={onChangeChapterTitle}
            />
          )}
        </Panel>
      </Collapse>
      <Collapse
        className="sidebar-collapse-transparent"
        defaultActiveKey={['1']}
        expandIconPosition="end"
        expandIcon={() => arrowDownIcon}
        ghost
      >
        <Panel header={<h4 className="sidebar-collapse-title">Interactive elements</h4>} key="1">
          <div className="title-info-wrapper">
            <h4 className="elements-title">Questions</h4>
            <Tooltip title={questionsTooltip}>
              <Icon name="info" />
            </Tooltip>
          </div>
          <Tooltip title={isInteractiveElementAdded ? QUESTIONS_BLOCKED_TOOLTIP : null}>
            <Row gutter={[8, 8]} className={isInteractiveElementAdded ? 'white-coating' : ''}>
              {questions.map((question) => (
                <Col key={question.id} span={12}>
                  <div
                    className={`question-card ${isInteractiveElementAdded && 'interactivity-disabled'}`}
                    onClick={addQuestion(question.id)}
                  >
                    <img src={question.thumbnail} alt="Question thumbnail" />
                  </div>
                </Col>
              ))}
            </Row>
          </Tooltip>
          <div className="title-info-wrapper">
            <h4 className="elements-title">Buttons</h4>
            <Tooltip title={buttonsTooltip}>
              <Icon name="info" />
            </Tooltip>
          </div>
          <Tooltip title={isQuestionsAdded ? buttonsTooltip : null}>
            <Row gutter={[8, 8]} className={isQuestionsAdded ? 'white-coating' : ''}>
              {elements.map((element) => (
                <Col key={element.id} span={12}>
                  <div
                    className={`element-card ${isQuestionsAdded && 'interactivity-disabled'}`}
                    onClick={addButton(element.id)}
                  >
                    <div className="element-card-image" style={{ backgroundImage: `url(${element.thumbnail})` }}></div>
                  </div>
                </Col>
              ))}
            </Row>
          </Tooltip>
        </Panel>
      </Collapse>
      {!hideElaiMentions && (
        <a href={tutorialVideos.interactiveVideos.url} target="_blank" rel="noopener noreferrer">
          <img className="tutorial-thumbnail" src={tutorialVideos.interactiveVideos.thumbnail}></img>
        </a>
      )}
    </div>
  )
}
