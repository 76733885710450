import { useEffect, useRef, useState } from 'react'

const useScrollToBottom = (callback, shouldKeepScroll, containerSelector = (el) => el, threshold = 50) => {
  const scrollRef = useRef(null)
  const [savedScrollTop, setSavedScrollTop] = useState(0)

  useEffect(() => {
    const container = containerSelector(scrollRef.current)
    if (!container) return

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = container
      if (scrollHeight - scrollTop <= clientHeight + threshold) {
        callback()
      }
    }

    container.addEventListener('scroll', handleScroll)
    return () => container.removeEventListener('scroll', handleScroll)
  }, [callback, threshold])

  useEffect(() => {
    const container = containerSelector(scrollRef.current)
    if (!container) return

    if (!shouldKeepScroll && savedScrollTop) {
      setTimeout(() => (container.scrollTop = savedScrollTop), 250)
    }
  }, [shouldKeepScroll, savedScrollTop])

  const saveScrollPosition = () => {
    const container = containerSelector(scrollRef.current)
    if (!container) return

    setSavedScrollTop(container.scrollTop)
  }

  return { scrollRef, saveScrollPosition }
}

export default useScrollToBottom
