import { Layout, Button } from 'antd'
import { academyIcons, zeroPadding } from '../constants'
import PageHeader from '../../../../components/PageHeader/pageHeader'
import { useAdminLessonsState } from './useAdminLessonsState'
import { CreateLessonModal, LessonsTable } from '../components'

export const AdminLessons = () => {
  const {
    id,
    lessonForm,
    editLesson,
    isModalOpen,
    lessonsData,
    deleteLesson,
    editableLesson,
    setLessonsData,
    setIsModalOpen,
    setEditableLesson,
    handleCreateLesson,
  } = useAdminLessonsState()

  const pageHeaderExtra = [
    <Button type="primary" key="create-video-btn" onClick={handleCreateLesson} icon={academyIcons.plusOutlined}>
      New Lesson
    </Button>,
  ]

  return (
    <Layout.Content className="admin-content">
      <div className="content">
        <PageHeader title={`${lessonsData.course?.name} lessons`} style={zeroPadding} extra={pageHeaderExtra} />
        <LessonsTable lessons={lessonsData.lessons} deleteLesson={deleteLesson} editLesson={editLesson} />
      </div>
      <CreateLessonModal
        courseId={id}
        lessonForm={lessonForm}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setLessonsData={setLessonsData}
        editableLesson={editableLesson}
        setEditableLesson={setEditableLesson}
      />
    </Layout.Content>
  )
}
