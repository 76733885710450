import { Tooltip, Button } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import megaphone from '../../../../assets/images/public_custom_voice/megaphone.png'

const Purchase = ({ canBuyVoice, onClickBuyNow }) => {
  return (
    <div className="purchase-container content-wrapper">
      <h2 className="section-title">Simple annual fee</h2>
      <div className="purchase-content-container">
        <div className="img-megaphone-container">
          <img className="img-megaphone" src={megaphone} alt="Megaphone" />
        </div>
        <div className="main-content">
          <h2>Buy voice cloning</h2>
          <h3>$200.00</h3>
          <ul>
            <li>Create narration with your voice in 32 different languages</li>
            <li>3-5 minutes recording</li>
            <li>Turn your narration into a digital voice</li>
          </ul>
          <Tooltip
            title="Please contact your account owner to perform this action."
            overlayStyle={{
              visibility: canBuyVoice ? 'hidden' : 'visible',
            }}
          >
            <Button
              type="primary"
              disabled={!canBuyVoice}
              icon={<RightOutlined />}
              className="btn-arrow"
              size="large"
              onClick={onClickBuyNow}
            >
              Buy now
            </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default Purchase
