import { fabric } from 'fabric'
import { GifReader } from 'omggif'
import * as storage from 'idb-keyval'
import { isSignedUrl, requestIdleCallback } from '../helpers'

const itemsToProcessBeforeIdle = 20

const gifCache = new Map()

const idle = () => new Promise((resolve) => requestIdleCallback(resolve, { timeout: 20 }))

const fetchGifAsArrayBuffer = async (src) => {
  const response = await fetch(src)
  return new Uint8Array(await response.arrayBuffer())
}

const buildCacheKey = (src) => (isSignedUrl(src) ? src.split('?')[0] : src)

const loadGifWithImageDecoder = async (url) => {
  const imageDecoder = new window.ImageDecoder({ type: 'image/gif', data: await fetchGifAsArrayBuffer(url) })
  await imageDecoder.tracks.ready

  const track = imageDecoder.tracks.selectedTrack
  const frameCount = track.frameCount

  const canvas = document.createElement('canvas')
  const canvasContext = canvas.getContext('2d')

  let width
  let height

  const frames = []
  for (let imageIndex = 0; imageIndex < frameCount; imageIndex++) {
    const result = await imageDecoder.decode({ frameIndex: imageIndex })
    if (imageIndex === 0) {
      width = result.image.displayWidth || result.image.width
      height = result.image.displayHeight || result.image.height
      canvas.width = width
      canvas.height = height
    }
    canvasContext.clearRect(0, 0, width, height)
    canvasContext.drawImage(result.image, 0, 0)

    const base64Image = canvas.toDataURL('image/png')
    frames.push({
      url: base64Image,
      delay: result.image.duration / 1000,
    })
  }
  imageDecoder.close()
  return { width, height, frames }
}

const renderGifFrames = async (src) => {
  const gifReader = new GifReader(await fetchGifAsArrayBuffer(src))

  const frameData = []
  const { width, height } = gifReader.frameInfo(0)
  for (let frameIndex = 0; frameIndex < gifReader.numFrames(); frameIndex++) {
    const framePixels = new Uint8ClampedArray(gifReader.width * gifReader.height * 4)
    gifReader.decodeAndBlitFrameRGBA(frameIndex, framePixels)

    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height
    const ctx = canvas.getContext('2d')

    const imageData = ctx.createImageData(width, height)
    imageData.data.set(framePixels)
    ctx.putImageData(imageData, 0, 0)

    frameData.push({
      getImage: () => canvas,
      frameInfo: gifReader.frameInfo(frameIndex),
    })
    if (frameIndex % itemsToProcessBeforeIdle === 0) await idle()
  }
  return { width, height, frameData }
}

const renderCumulativeFrames = async (frameData, width, height) => {
  if (frameData.length === 0) return frameData
  const previous = document.createElement('canvas')
  const current = document.createElement('canvas')
  previous.width = current.width = width
  previous.height = current.height = height
  const previousContext = previous.getContext('2d')
  const currentContext = current.getContext('2d')

  for (let i = 0; i < frameData.length; i++) {
    const frame = frameData[i]
    previousContext.clearRect(0, 0, width, height)
    previousContext.drawImage(current, 0, 0)

    const canvas = frame.getImage()
    currentContext.drawImage(canvas, 0, 0)
    canvas.getContext('2d').clearRect(0, 0, width, height)
    canvas.getContext('2d').drawImage(current, 0, 0)

    const { frameInfo } = frame
    if (frameInfo.disposal === 2) {
      currentContext.clearRect(frameInfo.x, frameInfo.y, frameInfo.width, frameInfo.height)
    } else if (frameInfo.disposal === 3) {
      currentContext.clearRect(0, 0, width, height)
      currentContext.drawImage(previous, 0, 0)
    }
    const image = canvas.toDataURL('image/png')
    frame.getImage = () => image

    if (i % itemsToProcessBeforeIdle === 0) await idle()
  }
  return frameData
}

const loadGifExternally = async (src) => {
  let { width, height, frameData } = await renderGifFrames(src)
  frameData = await renderCumulativeFrames(frameData, width, height)

  const frames = []
  for (const frame of frameData) {
    frames.push({
      url: frame.getImage(),
      delay: frame.frameInfo.delay * 10,
    })
    if (frames.length % itemsToProcessBeforeIdle === 0) await idle()
  }
  return { width, height, frames }
}

const gifLoader = window.ImageDecoder ? loadGifWithImageDecoder : loadGifExternally

const convertGifToFrames = async (src, cacheKey) => {
  const cachedFrames = await storage.get(cacheKey).catch(() => null)
  if (cachedFrames) return cachedFrames

  let { width, height, frames } = await gifLoader(src)

  const retval = { width, height, frames }
  storage.set(cacheKey, retval).catch(() => {})
  return retval
}

const Gif = fabric.util.createClass(fabric.Image, {
  lockSkewingX: true,
  lockSkewingY: true,
  lockScalingFlip: true,
  spriteIndex: 0,

  initialize: function (_, options) {
    this.id = options.id
    this.type = 'gif'
    this.frames = options.frames
    this.src = options.src
    this.previewSrc = options.previewSrc
    this.width = options.width
    this.height = options.height
    this.frameTime = this.frames[0].delay
    this.animation = { type: null }

    this.scaleToWidth(200)
    this.createSpriteImages()
    this.callSuper('initialize', null, options)

    this.on({ removed: () => this.stop() })
    this.setControlsVisibility({ mt: false, ml: false, mr: false, mb: false })
    this.play()
  },

  createSpriteImages: function () {
    this.spriteImages = []
    for (const frame of this.frames) {
      const tmpImg = fabric.util.createImage()
      tmpImg.src = frame.url
      this.spriteImages.push(tmpImg)
    }
  },

  _render: function (ctx) {
    ctx.drawImage(this.spriteImages[this.spriteIndex], -this.width / 2, -this.height / 2)
  },

  _updateFrame: function () {
    this.spriteIndex++
    if (this.spriteIndex === this.spriteImages.length) this.spriteIndex = 0
    this.frameTime = this.frames[this.spriteIndex].delay
    if (this.canvas?.getObjects().length) this.canvas.requestRenderAll()
    this.animTimeout = setTimeout(this._updateFrame.bind(this), this.frameTime)
  },

  play: function () {
    this.stop()
    this.animTimeout = setTimeout(this._updateFrame.bind(this), this.frameTime)
  },

  stop: function () {
    clearTimeout(this.animTimeout)
  },

  toObject: function () {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      id: this.id,
      src: this.src,
      previewSrc: this.previewSrc,
      animation: this.animation,
      type: this.type,
      meta: this.meta,
    })
  },
})

Gif.fromObject = function (object, callback) {
  Gif.createGif(object)
    .then((gif) => {
      callback(gif)
    })
    .catch(() => callback(null, true))
}

Gif.createGif = async function (options) {
  const gifSrc = options.previewSrc || options.src
  const cacheKey = buildCacheKey(gifSrc)
  const { width, height, frames } = gifCache.has(cacheKey)
    ? gifCache.get(cacheKey)
    : await convertGifToFrames(gifSrc, cacheKey)

  if (!gifCache.has(gifSrc)) gifCache.set(cacheKey, { width, height, frames })

  const gif = new fabric.Gif(null, { frames, width, height, ...options })
  return gif
}

Gif.dropCache = () => gifCache.clear()

Gif.async = true

export default Gif
