import { Button } from 'antd'
import { useRef, useState, useEffect } from 'react'

import Icon from '../Icon'

import './horizontalScroll.less'

const arrowRightIcon = <Icon name="right_arrow" />
const arrowLeftIcon = <Icon name="left_arrow" />

const HorizontalScroll = (props) => {
  const { items } = props

  const scrollbarRef = useRef(null)
  const [scrollX, setScrollX] = useState(0)
  const [scrollEnd, setScrollEnd] = useState(false)

  const slide = (shift) => {
    scrollbarRef.current.scrollLeft = scrollbarRef.current.scrollLeft + shift
  }

  const scrollCheck = () => {
    setScrollX(scrollbarRef.current.scrollLeft)
    const temp = Math.floor(scrollbarRef.current.scrollWidth - scrollbarRef.current.scrollLeft)
    if (temp <= scrollbarRef.current.offsetWidth) {
      setScrollEnd(true)
    } else {
      setScrollEnd(false)
    }
  }

  useEffect(() => {
    if (!scrollbarRef.current || !scrollbarRef.current.scrollWidth || !scrollbarRef.current.offsetWidth) return
    if (scrollbarRef?.current?.scrollWidth === scrollbarRef?.current?.offsetWidth) {
      setScrollEnd(true)
    } else {
      setScrollEnd(false)
    }
  }, [scrollbarRef.current?.scrollWidth, scrollbarRef.current?.offsetWidth])

  const leftClick = () => slide(-100)
  const rightClick = () => slide(100)

  return (
    <div className="horizontal-scroll">
      <div className={`btn-arrow-mask left ${scrollX !== 0 ? 'visible' : ''}`}>
        <Button
          className="btn-arrow"
          type="primary"
          shape="circle"
          size="small"
          icon={arrowLeftIcon}
          onClick={leftClick}
        />
      </div>
      <div ref={scrollbarRef} className="items-wrapper" onScroll={scrollCheck}>
        {items}
      </div>
      <div className={`btn-arrow-mask right ${!scrollEnd ? 'visible' : ''}`}>
        <Button
          className="btn-arrow"
          type="primary"
          shape="circle"
          size="small"
          icon={arrowRightIcon}
          onClick={rightClick}
        />
      </div>
    </div>
  )
}

export default HorizontalScroll
