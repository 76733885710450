import { Col, Button } from 'antd'
import ButtonGroup from 'antd/lib/button/button-group'

import { minusIcon, listIcon, orderedListIcon } from '../constants'

export const TextType = (props) => {
  const { activeObjectProps, setActiveObjectModifier } = props

  const isTextType = activeObjectProps.type === 'i-text' || activeObjectProps.type === 'textbox' ? 'primary' : 'default'
  const isListType = activeObjectProps.listType === 'unordered' ? 'primary' : 'default'
  const isOrderedListType = activeObjectProps.listType === 'ordered' ? 'primary' : 'default'

  const onTextTypeClick = () => {
    setActiveObjectModifier({ change: 'textType', value: 'text' })
  }

  const onListTypeClick = () => {
    setActiveObjectModifier({ change: 'textType', value: 'unordered' })
  }

  const onOrderedListTypeClick = () => {
    setActiveObjectModifier({ change: 'textType', value: 'ordered' })
  }

  return (
    <Col>
      <ButtonGroup>
        <Button icon={minusIcon} type={isTextType} onClick={onTextTypeClick} />
        <Button icon={listIcon} type={isListType} onClick={onListTypeClick} />
        <Button icon={orderedListIcon} type={isOrderedListType} onClick={onOrderedListTypeClick} />
      </ButtonGroup>
    </Col>
  )
}
