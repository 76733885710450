import { Button, Modal, Row } from 'antd'

import { useScreenRecorderState } from './useScreenRecorderState'

import { stopRecord, redRecord } from './icons'
import Icon from '../../../../components/Icon'

export const ScreenRecorder = (props) => {
  const { setShouldUpdateUploads, setActiveObjectModifier } = props
  const {
    video,
    isOpen,
    seconds,
    videoRef,
    handleOk,
    isRecording,
    handleCancel,
    recordHandler,
    getDisplayTime,
    handleStopRecord,
  } = useScreenRecorderState({ setShouldUpdateUploads, setActiveObjectModifier })

  return (
    <div className="tab-content">
      <h3>Screen recording</h3>
      <p>Record your screen and add a recording to your video instantly.</p>
      <div className="record-container">
        <span onClick={isRecording ? handleStopRecord : recordHandler} className="record-button">
          {isRecording ? stopRecord : redRecord}
        </span>
        <div>
          {getDisplayTime(seconds)} / <span className="time-limit">30:00</span>
        </div>
      </div>
      <Modal
        title="Screen Recording"
        open={isOpen}
        onCancel={handleCancel}
        width={568}
        footer={
          <Row justify="space-between">
            <Button
              ghost
              type="primary"
              icon={<Icon name="record" />}
              style={{ color: 'gray', fill: 'gray' }}
              onClick={recordHandler}
            >
              Restart
            </Button>
            <Button type="primary" icon={<Icon name="plus" />} onClick={handleOk}>
              Add to video
            </Button>
          </Row>
        }
      >
        <video ref={videoRef} width="520" height="300" src={video.url && video.url} controls />
      </Modal>
    </div>
  )
}

/**
 * TODO:
 * add sound to video OS & micro
 * display notification untill video is uploaded
 */
