import { useNavigate } from 'react-router-dom'
import { Collapse, Button } from 'antd'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { useStore } from '../../../../store'
import voice_shape from '../../../../assets/images/public_custom_avatar/voice-shape.png'
import voice_shape_2 from '../../../../assets/images/public_custom_avatar/voice-shape-2.png'
import Voice from '../components/voice/voice'

const { Panel } = Collapse

export default ({ activeTab, setIsOpenPhotoAvatarModal }) => {
  const navigate = useNavigate()
  const authStore = useStore((stores) => stores.authStore)

  return (
    <div className="voice-collapse-container tabs-content-wrapper">
      <Collapse
        className="voice-collapse"
        expandIcon={({ isActive }) => (
          <Button type="primary" size="large" icon={isActive ? <UpOutlined /> : <DownOutlined />} className="btn-arrow">
            See {isActive ? 'less' : 'more'}
          </Button>
        )}
      >
        <Panel
          header={
            <div className="header-container">
              <h2 style={{ marginBottom: 0 }}>Generate videos with your own voice</h2>
              <img className="stick-shape" src={voice_shape} alt="stick shape"></img>
              <img className="triangle-shape" src={voice_shape_2} alt="triangle shape"></img>
            </div>
          }
          key="1"
        >
          <Voice isAvatarsPage={true} />
        </Panel>
      </Collapse>
      {activeTab === 'photo' && (
        <Button
          type="primary"
          size="large"
          className="btn-get-started"
          onClick={() => (authStore.user ? setIsOpenPhotoAvatarModal(true) : navigate('/signup'))}
        >
          Get started
        </Button>
      )}
    </div>
  )
}
