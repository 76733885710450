import { Form, Checkbox } from 'antd'

const AgreementCheckbox = ({ isVoice }) => {
  return (
    <Form.Item
      name="accept"
      valuePropName="checked"
      style={{ marginTop: 20 }}
      rules={[
        {
          validator: (_, value) =>
            value
              ? Promise.resolve()
              : Promise.reject(
                  new Error(`Please confirm that you allow us to create your ${isVoice ? 'voice' : 'avatar'}.`),
                ),
        },
      ]}
    >
      <Checkbox>
        I accept{' '}
        <a href="https://elai.io/terms-and-conditions" target="_blank" rel="noreferrer">
          Terms & Conditions
        </a>{' '}
        and{' '}
        <a href="https://elai.io/privacy-policy" target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
        . I confirm that I am the owner of this {isVoice ? 'recording' : 'footage'} and allow Elai to create a digital{' '}
        {isVoice ? 'voice' : 'avatar'}.
      </Checkbox>
    </Form.Item>
  )
}

export default AgreementCheckbox
