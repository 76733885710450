import { memo } from 'react'

const styles = {
  colorRed: { color: 'red' },
  colorRedBoldFont: { color: 'red', fontWeight: 'bold' },
  marginTop20: { marginTop: 20 },
}
/**
 * @param {boolean} isAdmin  user priveleges
 * @param {string} videoStatus status of the video
 * @param {object} moderation object from video, if video need moderation
 */
export const ModeratioStatusPanel = memo((props) => {
  const { isAdmin, videoStatus, moderation } = props

  return isAdmin && videoStatus === 'moderation' ? (
    <div style={styles.marginTop20}>
      {moderation?.containsCustomVoices && <p style={styles.colorRedBoldFont}>CHECK CUSTOM VOICE</p>}
      {moderation?.error && <p style={styles.colorRedBoldFont}>Error: {moderation.error.message}</p>}
      {moderation?.data?.profanity?.matches?.length > 0 && (
        <>
          <h4>
            Profanity: <span style={styles.colorRed}>{moderation.data.profanity.matches.join('; ')}</span>
          </h4>
        </>
      )}
      {moderation?.data?.toxicity && moderation.data.toxicity.label !== 'NEUTRAL' && (
        <p style={styles.colorRedBoldFont}>
          {moderation.data.toxicity.label}: {moderation.data.toxicity.score}
        </p>
      )}
      {moderation?.data?.propriety && moderation.data.propriety.label !== 'NEUTRAL' && (
        <p style={styles.colorRedBoldFont}>
          {moderation.data.propriety.label}: {moderation.data.propriety.score}
        </p>
      )}
      <p>{moderation?.text}</p>
    </div>
  ) : null
})
