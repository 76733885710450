import { useStore } from '../../../../../../store'
import React, { useCallback, useEffect, useState } from 'react'
import { Input } from 'antd'
import { debounce } from 'throttle-debounce'
import LibraryFilterButton from './libraryFilterButton'
import LibraryContent from './libraryContent'
import GenerateWithAiButton from './generateWithAiButton'
import GenerateWithAiDrawer from './generateWithAiDrawer'
import { mediaStockProvidersMap, mediaStockProvidersPathMap } from './content/media-provider.config'
import { useElaiNotification } from '../../../../../../hooks/useElaiNotification'

import './stock-media-providers.less'

const LibraryViewer = (props) => {
  const { filesType, onChooseFile, setShouldUpdateUploads } = props

  const [filter, setFilter] = useState({})
  const [metaData, setMetaData] = useState({})
  const [content, setContent] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [searchInputValue, setSearchInputValue] = useState(null)
  const [shouldApplyFilter, setShouldApplyFilter] = useState(false)
  const [isAiGenerateDrawerVisible, setIsAiGenerateDrawerVisible] = useState(false)
  const { mediaStockProvider } = useStore((stores) => stores.videosStore)

  const notification = useElaiNotification()

  const { name, label, searchKey, getContent, getDefaultContent } =
    mediaStockProvidersMap[mediaStockProvider[filesType]]

  const debounceSearchValue = useCallback(
    debounce(800, (value) => updateFilter({ [searchKey]: value })),
    [filesType, searchKey],
  )

  const showNotification = (description) => {
    notification.error({ key: 'stock-service-error', message: 'Stock service error', description, duration: null })
  }

  const onSearchHandler = (event) => {
    const { value } = event.target
    setSearchInputValue(value)
    debounceSearchValue(value)
  }

  const updateFilter = (props) => {
    setFilter((prev) => ({ ...prev, ...props }))
  }

  const updateContent = (data, rewrite = false) => {
    if (rewrite) setContent(data)
    else setContent((prev) => [...prev, ...data])
  }

  const updateMeta = (data) => {
    setMetaData(data)
  }

  const onShowMore = async () => {
    setIsLoading(true)
    const { content, meta, error } = await getContent({ nextPage: metaData.nextPage, filesType, filter })
    setIsLoading(false)
    if (error) return showNotification(error)
    updateContent(content)
    updateMeta(meta)
  }

  const setDefaultProviderContent = async () => {
    setIsLoading(true)
    const { content, meta, error } = await getDefaultContent(filesType)
    setIsLoading(false)
    if (error) return showNotification(error)
    updateContent(content, true)
    updateMeta(meta)
  }

  const searchHandler = async () => {
    setIsLoading(true)
    updateContent([], true)
    const { content, meta, error } = await getContent({ filesType, filter })
    setIsLoading(false)
    if (error) return showNotification(error)
    updateContent(content, true)
    updateMeta(meta)
  }

  useEffect(() => {
    if (filter[searchKey]?.length) return
    setDefaultProviderContent(filesType)
  }, [filesType])

  useEffect(() => {
    if (!shouldApplyFilter) return
    searchHandler()
    setShouldApplyFilter(false)
  }, [shouldApplyFilter])

  useEffect(() => {
    if (!filter[searchKey]?.length) {
      setDefaultProviderContent(filesType)
      return
    }
    searchHandler()
  }, [filter[searchKey]])

  useEffect(() => {
    setSearchInputValue('')
  }, [filesType])

  useEffect(() => {
    // drop filter if provider changed
    setFilter({})
  }, [name])

  return (
    <div className="search-media">
      {filesType === 'image' && (
        <GenerateWithAiButton setIsAiGenerateDrawerVisible={setIsAiGenerateDrawerVisible} onChooseFile={onChooseFile} />
      )}
      <div className="search-media-header">
        <Input
          placeholder={`Search awesome ${mediaStockProvidersPathMap[filesType]} on ${label}`}
          className="media-items-controls"
          value={searchInputValue}
          onChange={onSearchHandler}
        />
        <LibraryFilterButton
          filter={filter}
          filesType={filesType}
          updateFilter={updateFilter}
          setShouldApplyFilter={setShouldApplyFilter}
        />
      </div>
      <LibraryContent
        content={content}
        metaData={metaData}
        filesType={filesType}
        isLoading={isLoading}
        onShowMore={onShowMore}
        onChooseFile={onChooseFile}
      />
      <GenerateWithAiDrawer
        onChooseFile={onChooseFile}
        isAiGenerateDrawerVisible={isAiGenerateDrawerVisible}
        setIsAiGenerateDrawerVisible={setIsAiGenerateDrawerVisible}
        setShouldUpdateUploads={setShouldUpdateUploads}
      />
    </div>
  )
}

export default LibraryViewer
