import { useEffect, useState, useMemo } from 'react'
import { request } from '../utils/api'
import { mapSubfamilyToStyle } from '../utils/fabric/fontUtils'
import { videoStoreMediaStockProviderInitial } from '../routes/video/sidebar/elements/elementsTabs/stock-media-providers/content/media-provider.config'

export default () => {
  const [templates, setTemplates] = useState([])
  const [templateTags, setTemplateTags] = useState([])
  const [loadingTemplates, setLoadingTemplates] = useState(false)
  const [fonts, setFonts] = useState([])
  const [fontsReady, setFontsReady] = useState(false)
  const [loadingFonts, setLoadingFonts] = useState(false)
  const [avatars, setAvatars] = useState()
  const [markMap, setMarkMap] = useState([])
  const [mediaStockProvider, setMediaStockProvider] = useState(videoStoreMediaStockProviderInitial)

  const syncTemplatesTags = () => {
    let tags = []
    if (templates)
      templates.forEach((v) => {
        if (v.template.tags) tags = [...tags, ...v.template.tags]
      })
    setTemplateTags([...new Set(tags)])
  }

  useEffect(() => {
    syncTemplatesTags()
  }, [templates])

  const publicTemplates = useMemo(() => {
    if (!templates) return undefined
    return templates.filter((v) => v.template.enabled && !v.template.private).sort((v) => (!v.template.blank ? 1 : -1))
  }, [templates])

  const fetchTemplates = async () => {
    setLoadingTemplates(true)
    const data = await request({ method: 'get', url: 'templates' })
    setTemplates(data)
    setLoadingTemplates(false)
  }

  const fetchTemplate = async (templateId) => {
    const template = await request({ method: 'get', url: `templates/${templateId}` })
    setTemplates((templates) => {
      const index = templates.findIndex((t) => t._id === templateId)
      templates[index] = template
      return templates
    })
    return template
  }

  const fetchFonts = async () => {
    setLoadingFonts(true)
    const userFonts = await request({ method: 'get', url: 'fonts' })
    if (!userFonts) return setLoadingFonts(false)
    setFonts(userFonts)
    const fontsPromises = userFonts.map((font) => {
      const { fontStyle: style, fontWeight: weight } = mapSubfamilyToStyle(font.subfamily)
      const fontFace = new FontFace(font.name, `url('${font.url}')`, { style, weight })
      return fontFace.load().catch(() => null)
    })
    const loadedFonts = await Promise.all(fontsPromises)
    loadedFonts.filter(Boolean).forEach((font) => document.fonts.add(font))
    setFontsReady(true)
    setLoadingFonts(false)
  }

  const deleteFont = async (fontId) => {
    setLoadingFonts(true)
    setFonts((fonts) => fonts.filter((f) => f._id !== fontId))
    const ok = await request({ method: 'delete', url: `fonts/${fontId}` })
    if (!ok) return fetchFonts()
    setLoadingFonts(false)
  }

  const fetchAvatars = async () => {
    const data = await request({ method: 'get', url: 'avatars' })
    setAvatars(data)
  }

  const insertMarks = (value) => {
    setMarkMap(value)
  }

  const updateMediaStockProvider = (key, value) => {
    setMediaStockProvider((prev) => ({ ...prev, [key]: value }))
  }

  return {
    templates,
    templateTags,
    loadingTemplates,
    publicTemplates,
    syncTemplatesTags,
    fetchTemplates,
    fetchTemplate,
    fonts,
    fontsReady,
    loadingFonts,
    fetchFonts,
    deleteFont,
    avatars,
    insertMarks,
    fetchAvatars,
    markMap,
    mediaStockProvider,
    updateMediaStockProvider,
  }
}
