import React from 'react'
import { Button, Modal } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

import './pptxFontsPromptModal.less'

const PptxFontsPromptModal = ({
  showFontPrompt,
  setShowFontPrompt,
  setIsVideoCreating,
  createVideoFromPresentation,
  missedFonts,
}) => {
  const handleProceedIgnoreFonts = () => {
    createVideoFromPresentation('ignoreFonts')
  }

  const handleProceedStatic = () => {
    createVideoFromPresentation('static')
  }

  const handleCancel = () => {
    setShowFontPrompt(false)
    setIsVideoCreating(false)
  }

  return (
    <Modal
      width={400}
      open={showFontPrompt}
      maskClosable={false}
      onCancel={handleCancel}
      footer={
        <>
          <Button onClick={handleProceedIgnoreFonts}>Use default fonts</Button>
          <Button onClick={handleProceedStatic} type={'primary'}>
            Convert with static
          </Button>
        </>
      }
      title="Unsupported fonts detected"
      className="pptx-fonts-prompt-modal"
    >
      <p className="subtitle">The uploaded file contains fonts that are not yet available in your account:</p>
      <div className="list-wrapper">
        <ul>
          {missedFonts?.map((font) => (
            <li key={font}>{font}</li>
          ))}
        </ul>
      </div>
      <div className="warning">
        <InfoCircleOutlined />
        <p>
          Hint: To keep your original fonts, please{' '}
          <b>
            <a
              href="https://elai.freshdesk.com/support/solutions/articles/73000627996-font-upload"
              target="_blank"
              rel="noreferrer"
            >
              upload them
            </a>
          </b>{' '}
          to your account before converting the PPTX file into a video.
        </p>
      </div>
      <p className="footer-question">We recommend converting with static backgrounds to keep the original design</p>
    </Modal>
  )
}

export default PptxFontsPromptModal
