const fallbackFonts = [
  'Noto Sans Devanagari',
  'Noto Sans Bengali',
  'Noto Sans Arabic',
  'Noto Sans JP',
  'Noto Sans KR',
  'Noto Sans TC',
  'Noto Sans SC',
  'Noto Serif Devanagari',
  'Noto Serif Bengali',
].join(', ')

// NOTE: order is important
const fontWeightMap = {
  Thin: 100,
  ExtraLight: 200,
  UltraLight: 200,
  SemiLight: 350,
  DemiLight: 350,
  Light: 300,
  Regular: 400,
  Normal: 400,
  Medium: 500,
  SemiBold: 600,
  DemiBold: 600,
  ExtraBold: 800,
  UltraBold: 800,
  Bold: 700,
  ExtraBlack: 1000,
  UltraBlack: 1000,
  Black: 900,
}
const defaultWeight = 'normal'

export const getFontFamilyWithFallback = (font) => `${quotesIfMistakenFontName(font)}, ${fallbackFonts}`

const mapHumanReadableFontWeight = (weight) => {
  if (!weight) return defaultWeight

  for (const [key, value] of Object.entries(fontWeightMap)) {
    if (weight.includes(key)) {
      return value
    }
  }
  return defaultWeight
}

export const mapSubfamilyToStyle = (subfamily) => {
  const fontWeight = mapHumanReadableFontWeight(subfamily)
  const fontStyle = subfamily?.includes('Italic') ? 'italic' : 'normal'

  return { fontWeight, fontStyle }
}

export const quotesIfMistakenFontName = (string) => {
  if (/\d/.test(string)) return string.startsWith('"') && string.endsWith('"') ? string : `"${string}"`
  return string
}
