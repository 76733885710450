const template = {
  type: 'icon',
  url: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/icons/',
  thumbnail: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/icons/',
}

export const defaultIcons = [...Array(24).keys()].map((i) => ({
  type: 'icon',
  url: `${template.url}icon${i + 1}.svg`,
  thumbnail: `${template.url}icon${i + 1}.svg`,
}))
