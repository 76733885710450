import { Button, Tooltip } from 'antd'
import { useStore } from '../../../../../store'
import { grabVideoColors, applyLogo, applyCorporateColors } from '../../../colorsEditor/helpers'
import { useMemo } from 'react'
import { BrandkitControls } from '../../../../../components/BrandkitControls/brandkitControls'

/**
 * Brandbook component for sidebar
 * @returns JSX element
 */
export const Brandbook = (props) => {
  const {
    video,
    slide,
    setActiveObjectModifier,
    onVideoColorsChange,
    onChooseFile,
    updateVideo,
    visible = true,
  } = props
  const {
    user: { account },
  } = useStore((stores) => stores.authStore)
  const { fonts } = useStore((stores) => stores.videosStore)

  const handleApplyClick = async () => {
    await applyLogo(video, account.logo, slide.id, setActiveObjectModifier)
    applyAll()
    onVideoColorsChange()
  }

  const videoColors = useMemo(() => grabVideoColors(video, true), [video, visible])

  const applyAll = async () => {
    if (!account.corporateColors?.length) return

    applyCorporateColors(video, account.corporateColors, videoColors)
  }

  const applyAllDisabled = !Object.keys(account.logo).length && !account.corporateColors?.length && !fonts?.length

  return (
    <div className="brandbook-wrapper">
      <BrandkitControls onChooseFile={onChooseFile} updateVideo={updateVideo} />
      <div className="brandbook-apply-button">
        <Tooltip
          placement="top"
          overlayStyle={{ visibility: applyAllDisabled ? 'hidden' : 'visible' }}
          title="Apply your Brandbook color scheme and logo to the current video. Colors in the video will be replaced with those from your Brandbook. The logo will be added to the first slide and will replace any existing logos in the video."
        >
          <Button block type="primary" disabled={applyAllDisabled} onClick={handleApplyClick}>
            Apply to video
          </Button>
        </Tooltip>
      </div>
    </div>
  )
}
