import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Input, Space, Tooltip, Button, Popover, Menu, notification } from 'antd'
import { SyncOutlined } from '@ant-design/icons'
import { useStore } from '../../../store'
import Icon from '../../../components/Icon'
import VideoBackButton from '../../video/components/videoHeader/videoBackButton'
import { Comments } from '../../../components/Comments/comments'
import { CommentsBadge } from '../../../components/Comments/commentsBadge'
import { defaultTemplateId, maxVideoNameLength } from '../../../utils/constants'
import { requestCopyVideoToAdmin } from '../../../utils/videoCreation/videoCreation'

export const VideoHeader = ({
  video,
  videoSavingStatus,
  isVideoGenerating,
  changesHistory,
  isSwitching,
  setIsSwitching,
  undoLastChanges,
  updateVideo,
  saveVideo,
  validateAndGenerateVideo,
  setIsOpenTemplatesModal,
  isEditorBusy,
}) => {
  const navigate = useNavigate()
  const { user, logout, checkAdminLoginedAsUser, adminLoginAs } = useStore((stores) => stores.authStore)
  const [isClickAllowed, setIsClickAllowed] = useState(true)
  const [editingName, setEditingName] = useState(false)
  const [videoName, setVideoName] = useState()
  const [isOpenHeaderMobileMenu, setIsOpenHeaderMobileMenu] = useState(false)

  const saveVideoName = async () => {
    if (!videoName) {
      notification.error({ message: 'Video name is required' })
      return
    }
    if (videoName.length > maxVideoNameLength) {
      notification.error({ message: `Video name should be less than ${maxVideoNameLength} symbols` })
      return
    }
    updateVideo({ name: videoName }, { ignoreHistory: true, triggerPartialSave: true })
    setEditingName(false)
  }

  const buttonSaveVideo = async () => {
    if (!isClickAllowed) {
      return
    }
    setIsClickAllowed(false)
    await saveVideo()
    setIsClickAllowed(true)
  }

  const handleCopyVideo = () => {
    // If the video name is too long, the validation will throw an error, and the video will not be copied.
    if (video.name.length > 60) {
      video.name = video.name.substring(0, 60)
    }
    requestCopyVideoToAdmin(video, user, logout)
  }

  const handleConvert = () => {
    if (video.sourceId === defaultTemplateId && isSwitching === false) {
      setIsSwitching(false)
      setIsOpenTemplatesModal(true)
    } else {
      validateAndGenerateVideo()
    }
  }

  useEffect(() => {
    if (editingName) setVideoName(video.name)
  }, [editingName])

  return (
    <>
      <div className="video-header">
        <div className="video-name-wrapper">
          <div className="video-name">
            <VideoBackButton video={video} isVideoSaved={videoSavingStatus === 'saved'} />
            {editingName ? (
              <Input
                className="video-name-input"
                value={videoName}
                onChange={(e) => setVideoName(e.target.value)}
                maxLength={100}
              />
            ) : (
              <span className="video-name-text" onClick={() => setEditingName(true)}>
                {video.name}
              </span>
            )}
            <div className="video-name-icon">
              {editingName ? (
                <Icon name="save" onClick={saveVideoName} />
              ) : (
                <Icon name="edit" onClick={() => setEditingName(true)} />
              )}
            </div>
            {['custom', 'unlimited'].includes(user.account.plan) && (
              <Popover
                content={<Comments video={video} defaultAnchorToSlides={true} updateVideo={updateVideo} />}
                trigger="click"
                arrow={false}
                placement="bottomRight"
                overlayClassName="comments-popover"
              >
                <Button
                  icon={
                    <CommentsBadge style={{ width: 50, height: 50 }} comments={video.comments} userId={user.id}>
                      <Icon name="comment" />
                    </CommentsBadge>
                  }
                  type="text"
                  className="btn-comments"
                >
                  View comments
                </Button>
              </Popover>
            )}
          </div>
          {(video.userEmail || checkAdminLoginedAsUser()) && (
            <Space size="small" style={{ marginTop: 10 }}>
              <Tooltip title="Login as user">
                <Button
                  type="ghost"
                  icon={<Icon name="logout" style={{ color: '#f3f3f3', fontSize: 18 }} />}
                  onClick={async () => {
                    await adminLoginAs({ accountId: video.accountId })
                    navigate('/')
                  }}
                />
              </Tooltip>
              <Tooltip title="Copy video to admin account">
                <Button
                  type="ghost"
                  icon={<Icon name="copy" style={{ color: '#f3f3f3', fontSize: 18 }} />}
                  onClick={handleCopyVideo}
                />
              </Tooltip>
              <h3 className="user-email-admin">{video.userEmail || user.email}</h3>
            </Space>
          )}
        </div>
        <Space className="header-extra">
          <Icon name="menu" className="menu-icon" onClick={() => setIsOpenHeaderMobileMenu((isOpen) => !isOpen)} />
          <Tooltip key="undo" placement="top" title="Undo" mouseEnterDelay={0.2}>
            <Icon
              name="undo"
              style={{
                fontSize: '22px',
                opacity: changesHistory.length > 1 ? 1 : 0.4,
                color: '#f3f3f3',
                marginRight: 4,
              }}
              onClick={() => undoLastChanges()}
            />
          </Tooltip>
          <Tooltip
            key="autoSave"
            placement="top"
            title={
              videoSavingStatus === 'saved'
                ? 'All changes are saved'
                : 'Your story has updates that are not saved. Click to save as draft.'
            }
            mouseEnterDelay={0.2}
          >
            <div className="save-icon-wrapper">
              {videoSavingStatus === 'saved' ? (
                <Icon
                  name="check"
                  style={{ fontSize: '14px', color: '#3bad5f', paddingRight: 8 }}
                  onClick={() => buttonSaveVideo()}
                />
              ) : (
                <SyncOutlined
                  spin
                  style={{
                    fontSize: '22px',
                    opacity: videoSavingStatus === 'saving' ? '0.25' : '0.45',
                    color: '#f3f3f3',
                  }}
                  onClick={() => buttonSaveVideo()}
                />
              )}
            </div>
          </Tooltip>
          <Button
            icon={<Icon name="multiple_image" />}
            className="btn-template"
            disabled={isEditorBusy}
            onClick={() => {
              setIsSwitching(() => true)
              setIsOpenTemplatesModal(true)
            }}
          >
            Change template
          </Button>
          <Tooltip
            placement="bottomRight"
            title={video.slides.length > 100 ? "Can't convert to slides. Maximum slides limit reached" : ''}
          >
            <Button
              type="primary"
              loading={isVideoGenerating}
              disabled={video.slides.length > 100 || isEditorBusy}
              icon={<Icon name="video_camera" />}
              className="btn-render"
              onClick={handleConvert}
            >
              Convert to slides
            </Button>
          </Tooltip>
        </Space>
      </div>
      <div className={`header-menu-mobile ${isOpenHeaderMobileMenu ? 'open' : ''}`}>
        <Menu
          theme="dark"
          items={[
            {
              icon: <Icon name="multiple_image" />,
              label: 'Change template',
              key: 'template',
            },
            {
              icon: <Icon name="video_camera" />,
              label: 'Convert to slides',
              key: 'convert',
            },
          ]}
          onClick={({ key }) => {
            if (key === 'template') {
              setIsOpenTemplatesModal(true)
            } else if (key === 'convert') validateAndGenerateVideo()
            setIsOpenHeaderMobileMenu(false)
          }}
        />
      </div>
    </>
  )
}
