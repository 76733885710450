import { Row, Col, Space } from 'antd'
import { normalizeColor } from './helpers'
import { useMemo, useState } from 'react'
import ColorPicker from './colorPicker'

const colors = [
  '#000',
  '#555',
  '#777',
  '#999',
  '#bbb',
  '#ddd',
  '#fff',
  '#7b64ff',
  '#DFFF00',
  '#FFBF00',
  '#FF7F50',
  '#DE3163',
  '#9FE2BF',
  '#40E0D0',
  '#6495ED',
  '#CCCCFF',
  '#FA8072',
  '#C71585',
  '#3CB371',
  '#1E90FF',
  '#4169E1',
  '#FFDEAD',
  '#708090',
  '#BC8F8F',
  '#D2B48C',
  '#00CED1',
  '#9de19a',
  '#e7eca3',
]

const style = {
  marginTop14: { marginTop: 14 },
  marginTop18: { marginTop: 28 },
}

export const Color = (props) => {
  const { currentColor, color, onChangeColor } = props

  const changeColor = () => {
    onChangeColor(color)
  }

  const itemClass = `color-item ${color === currentColor ? 'active' : 'inactive'}`
  return (
    <button type="button" className={itemClass} onClick={changeColor}>
      <div className="color-value" style={{ backgroundColor: color }}></div>
    </button>
  )
}

const DefaultColors = (props) => {
  const {
    color,
    onChangeColor,
    customColor,
    colorPalette = colors,
    title = 'Default Color Palette',
    triggerOnButton,
  } = props
  const [isOpenColorPicker, setIsOpenColorPicker] = useState()
  const normalizedColors = useMemo(() => colorPalette.map((color) => normalizeColor(color)), [colorPalette])

  return (
    <>
      <Row style={style.marginTop18} align="top">
        <Col>
          <Space>
            <h4>{title}</h4>
          </Space>
        </Col>
      </Row>
      <Row style={style.marginTop14} align="top">
        <Col>
          <Space wrap={true}>
            {customColor && (
              <ColorPicker
                onChangeColor={onChangeColor}
                isOpen={isOpenColorPicker}
                setIsOpen={setIsOpenColorPicker}
                triggerOnButton={triggerOnButton}
              />
            )}
            {normalizedColors.map((c) => (
              <Color key={c} currentColor={color} color={c} onChangeColor={onChangeColor} />
            ))}
          </Space>
        </Col>
      </Row>
    </>
  )
}

export default DefaultColors
