import { Breadcrumb } from 'antd'
import Icon from '../../../components/Icon'

export const CourseBreadcrumb = ({ course, lesson }) => {
  return (
    <Breadcrumb className="course-breadcrumb" separator={<Icon name="right_arrow" />}>
      <Breadcrumb.Item href="/academy">All courses</Breadcrumb.Item>
      <Breadcrumb.Item {...(lesson ? { href: `/academy/course-preview/${course.id}` } : null)}>
        {course.name}
      </Breadcrumb.Item>
      {lesson && <Breadcrumb.Item>{lesson.name}</Breadcrumb.Item>}
    </Breadcrumb>
  )
}
