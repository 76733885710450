import { useState } from 'react'

import { useMemo } from 'react'
import { YOUNG_AGE_FILTER, MID_AGE_FILTER, SENIOR_AGE_FILTER } from '../components/AvatarsFilterPopover/constants'

const initialAvatarsFiltersState = {
  gender: 'all',
  ages: [],
  ethnicities: [],
  looks: [],
  premium: true,
  fiveMinDuration: false,
}

const fiveMinLimit = 300

const searchAvatars = (avatar, searchValue) => {
  if (searchValue && searchValue.length > 0) {
    return avatar.name.toLowerCase().includes(searchValue.toLowerCase())
  } else {
    return true
  }
}

const filterGender = (avatar, gender) => {
  if (gender === 'all') return true
  return avatar.gender === gender
}

const filterAge = (avatar, ages) => {
  if (!ages.length) return true
  if (ages.includes(YOUNG_AGE_FILTER) && avatar.age < 40) return true
  else if (ages.includes(MID_AGE_FILTER) && avatar.age >= 40 && avatar.age < 60) return true
  else if (ages.includes(SENIOR_AGE_FILTER) && avatar.age >= 60) return true
  return false
}

const filterEthnicity = (avatar, ethnicities) => {
  if (ethnicities.length) return ethnicities.includes(avatar.ethnicity)
  else return true
}

const filterPremium = (avatar, premium) => {
  if (premium) return true
  else return !avatar.premium
}

const filterAvatars = (avatars, filters, searchFilter) => {
  return avatars?.filter((avatar) => {
    return (
      searchAvatars(avatar, searchFilter) &&
      filterGender(avatar, filters.gender) &&
      filterAge(avatar, filters.ages) &&
      filterEthnicity(avatar, filters.ethnicities) &&
      filterPremium(avatar, filters.premium)
    )
  })
}

const filterLooks = (avatars, looks, avatarsDataWithoutVariants) => {
  if (looks.length) {
    return avatars
      .flatMap((avatar) => {
        if (avatar.variants?.length) {
          const filteredVariants = avatar.variants.filter((variant) => looks.includes(variant.name))
          if (filteredVariants.length) {
            return { ...avatar, variants: filteredVariants }
          } else {
            return null
          }
        } else if (avatarsDataWithoutVariants) {
          if (looks.includes(avatar.name)) return avatar
          else return null
        } else {
          return null
        }
      })
      .filter((avatar) => avatar)
  } else {
    return avatars
  }
}

const filterByLimit = (avatars, fiveMinDuration) => {
  if (fiveMinDuration) {
    return avatars
      .flatMap((avatar) => {
        if (avatar.variants?.length) {
          const filteredVariants = avatar.variants.filter((variant) => variant.limit >= fiveMinLimit)
          if (filteredVariants.length) {
            return { ...avatar, variants: filteredVariants }
          } else {
            return null
          }
        } else {
          return avatar.limit >= fiveMinLimit ? avatar : null
        }
      })
      .filter((avatar) => avatar)
  } else {
    return avatars
  }
}

const useFilterAvatars = ({ avatars, avatarsDataWithoutVariants }) => {
  const [filters, setFilters] = useState(initialAvatarsFiltersState)
  const [searchFilter, setSearchFilter] = useState('')

  const filteredAvatars = useMemo(() => {
    let filteredAvatars = filterAvatars(avatars, filters, searchFilter)

    filteredAvatars = filterLooks(filteredAvatars, filters.looks, avatarsDataWithoutVariants)

    filteredAvatars = filterByLimit(filteredAvatars, filters.fiveMinDuration)

    return filteredAvatars
  }, [avatars, searchFilter, filters])

  return { filteredAvatars, filters, setFilters, setSearchFilter }
}

export { useFilterAvatars, initialAvatarsFiltersState }
