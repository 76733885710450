import { useCallback, useEffect, useRef } from 'react'

export const useSVGHandlers = (props) => {
  const { canvasActiveObject, setActiveObjectModifier } = props

  const colorRefs = useRef([])

  const canvasObjects = canvasActiveObject?.getObjects?.() ?? []

  const getColorsFromSvgProps = useCallback((prop) => {
    if (typeof prop === 'string') return [prop]
    if (prop?.colorStops) return prop.colorStops.map(({ color }) => color)
    return []
  }, [])

  const svgObjectsColorMap = () => {
    const colorMap = new Map()
    for (let i = 0; i < canvasObjects?.length; ++i) {
      // TODO support for stroke, etc.
      const { fill } = canvasObjects[i]
      getColorsFromSvgProps(fill).forEach((color) => colorMap.set(color, [...(colorMap.get(color) ?? []), i]))
    }
    return colorMap
  }

  const getSVGUpdateModifier = useCallback((color, newColor, object, index) => {
    if (!object) return
    if (typeof object.fill === 'string') return { type: 'SVG', index, change: 'fill', value: newColor }
    const { fill } = { ...object }
    if (fill?.colorStops) {
      if (fill.colorStops[0]?.color === color) fill.colorStops[0].color = newColor
      if (fill.colorStops[1]?.color === color) fill.colorStops[1].color = newColor
      return { change: 'gradient', index, fill }
    }
  }, [])

  const colorsArray = Array.from(svgObjectsColorMap()).map(([color]) => color)

  // TODO old color updates works only with ref. Maybe more elegant solution?
  useEffect(() => {
    colorsArray.forEach((color, index) => {
      colorRefs.current[index] = color
    })
  }, [colorsArray])

  const updateSvgColors = (index, newColor) => {
    const colorMap = svgObjectsColorMap()
    const oldColor = colorRefs.current[index]
    if (!colorMap.has(oldColor)) return
    const idxArray = colorMap.get(oldColor)
    if (!idxArray.length) return
    const modifiers = []
    idxArray.forEach((index) => {
      const modifier = getSVGUpdateModifier(oldColor, newColor, canvasObjects[index], index)
      if (modifier) modifiers.push(modifier)
    })
    setActiveObjectModifier(modifiers)
  }

  return { updateSvgColors, colorsArray }
}
