import React, { useState } from 'react'
import { Button, Tooltip } from 'antd'
import Icon from '../../../../../../components/Icon'
import LibraryFilter from './libraryFilter'

const LibraryFilterButton = (props) => {
  const { filesType, setShouldApplyFilter, filter, setQuery, updateFilter } = props
  const [isVisible, setIsVisible] = useState(false)

  const filterHandler = () => {
    setIsVisible(!isVisible)
  }

  const onApplyFilter = () => {
    setIsVisible(false)
    setShouldApplyFilter(true)
  }

  return (
    <Tooltip
      arrow={false}
      title={
        <LibraryFilter
          filter={filter}
          setQuery={setQuery}
          filesType={filesType}
          updateFilter={updateFilter}
          onApplyFilter={onApplyFilter}
        />
      }
      placement="bottomRight"
      open={isVisible}
      color="#ffffff"
      trigger="click"
      onOpenChange={filterHandler}
      id={`search-media-tooltip-${filesType}`}
      overlayClassName="search-media-tooltip"
    >
      <Button
        type={isVisible ? 'primary' : 'default'}
        icon={<Icon name="filters" />}
        onClick={filterHandler}
        className="search-media-header-filter-button"
      />
    </Tooltip>
  )
}

export default LibraryFilterButton
