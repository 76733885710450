import { useMemo, useRef, useState } from 'react'
import { Popover, Tooltip, Button, Row, Col } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import Scrollbars from 'react-custom-scrollbars'
import Icon from '../../../../../components/Icon'
import { videoFormats } from '../../../../../data/formats'
import { CANVAS_WIDTH } from '../../../../video/slide/canvas/constans'
import useClickOutside from '../../../../../hooks/useClickOutside'

const layoutItemWidth = 130
const layoutPreviewWidth = 270

const getLayoutDescriptiveTitle = (story = {}) => {
  const { initial, isTooComplex, ...storyFields } = story

  if (isTooComplex || !Object.keys(storyFields).length) return 'Only speech text can be edited in Storyboard mode'
  const blocks = []
  if (Object.hasOwn(storyFields, 'header')) blocks.push('Heading')
  if (Object.hasOwn(storyFields, 'subHeader')) blocks.push('Subheader')
  if (Object.hasOwn(storyFields, 'list')) blocks.push('List')
  if (Object.hasOwn(storyFields, 'images')) blocks.push('Images')

  if (blocks.length === 1) return blocks[0]
  return [blocks[0], ...blocks.slice(1).map((b) => b.toLowerCase())].join(' + ')
}

const LayoutsPopover = ({
  slideIndex,
  slide,
  layoutsData: { videoLayouts, defaultLayouts, thumbnails },
  video,
  applyLayoutToSlide,
  isEditorBusy,
}) => {
  const [isOpenLayoutsPopover, setIsOpenLayoutsPopover] = useState(false)
  const [isApplyingLayout, setIsApplyingLayout] = useState(false)
  const [isOpenDefaultLayouts, setIsOpenDefaultLayouts] = useState(false)
  const [selectedLayout, setSelectedLayout] = useState(null)
  const layoutsPopoverRef = useRef(null)
  const slideImageRef = useRef(null)

  const layouts = isOpenDefaultLayouts ? videoLayouts.concat(defaultLayouts) : videoLayouts

  const toggleShowDefaultLayouts = () => {
    setIsOpenDefaultLayouts((prevState) => !prevState)
  }

  const toggleLayoutsPopover = () => {
    if (isEditorBusy) return
    setIsOpenLayoutsPopover((prevState) => !prevState)
    if (selectedLayout) setSelectedLayout(null)
  }

  const changeSlideTemplate = async () => {
    setIsApplyingLayout(true)
    await applyLayoutToSlide(selectedLayout)
    setIsApplyingLayout(false)
    toggleLayoutsPopover()
  }

  const onClickBackButton = () => {
    setSelectedLayout(null)
  }

  const onClickOutside = () => {
    if (!isOpenLayoutsPopover) return
    toggleLayoutsPopover()
  }

  const getLimiterWidth = (layoutImageWidth) => {
    const videoFormatWidth = (videoFormats[video.data?.format || '16_9'].width * layoutImageWidth) / CANVAS_WIDTH
    return { width: (layoutImageWidth - videoFormatWidth) / 2 }
  }

  const layoutItemLimiterWidth = useMemo(() => getLimiterWidth(layoutItemWidth), [video.data?.format])

  const layoutPreviewLimiterWidth = useMemo(() => getLimiterWidth(layoutPreviewWidth), [video.data?.format])

  useClickOutside([layoutsPopoverRef, slideImageRef], onClickOutside)

  if (!thumbnails) return null

  return (
    <Popover
      open={isOpenLayoutsPopover}
      overlayClassName="layouts-popover"
      placement="rightTop"
      arrow={false}
      trigger="click"
      getPopupContainer={(triggerNode) => triggerNode}
      content={
        <div ref={layoutsPopoverRef} className="layouts-popover-content">
          <div className="popover-title-wrapper">
            <div className="popover-title">
              <Button
                className={`title-btn ${selectedLayout ? 'visible' : 'hidden'}`}
                type="text"
                size="small"
                icon={<Icon name="left_arrow" />}
                onClick={onClickBackButton}
              />
              <h4>Change layout</h4>
              <Tooltip title="Choose the new layout for your slide.">
                <Icon name="info" />
              </Tooltip>
            </div>
            <Button
              className="title-btn close"
              type="text"
              size="small"
              icon={<Icon name="close_simple" />}
              onClick={toggleLayoutsPopover}
            />
          </div>
          <div className={`confirmation-content ${selectedLayout ? 'visible' : 'hidden'}`}>
            <div
              className="slide-item-image layout-image"
              style={{
                backgroundImage: `url(${thumbnails[selectedLayout?.id]?.src || selectedLayout?.screenshot})`,
              }}
            >
              {!(thumbnails[selectedLayout?.id]?.src || selectedLayout?.screenshot) && <LoadingOutlined />}
              <div className="video-format-limiter left" style={layoutPreviewLimiterWidth} />
              <div className="video-format-limiter right" style={layoutPreviewLimiterWidth} />
            </div>
            <div className="warning-message">
              <Icon name="info" />
              <p className="warning-message-text">
                Changing the layout will not save your default design, and some content may be lost.
              </p>
            </div>
            <p className="confirmation-question-text">Do you want to proceed and change the slide layout?</p>
            <div className="confirmation-buttons-wrapper">
              <Button onClick={toggleLayoutsPopover}>Cancel</Button>
              <Button type="primary" loading={isApplyingLayout} onClick={changeSlideTemplate}>
                Change
              </Button>
            </div>
          </div>
          <Scrollbars className={`layouts-scrollbar scrollbar ${selectedLayout ? 'hidden' : 'visible'}`}>
            <Row gutter={[8, 8]}>
              {layouts.map((slide) => (
                <Col key={slide.id} span={12}>
                  <Tooltip title={getLayoutDescriptiveTitle(slide.story)} placement="bottom" align={{ offset: [0, 2] }}>
                    <div
                      className="slide-item-image"
                      style={{
                        backgroundImage: `url(${thumbnails[slide.id]?.src || slide.screenshot})`,
                      }}
                      onClick={() => setSelectedLayout(slide)}
                    >
                      {!(thumbnails[slide.id]?.src || slide.screenshot) && <LoadingOutlined />}
                      <div className="video-format-limiter left" style={layoutItemLimiterWidth} />
                      <div className="video-format-limiter right" style={layoutItemLimiterWidth} />
                    </div>
                  </Tooltip>
                </Col>
              ))}
            </Row>
            <Button
              className="btn-show-more"
              type="dashed"
              icon={isOpenDefaultLayouts ? <Icon name="up_arrow" /> : <Icon name="down_arrow" />}
              block
              onClick={toggleShowDefaultLayouts}
            >
              Show {isOpenDefaultLayouts ? 'less' : 'more'}
            </Button>
          </Scrollbars>
        </div>
      }
    >
      <Tooltip
        title="Change slide layout"
        getPopupContainer={(triggerNode) => triggerNode}
        trigger={isEditorBusy ? [] : ['hover']}
      >
        <div
          className={`slide-item-thumbnail unselectable ${isEditorBusy ? 'disabled' : ''}`}
          contentEditable={false}
          suppressContentEditableWarning={true}
        >
          <span className="slide-item-index">{slideIndex + 1 < 10 ? `0${slideIndex + 1}` : slideIndex + 1}</span>
          <div
            ref={slideImageRef}
            className={`slide-item-image ${isOpenLayoutsPopover ? 'active' : ''}`}
            style={{
              backgroundImage: `url(${isApplyingLayout ? null : thumbnails[slide.id]?.src})`,
            }}
            onClick={toggleLayoutsPopover}
          >
            {(isApplyingLayout || !thumbnails[slide.id]?.src) && <LoadingOutlined />}
            {!isApplyingLayout && (
              <div className="item-image-mask">
                <Icon name="web_layout" />
              </div>
            )}
            <div className="video-format-limiter left" style={layoutItemLimiterWidth} />
            <div className="video-format-limiter right" style={layoutItemLimiterWidth} />
          </div>
        </div>
      </Tooltip>
    </Popover>
  )
}

export { LayoutsPopover }
