import { Layout, Row, Col, Button, Tag, Tooltip, Spin, Empty } from 'antd'
import Icon from '../../components/Icon'
import { AvatarsFilterPopover } from '../../components/AvatarsFilterPopover/avatarsFilterPopover'
import { VideoPreview } from './videoPreview'
import { AccountAvatars } from './accountAvatars'
import { avatarStatuses } from '../admin/constants'
import { useAvatarsState } from './useAvatarsState'
import { CREATE_VIDEO_BUTTON_TOOLTIP, PLUS_ICON, PREMIUM_AVATARS_LABEL_TOOLTIP } from './constants'

import './avatars.less'

const AvatarsLibrary = () => {
  const {
    user,
    account,
    isVideoCreating,
    accountAvatars,
    publicAvatars,
    filteredAvatars,
    filters,
    setFilters,
    isOpenFiltersPopup,
    setIsOpenFiltersPopup,
    onClickCreateVideo,
    onConfirmDelete,
  } = useAvatarsState()

  return (
    <Layout.Content className="avatars-content layout-container">
      {!!accountAvatars?.length && (
        <AccountAvatars
          accountAvatars={accountAvatars}
          avatarStatuses={avatarStatuses}
          onConfirmDelete={onConfirmDelete}
          isVideoCreating={isVideoCreating}
          onClickCreateVideo={onClickCreateVideo}
        />
      )}
      {publicAvatars?.length ? (
        <>
          <div className="library-title-wrapper">
            <h2 className="title">Library of avatars</h2>
            <AvatarsFilterPopover
              placement="topLeft"
              avatars={publicAvatars}
              filters={filters}
              setFilters={setFilters}
              isOpenFiltersPopup={isOpenFiltersPopup}
              setIsOpenFiltersPopup={setIsOpenFiltersPopup}
              getPopupContainer={(triggerNode) => triggerNode}
            >
              <Button className="btn-filters">
                <span>Filter</span>
                <Icon name="filters" />
              </Button>
            </AvatarsFilterPopover>
          </div>
          <Row gutter={[16, 16]}>
            {filteredAvatars.length ? (
              filteredAvatars.map((avatar, index) => (
                <Col key={index} xl={6} lg={8} md={8} sm={12} xs={24}>
                  <div className="avatar-intro-wrapper">
                    <VideoPreview avatar={avatar} key={index} />
                  </div>
                  <div
                    className="avatar-name-wrapper"
                    style={{ justifyContent: avatar.name ? 'space-between' : 'flex-end' }}
                  >
                    <p>
                      {avatar.previewName || avatar.name}{' '}
                      {user.isAdmin && avatar.status !== 2 && (
                        <Tag color={avatarStatuses[avatar.status]?.color}>{avatarStatuses[avatar.status]?.text}</Tag>
                      )}
                    </p>
                    <Tooltip
                      title={
                        avatar.premium && !account.premiumAvatarsAllowed
                          ? PREMIUM_AVATARS_LABEL_TOOLTIP
                          : CREATE_VIDEO_BUTTON_TOOLTIP
                      }
                    >
                      <Button
                        type="primary"
                        size="small"
                        disabled={avatar.status === 5 || (avatar.premium && !account.premiumAvatarsAllowed)}
                        loading={isVideoCreating === avatar.code}
                        icon={PLUS_ICON}
                        className="btn-create-video"
                        onClick={onClickCreateVideo(avatar)}
                      />
                    </Tooltip>
                    {avatar.premium && (
                      <Tooltip title={PREMIUM_AVATARS_LABEL_TOOLTIP}>
                        <Tag className="premium-card">
                          <Icon name="diamond" /> Premium
                        </Tag>
                      </Tooltip>
                    )}
                  </div>
                </Col>
              ))
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No avatars found" />
            )}
          </Row>
        </>
      ) : (
        <Spin size="large" className="spin-centered" />
      )}
    </Layout.Content>
  )
}

export default AvatarsLibrary
