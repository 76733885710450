import { memo, useState } from 'react'
import { Modal, Row, Button, Col } from 'antd'

import { transitions } from '../../../../../data/transitions'

const cursorPointer = { cursor: 'pointer' }

/**
 * Split to avoid passing down arrow functions, which every time would create a new object
 * Maybe, no need in memo here. The idea is to not re-render on every open / close modal.
 * But is it really impactful for every equality check ?
 */
export const TransitionBody = memo((props) => {
  const { transition, activeTransition, setActiveTransition, setIsPlayingVideoPreview, isPlayingVideoPreview } = props

  const handleTransition = () => {
    setActiveTransition(transition.id)
  }

  const handleOnMouseOver = () => {
    setIsPlayingVideoPreview(transition.id)
  }
  const handleOnMouseOut = () => {
    setIsPlayingVideoPreview(false)
  }

  return (
    <Col span={6} style={cursorPointer} onClick={handleTransition}>
      <div
        className={`effect-preview-container ${
          activeTransition === transition.id || (!activeTransition && !transition.id) ? 'active' : ''
        }`}
        onMouseOver={handleOnMouseOver}
        onMouseOut={handleOnMouseOut}
      >
        {isPlayingVideoPreview === transition.id && transition.thumbnail.video ? (
          <video
            className="effect-preview-thumbnail"
            src={transition.thumbnail.video}
            autoPlay={true}
            loop
            muted
          ></video>
        ) : (
          <img src={transition.thumbnail.image} className="effect-preview-thumbnail" />
        )}
      </div>
    </Col>
  )
})

/**
 * Make Modal as standalone component to memoize
 * as we do not wont to re-render modal on every parent component state update,
 * so every functions should be wrapped in useCallback.
 */
export const TransitionModal = memo((props) => {
  const {
    transitionModal,
    handleSetTransition,
    applyTransitionToAllSlides,
    closeTransitionModal,
    activeTransition,
    setActiveTransition,
  } = props

  const [isPlayingVideoPreview, setIsPlayingVideoPreview] = useState(false)

  const FooterButton = memo(() => [
    <Button type="primary" onClick={applyTransitionToAllSlides}>
      Apply to all
    </Button>,
    <Button type="primary" onClick={handleSetTransition}>
      Apply transition
    </Button>,
  ])

  return (
    <Modal
      title={`Transition ${transitionModal + 1} -> ${transitionModal + 2}`}
      footer={<FooterButton />}
      width={700}
      open={transitionModal !== false}
      className="transitions-modal"
      onCancel={closeTransitionModal}
    >
      <Row gutter={[8, 8]}>
        {transitions.map((t) => (
          <TransitionBody
            key={t.id}
            transition={t}
            activeTransition={activeTransition}
            setActiveTransition={setActiveTransition}
            isPlayingVideoPreview={isPlayingVideoPreview}
            setIsPlayingVideoPreview={setIsPlayingVideoPreview}
          />
        ))}
      </Row>
    </Modal>
  )
})
