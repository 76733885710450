import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { request } from '../../utils/api'
import { checkObjectsAnimationTime } from '../../utils/canvas/canvas'
import { isSignedUrl } from '../../utils/helpers'

const audioCache = new Map()
const activeCacheKeys = new Map()

const activeSpeechCachingMap = {}
const activeReadyAudioCachingMap = {}

const purgeAll = () => {
  audioCache.clear()
  activeCacheKeys.clear()
}

const getSpeechCacheKey = (slide) => `${slide.id}:${slide.voice}:${slide.speech}`

const getReadyAudioCacheKey = (slide) => `${slide.id}:${slide.audioUrl}`

const hasReadyAudio = (slide) => !!slide.audioUrl && (slide.status !== 'edited' || slide.voiceType === 'file')

const waitForAudio = (audio) => new Promise((resolve) => audio.addEventListener('canplay', resolve, { once: true }))

const cacheAudio = async (cacheKey, slide, video) => {
  if (activeReadyAudioCachingMap[cacheKey]) {
    const audio = await activeReadyAudioCachingMap[cacheKey]
    return audio
  }
  let resolve
  let reject
  activeReadyAudioCachingMap[cacheKey] = new Promise((_resolve, _reject) => {
    resolve = _resolve
    reject = _reject
  })
  const audio = new Audio(
    slide.audioUrl + (isSignedUrl(slide.audioUrl) ? '' : `?t=${new Date(video.updatedAt).getTime()}`),
  )
  audio.preload = 'auto'
  await waitForAudio(audio).catch((err) => {
    reject(err)
    delete activeReadyAudioCachingMap[cacheKey]
    throw err
  })
  audioCache.set(cacheKey, audio)
  resolve(audio)
  delete activeReadyAudioCachingMap[cacheKey]
  return audio
}

/**
 * Based on https://github.com/edoudou/create-silent-audio
 */
const bufferToWave = (audioBuffer, len) => {
  let numOfChan = audioBuffer.numberOfChannels,
    length = len * numOfChan * 2 + 44,
    buffer = new ArrayBuffer(length),
    view = new DataView(buffer),
    channels = [],
    i,
    sample,
    offset = 0,
    pos = 0

  const setUint16 = (data) => {
    view.setUint16(pos, data, true)
    pos += 2
  }

  const setUint32 = (data) => {
    view.setUint32(pos, data, true)
    pos += 4
  }

  // write WAVE header
  setUint32(0x46464952)
  setUint32(length - 8)
  setUint32(0x45564157)

  setUint32(0x20746d66)
  setUint32(16)
  setUint16(1)
  setUint16(numOfChan)
  setUint32(audioBuffer.sampleRate)
  setUint32(audioBuffer.sampleRate * 2 * numOfChan)
  setUint16(numOfChan * 2)
  setUint16(16)

  setUint32(0x61746164)
  setUint32(length - pos - 4)

  // write interleaved data
  for (i = 0; i < audioBuffer.numberOfChannels; i++) channels.push(audioBuffer.getChannelData(i))

  while (pos < length) {
    for (i = 0; i < numOfChan; i++) {
      // interleave channels
      sample = Math.max(-1, Math.min(1, channels[i][offset])) // clamp
      sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0 // scale to 16-bit signed int
      view.setInt16(pos, sample, true) // write 16-bit sample
      pos += 2
    }
    offset++ // next source sample
  }

  // create Blob
  return new Blob([buffer], { type: 'audio/wav' })
}

const createSilentAudio = (time, freq = 44100) => {
  const length = time * freq
  const AudioContext = window.AudioContext || window.webkitAudioContext || window.mozAudioContext
  if (!AudioContext) {
    throw new Error('No Audio Context')
  }
  const context = new AudioContext()
  const audioFile = context.createBuffer(1, length, freq)
  return URL.createObjectURL(bufferToWave(audioFile, length))
}

export const useAudioCache = (video) => {
  const { slideId } = useParams()

  useEffect(() => {
    return () => purgeAll()
  }, [])

  const cacheVoiceSpeech = async (cacheKey, slide, activePreview = false) => {
    const activeSlideId = slideId
    if (activeSpeechCachingMap[cacheKey]) {
      const audio = await activeSpeechCachingMap[cacheKey]
      return audio
    }
    let resolve, reject
    activeSpeechCachingMap[cacheKey] = new Promise((_resolve, _reject) => {
      resolve = _resolve
      reject = _reject
    })
    const res = await request({
      method: 'patch',
      url: `/voices`,
      data: {
        text: slide.speech,
        voice: slide.voice,
        language: slide.language,
        provider: slide.voiceProvider,
        markersTime: checkObjectsAnimationTime(slide.canvas.objects),
      },
    })
    if (!res) {
      reject()
      delete activeSpeechCachingMap[cacheKey]
      return null
    }
    const audio = new Audio(`data:audio/x-wav;base64, ${res.data}`)
    audio.preload = 'auto'
    // ignore if slide was changed while the speech was being generated
    if (!activePreview && activeSlideId !== window.location.pathname.split('/').at(-1)) {
      return reject()
    }
    await waitForAudio(audio)
    audioCache.set(cacheKey, audio)
    resolve({ audio, markers: res.markers })
    delete activeSpeechCachingMap[cacheKey]
    return { audio, markers: res.markers }
  }

  const dropCacheForSlide = (slide) => {
    const cacheKey = hasReadyAudio(slide) ? getReadyAudioCacheKey(slide) : getSpeechCacheKey(slide)
    if (audioCache.has(cacheKey)) audioCache.delete(cacheKey)
    activeCacheKeys.delete(slide.id)
  }

  const preloadAudio = async (slide) => {
    // speech
    if (hasReadyAudio(slide)) {
      const cacheKey = getReadyAudioCacheKey(slide)
      if (!audioCache.has(cacheKey)) cacheAudio(cacheKey, slide, video)
    } else if (slide.speech) {
      const cacheKey = getSpeechCacheKey(slide)
      if (!audioCache.has(cacheKey)) {
        const speechData = await cacheVoiceSpeech(cacheKey, slide, true)
        return speechData
      }
    }
  }

  const getVoiceForSlideSpeech = async (slide, activePreview, onChangeReadyStatus) => {
    let audio, markers
    if (hasReadyAudio(slide)) {
      const cacheKey = getReadyAudioCacheKey(slide)
      if (audioCache.has(cacheKey)) audio = audioCache.get(cacheKey)
      else {
        activeCacheKeys.set(slide.id, cacheKey)
        audio = await cacheAudio(cacheKey, slide, video)
      }
    } else {
      const cacheKey = getSpeechCacheKey(slide)
      if (audioCache.has(cacheKey)) audio = audioCache.get(cacheKey)
      else {
        if (activeCacheKeys.has(slide.id) && activeCacheKeys.get(slide.id) !== cacheKey) {
          audioCache.delete(activeCacheKeys.get(slide.id))
        }
        onChangeReadyStatus(true)
        const speechData = await cacheVoiceSpeech(cacheKey, slide, activePreview)
        audio = speechData.audio
        markers = speechData.markers
        activeCacheKeys.set(slide.id, cacheKey)
        onChangeReadyStatus(false)
      }
    }
    return { audio, markers }
  }

  return {
    getVoiceForSlideSpeech,
    preloadAudioForSlide: preloadAudio,
    createSilentAudio,
    dropCacheForSlide,
    purgeAll,
  }
}
