import { Row, Col, Switch } from 'antd'

import { Animations } from '../../animations'
import { useAnimationControlsState } from './useAnimationControlsState'

const STYLE = {
  row: { minHeight: '32px', marginTop: '16px' },
}

export const AnimationControls = (props) => {
  const { data, activeObjectProps, setActiveObjectModifier } = props

  const { onChangeTime, onChangeDuration, onCustomAnimationTurn, handleUpdateSlide, onChangeEasing } =
    useAnimationControlsState({
      data,
      activeObjectProps,
      setActiveObjectModifier,
    })

  return (
    <>
      <Row gutter={[0, 16]} align="middle" justify="space-between" style={STYLE.row}>
        <Col>
          <h4>Custom Animation</h4>
        </Col>
        <Col>
          <Switch checked={activeObjectProps.animation?.type !== undefined} onChange={onCustomAnimationTurn} />
        </Col>
        {activeObjectProps.animation?.type !== undefined && (
          <Col span={24}>
            <Animations
              data={data}
              activeObjectProps={activeObjectProps}
              updateSlide={handleUpdateSlide}
              onChangeTime={onChangeTime}
              onChangeDuration={onChangeDuration}
              onChangeEasing={onChangeEasing}
            />
          </Col>
        )}
      </Row>
    </>
  )
}
