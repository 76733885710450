import { Collapse } from 'antd'
import './faq.less'

const { Panel } = Collapse
const avatarTypes = ['studio', 'selfie', 'photo']

const FAQ = ({ activeTab }) => {
  return (
    <div className="faq-container">
      <div className="faq-content content-wrapper tabs-content-wrapper">
        <h2>FAQ</h2>
        <Collapse accordion expandIconPosition="end" className="card-collapse">
          {questions[avatarTypes.includes(activeTab) ? 'allTypes' : activeTab].map((item, index) => (
            <Panel key={index} header={item.question}>
              {item.answer.map((a, i) => (
                <p key={i}>{a}</p>
              ))}
            </Panel>
          ))}
        </Collapse>
      </div>
    </div>
  )
}

const questions = {
  allTypes: [
    {
      question: 'What is a Custom Avatar, and how can I use it?',
      answer: [
        'Custom Avatar is a hyperrealistic digital presenter of a person, that can be used for producing high-quality personalized content for marketing, personalized sales, e-learning, news generation, and many other purposes. Elai team creates custom avatars based on a video recording of a person speaking, and you can request your own Custom Avatar at any time by filling in the form below.',
      ],
    },
    {
      question: 'How much does it cost to get my own Custom Avatar?',
      answer: [
        'Custom avatar is our additional premium feature that is not included in any standard or enterprise subscription plan and requires additional payment. Currently, there are 2 different versions available - the Selfie and the Studio version. The Custom Avatar feature implies annual subscription with automatic renewal. The price of one Studio avatar is $859 per year, and the price of one Selfie avatar is $199 per year.',
        <>
          Please, note that you also need to get a subscription with a package of minutes to be able to render videos
          with your avatar. You can choose a suitable subscription{' '}
          <a href="https://elai.io/pricing" target="_blank" rel="noreferrer">
            here
          </a>
        </>,
      ],
    },
    {
      question: 'How long does it take to create a Custom Avatar?',
      answer: [
        <>
          It takes approximately <b>7-10 business days</b> for our team to create a custom avatar, from the day we
          approve the footage provided by customer.
        </>,
      ],
    },
    {
      question: 'What is the difference between the Selfie Avatar and the Studio Avatar?',
      answer: [
        <>
          <b>Studio avatar</b> is a version created based on <b>high-quality footage filmed in a studio</b> using{' '}
          <b>professional equipment and green screen background</b>. Our team of professionals will take care of
          everything and will remove a green screen background, enhance the quality and get the maximum out of your
          footage, this way we will be able to provide the best results.
        </>,
        <>
          <b>Selfie avatar</b> is a version created based on footage made using a smartphone or web camera. Your Selfie
          avatar will be created almost <b>automatically, without any manual work</b> such as colour correction,
          meticulous background object removal, or hand-operated lip sync enhancement or any other additional
          adjustments.
        </>,
      ],
    },
    {
      question: 'Can I get my own Custom Voice and use it with my own Custom Avatar?',
      answer: [
        <>
          Sure! By requesting our voice cloning service, you get the opportunity to obtain an accurate replication of
          your own voice, which can be utilized for professional voice-over purposes in your videos. Currently, the
          voice cloning service is available in{' '}
          <b>
            8 different languages - English, Spanish, German, Polish, Italian, French, Portuguese, and Hindi. The price
            of one custom voice is $200 per year.
          </b>
        </>,
      ],
    },
    {
      question: 'Are there any restrictions on the use of my Custom Avatar?',
      answer: [
        <>
          According to the{' '}
          <a href="https://elai.io/terms-and-conditions" target="_blank" rel="noreferrer">
            Prohibited Use Policy
          </a>
          , you can use your Avatar to produce any content that is personal or expresses personal opinions and beliefs;
          produce any content that is religious and/or encourages joining any religion. Your content{' '}
          <b>may not violate the restrictions</b> stated in the{' '}
          <a href="https://elai.io/terms-and-conditions" target="_blank" rel="noreferrer">
            Prohibited Use Policy
          </a>
          .{' '}
        </>,
      ],
    },
    {
      question: 'Can I request a Custom Avatar of a celebrity or a famous person?',
      answer: [
        'No. By requesting the Custom Avatar creation service on our platform, you confirm that you have all necessary rights or consents on the video/audio recordings and files you provide. As a customer, you agree not to use the content generated on our platform for any illegal, fraudulent or harmful purposes.',
      ],
    },
    {
      question: 'Will my avatar be able to move its hands, and express emotions?',
      answer: [
        'No. Currently, there is no option to add body movements or control/change emotions of your avatar, however, as our team is constantly working on the development of our technology, we may release some new exciting updates in the future.',
      ],
    },
    {
      question: 'Can other users use or get access to my Custom Avatar?',
      answer: [
        'No. Your custom avatar will only be available for you in your avatar library. Elai Team does not use your avatar for marketing, sales, or any other purposes without your permission.',
      ],
    },
    {
      question: 'Can I get a refund if I am not happy with my Custom Avatar?',
      answer: [
        <>
          <b>There is no option to request a refund for</b> the custom avatar creation service once your avatar is
          ready, as we spend irretrievable resources on footage preparation and model training. In the unlikely event
          that you are dissatisfied with the final result, we will review your concerns and strive to find a suitable
          solution.
        </>,
      ],
    },
    {
      question: 'What is a Photo avatar and how can I get my own Photo avatar?',
      answer: [
        <>
          Photo avatar is a realistic digital presenter of a person created just from a photo. You can create your photo
          avatar automatically by uploading your photos in{' '}
          <a href="https://app.elai.io" target="_blank" rel="noreferrer">
            Elai Builder
          </a>
          .
        </>,
      ],
    },
    {
      question: 'How much does it cost to get my Photo avatar?',
      answer: [
        'You can request your own Photo avatar for free if you have an active paid subscription on our platform.',
      ],
    },
  ],
  mascot: [
    {
      question: ' What is a Cartoon Avatar, and how can I use it?',
      answer: [
        <>
          Cartoon avatar is <b>a 2D-animated talking avatar</b> created by our illustrators per your request. Using our
          platform, you can easily turn your mascot or fictional character into a talking cartoon avatar, and utilize it
          to create promotional or educational video content for your project.{' '}
        </>,
      ],
    },
    {
      question: 'How much does it cost to get my Cartoon avatar?',
      answer: [
        'Cartoon Avatar is our additional premium feature that is not included in any standard subscription plan and requires additional payment. The Cartoon Avatar feature implies annual subscription with automatic renewal. The price of one Cartoon Avatar is $700 (*$500 one-time fee for illustrator’s work and $200 annual fee).',
        <>
          * Please, note that you also need to get a subscription with a package of minutes to be able to render videos
          with your avatar. You can choose a suitable subscription{' '}
          <a href="https://elai.io/pricing" target="_blank" rel="noreferrer">
            here
          </a>
        </>,
      ],
    },
    {
      question: 'How much time it would take to create my Cartoon avatar?',
      answer: [
        'It takes approximately 14 business days for our team to create one Cartoon Avatar, from the day we approve the references and final specifications provided by a customer.',
      ],
    },
    {
      question: 'Can I request a 3D Cartoon avatar?',
      answer: [
        "At this moment, our team only works with 2D models, and we won't be able to use a 3D model provided by a customer or create a 3D avatar per customer’s request due to the limitations of our current technology.",
      ],
    },
    {
      question: 'Can I import my own avatar made using other software and use it in Elai Builder?',
      answer: ['No, currently, there is no such option available on our platform.'],
    },
    {
      question: 'Can I provide my own references for my Cartoon avatar?',
      answer: [
        'Sure! You can provide your own references and examples, and our illustrator will definitely consider them in the process of creating your Cartoon Avatar.',
      ],
    },
    {
      question: 'Can I get a refund if I am not happy with my Cartoon Avatar?',
      answer: [
        <>
          <b>There is no option to request a refund for</b> the Cartoon avatar creation service once your avatar is
          ready, as we spend irretrievable resources on model preparation and training. <b>Before</b> animating and
          embedding your Cartoon Avatar into your Avatar's library, you will have the opportunity to request edits to
          the samples <b>a maximum of 3 times</b>. You can provide <b>the list of the desired modifications</b>.
        </>,
      ],
    },
  ],
  voice: [
    {
      question: 'How long does it take to get my voice cloned?',
      answer: ['It usually takes around 3 business days to clone your voice and add it to your builder.'],
    },
    {
      question: 'Can other users see and utilize my cloned voice?',
      answer: [
        'No, the voice will be added to your account and available only for you and your colleagues which you can add to the Team for collaborative work.',
      ],
    },
    {
      question: 'How can I clone my voice?',
      answer: [
        'To be able to create your custom voice our team requires 3-5 minutes of a well-prepared voice recording of a person speaking with no extra noise. To record your voice, you can use any voice-recording software. Your speech can be either emotional or calm. You can record your voice in any language.',
      ],
    },
    {
      question: 'Will the voice sound natural?',
      answer: ['Your cloned voice is the exact copy of the audio recording provided by a user.'],
    },
    {
      question: 'Will the Elai.io Team make adjustments to the provided audio file?',
      answer: [
        'No, we are not responsible for background noise reduction and reverberation, plosives, or harsh esses removal. ',
      ],
    },
    {
      question: 'Where should I upload the audio file?',
      answer: [
        'Save your audio recording in cloud storage, Google Drive, etc., and send us a link to it to keep the quality of the recording.',
      ],
    },
  ],
}

export default FAQ
