import Icon from '../Icon'

const STYLE = { resizeNone: { resize: 'none' }, cursorPointer: { cursor: 'pointer' } }

export const downArrowIcon = <Icon name="down_arrow" />
export const paperPlaneIcon = <Icon name="paper_plane" />

const onlyNewLinesRegex = /^[\n]+$/

const sorterOptions = [
  { label: 'Sort by slides', value: 'slides' },
  { label: 'Sort by date', value: 'date' },
]

const getSlideSelectOptions = (slides) => {
  return slides.map((slide, index) => ({ label: `Slide ${index + 1}`, value: slide.id }))
}

const formatDate = (dateString) => {
  const date = new Date(dateString)
  const currentDate = new Date()
  const timeDiff = currentDate - date

  const minute = 60 * 1000
  const hour = 60 * minute
  const day = 24 * hour
  const year = 365 * day

  if (timeDiff < 1 * minute) {
    return 'Just now'
  } else if (timeDiff < 1 * hour) {
    const minutesAgo = Math.floor(timeDiff / minute)
    return `${minutesAgo} minute${minutesAgo > 1 ? 's' : ''} ago`
  } else if (timeDiff < day / 2) {
    const options = { hour: 'numeric', minute: 'numeric' }
    return date.toLocaleTimeString(undefined, options)
  } else if (timeDiff < year) {
    const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit' }
    return date.toLocaleDateString(undefined, options)
  } else {
    const options = { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' }
    return date.toLocaleDateString(undefined, options)
  }
}

export { STYLE, onlyNewLinesRegex, sorterOptions, formatDate, getSlideSelectOptions }
