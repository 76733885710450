export const inputFieldsPlaceholders = {
  header: 'Insert heading here',
  subHeader: 'Insert subheader to add more information',
  speech: 'Insert speech text here',
  list: 'Type list item here',
}

export const selectableFields = ['header', 'subHeader', 'speech', 'list']

export const MAX_TOKENS_FOR_TRIAL = 5000

export const EDITOR_SYNC_TIMEOUT_MS = 10
export const SAVE_TRIGGER_DELAY_MS = 1000

export const MAX_LIST_ITEMS = 6
