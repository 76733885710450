import { Space, Typography } from 'antd'

export const ModalTitle = () => {
  return (
    <Space direction="vertical">
      <Typography.Title level={5} className="margin-zero">
        Select a voice
      </Typography.Title>
      <Typography.Text type="secondary" className="voice-modal-subheading">
        Listen to voice samples and use tags to find the best voice for your needs.
      </Typography.Text>
    </Space>
  )
}
