import { Tooltip } from 'antd'
import Icon from '../Icon'
import './minutesNotification.less'

export const MinutesNotification = () => {
  return (
    <div className="minutes-notification">
      <span className="minutes-notification-text">*minutes do not roll over</span>
      <Tooltip title="*Please note that with monthly plans, any unused minutes do not carry over to the next month. However, with annual plans, you receive all your minutes upfront for the entire year.">
        <Icon name="info" />
      </Tooltip>
    </div>
  )
}
