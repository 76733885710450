import { Col, Row, Select, Space, Switch } from 'antd'

import { SLIDER_OUTLINED, flagsUrl } from '../constants'

const { Option } = Select

export const VoiceModalHeader = (props) => {
  const {
    languages,
    voicesTags,
    currentLanguage,
    setSelectedTags,
    selectedTags,
    onSwitchClick,
    onLanguageSelect,
    premiumVoicesVisible,
    laguageFilterOptions,
  } = props

  const onTagSelect = (values) => {
    setSelectedTags(values)
  }

  return (
    <Row align="middle" gutter={[30, 20]} className="controls-row">
      <Col lg={4} md={10} sm={12} xs={24}>
        <Select
          showSearch
          placeholder="Select a language"
          className="language-select width-100"
          value={currentLanguage}
          onChange={onLanguageSelect}
          filterOption={laguageFilterOptions}
        >
          {languages.map((language) => (
            <Option key={language.name.toLowerCase()} value={language.name} className="language-select-option">
              <img
                src={`${flagsUrl}${language.male[0].icon}.svg`}
                width="20"
                className="flag-icon margin-right-10px"
                alt={language.name}
              />
              <span>{language.name}</span>
            </Option>
          ))}
        </Select>
      </Col>
      <Col lg={8} md={10} sm={12} xs={24}>
        <Select
          showSearch
          value={selectedTags}
          mode="multiple"
          className="width-100"
          placeholder="Search by tags"
          suffixIcon={SLIDER_OUTLINED}
          showArrow="true"
          maxTagCount="responsive"
          onChange={onTagSelect}
          filterOption={laguageFilterOptions}
        >
          {voicesTags.map((t) => (
            <Select.Option key={t}>{t}</Select.Option>
          ))}
        </Select>
      </Col>
      <Col lg={5} md={14} sm={12} xs={24}>
        <Space>
          <Switch checked={premiumVoicesVisible} onChange={onSwitchClick} />
          Show Premium
        </Space>
      </Col>
    </Row>
  )
}
