import { useEffect, useReducer } from 'react'
import { Form, Button, Input, Tooltip, Popconfirm, Empty, Alert, Switch } from 'antd'
import { CopyOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import Icon from '../../components/Icon'
import PageHeader from '../../components/PageHeader/pageHeader'
import { request } from '../../utils/api'
import { useStore } from '../../store'
import { useElaiNotification } from '../../hooks/useElaiNotification'

const formatDate = (date) => {
  if (typeof date === 'string') date = new Date(date)
  return new Intl.DateTimeFormat(window.navigator.languages, {
    timeStyle: 'medium',
    dateStyle: 'short',
  }).format(date)
}

const ApiConfig = () => {
  const notification = useElaiNotification()
  const authStore = useStore((stores) => stores.authStore)
  const { setHeader } = useStore((stores) => stores.domStore)
  const [apiConfig, updateApiConfig] = useReducer(
    (state, updates) => ({
      ...state,
      ...updates,
    }),
    {},
  )

  const fetchApiConfig = async () => {
    if (authStore.user.accountRole !== 'admin') return
    const { apiConfig } = await request({
      method: 'get',
      url: 'integration/apiConfig',
    })
    updateApiConfig(apiConfig)
  }

  useEffect(() => {
    setHeader({
      title: 'API Settings',
      icon: <Icon name="setting" />,
      link: '/api',
      extra: null,
    })
    fetchApiConfig()
  }, [])

  const [form] = Form.useForm()
  if (apiConfig?.webhook) {
    form.setFieldsValue(apiConfig.webhook)
  }

  const onFinish = async (data) => {
    const webhookUrl = data.url
    const res = await request({
      method: 'post',
      url: 'integration/webhook',
      data: { url: webhookUrl, techEmail: data.techEmail, enabled: apiConfig.webhook.enabled },
    })
    if (res === false) return
    updateApiConfig({ webhook: res })
    notification.success({
      message: 'Сhanges saved successfully',
      duration: 3,
    })
  }

  const generateToken = async (isRegenerated) => {
    const { token } = await request({ method: 'post', url: 'integration/generateToken' })
    if (token) {
      updateApiConfig({ token })
      authStore.refreshSession()
      if (isRegenerated) {
        notification.success({ message: 'Your API Key was successfully renewed.' })
      }
    }
  }

  const revokeToken = async () => {
    await request({ method: 'post', url: 'integration/revokeToken' })
    updateApiConfig({ token: null, webhook: { enabled: false } })
    authStore.refreshSession()
    notification.success({ message: 'Your API Key was successfully removed.' })
  }

  const copyToClipboard = (data) => () => {
    navigator.clipboard.writeText(data)
  }

  return (
    <div className="profile-content">
      <div className="content" style={{ maxWidth: 450 }}>
        <PageHeader
          title="API Settings"
          style={{ padding: 0, marginBottom: 35 }}
          extra={
            <Button icon={<QuestionCircleOutlined />} href="https://elai.readme.io/" target="_blank">
              API Documentation
            </Button>
          }
        />
        {authStore.user.accountRole === 'admin' ? (
          <Form form={form} onFinish={onFinish} autoComplete="off" layout="vertical">
            {!apiConfig?.token && (
              <Empty
                imageStyle={{
                  height: 60,
                }}
                description={<span>Generate API Token to get access with Elai API</span>}
              >
                <Button
                  type="primary"
                  onClick={() => {
                    generateToken(false)
                  }}
                >
                  Generate
                </Button>
              </Empty>
            )}
            {apiConfig?.token && (
              <>
                <Form.Item label="API Token">
                  <Input.Group compact>
                    <Input style={{ width: 'calc(100% - 32px)' }} value={apiConfig.token} readOnly />
                    <Tooltip title="Copy token to clipboard">
                      <Button icon={<CopyOutlined />} onClick={copyToClipboard(apiConfig.token)} />
                    </Tooltip>
                  </Input.Group>
                </Form.Item>
                <Popconfirm
                  placement="top"
                  title="Are you sure you want to regenerate your API token?"
                  onConfirm={() => {
                    generateToken(true)
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="primary">Regenerate</Button>
                </Popconfirm>
                <Popconfirm
                  placement="top"
                  title="Are you sure you want to revoke your API token?"
                  onConfirm={revokeToken}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button danger style={{ marginLeft: 8 }}>
                    Revoke token
                  </Button>
                </Popconfirm>
                <Form.Item
                  label={
                    <>
                      API Email{' '}
                      <span style={{ marginLeft: 8 }}>
                        <Tooltip title="We will send email to this address in case of issues with integration or if webhooks will stop working.">
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </span>
                    </>
                  }
                  name="techEmail"
                  style={{ marginTop: 32 }}
                  rules={[
                    {
                      type: 'email',
                      required: false,
                      message: 'Please input valid email!',
                    },
                  ]}
                >
                  <Input placeholder="tech@company.com" />
                </Form.Item>
                <Form.Item label="Webhooks enabled">
                  <Switch
                    size="default"
                    checked={apiConfig.webhook?.enabled}
                    onChange={(checked) => {
                      if (!apiConfig.webhook.url && !form.getFieldValue('url'))
                        return notification.warning({ message: 'Please input Webhook URL' })
                      updateApiConfig({
                        ...apiConfig,
                        webhook: { ...apiConfig.webhook, url: form.getFieldValue('url'), enabled: checked },
                      })
                      form.setFieldsValue({ enabled: checked })
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Webhook URL"
                  name="url"
                  rules={[
                    {
                      type: 'url',
                      required: false,
                      message: 'Please input valid url!',
                    },
                  ]}
                >
                  <Input onChange={() => form.setFieldValue('enabled', true)} />
                </Form.Item>
                {apiConfig.webhook.lastInvokeError && apiConfig.webhook.failedCounter < 10 && (
                  <Alert
                    style={{ marginBottom: 16 }}
                    message={`Your last webhook call was failed at ${formatDate(
                      apiConfig.webhook.lastInvokeAt,
                    )} due to an error: ${apiConfig.webhook.lastInvokeError}. ${
                      apiConfig.webhook.failedCounter
                    } requests were failed. ${
                      !apiConfig.webhook.enabled
                        ? 'Your webhook is disabled.'
                        : apiConfig.webhook.failedCounter < 10
                        ? 'Your webhook will be disabled after 10 failed requests.'
                        : ''
                    }`}
                    type="error"
                  />
                )}
                <p style={{ marginTop: 16 }}>
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </p>
              </>
            )}
          </Form>
        ) : (
          <Alert
            message="Managed by account admin"
            description="Please talk to your account admin to manage API settings."
          />
        )}
      </div>
    </div>
  )
}

export default ApiConfig
