import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Scrollbars from 'react-custom-scrollbars'
import { LoadingOutlined } from '@ant-design/icons'
import { Row, Col, Tag, Card, Button, Spin, Carousel, Empty, Modal, Alert, List } from 'antd'

import { useStore } from '../../store'
import { request } from '../../utils/api'
import { requestCreateVideo, requestCreateVideoFromThinkificCourse } from '../../utils/videoCreation/videoCreation'
import { useElaiNotification } from '../../hooks/useElaiNotification'
import configService from '../../utils/config'

import video_chat from '../../assets/images/video-chat.svg'
import create_avatar from '../../assets/images/default_create_avatar.png'
import draft_video_thumbnail from '../../assets/images/draft-video-thumbnail.jpg'

import Icon from '../../components/Icon'
import CreatePhotoAvatarModal from '../../components/CreatePhotoAvatarModal/createPhotoAvatarModal'
import { tutorialVideos } from '../../utils/constants'

import './home.less'

const Home = () => {
  const notification = useElaiNotification()
  const navigate = useNavigate()
  const [videosData, setVideosData] = useState()
  const [courses, setCourses] = useState()
  const [isCoursesModalOpen, setIsCoursesModalOpen] = useState(false)
  const [isCourseVideoLoader, setIsCourseVideoLoader] = useState(false)
  const [isOpenPhotoAvatarModal, setIsOpenPhotoAvatarModal] = useState(false)
  const { setIsNewVideoModalOpen, setHeader } = useStore((stores) => stores.domStore)
  const authStore = useStore((stores) => stores.authStore)
  const { apiUrl } = configService.get().urls

  const fetchVideos = async () => {
    const data = await request({
      method: 'post',
      url: 'videos/lookup',
      data: { match: { deleted: false } },
    })
    setVideosData(data)
  }

  useEffect(() => {
    setHeader({
      title: 'Home',
      icon: <Icon name="house" />,
      link: '/',
      extra: [
        authStore.user.account.source?.provider === 'thinkific' && (
          <Button key="btn-thinkific" icon={<Icon name="mortarboard" />} onClick={showCoursesModal}>
            Create from Thinkific course
          </Button>
        ),
        <Button
          key="btn-create"
          type="primary"
          icon={<Icon name="add_video" />}
          onClick={() => setIsNewVideoModalOpen(true)}
        >
          Create video
        </Button>,
      ],
    })
    fetchVideos()
  }, [])

  const showCoursesModal = async () => {
    const courses = await request({
      method: 'get',
      url: `${apiUrl}/thinkific/courses`,
    })
    if (!courses) return
    setCourses(courses)
    setIsCoursesModalOpen(true)
  }

  const createCourseVideo = async (id, name) => {
    setIsCourseVideoLoader(id)
    try {
      const data = await requestCreateVideoFromThinkificCourse(id, name)
      if (!data) return setIsCourseVideoLoader(false)
      const videoId = await requestCreateVideo(data)
      navigate(`/video/${videoId}`)
      setIsCourseVideoLoader(false)
    } catch (e) {
      setIsCourseVideoLoader(false)
    }
  }

  const navigateToAvatarsPage = () => {
    notification.destroy('photo-avatar-success')
    navigate('/avatars')
  }

  const handleOpenModal = (hash) => {
    window.location.hash = hash
    setIsNewVideoModalOpen(true)
  }

  const cards = [
    <div className="option-card" onClick={() => handleOpenModal('regular')}>
      <p>From template</p>
      <div className="icon-circle">
        <Icon name="blank" />
      </div>
      <span className="option-card-hint">Create amazing videos from template</span>
    </div>,
    <div className="option-card" onClick={() => handleOpenModal('story')}>
      <Tag color="#C971FF" className="card-tag positioned-absolute">
        Beta
      </Tag>
      <p>AI storyboard</p>
      <div className="icon-circle">
        <Icon name="clipboard" />
      </div>
      <span className="option-card-hint">Type a topic and get a video in minutes</span>
    </div>,
    <div className="option-card" onClick={() => navigate('avatars')}>
      <p>Start with an avatar</p>
      <div className="icon-circle">
        <Icon name="users" />
      </div>
      <span className="option-card-hint">Create blank video with an avatar</span>
    </div>,
    <div className="option-card" onClick={() => handleOpenModal('from_pdf')}>
      <Tag color="#31353C" className="card-tag positioned-absolute">
        New
      </Tag>
      <p>From presentation</p>
      <div className="icon-circle">
        <Icon name="upload_file" />
      </div>
      <span className="option-card-hint">Upload PPT/PDF file and turn it into a video</span>
    </div>,
  ]

  return (
    <div className="home-content">
      <h2>How would you like to start video creation today?</h2>
      <div className="carousel-container mobile">
        <Carousel dots={false}>
          {cards.map((card, index) => (
            <div key={index}>{card}</div>
          ))}
        </Carousel>
      </div>
      <Row gutter={[20, { xs: 20, sm: 20, xl: 40 }]} className="option-cards-row desktop">
        {cards.map((card, index) => (
          <Col key={index}>{card}</Col>
        ))}
      </Row>
      <Row
        gutter={[
          { xs: 24, sm: 24, xl: 30 },
          { xs: 30, sm: 30, xl: 40 },
        ]}
        style={{ marginTop: 35 }}
      >
        <Col xs={24} sm={24} md={12} lg={6}>
          <Card
            className="blank-video-card"
            onClick={async () => {
              let videoId = await requestCreateVideo()
              if (!videoId) return
              setIsNewVideoModalOpen(false)
              navigate(`/video/${videoId}`)
            }}
          >
            <h4
              style={{
                textAlign: 'center',
              }}
            >
              Blank video
            </h4>
            <img src={create_avatar}></img>
            <div className="link" style={{ textAlign: 'center' }}>
              Create
            </div>
          </Card>
        </Col>

        <Col xs={24} sm={24} md={24} lg={9}>
          <Card className="last-videos-card">
            <h4>Last videos</h4>
            <Scrollbars className="last-videos-scrollbar scrollbar">
              {videosData ? (
                <>
                  {videosData.total === 0 ? (
                    <Empty
                      description={<p style={{ marginBottom: 30 }}>There are no recent videos</p>}
                      image={<img src={video_chat} />}
                      imageStyle={{ margin: '20px 0 30px', height: 70 }}
                    >
                      <Button
                        icon={<Icon name="add_video" />}
                        style={{ margin: '0 auto' }}
                        type="primary"
                        onClick={() => setIsNewVideoModalOpen(true)}
                      >
                        Create Now
                      </Button>
                    </Empty>
                  ) : (
                    <Row gutter={[16, 8]}>
                      {videosData.videos.map((video, index) => (
                        <Col key={index} span={12}>
                          <Link to={`/video/${video._id}`}>
                            <div className="img-wrapper">
                              {video.thumbnail ? (
                                <img
                                  className={`${video.data?.format === '9_16' ? 'vertical' : ''} ${
                                    video.data?.format === '1_1' ? 'square' : ''
                                  }`}
                                  src={video.thumbnail}
                                ></img>
                              ) : (
                                <img src={draft_video_thumbnail}></img>
                              )}
                            </div>
                            <p>{video.name}</p>
                          </Link>
                        </Col>
                      ))}
                    </Row>
                  )}
                </>
              ) : (
                <Spin style={{ width: '100%', marginTop: 10 }} />
              )}
            </Scrollbars>
            <Link to="/videos">View all</Link>
          </Card>
        </Col>

        <Col xs={24} sm={24} md={24} lg={9}>
          <Card className="tutorial-video-card">
            <h4>Elai tutorials</h4>
            <Scrollbars className="tutorial-scrollbar scrollbar">
              <Row gutter={12}>
                {Object.values(tutorialVideos).map((video, index) => (
                  <Col key={index} span={12}>
                    <a href={video.url} target="_blank" rel="noopener noreferrer">
                      <div className="img-wrapper">
                        <img src={video.thumbnail}></img>
                      </div>
                    </a>
                    <p>{video.name}</p>
                  </Col>
                ))}
              </Row>
            </Scrollbars>
          </Card>
        </Col>
      </Row>
      <Modal
        title="Choose Thinkific Course"
        open={isCoursesModalOpen}
        className="courses-modal"
        bodyStyle={{ padding: 0 }}
        footer={null}
        onCancel={() => setIsCoursesModalOpen(false)}
      >
        <Alert
          type="info"
          message='Make sure the course is published and all lessons that you want to use in a video are not drafts. So far we can use only "Text" type of lessons.'
        />
        <List
          size="small"
          dataSource={courses}
          renderItem={(item) => (
            <List.Item
              style={{ display: 'flex', justifyContent: 'space-between' }}
              onClick={() => createCourseVideo(item.id, item.name)}
            >
              {item.name}
              {isCourseVideoLoader === item.id && (
                <Spin indicator={<LoadingOutlined style={{ fontSize: 18 }} spin />} />
              )}
            </List.Item>
          )}
        />
      </Modal>
      <CreatePhotoAvatarModal
        successMessage={
          <span>
            Your avatar is ready! Find it in your <a onClick={navigateToAvatarsPage}>avatars library</a> or just use it
            in existing video.
          </span>
        }
        isOpenPhotoAvatarModal={isOpenPhotoAvatarModal}
        setIsOpenPhotoAvatarModal={setIsOpenPhotoAvatarModal}
      />
    </div>
  )
}

export default Home
