import { Card } from 'antd'

import { MIC_ICON, PLUS_ICON } from '../constants'

export const VoiceModalCardTitle = () => {
  return (
    <Card.Meta
      avatar={
        <div className="card-avatar">
          {MIC_ICON}
          {PLUS_ICON}
        </div>
      }
      title="Create a custom voice"
      description="Clone your own voice in 28 languages"
    />
  )
}
