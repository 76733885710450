/**
 * languages for voice cloning supported by Elevenlabs
 */
const languages = [
  {
    name: 'English',
    icon: 'us',
  },
  {
    name: 'Arabic',
    icon: 'ae',
  },
  {
    name: 'Bulgarian',
    icon: 'bg',
  },
  {
    name: 'Chinese',
    icon: 'cn',
  },
  {
    name: 'Croatian',
    icon: 'hr',
  },
  {
    name: 'Czech',
    icon: 'cz',
  },
  {
    name: 'Danish',
    icon: 'dk',
  },
  {
    name: 'Dutch',
    icon: 'nl',
  },
  {
    name: 'Filipino',
    icon: 'ph',
  },
  {
    name: 'Finnish',
    icon: 'fi',
  },
  {
    name: 'French',
    icon: 'fr',
  },
  {
    name: 'German',
    icon: 'de',
  },
  {
    name: 'Greek',
    icon: 'gr',
  },
  {
    name: 'Hindi',
    icon: 'in',
  },
  {
    name: 'Hungarian',
    icon: 'hu',
  },
  {
    name: 'Indonesian',
    icon: 'id',
  },
  {
    name: 'Italian',
    icon: 'it',
  },
  {
    name: 'Japanese',
    icon: 'jp',
  },
  {
    name: 'Korean',
    icon: 'kr',
  },
  {
    name: 'Malay',
    icon: 'my',
  },
  {
    name: 'Norwegian',
    icon: 'no',
  },
  {
    name: 'Polish',
    icon: 'pl',
  },
  {
    name: 'Portuguese',
    icon: 'pt',
  },
  {
    name: 'Romanian',
    icon: 'ro',
  },
  {
    name: 'Russian',
    icon: 'ru',
  },
  {
    name: 'Slovak',
    icon: 'sk',
  },
  {
    name: 'Spanish',
    icon: 'es',
  },
  {
    name: 'Swedish',
    icon: 'se',
  },
  {
    name: 'Tamil',
    icon: 'in',
  },
  {
    name: 'Turkish',
    icon: 'tr',
  },
  {
    name: 'Ukrainian',
    icon: 'ua',
  },
  {
    name: 'Vietnamese',
    icon: 'vn',
  },
]

export default languages
