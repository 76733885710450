import { useEffect, useRef, useState } from 'react'

import { useUserState } from '../../../workspace/constants'
import { uploadDirectlyToS3 } from '../../../../utils/api'
import { useElaiNotification } from '../../../../hooks/useElaiNotification'
import { track } from '../../../../utils/analytics'

export const useScreenRecorderState = (props) => {
  const { setShouldUpdateUploads, setActiveObjectModifier } = props
  const notification = useElaiNotification()
  const videoRef = useRef()
  const mediaRecorderRef = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false)

  const [seconds, setSeconds] = useState(0)
  const [isRecording, setIsRecording] = useState(false)

  const [videoType, setVideType] = useState()
  // src of the video
  const [video, setVideo] = useState({ url: null, blob: null })
  const { authStore } = useUserState()

  const resetRecording = () => {
    setIsRecording(false)
    setSeconds(0)
    setIsCompleted(false)
    setIsOpen(true)
  }

  const recordHandler = async () => {
    const isChromium = window.chrome
    const winNav = window.navigator
    const isOpera = typeof window.opr !== 'undefined'
    const isIOSChrome = winNav.userAgent.match('CriOS')
    const isSupported = (isChromium !== null && typeof isChromium !== 'undefined' && !isIOSChrome) || isOpera === true

    track('editor_launch_screen_recording', { isSupported })

    if (isSupported) {
      setIsOpen(false)
      const streamOptions = {
        audio: false,
        video: {
          // this constraint should fix all issues including any glitches for low performance laptops on powersaving mode
          frameRate: 25,
        },
      }
      const stream = await navigator.mediaDevices.getDisplayMedia(streamOptions).catch(() => null)
      if (!stream) return
      const options = {
        mimeType: '',
      }
      if (MediaRecorder.isTypeSupported('video/webm; codecs="vp8,opus"')) {
        setVideType('webm')
        options.mimeType = 'video/webm; codecs="vp8,opus"'
      } else if (MediaRecorder.isTypeSupported('video/mp4; codecs="avc1.42E01E, mp4a.40.2"')) {
        options.mimeType = 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
        setVideType('mp4')
      } else {
        console.error('WebM and MP4 formats are not supported')
      }

      const mediaRecorder = new MediaRecorder(stream, options)

      const chunks = []
      mediaRecorder.ondataavailable = (e) => chunks.push(e.data)
      mediaRecorder.onstop = (e) => {
        const blob = new Blob(chunks, { type: options.mimeType })
        chunks.length = 0
        const url = URL.createObjectURL(blob)
        resetRecording()
        setVideo({ blob, url })
      }

      mediaRecorderRef.current = stream

      const video = document.createElement('video')
      video.srcObject = stream
      video.muted = true

      const startTime = Date.now()
      const checkDimensions = (_, metadata) => {
        if (metadata.presentedFrames > 1 || Date.now() - startTime > 1000) {
          // now dimensions should be stabilized
          video.srcObject = null

          // start recording and timer
          mediaRecorder.start()
          setIsRecording(true)
        } else {
          video.requestVideoFrameCallback(checkDimensions)
        }
      }

      video.requestVideoFrameCallback(checkDimensions)
    } else {
      notification.error({
        message: 'Browser is not supported',
        description:
          'Only Chrome, Microsoft Edge or Opera properly support Screen Recording. Please use one of these browsers.',
      })
    }
  }

  const handleStopRecord = () => {
    resetRecording()
    /*
     * for some reason calling stop event for mediaRecorder is do not stop recording
     * and u need to stop stream the same way as browser does
     */
    mediaRecorderRef.current.getTracks().forEach((track) => track.stop())
    const audioContext = new AudioContext()
    audioContext.close()
  }

  useEffect(() => {
    if (isCompleted) {
      handleStopRecord()
    }
  }, [isCompleted])

  useEffect(() => {
    if (isRecording) {
      const timerInterval = setInterval(() => {
        if (seconds < 1800) {
          setSeconds((prevTime) => prevTime + 1)
        } else {
          setIsCompleted(true)
          clearInterval(timerInterval)
        }
      }, 1000)

      return () => {
        clearInterval(timerInterval)
      }
    }
  }, [seconds, isRecording])

  const getDisplayTime = (seconds) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${minutes < 10 ? '0' + minutes : minutes}:${
      remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds
    }`
  }

  const onSuccess = (upload) => {
    setIsOpen(false)
    setShouldUpdateUploads(true)
    setActiveObjectModifier({
      newObject: 'video',
      url: upload.url,
      thumbnail: upload.thumbnail,
      durationFitAudio: true,
    })
    notification.destroy('uploading')
  }

  const onError = (error) => {
    setIsOpen(false)
    notification.error({
      description: error.msg,
      duration: 10,
    })
  }

  const onProgress = () => {
    notification.info({
      key: 'uploading',
      message: 'Recording is being processed...',
      description:
        'We are uploading and transcoding your recording. It will appear on your slide and in your "Uploads". Please hold on as it may take a while...',
      duration: null,
    })
  }

  const beforeUpload = (file) => {
    const limitMb = authStore.user.account.plan === 'basic' ? 20 : 200
    if (file.size / 1024 / 1024 > limitMb) {
      notification.error({
        message: `File must be smaller than ${limitMb}MB.${
          authStore.user.account.plan === 'basic'
            ? ' If you want to uplad more than 20MB please upgrade to Advanced or Enterprise plan.'
            : ''
        }`,
      })
      return false
    }
    return true
  }

  const handleOk = () => {
    const date = new Date()
    const formattedDate = date.toLocaleDateString('en-CA')
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    const screenRecord = new File(
      [video.blob],
      `Screen Recording ${formattedDate} ${hours}-${minutes}-${seconds}.${videoType}`,
      {
        type: `video/${videoType}`,
      },
    )
    if (!beforeUpload(screenRecord)) {
      return
    }

    setIsOpen(false)
    uploadDirectlyToS3({
      file: screenRecord,
      data: {
        screenRecord: true,
      },
      onSuccess,
      onError,
      onProgress,
    })
  }

  const handleCancel = () => {
    setIsOpen(false)
  }

  return {
    video,
    recordHandler,
    videoRef,
    isRecording,
    getDisplayTime,
    seconds,
    handleStopRecord,
    isOpen,
    handleOk,
    handleCancel,
  }
}
