import { useEffect, useReducer } from 'react'
import { Form, Button, Input, Checkbox, Tooltip, Popconfirm, Alert, Space } from 'antd'
import { CopyOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import Icon from '../../components/Icon'
import PageHeader from '../../components/PageHeader/pageHeader'
import { request } from '../../utils/api'
import { useStore } from '../../store'
import './setup-sso.less'
import { useElaiNotification } from '../../hooks/useElaiNotification'

const SetupSSO = () => {
  const notification = useElaiNotification()
  const authStore = useStore((stores) => stores.authStore)
  const { setHeader } = useStore((stores) => stores.domStore)
  const [authIntegration, updateAuthIntegration] = useReducer(
    (state, updates) => ({
      ...state,
      ...updates,
    }),
    {},
  )
  const loginUrl = window.location.origin + '/sso/' + authIntegration?.integrationId

  const [form] = Form.useForm()

  const fetchSSOConfig = async () => {
    if (authStore.user.accountRole !== 'admin') return
    const integration = await request({
      method: 'get',
      url: 'integration/sso',
    })
    updateAuthIntegration(integration)
    form.setFieldsValue(integration)
  }

  useEffect(() => {
    setHeader({
      title: 'Single Sign-on',
      icon: <Icon name="setting" />,
      link: '/setup-sso',
      extra: null,
    })
    fetchSSOConfig()
  }, [])

  const onFinish = async (data) => {
    if (!data.protocol) data.protocol = 'oidc'

    if (authIntegration._id) {
      const ok = await request({
        method: 'put',
        url: 'integration/sso',
        data,
      })
      if (ok) {
        notification.success({
          message: 'SSO integration has been updated',
          duration: 3,
        })
        updateAuthIntegration(data)
      }
    } else {
      const integration = await request({
        method: 'post',
        url: 'integration/sso',
        data,
      })
      if (integration) {
        notification.success({
          message: 'SSO integration has been enabled',
          duration: 3,
        })
        updateAuthIntegration(integration)
      }
    }
    form.setFieldValue(['config', 'clientSecret'], '*'.repeat(64))
  }

  const disableIntegration = async () => {
    const res = await request({
      method: 'delete',
      url: 'integration/sso',
    })
    if (res === false) return
    updateAuthIntegration({ _id: null, integrationId: null, config: {} })
    form.resetFields()
    notification.success({
      message: 'SSO has been disabled',
      duration: 3,
    })
  }

  const copyToClipboard = (data) => () => {
    navigator.clipboard.writeText(data)
  }

  return (
    <div className="profile-content">
      <div className="content" style={{ maxWidth: 450 }}>
        <PageHeader title="Single Sign-on Settings" style={{ padding: 0, marginBottom: 35 }} />
        {authStore.user.accountRole === 'admin' ? (
          <>
            <h3>OpenID Connect (OIDC) Settings</h3>
            <p>
              OpenID Connect authentification protocol is supported by most identity providers, such as{' '}
              <b>Okta, Microsoft Azure AD, OneLogin, JumpCloud, Auth0 and more...</b>
              Please refer to your identity provider documentation on how to setup OIDC.
              <p>
                <small>
                  Note: For <b>Okta</b> please use endpoint like{' '}
                  <span style={{ fontWeight: 500 }}>https://&lt;your-domain&gt;.okta.com/oauth2/default</span> and for{' '}
                  <b>Microsoft Azure AD</b> like{' '}
                  <span style={{ fontWeight: 500 }}>https://login.microsoftonline.com/&lt;TenantIDGUID&gt;/v2.0</span>
                </small>
              </p>
            </p>
            <p>1. Add your OIDC details in the form below to allow your users login with SSO.</p>
            <p>
              2. Add <b>{window.location.origin}/sso</b> to your Redirect/Callback URIs.
            </p>
            <p>3. Test your integration by visiting your SSO url.</p>
            <Form form={form} onFinish={onFinish} autoComplete="off" layout="vertical">
              <Form.Item
                label="Issuer Endpoint"
                name={['config', 'issuerEndpoint']}
                style={{ marginTop: 32 }}
                rules={[
                  {
                    type: 'url',
                    required: true,
                    message: 'Please input valid url icluding protocol',
                  },
                ]}
                validateTrigger="onBlur"
              >
                <Input placeholder="https://your-url/authorize" />
              </Form.Item>
              <Form.Item
                label="Client ID"
                name={['config', 'clientId']}
                style={{ marginTop: 32 }}
                rules={[
                  {
                    type: 'string',
                    required: true,
                    message: 'Please input valid client id',
                  },
                ]}
                validateTrigger="onBlur"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Client Secret"
                name={['config', 'clientSecret']}
                style={{ marginTop: 32 }}
                rules={[
                  {
                    type: 'string',
                    required: true,
                    message: 'Please input valid client secret',
                  },
                ]}
                validateTrigger="onBlur"
              >
                <Input />
              </Form.Item>
              <Form.Item name="authByInvite" valuePropName="checked">
                <Checkbox>
                  Invite only{' '}
                  <span style={{ marginLeft: 8 }}>
                    <Tooltip
                      title={
                        <>
                          In this mode, only users that you add to{' '}
                          <a href="/workspaces" target="_blank">
                            your team
                          </a>{' '}
                          will be able to use SSO. Others will be declined.
                        </>
                      }
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                </Checkbox>
              </Form.Item>
              <Form.Item name="anonymizeUsers" valuePropName="checked">
                <Checkbox>
                  Anonymize users{' '}
                  <span style={{ marginLeft: 8 }}>
                    <Tooltip
                      title={
                        <>
                          By default, our SSO client requires the email and name of each user from your server. If this
                          is set to true, the scope will be only “openid“, so no email and name are required. Users will
                          be created with the default name "SSO User" and a unique email generated from a user ID like
                          "667bacfb2c0f20e392444f0e@elai.io".
                        </>
                      }
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                </Checkbox>
              </Form.Item>
              <Form.Item
                label={
                  <>
                    Redirect URL{' '}
                    <span style={{ marginLeft: 8 }}>
                      <Tooltip title="After successful login user will be redirected to this URL. You can also add redirect url dynamically using get param like 'https://app.elai.io/sso/...?redirect_url=https://redirect_uri'. By default users will be redirected to Elai app homepage.">
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </span>
                  </>
                }
                name="redirectUrl"
                style={{ marginTop: 32 }}
                rules={[
                  {
                    type: 'url',
                    required: false,
                  },
                ]}
              >
                <Input placeholder="https://redirect-url/on-login" />
              </Form.Item>
              {authIntegration.integrationId && (
                <Form.Item
                  label={
                    <Space>
                      <>Login link</>
                      <Button
                        size="small"
                        type="primary"
                        target="_blank"
                        href={'/sso/' + authIntegration.integrationId}
                      >
                        Test
                      </Button>
                    </Space>
                  }
                  style={{ marginTop: 32 }}
                >
                  <Input.Group compact>
                    <Input style={{ width: 'calc(100% - 32px)' }} value={loginUrl} readOnly />
                    <Tooltip title="Copy URL to clipboard">
                      <Button icon={<CopyOutlined />} onClick={copyToClipboard(loginUrl)} />
                    </Tooltip>
                  </Input.Group>
                </Form.Item>
              )}
              <div style={{ marginTop: 32 }}>
                {authIntegration.integrationId ? (
                  <>
                    <Button type="primary" htmlType="submit">
                      Save
                    </Button>
                    <Popconfirm
                      placement="top"
                      title="Are you sure you want to disable SSO integration?"
                      onConfirm={disableIntegration}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button danger style={{ marginLeft: 8 }}>
                        Disable integration
                      </Button>
                    </Popconfirm>
                  </>
                ) : (
                  <Button type="default" htmlType="submit">
                    Enable integration
                  </Button>
                )}
              </div>
            </Form>
          </>
        ) : (
          <Alert
            message="Managed by account admin"
            description="Please talk to your account admin to manage API settings."
          />
        )}
      </div>
    </div>
  )
}

export default SetupSSO
