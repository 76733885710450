/* eslint-disable no-undef */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-sequences */
/* eslint-disable no-unused-expressions */
// import './wdyr'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module'
import { Helmet } from 'react-helmet'
import * as Sentry from '@sentry/react'
import { CaptureConsole } from '@sentry/integrations'
import App from './App'
import { StoreProvider } from './store'
import { ConfigProvider } from 'antd'
import configService from './utils/config'

const startApp = () => {
  // TODO Is refresh needed here?. Fn run only ones, so data from configService doesn't refresh.
  const { globalProd } = configService.get().env
  const { favIcon, title, description } = configService.get().theme

  if (globalProd) {
    /**
     * SENTRY INIT
     */
    Sentry.init({
      dsn: 'https://ba6078b955be476cafaa461dc33553ed@o1106645.ingest.sentry.io/6133332',
      integrations: [new Sentry.BrowserTracing(), new CaptureConsole({ levels: ['error'] })],
      tracesSampleRate: 1.0,
      environment: 'production',
      beforeSend(event) {
        // Check if it is an exception thrown by a component
        const isComponentError =
          event.exception?.values?.[0]?.type === 'ReactError' || event.message?.includes('component')
        if (isComponentError) {
          Sentry.showReportDialog({
            eventId: event.event_id,
            user: { name: localStorage.getItem('name'), email: localStorage.getItem('email') },
          })
        }
        return event
      },
      ignoreErrors: [
        // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/8
        'Non-Error promise rejection captured',
        'AbortError: The play() request',
        'WebM and MP4 formats are not supported',
      ],
    })

    /**
     * Rewardful INIT
     * */
    // prettier-ignore
    {
      (function(w, r) {
        w._rwq = r;
        w[r] = w[r] || function() {
          (w[r].q = w[r].q || []).push(arguments)
        }
      })(window, 'rewardful');
    }
    /**
     * IMPACT.COM INIT
     */
    // prettier-ignore
    {
      (function(a, b, c, d, e, f, g) {
        e['ire_o'] = c;
        e[c] = e[c] || function() {
          (e[c].a = e[c].a || []).push(arguments)
        };
        f = d.createElement(b);
        g = d.getElementsByTagName(b)[0];
        f.async = 1;
        f.src = a;
        g.parentNode.insertBefore(f, g);
      })('https://utt.impactcdn.com/A5052381-4c60-4d3f-a07e-26118a4c993a1.js', 'script', 'ire', document, window);
    }
    /**
     * Google Analytics Init
     */
    ReactGA.initialize('G-YZ5HV3QQ5W')
    /**
     * Google Tag Manager (GTM) Init
     */
    TagManager.initialize({ gtmId: 'GTM-T6V72PV' })
  }

  const root = ReactDOM.createRoot(document.getElementById('root'))
  root.render(
    <DndProvider backend={HTML5Backend}>
      {globalProd && (
        <Helmet>
          <script async src="https://r.wdfl.co/rw.js" data-rewardful="e0b860"></script>
          <script
            async
            id="ioni"
            data-account-id="648241b1bf8020c4c9a9c608"
            src="https://widget.ioni.ai/widget.js"
          ></script>
        </Helmet>
      )}
      <Helmet>
        <link rel="icon" href={favIcon} />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <StoreProvider>
        <BrowserRouter>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#4868ff',
                colorPrimaryActive: '#2d4ffc',
                colorPrimaryHover: 'rgba(72, 102, 255, 0.8)',
                colorLink: '#4868ff',
                colorLinkActive: '#2d4ffc',
                colorLinkHover: 'rgba(72, 102, 255, 0.8)',
                colorError: '#cf5d60',
                fontFamily: `'Basier Circle', Arial, sans-serif`,
                borderRadius: 4,
              },
              components: {
                Button: {
                  borderRadius: 8,
                  borderRadiusLG: 8,
                },
                ButtonGroup: {
                  colorBorder: '#434547',
                },
                Modal: {
                  borderRadiusLG: 20,
                },
                Segmented: {
                  borderRadius: 10,
                },
                Select: {
                  borderRadiusSM: 4,
                },
              },
            }}
          >
            <App />
          </ConfigProvider>
        </BrowserRouter>
      </StoreProvider>
    </DndProvider>,
  )
}

export { startApp }
