import { useCallback } from 'react'

export const formatSeconds = (value) => `${value}s`
export const formatVolume = (value) => `${Math.round(value * 100)}%`

export const useVideoControls = (props) => {
  const { setActiveObjectModifier, canvasActiveObject, activeObjectProps, syncVideoState, duration, volume } = props

  const canvasVideoObject = canvasActiveObject?.getElement
    ? canvasActiveObject
    : canvasActiveObject?._objects?.length === 1 && canvasActiveObject._objects[0].type === 'video'
    ? canvasActiveObject._objects[0]
    : null

  const handleFitAudio = (durationFitAudio) => {
    setActiveObjectModifier(
      durationFitAudio
        ? {
            change: {
              durationFitAudio,
              loop: false,
            },
          }
        : {
            change: {
              durationFitAudio,
              speedRate: activeObjectProps.speedRate,
            },
          },
    )
  }

  const handleLoop = (loop) => {
    const trimEnd = canvasVideoObject.getElement().duration
    const result = loop
      ? {
          change: {
            loop,
            durationFitAudio: false,
            trimStart: 0,
            trimEnd,
          },
        }
      : {
          change: {
            loop,
          },
        }
    setActiveObjectModifier(result)
  }

  const onStep = useCallback(
    ([trimStart, trimEnd]) => {
      const changedFields = {}
      if (trimStart !== canvasVideoObject.trimStart) {
        changedFields.trimStart = true
        canvasVideoObject.trimStart = trimStart
      } else if (trimEnd !== canvasVideoObject.trimEnd) {
        changedFields.trimEnd = true
        canvasVideoObject.trimEnd = trimEnd
      }

      syncVideoState(canvasVideoObject.canvas, canvasVideoObject, changedFields, duration)
    },
    [duration, canvasActiveObject],
  )

  const onTrimStartChange = useCallback(
    (value) => {
      if (value >= canvasVideoObject.trimEnd) return
      setActiveObjectModifier({
        change: {
          trimStart: value,
          loop: false,
        },
        changedFields: {
          trimStart: value !== canvasVideoObject.trimStart,
          trimEnd: false,
        },
      })
    },
    [canvasActiveObject],
  )

  const onTrimEndChange = useCallback(
    (value) => {
      if (value <= canvasVideoObject.trimStart) return
      setActiveObjectModifier({
        change: {
          trimEnd: value,
          loop: false,
        },
        changedFields: {
          trimEnd: value !== canvasVideoObject.trimEnd,
          trimStart: false,
        },
      })
    },
    [canvasActiveObject],
  )

  const onTrimChange = useCallback(
    ([trimStart, trimEnd]) => {
      const elementDuration = canvasVideoObject.getElement().duration
      if (elementDuration < 1) return
      if (trimStart !== canvasVideoObject.trimStart && trimStart >= trimEnd - 1) trimStart = trimEnd - 1
      else if (trimEnd !== canvasVideoObject.trimEnd && trimEnd <= trimStart + 1) trimEnd = trimStart + 1
      setActiveObjectModifier({
        change: {
          trimStart,
          trimEnd,
          loop: false,
        },
        changedFields: {
          trimStart: trimStart !== canvasVideoObject.trimStart,
          trimEnd: trimEnd !== (canvasVideoObject.trimEnd || canvasVideoObject.getElement().duration),
        },
      })
    },
    [canvasActiveObject],
  )

  const onSpeedRateChange = (speedRate) => {
    const modifier = {
      change: {
        speedRate,
      },
    }
    if (speedRate !== 1 && volume) {
      modifier.change.volume = 0
    }
    setActiveObjectModifier(modifier)
  }

  const onVolumeChange = useCallback(
    (volume) =>
      setActiveObjectModifier({
        change: 'volume',
        value: volume,
      }),
    [],
  )

  return {
    onStep,
    handleLoop,
    onTrimChange,
    onVolumeChange,
    handleFitAudio,
    onSpeedRateChange,
    onTrimStartChange,
    onTrimEndChange,
  }
}
