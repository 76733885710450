export const transitions = [
  {
    id: null,
    name: 'No Transition',
    thumbnail: {
      image: 'https://d3u63mhbhkevz8.cloudfront.net/transitions/no.png',
    },
  },
  {
    id: 'fade',
    name: 'Fade',
    thumbnail: {
      image: 'https://d3u63mhbhkevz8.cloudfront.net/transitions/fade.png',
      video: 'https://d3u63mhbhkevz8.cloudfront.net/transitions/fade.mp4',
    },
  },
  {
    id: 'fadeblack',
    name: 'Fade To Black',
    thumbnail: {
      image: 'https://d3u63mhbhkevz8.cloudfront.net/transitions/fade_black.png',
      video: 'https://d3u63mhbhkevz8.cloudfront.net/transitions/fade_black.mp4',
    },
  },
  {
    id: 'fadewhite',
    name: 'Fade To White',
    thumbnail: {
      image: 'https://d3u63mhbhkevz8.cloudfront.net/transitions/fade_white.png',
      video: 'https://d3u63mhbhkevz8.cloudfront.net/transitions/fade_white.mp4',
    },
  },
  {
    id: 'slideleft',
    name: 'Slide Left',
    thumbnail: {
      image: 'https://d3u63mhbhkevz8.cloudfront.net/transitions/slide_left.png',
      video: 'https://d3u63mhbhkevz8.cloudfront.net/transitions/slide_left.mp4',
    },
  },
  {
    id: 'slideright',
    name: 'Slide Right',
    thumbnail: {
      image: 'https://d3u63mhbhkevz8.cloudfront.net/transitions/slide_right.png',
      video: 'https://d3u63mhbhkevz8.cloudfront.net/transitions/slide_right.mp4',
    },
  },
  {
    id: 'slidedown',
    name: 'Slide Down',
    thumbnail: {
      image: 'https://d3u63mhbhkevz8.cloudfront.net/transitions/slide_down.png',
      video: 'https://d3u63mhbhkevz8.cloudfront.net/transitions/slide_down.mp4',
    },
  },
  {
    id: 'slideup',
    name: 'Slide Up',
    thumbnail: {
      image: 'https://d3u63mhbhkevz8.cloudfront.net/transitions/slide_up.png',
      video: 'https://d3u63mhbhkevz8.cloudfront.net/transitions/slide_up.mp4',
    },
  },
  {
    id: 'wipeleft',
    name: 'Wipe Left',
    thumbnail: {
      image: 'https://d3u63mhbhkevz8.cloudfront.net/transitions/wipe_left.png',
      video: 'https://d3u63mhbhkevz8.cloudfront.net/transitions/wipe_left.mp4',
    },
  },
  {
    id: 'wiperight',
    name: 'Wipe Right',
    thumbnail: {
      image: 'https://d3u63mhbhkevz8.cloudfront.net/transitions/wipe_right.png',
      video: 'https://d3u63mhbhkevz8.cloudfront.net/transitions/wipe_right.mp4',
    },
  },
  {
    id: 'wipedown',
    name: 'Wipe Down',
    thumbnail: {
      image: 'https://d3u63mhbhkevz8.cloudfront.net/transitions/wipe_down.png',
      video: 'https://d3u63mhbhkevz8.cloudfront.net/transitions/wipe_down.mp4',
    },
  },
  {
    id: 'wipeup',
    name: 'Wipe Up',
    thumbnail: {
      image: 'https://d3u63mhbhkevz8.cloudfront.net/transitions/wipe_up.png',
      video: 'https://d3u63mhbhkevz8.cloudfront.net/transitions/wipe_up.mp4',
    },
  },
  {
    id: 'smoothleft',
    name: 'Smooth Left',
    thumbnail: {
      image: 'https://d3u63mhbhkevz8.cloudfront.net/transitions/smooth_left.png',
      video: 'https://d3u63mhbhkevz8.cloudfront.net/transitions/smooth_left.mp4',
    },
  },
  {
    id: 'smoothright',
    name: 'Smooth Right',
    thumbnail: {
      image: 'https://d3u63mhbhkevz8.cloudfront.net/transitions/smooth_right.png',
      video: 'https://d3u63mhbhkevz8.cloudfront.net/transitions/smooth_right.mp4',
    },
  },
  {
    id: 'smoothdown',
    name: 'Smooth Down',
    thumbnail: {
      image: 'https://d3u63mhbhkevz8.cloudfront.net/transitions/smooth_down.png',
      video: 'https://d3u63mhbhkevz8.cloudfront.net/transitions/smooth_down.mp4',
    },
  },
  {
    id: 'smoothup',
    name: 'Smooth Up',
    thumbnail: {
      image: 'https://d3u63mhbhkevz8.cloudfront.net/transitions/smooth_up.png',
      video: 'https://d3u63mhbhkevz8.cloudfront.net/transitions/smooth_up.mp4',
    },
  },
]
