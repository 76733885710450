import { Tooltip } from 'antd'
import Icon from '../../../components/Icon'
import { osMap } from '../../../utils/constants'
import { os } from '../constants'

const ShortcutTooltip = ({
  children,
  title,
  keyName,
  placement,
  showKeys = true,
  showMetaKey = true,
  disabled = false,
}) => {
  return (
    <Tooltip
      key="undo"
      placement={placement}
      title={
        disabled ? (
          ''
        ) : (
          <span className="shortcut-tooltip-title">
            <span className="title-text">{title}</span>
            {showKeys && (
              <>
                {showMetaKey && (
                  <span className="key-wrapper">
                    <span>{os === osMap.MAC ? <Icon name="mac_command" /> : 'Ctrl'}</span>
                  </span>
                )}
                <span className="key-wrapper">
                  <span>{keyName}</span>
                </span>
              </>
            )}
          </span>
        )
      }
      mouseEnterDelay={0.4}
    >
      {children}
    </Tooltip>
  )
}

export default ShortcutTooltip
