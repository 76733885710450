import { Modal, Button } from 'antd'
import Icon from '../../../components/Icon'

export const ValidationModal = ({
  isOpenErrorsModal,
  setIsOpenErrorsModal,
  gptLoading,
  setGptLoading,
  videoErrors,
  setVideoErrors,
  isVideoGenerating,
  generateVideo,
  ensureVideoSaved,
}) => {
  const handleClickFixWithGpt = async () => {
    await ensureVideoSaved()

    setGptLoading({ key: 'add-missed-texts', message: 'Generating missed content', isLoading: true })
    await generateVideo(true)
    setGptLoading({ isLoading: false })
    setVideoErrors(false)
    setIsOpenErrorsModal(false)
  }

  const renderError = (field, ending) => {
    if (!videoErrors) return
    if (videoErrors.get(field).length) {
      const isPlural = videoErrors.get(field).length > 1
      return (
        <p className="error-message">
          Slide{isPlural ? 's' : ''}{' '}
          <span>
            {videoErrors
              .get(field)
              .map((index) => index + 1)
              .join(', ')}
          </span>
          : {ending}
        </p>
      )
    } else return null
  }

  return (
    <Modal
      centered
      open={isOpenErrorsModal}
      width={400}
      className="validation-modal"
      footer={[
        <Button key="fix" loading={gptLoading.key === 'add-missed-texts'} onClick={handleClickFixWithGpt}>
          Add with AI
        </Button>,
        <Button
          key="convert"
          type="primary"
          disabled={gptLoading.isLoading}
          loading={isVideoGenerating && !gptLoading.isLoading}
          onClick={() => generateVideo()}
        >
          Remove
        </Button>,
      ]}
      onCancel={() => setIsOpenErrorsModal(false)}
    >
      <h2>Some content missing</h2>
      <p className="description">
        It appears that some content is missing in your video. You could use AI to generate it or remove it from your
        slides.
      </p>
      <div className="error-alert">
        <Icon name="info" />
        <div>
          {renderError('header', 'header')}
          {renderError('subHeader', 'subheader')}
          {renderError('list', 'list')}
          {renderError('images', 'images')}
          {renderError('speech', 'speech')}
        </div>
      </div>
    </Modal>
  )
}
