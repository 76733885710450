import { useState, useRef, useCallback, useMemo, useEffect } from 'react'
import { Dropdown, Typography } from 'antd'
import {
  removeHandler,
  copyHandler,
  pasteHandler,
  getActiveObjectLayers,
  groupObjects,
  ungroupObjects,
} from '../../../../../utils/canvas/canvas'
import {
  copyIcon,
  pasteIcon,
  deleteIcon,
  layersIcon,
  alignLeftIcon,
  rightArrowIcon,
  moveToIcon,
  groupIcon,
  ungroupIcon,
  alignMenuItemChildren,
  moveToMenuItemChildren,
  createLayerSubmenuItem,
} from '../constans'
import { osMap } from '../../../../../utils/constants'
import { os } from '../../../constants'

const { Text } = Typography

const emulateDeleteTextFromKeyboard = (textbox) => {
  const selectionStart = textbox.selectionStart
  const selectionEnd = textbox.selectionEnd

  if (selectionStart !== selectionEnd) {
    const textBeforeSelection = textbox.text.slice(0, selectionStart)
    const textAfterSelection = textbox.text.slice(selectionEnd)
    textbox.text = textBeforeSelection + textAfterSelection
    textbox.selectionStart = textbox.selectionEnd = selectionStart
  } else if (selectionEnd < textbox.text.length) {
    const textBeforeCursor = textbox.text.slice(0, selectionEnd)
    const textAfterCursor = textbox.text.slice(selectionEnd + 1)
    textbox.text = textBeforeCursor + textAfterCursor
    textbox.selectionStart = textbox.selectionEnd = selectionEnd
  }

  textbox.setCoords()
  textbox.canvas.renderAll()
}

const ContextMenu = ({
  children,
  data,
  canvas,
  canvasActiveObject,
  isShiftPressed,
  setActiveObjectModifier,
  updateCanvas,
  getSlideChangesOnPasteAvatar,
  isTextEditingEntered,
}) => {
  const [isOpenContextMenu, setIsOpenContextMenu] = useState(false)
  const [contextMenuOpenKeys, _setContextMenuOpenKeys] = useState([])
  const layersSubmenuClicked = useRef(false)
  const contextMenuOpenKeysRef = useRef(null)
  const cursorPosition = useRef({ top: 0, left: 0 })

  const setContextMenuOpenKeys = (state) => {
    _setContextMenuOpenKeys(state)
    contextMenuOpenKeysRef.current = state
  }

  const onContextMenuOpenChange = (open) => {
    setIsOpenContextMenu(open)
    if (!open) return

    // fix an issue in antd where the menu wouldn't appear after repeatedly spamming the context menu
    setTimeout(() => {
      const menu = document.querySelector('.canvas-context-menu')
      if (!menu) return

      const isInvisible =
        menu.classList.contains('ant-slide-up-enter') || menu.classList.contains('ant-dropdown-hidden')
      if (!isInvisible) return

      menu.classList.remove('ant-slide-up-enter', 'ant-dropdown-hidden')
      menu.style.opacity = 1

      const { top, left } = cursorPosition.current
      menu.style.top = `${top + 10}px`
      menu.style.left = `${left}px`
    }, 100)
  }

  const handleSubmenuOpen = (openKeys) => {
    if (layersSubmenuClicked.current) {
      layersSubmenuClicked.current = false
      openKeys = ['layers']
    }
    setContextMenuOpenKeys(openKeys)
  }

  const saveLastCursorPosition = useCallback((e) => {
    cursorPosition.current.top = e.clientY
    cursorPosition.current.left = e.clientX
  }, [])

  const handleClickCanvasContextMenu = useCallback(
    async ({ key, keyPath }) => {
      switch (true) {
        case keyPath.includes('layers'):
          layersSubmenuClicked.current = true
          setActiveObjectModifier({ change: 'layersSelection', value: key })
          if (
            (!canvasActiveObject.group || canvasActiveObject.group.getObjects().length === 1) &&
            canvasActiveObject.id === +key &&
            isShiftPressed.current
          ) {
            setIsOpenContextMenu(false)
          }
          break
        case key === 'copy':
          if (isTextEditingEntered) {
            const text = document.getSelection().toString()
            navigator.clipboard.writeText(text).catch(() => {})
          } else {
            const { id: slideId, avatar, voice, voiceProvider } = data
            await copyHandler({ slideId, canvas, avatar, voice, voiceProvider })
          }
          break
        case key === 'paste':
          if (isTextEditingEntered) {
            const text = await navigator.clipboard.readText().catch(() => '')
            document.execCommand('insertText', false, text)
          } else {
            await pasteHandler(
              { slideId: data.id, canvas, speech: data.speech },
              canvas,
              updateCanvas,
              getSlideChangesOnPasteAvatar,
            )
          }
          break
        case key === 'delete':
          if (isTextEditingEntered) {
            emulateDeleteTextFromKeyboard(canvasActiveObject)
          } else {
            removeHandler(canvas)
            canvas.renderAll()
          }
          updateCanvas()
          break
        case key === 'group':
          groupObjects(canvas)
          updateCanvas()
          break
        case key === 'ungroup':
          ungroupObjects(canvas)
          updateCanvas()
          break
        case keyPath.includes('moveTo'):
          setActiveObjectModifier({ change: 'layering', action: key })
          break
        case keyPath.includes('align'):
          setActiveObjectModifier({ change: 'groupedAlignment', value: key })
          break
        default:
          break
      }

      if (!keyPath.includes('layers')) setIsOpenContextMenu(false)
    },
    [canvas, canvasActiveObject, data, updateCanvas, isTextEditingEntered],
  )

  useEffect(() => {
    if (!isOpenContextMenu && contextMenuOpenKeys.length) {
      setContextMenuOpenKeys([])
    }
  }, [isOpenContextMenu])

  const handleMenuMouseLeave = () => {
    setTimeout(() => {
      if (!contextMenuOpenKeysRef.current?.length) setIsOpenContextMenu(false)
    }, 100)
  }

  const modifierKey = os === osMap.MAC ? '⌘' : 'Ctrl+'

  const canvasContextMenuItems = useMemo(() => {
    if (!isOpenContextMenu) return []
    const pasteMenuItem = {
      key: 'paste',
      icon: pasteIcon,
      label: (
        <span className="menu-item-label">
          <span>Paste</span> <Text type="secondary">{modifierKey}V</Text>
        </span>
      ),
    }
    if (!canvasActiveObject) return [pasteMenuItem]
    const layers = getActiveObjectLayers(canvasActiveObject)
    const layersMenuItemChildren = layers.map((layer) => createLayerSubmenuItem(layer, canvasActiveObject))
    const menuItems = [
      {
        key: 'copy',
        icon: copyIcon,
        label: (
          <span className="menu-item-label">
            <span>Copy</span> <Text type="secondary">{modifierKey}C</Text>
          </span>
        ),
      },
      pasteMenuItem,
      {
        key: 'delete',
        icon: deleteIcon,
        label: (
          <span className="menu-item-label">
            <span>Delete</span> <Text type="secondary">{os === osMap.MAC ? 'Del' : 'Bcksp'}</Text>
          </span>
        ),
      },
      {
        icon: moveToIcon,
        label: 'Move to',
        key: 'moveTo',
        expandIcon: rightArrowIcon,
        children: moveToMenuItemChildren,
        popupClassName: 'canvas-context-submenu',
      },
    ]

    const groupMenuItem =
      canvasActiveObject.type === 'activeSelection'
        ? {
            key: 'group',
            icon: groupIcon,
            label: (
              <span className="menu-item-label">
                <span>Group</span> <Text type="secondary">{modifierKey}G</Text>
              </span>
            ),
          }
        : {
            key: 'ungroup',
            icon: ungroupIcon,
            label: (
              <span className="menu-item-label">
                <span>Ungroup</span>{' '}
                <Text type="secondary">
                  {modifierKey}
                  {os === osMap.MAC ? ' Shift ' : 'Shift+'}G
                </Text>
              </span>
            ),
          }

    if (canvasActiveObject._objects && canvasActiveObject.type !== 'question') menuItems.splice(3, 0, groupMenuItem)

    if (layers.length)
      menuItems.push({
        icon: layersIcon,
        label: 'Select layer',
        key: 'layers',
        expandIcon: rightArrowIcon,
        children: layersMenuItemChildren,
        popupClassName: 'canvas-layer-submenu',
      })

    if (canvasActiveObject.type === 'activeSelection')
      menuItems.push({
        icon: alignLeftIcon,
        label: 'Align elements',
        key: 'align',
        expandIcon: rightArrowIcon,
        children: alignMenuItemChildren,
        popupClassName: 'canvas-context-submenu',
      })

    return menuItems
  }, [canvasActiveObject, canvasActiveObject?.left, canvasActiveObject?.top, isOpenContextMenu])

  return (
    <Dropdown
      open={isOpenContextMenu}
      menu={{
        items: canvasContextMenuItems,
        openKeys: contextMenuOpenKeys,
        onOpenChange: handleSubmenuOpen,
        onClick: handleClickCanvasContextMenu,
      }}
      trigger={['contextMenu']}
      overlayClassName="canvas-context-menu"
      dropdownRender={(menu) => <div onMouseLeave={handleMenuMouseLeave}>{menu}</div>}
      onOpenChange={onContextMenuOpenChange}
      onContextMenu={saveLastCursorPosition}
    >
      {children}
    </Dropdown>
  )
}

export default ContextMenu
