import { RightOutlined } from '@ant-design/icons'

export const stepsQuestions = [
  [
    {
      question: 'What is your use case?',
      answers: [
        'Learning & Development',
        'L&D Agency',
        'HR',
        'Marketing',
        'API',
        'Real-time Avatars',
        'Sales',
        'News',
        'Other',
      ],
      name: 'userType',
      component: 'button',
    },
  ],
  [
    {
      question: 'What is your company size?',
      answers: ['1', '2-10', '11-50', '51-200', '201-1000', '1000+'],
      name: 'companySize',
      component: 'button',
      show: 'b2b',
    },
    {
      question: 'What is your company name?',
      name: 'companyName',
      component: 'input',
      show: 'public-email',
      requiredMessage: 'Please input your company name',
    },
  ],
  [
    {
      question: 'What is your role?',
      answers: ['Business Owner', 'Executive', 'L&D Manager', 'HR', 'Marketer', 'Developer', 'Teacher', 'Other'],
      name: 'role',
      component: 'button',
      show: 'b2b',
    },
  ],
  [
    {
      question: 'How often are you planning to create videos?',
      answers: ['Daily', 'Weekly', 'Occasionally', 'Project Based'],
      name: 'usage',
      component: 'button',
    },
  ],
  [
    {
      question: 'How did you hear about us?',
      answers: ['Online Search', 'Social Media', 'Word of Mouth', 'Ads', 'Email', 'Other'],
      name: 'source',
      component: 'button',
    },
  ],
]

export const rightOutlinedIcon = <RightOutlined />

export const { REACT_APP_CHAT_URL } = process.env
