import { Helmet } from 'react-helmet'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, Link } from 'react-router-dom'
import { Card, Spin, Result, Button, Layout } from 'antd'

import { request } from '../../utils/api'
import configService from '../../utils/config'
import PageHeader from '../../components/PageHeader/pageHeader'
import { useElaiNotification } from '../../hooks/useElaiNotification'

import './preview.less'

const { Content } = Layout

export default () => {
  const notification = useElaiNotification()
  const { id } = useParams()
  const [video, setVideo] = useState()
  const [iframeRef, setIframeRef] = useState(null)

  const { playerUrl } = configService.get().urls
  const { hideElaiMentions } = configService.get().features

  const { t } = useTranslation()

  const fetchVideo = async () => {
    try {
      setVideo(undefined)
      const v = await request({ method: 'get', url: `/public/video/${id}` })
      setVideo(v)
    } catch (error) {
      const message = error.response?.data?.message || error.message
      notification.error({ message })
      setVideo(false)
    }
  }

  const resizeVideoContainer = () => {
    if (!iframeRef) return
    iframeRef.height = iframeRef.clientWidth / 1.777
  }

  useEffect(() => resizeVideoContainer(), [iframeRef])

  window.onresize = resizeVideoContainer

  useEffect(() => {
    fetchVideo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (video === false)
    return (
      <Result
        status="warning"
        title="This video is not available at the moment"
        extra={
          <Link to="/">
            <Button type="primary">Back Home</Button>
          </Link>
        }
      />
    )

  if (!video) return <Spin />

  return (
    <Layout className="preview-main-layout" hasSider>
      <Content className="preview-main-content">
        <Card bodyStyle={{ padding: 0 }} className="preview-main-card">
          <PageHeader title={video.name} />
          <Helmet>
            <title>{video.name} - AI generated video</title>
          </Helmet>
          <iframe
            ref={setIframeRef}
            src={`${playerUrl}/${video.id}`}
            title={t.title || 'Elai.io video player'}
            frameBorder="0"
            allowFullScreen
            scrolling="no"
          ></iframe>
          {!hideElaiMentions && (
            <a style={{ margin: 5 }} href="https://elai.io" target="_blank" rel="noreferrer">
              Want to create such a video yourself?
            </a>
          )}
        </Card>
      </Content>
    </Layout>
  )
}
