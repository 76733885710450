import { CloseOutlined, SlidersOutlined } from '@ant-design/icons'

import Icon from '../../../../../components/Icon'

export const PLUS_ICON = <Icon name="plus" className="plus-icon" />
export const MIC_ICON = <Icon name="mic" className="mic-icon" />
export const SLIDER_OUTLINED = <SlidersOutlined />
export const CLOSE_ICON = (
  <span>
    <CloseOutlined style={{ fontSize: '13px', marginRight: '1px', marginTop: '-1px' }} />
    <span>Close</span>
  </span>
)

export const flagsUrl = 'https://d3u63mhbhkevz8.cloudfront.net/flags/'
