import { SearchOutlined, EditOutlined, LoginOutlined, PlusOutlined } from '@ant-design/icons'

import Icon from '../../../components/Icon'

export const zeroPadding = { padding: 0 }

export const academyIcons = {
  plusOutlined: <PlusOutlined />,
  editOutlined: <EditOutlined />,
  searchOutlined: <SearchOutlined />,
  delete: <Icon name="delete" />,
  loginOutlined: <LoginOutlined />,
}

export const COURSE_URL = 'admin/academy/courses'
export const LESSONS_URL = 'admin/academy/lessons'

export const COURSE_EDITOR_PLUGINS = ['lists', 'table', 'fullscreen', 'insertdatetime', 'preview', 'link']
export const LESSON_EDITOR_PLUGINS = [
  ...COURSE_EDITOR_PLUGINS,
  'media',
  'anchor',
  'autolink',
  'image',
  'link',
  'searchreplace',
  'wordcount',
  'code',
  'visualblocks',
  'visualchars',
  'charmap',
]
