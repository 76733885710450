import { fabric } from 'fabric'
import { CANVAS_WIDTH, CANVAS_HEIGHT } from './constans'

const lines = {
  top: null,
  left: null,
  right: null,
  bottom: null,
  centerV: null,
  centerH: null,
}

let canvas

export const removeLines = () => {
  if (canvas)
    canvas.forEachObject((obj) => {
      if (obj.type === 'line') canvas.remove(obj)
    })
}

const drawLine = (posLine, posT, posL, lineCoords) => {
  const ln = new fabric.Line(lineCoords, {
    left: posL,
    top: posT,
    stroke: 'rgb(178, 207, 255)',
  })
  lines[posLine] = ln
  canvas.add(ln)
}

const snapObject = (currentObject, targetPos, snappedPositionValue, pos, posLine, matches) => {
  drawLines(posLine, targetPos[posLine])
  matches[posLine] = true
  currentObject.set(pos, snappedPositionValue)
}

const drawLines = (side, pos) => {
  if (lines[side]) return
  switch (side) {
    case 'top':
      drawLine('top', pos, 0, [canvas.get('width'), 0, 0, 0])
      break
    case 'left':
      drawLine('left', 0, pos, [0, canvas.get('height'), 0, 0])
      break
    case 'right':
      drawLine('right', 0, pos, [0, canvas.get('height'), 0, 0])
      break
    case 'bottom':
      drawLine('bottom', pos, 0, [canvas.get('width'), 0, 0, 0])
      break
    case 'centerV':
      drawLine('centerV', 0, pos, [0, canvas.get('height'), 0, 0])
      break
    case 'centerH':
      drawLine('centerH', pos, 0, [canvas.get('width'), 0, 0, 0])
      break
    default:
      return
  }
}

export const snapObjects = (e, c, isShiftPressed) => {
  if (isShiftPressed) return
  canvas = c
  const minDistanceToSnap = 4
  const obj = e.target

  const matches = {
    top: false,
    left: false,
    right: false,
    bottom: false,
    centerV: false,
    centerH: false,
  }

  const curPos = {
    top: parseInt(obj.top),
    left: parseInt(obj.left),
    right: parseInt(obj.left + obj.width * obj.scaleX),
    bottom: parseInt(obj.top + obj.height * obj.scaleY),
    centerV: parseInt(obj.left + (obj.width * obj.scaleX) / 2),
    centerH: parseInt(obj.top + (obj.height * obj.scaleY) / 2),
  }

  if (Math.abs(CANVAS_WIDTH / 2 - curPos.centerV) < minDistanceToSnap) {
    drawLines('centerV', CANVAS_WIDTH / 2)
    matches.centerV = true
    obj.set('left', (CANVAS_WIDTH - obj.width * obj.scaleX) / 2)
  }
  if (Math.abs(CANVAS_HEIGHT / 2 - curPos.centerH) < minDistanceToSnap) {
    drawLines('centerH', CANVAS_HEIGHT / 2)
    matches.centerH = true
    obj.set('top', (CANVAS_HEIGHT - obj.height * obj.scaleY) / 2)
  }

  const activeObject = canvas.getActiveObject()

  canvas.forEachObject((targ) => {
    if (targ === activeObject || targ.type === 'line') return

    const targetPos = {
      top: parseInt(targ.top),
      left: parseInt(targ.left),
      right: parseInt(targ.left + targ.width * targ.scaleX),
      bottom: parseInt(targ.top + targ.height * targ.scaleY),
      centerV: parseInt(targ.left + (targ.width * targ.scaleX) / 2),
      centerH: parseInt(targ.top + (targ.height * targ.scaleY) / 2),
    }

    if (Math.abs(targetPos.top - curPos.top) < minDistanceToSnap) {
      const snappedPositionValue = targetPos.top
      snapObject(obj, targetPos, snappedPositionValue, 'top', 'top', matches)
    }
    if (Math.abs(targetPos.left - curPos.left) < minDistanceToSnap) {
      const snappedPositionValue = targetPos.left
      snapObject(obj, targetPos, snappedPositionValue, 'left', 'left', matches)
    }
    if (Math.abs(targetPos.right - curPos.right) < minDistanceToSnap) {
      const snappedPositionValue = targetPos.right - obj.width * obj.scaleX
      snapObject(obj, targetPos, snappedPositionValue, 'left', 'right', matches)
    }
    if (Math.abs(targetPos.bottom - curPos.bottom) < minDistanceToSnap) {
      const snappedPositionValue = targetPos.bottom - obj.height * obj.scaleY
      snapObject(obj, targetPos, snappedPositionValue, 'top', 'bottom', matches)
    }
    if (Math.abs(targetPos.centerV - curPos.centerV) < minDistanceToSnap) {
      const snappedPositionValue = targetPos.left - (obj.width * obj.scaleX - targ.width * targ.scaleX) / 2
      snapObject(obj, targetPos, snappedPositionValue, 'left', 'centerV', matches)
    }
    if (Math.abs(targetPos.centerH - curPos.centerH) < minDistanceToSnap) {
      const snappedPositionValue = targetPos.top - (obj.height * obj.scaleY - targ.height * targ.scaleY) / 2
      snapObject(obj, targetPos, snappedPositionValue, 'top', 'centerH', matches)
    }
  })

  for (let j in matches) {
    let m = matches[j]
    let line = lines[j]
    if (!m && line) {
      canvas.remove(line)
      lines[j] = null
    }
  }

  canvas.renderAll()
}
