import { Button, Popover, Tooltip } from 'antd'
import { useCallback, useEffect, useRef, useState } from 'react'
import useClickOutside from '../../../../../hooks/useClickOutside'
import Icon from '../../../../../components/Icon'

const sortInteractionData = (interactionsData) =>
  interactionsData.sort((a, b) => {
    if (a.type === 'slide' && a.slide !== 'end' && b.type === 'slide' && b.slide !== 'end') {
      return a.slideIndex - b.slideIndex
    }
    if (a.slide === 'end' && b.slide !== 'end') {
      return 1
    }
    if (b.slide === 'end' && a.slide !== 'end') {
      return -1
    }
    if (a.type === 'link' && b.type !== 'link') {
      if (b.slide === 'end') {
        return -1
      }
      return 1
    }
    if (b.type === 'link' && a.type !== 'link') {
      if (a.slide === 'end') {
        return 1
      }
      return -1
    }
    return 0
  })

const SlideNumber = ({ slides, slideIndex, updateActiveSlide }) => {
  const [interactions, setInteractions] = useState([])
  const [isOpenInteractionsPopover, setIsOpenInteractionsPopover] = useState(false)
  const interactionsPopoverContentRef = useRef(null)
  const interactivityButtonRef = useRef(null)

  const slide = slides[slideIndex]

  const openInteractionsPopover = () => {
    setIsOpenInteractionsPopover(true)
  }

  const closeInteractionsPopover = () => {
    setIsOpenInteractionsPopover(false)
  }

  const onClickSlideButton = (interaction) => {
    if (interaction.slide !== 'end') updateActiveSlide(interaction.slideIndex)
    closeInteractionsPopover()
  }

  const pushInteractionToData = useCallback(
    (interaction, data) => {
      if (interaction.type === 'slide') {
        const pushedSlideIndex = data.findIndex((i) => i.slide === interaction.slide)
        if (pushedSlideIndex !== -1) return
        const slideIndex = slides.findIndex((slide) => slide.id === interaction.slide)
        if (slideIndex === -1 && interaction.slide !== 'end') return
        data.push({ ...interaction, slideIndex })
      } else {
        data.push(interaction)
      }
    },
    [slides, slides.length],
  )

  const getQuestionInteractions = useCallback(
    (questionObject, interactionsData) => {
      const singleQuestionInteraction = questionObject.onCorrect?.slide || questionObject.onWrong?.slide
      if (questionObject.onCorrect?.slide && questionObject.onWrong?.slide) {
        pushInteractionToData({ type: 'slide', slide: questionObject.onCorrect.slide }, interactionsData)
        pushInteractionToData({ type: 'slide', slide: questionObject.onWrong.slide }, interactionsData)
      } else if (singleQuestionInteraction) {
        const currentSlideIndex = slides.findIndex((s) => s.id === slide.id)
        const nextSlideId = slides[currentSlideIndex + 1]?.id

        if (singleQuestionInteraction === nextSlideId) {
          // if there is at least one interaction and it's the next slide, save it to interactions
          pushInteractionToData({ type: 'slide', slide: nextSlideId }, interactionsData)
        } else {
          // if there is at least one interaction and it's not the next slide, save it to interactions
          pushInteractionToData({ type: 'slide', slide: singleQuestionInteraction }, interactionsData)

          const pushedRelatedInteraction = interactionsData.some(
            (interaction) => interaction.slide === singleQuestionInteraction,
          )
          if (!pushedRelatedInteraction) return

          // if there is at least one interaction and it's not the next slide, save 'next slide' interaction or 'end video' interaction
          if (nextSlideId) pushInteractionToData({ type: 'slide', slide: nextSlideId }, interactionsData)
          else pushInteractionToData({ type: 'slide', slide: 'end' }, interactionsData)
        }
      }
    },
    [slides.length, pushInteractionToData],
  )

  const getSlideInteractions = useCallback(
    (interactionsData) => {
      slide.canvas.objects.forEach((obj) => {
        if (
          obj.meta?.interactivity &&
          ((obj.meta.interactivity.type === 'slide' && obj.meta.interactivity.slide) ||
            (obj.meta.interactivity.type === 'link' && obj.meta.interactivity.link))
        ) {
          pushInteractionToData(obj.meta.interactivity, interactionsData)
        }
      })

      if (interactionsData.length) {
        // if there are objects with interactivity (without id of the next slide), save next slide or end of the video as another possible branch
        const objectsWithoutInteractivity = slide.canvas.objects.some(
          (obj) => obj.meta?.interactivity && obj.meta.interactivity.type === 'slide' && !obj.meta.interactivity.slide,
        )
        if (objectsWithoutInteractivity) {
          const currentSlideIndex = slides.findIndex((s) => s.id === slide.id)
          const nextSlideId = slides[currentSlideIndex + 1]?.id
          if (nextSlideId) pushInteractionToData({ type: 'slide', slide: nextSlideId }, interactionsData)
          else pushInteractionToData({ type: 'slide', slide: 'end' }, interactionsData)
        }
      } else if (slide.interactivity?.nextSlide) {
        // if there aren't objects with interactivity, save selected next slide as possible branch
        pushInteractionToData({ type: 'slide', slide: slide.interactivity.nextSlide }, interactionsData)
      }
    },
    [slide.canvas, slides.length, slide.interactivity, pushInteractionToData],
  )

  const getInteractions = useCallback(() => {
    const interactionsData = []

    const questionObject = slide.canvas.objects.find((obj) => obj.type === 'question')
    if (questionObject) {
      getQuestionInteractions(questionObject, interactionsData)
    } else {
      getSlideInteractions(interactionsData)
    }

    const sortedInteractionsData = sortInteractionData(interactionsData)

    setInteractions(sortedInteractionsData)
  }, [slide.canvas.objects, slide.interactivity, slide.id, pushInteractionToData])

  useEffect(() => {
    getInteractions()
  }, [getInteractions])

  useClickOutside([interactionsPopoverContentRef, interactivityButtonRef], closeInteractionsPopover)

  return (
    <div className="slide-number-wrapper">
      <span className="slide-number">{slideIndex + 1}</span>
      {!!interactions.length && (
        <>
          <Icon name="down_full_arrow" className="arrow-icon" />
          {interactions.length > 1 ? (
            <Popover
              open={isOpenInteractionsPopover}
              trigger="click"
              placement="rightTop"
              arrow={false}
              overlayClassName="interactions-popover"
              title={null}
              content={
                <div ref={interactionsPopoverContentRef} className="popover-content-wrapper">
                  <div className="btn-close-wrapper">
                    <Button className="btn-close" size="small" type="text" onClick={closeInteractionsPopover}>
                      <Icon name="close_simple" />
                    </Button>
                  </div>
                  <div className="popover-content">
                    <p className="description">This slide has several interactions. Choose the one to navigate to.</p>
                    <div className="interactions-buttons-container">
                      {interactions.map((interaction, index) =>
                        interaction.type === 'slide' ? (
                          <Button
                            key={index}
                            className={`btn-interaction ${interaction.slide === 'end' ? 'end' : null}`}
                            size="small"
                            onClick={() => onClickSlideButton(interaction)}
                          >
                            {interaction.slide === 'end' ? <Icon name="end_text" /> : interaction.slideIndex + 1}
                          </Button>
                        ) : (
                          <Tooltip key={index} title={interaction.link}>
                            <Button
                              href={interaction.link}
                              target="_blank"
                              rel="noreferrer"
                              className="btn-interaction link"
                              size="small"
                              onClick={closeInteractionsPopover}
                            >
                              <Icon name="link" />
                            </Button>
                          </Tooltip>
                        ),
                      )}
                    </div>
                  </div>
                </div>
              }
            >
              <Button
                ref={interactivityButtonRef}
                size="small"
                className={`btn-interactivity slide-branch ${isOpenInteractionsPopover ? 'active' : ''}`}
                onClick={openInteractionsPopover}
              >
                <Icon name="site_map" />
              </Button>
            </Popover>
          ) : interactions[0].type === 'slide' ? (
            <Button
              size="small"
              className="btn-interactivity slide"
              onClick={() => updateActiveSlide(interactions[0].slideIndex)}
            >
              <span className="interactivity-slide-number">{interactions[0].slideIndex + 1}</span>
              <Icon name="account" />
            </Button>
          ) : interactions[0].type === 'link' ? (
            <Button
              href={interactions[0].link}
              target="_blank"
              rel="noreferrer"
              size="small"
              className="btn-interactivity link"
            >
              <Icon name="link" />
            </Button>
          ) : null}
        </>
      )}
    </div>
  )
}

export { SlideNumber }
