import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Col, Row, Drawer, Select, Tag, Popconfirm, Segmented, Spin, Empty } from 'antd'
import { ArrowLeftOutlined, SearchOutlined } from '@ant-design/icons'
import { useStore } from '../../../store'
import SlidesCarousel from '../../../components/SlidesCarousel'
import Scrollbars from 'react-custom-scrollbars'
import Icon from '../../../components/Icon'
import { MAX_SLIDES_COUNT_PAID, MAX_SLIDES_COUNT_TRIAL } from '../constants'
import { isTemplateApplicable } from '../../../utils/videos'

const pageCollectionIcon = <Icon name="page_collection" />
const multipleImage = <Icon name="multiple_image" />

const templatesSegmentedOptions = [
  {
    label: (
      <span>
        {multipleImage}
        <span>My templates</span>
      </span>
    ),
    value: 'private',
  },
  {
    label: (
      <span>
        {pageCollectionIcon}
        <span>All</span>
      </span>
    ),
    value: 'all',
  },
]

const isSameFormat = (templateFormat, videoFormat) => (templateFormat || '16_9') === (videoFormat || '16_9')

const Templates = ({ isNewSlide, videoFormat, slidesCount, addSlideFromTemplate }) => {
  const { templates: storedTemplates, templateTags, fetchTemplate } = useStore((stores) => stores.videosStore)
  const { user } = useStore((stores) => stores.authStore)
  const [visible, setVisible] = useState({ isOpen: false, video: null })
  const [filters, setFilters] = useState({ privacy: 'all', tags: [] })

  const slidesLimit = user.account.status === 'trial' ? MAX_SLIDES_COUNT_TRIAL : MAX_SLIDES_COUNT_PAID

  const containsPrivateTemplates = useMemo(
    () =>
      storedTemplates?.some(
        (t) => t.template.private && isSameFormat(t.data?.format, videoFormat) && t.template.enabled,
      ),
    [storedTemplates, videoFormat],
  )

  const videos = useMemo(() => {
    return storedTemplates?.filter(
      (t) =>
        isSameFormat(t.data?.format, videoFormat) &&
        isTemplateApplicable(t) &&
        (filters.privacy === 'all' ? !t.template.private : t.template.private) &&
        (filters.tags.length ? t.template.tags?.some((tag) => filters.tags.includes(tag)) : true),
    )
  }, [storedTemplates, videoFormat, filters])

  const onTemplateClick = (v) => {
    setVisible({ isOpen: true, video: v })
  }

  const getSlideFromFullTemplate = async (template, slide) => {
    if (slide.canvas) return slide
    template = await fetchTemplate(template._id)
    setVisible((v) => ({ ...v, video: template }))
    return template.slides.find((s) => s.id === slide.id)
  }

  const handleFilterTemplates = (values) => {
    setFilters((prevState) => ({ ...prevState, tags: values }))
  }

  const onChangePrivacyFilter = (v) => setFilters((prevState) => ({ ...prevState, privacy: v }))

  const replaceCurrentSlide = async (slide) => {
    const templateSlide = await getSlideFromFullTemplate(visible.video, slide)
    addSlideFromTemplate({ template: templateSlide, replaceCurrent: true })
  }

  const createNewSlide = async (slide) => {
    const templateSlide = await getSlideFromFullTemplate(visible.video, slide)
    addSlideFromTemplate({ template: templateSlide, replaceCurrent: false })
  }

  const onClickTemplate = (slide) => {
    if (isNewSlide) createNewSlide(slide)
  }

  useEffect(() => {
    setFilters((prevState) => ({ ...prevState, privacy: containsPrivateTemplates ? 'private' : 'all' }))
  }, [containsPrivateTemplates])

  useEffect(() => {
    setVisible({ isOpen: false, video: null })
  }, [videoFormat])

  return (
    <div className="templates tab-content">
      {videos ? (
        <>
          <Select
            mode="multiple"
            style={{ width: '100%', marginBottom: 16 }}
            placeholder="Search by tags"
            suffixIcon={<SearchOutlined />}
            showArrow="true"
            onChange={handleFilterTemplates}
          >
            {templateTags.map((t) => (
              <Select.Option key={t}>{t}</Select.Option>
            ))}
          </Select>
          <Segmented
            value={filters.privacy}
            className="segmented-default"
            block
            options={templatesSegmentedOptions}
            onChange={onChangePrivacyFilter}
          />
          <div className="templates-wrapper">
            <Scrollbars className="templates-scrollbar scrollbar" style={{ minHeight: 500 }}>
              <Row gutter={[8, 8]} style={{ marginBottom: 16 }}>
                {videos.length ? (
                  videos.map((v, index) => (
                    <Col span={12} key={index}>
                      <div
                        className={`template-container ${
                          v.data?.format === '9_16' ? 'vertical' : v.data?.format === '1_1' ? 'square' : ''
                        }`}
                      >
                        <SlidesCarousel
                          collection={{
                            ...v,
                            items: v.slides.map((slide) => ({ ...slide, src: slide.screenshot })),
                          }}
                          index={index}
                          onCarouselClick={onTemplateClick}
                        />
                      </div>
                    </Col>
                  ))
                ) : (
                  <Empty
                    className="templates-empty"
                    description={
                      filters.tags.length ? (
                        <>There aren't any templates with such tags.</>
                      ) : (
                        <>
                          You don't have any{' '}
                          {!videoFormat || videoFormat === '16_9'
                            ? 'horizontal'
                            : videoFormat === '9_16'
                            ? 'vertical'
                            : 'square'}{' '}
                          templates. You can create one in{' '}
                          <Link to="/templates" target="_blank">
                            Templates
                          </Link>{' '}
                          section.
                        </>
                      )
                    }
                  />
                )}
              </Row>
            </Scrollbars>
          </div>
          <Drawer
            title={
              <p style={{ margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <span>{visible?.video?.name}</span>
              </p>
            }
            placement="right"
            width="100%"
            closeIcon={<ArrowLeftOutlined />}
            headerStyle={{ padding: 10, textAlign: 'right', borderBottom: 0 }}
            bodyStyle={{ padding: '0 10px' }}
            getContainer={false}
            onClose={() => setVisible({ isOpen: false })}
            open={visible.isOpen}
            style={{ position: 'absolute' }}
          >
            <Scrollbars className="templates-scrollbar templates-drawer-scrollbar scrollbar">
              <Row gutter={[8, 8]} className="templates-row">
                {videos &&
                  visible.video?.slides.map((slide) => (
                    <Col span={12} key={slide.id}>
                      <Popconfirm
                        placement="left"
                        overlayClassName="dark-popconfirm"
                        title={
                          <div style={{ width: '230px' }}>
                            Do you want to replace visuals of current slide (keeping speech) or create a new slide?
                          </div>
                        }
                        disabled={isNewSlide}
                        onConfirm={() => replaceCurrentSlide(slide)}
                        onCancel={() => createNewSlide(slide)}
                        okText="Replace Current"
                        cancelText="Create New"
                        cancelButtonProps={{
                          disabled: slidesCount >= slidesLimit,
                          title: slidesCount >= slidesLimit ? 'Maximum slides limit reached' : '',
                        }}
                      >
                        <div
                          className={`template-container ${
                            visible.video.data?.format === '9_16'
                              ? 'vertical'
                              : visible.video.data?.format === '1_1'
                              ? 'square'
                              : ''
                          }`}
                        >
                          <img src={slide.screenshot} onClick={() => onClickTemplate(slide)} />
                        </div>
                      </Popconfirm>
                    </Col>
                  ))}
              </Row>
              <div className="template-drawer-tags">
                {visible?.video?.template?.tags?.map((tag) => (
                  <Tag key={tag}>{tag}</Tag>
                ))}
              </div>
            </Scrollbars>
          </Drawer>
        </>
      ) : (
        <Spin />
      )}
    </div>
  )
}

export default Templates
