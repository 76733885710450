import { Button, Tooltip } from 'antd'
import Icon from '../../../../components/Icon'

const Languages = ({ canBuyVoice, onClickBuyNow }) => {
  return (
    <div className="languages-container">
      <div className="languages-content-wrapper content-wrapper">
        <div className="languages-content">
          <h2 className="section-title">
            <span>Speak 32 languages</span> with only one voice cloning
          </h2>
          <p>
            Send us a few minutes of recorded speech in one of the supported languages and we will do the rest. The
            language that you initially speak for the recording will be trained best, others might have a slight accent.
          </p>
          <Tooltip
            title="Please contact your account owner to perform this action."
            overlayStyle={{
              visibility: canBuyVoice ? 'hidden' : 'visible',
            }}
          >
            <Button
              type="link"
              disabled={!canBuyVoice}
              size="large"
              className="btn-get-started"
              onClick={onClickBuyNow}
            >
              Get started <Icon name="right_full_arrow" />
            </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default Languages
