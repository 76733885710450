import { fabric } from 'fabric'

const Logo = fabric.util.createClass(fabric.Image, {
  initialize: function (_, _options) {
    this.type = 'logo'
  },
})

Logo.fromObject = fabric.Image.fromObject

Logo.async = true

export default Logo
