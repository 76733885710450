import { Tabs } from 'antd'

import HorizontalScroll from '../../../../../components/HorizontalScroll/horizontalScroll'

import { useElementsTabsState } from './useElementsTabsState'

export const ElementsTabs = (props) => {
  const {
    visible,
    video,
    slideCanvas,
    setActiveObjectModifier,
    setIsOpenGradient,
    setBackArrow,
    backArrow,
    setShouldUpdateUploads,
  } = props

  const { activeTab, handleTabClick, tabs } = useElementsTabsState({
    video,
    backArrow,
    slideCanvas,
    setBackArrow,
    setIsOpenGradient,
    setActiveObjectModifier,
    setShouldUpdateUploads,
  })

  const renderTabBar = (props) => {
    const { panes, activeKey, onTabClick } = props
    const items = panes.map((pane) => (
      <div
        key={pane.props.tabKey}
        className={`tab-item ${activeKey === pane.props.tabKey ? 'active' : ''}`}
        onClick={(e) => onTabClick(pane.props.tabKey, e)}
      >
        {pane.props.tab}
      </div>
    ))
    return <HorizontalScroll items={items} />
  }

  const style = { display: visible ? 'flex' : 'none' }

  return (
    <Tabs
      type="card"
      items={tabs}
      style={style}
      activeKey={activeTab}
      className="elements-tabs"
      onTabClick={handleTabClick}
      renderTabBar={renderTabBar}
    />
  )
}
