import { Layout, Spin, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { request } from '../../utils/api'
import PageHeader from '../../components/PageHeader/pageHeader'
import { useStore } from '../../store'
import Icon from '../../components/Icon'

const columns = [
  {
    title: 'Who Changed',
    dataIndex: 'userId',
    render: (u) => u.name,
  },
  {
    title: 'Email',
    dataIndex: 'userId',
    render: (u) => u.email,
  },
  {
    title: 'Started At',
    dataIndex: 'createdAt',
    render: (d) => d && new Date(d).toLocaleString(),
  },
  {
    title: 'Last Edited',
    dataIndex: 'updatedAt',
    render: (d) => d && new Date(d).toLocaleString(),
  },
  {
    title: 'Versions',
    dataIndex: 'initVersion',
    render: (t, r) => `${r?.initVersion} - ${r?.lastVersion}`,
  },
  {
    title: 'Slides',
    dataIndex: 'initSlidesCount',
    render: (t, r) => (r?.lastSlidesCount ? `${r.initSlidesCount} - ${r.lastSlidesCount}` : ''),
  },
  {
    title: 'IP address',
    dataIndex: 'ip',
  },
]

const VideoHistory = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { id } = params
  const [data, setData] = useState()
  const { setHeader } = useStore((stores) => stores.domStore)

  const fetchHistory = async () => {
    const result = await request({ method: 'get', url: `/videos//history/${id}` })
    setData(result)
  }

  useEffect(() => {
    setHeader({
      title: 'Videos',
      icon: <Icon name="video_camera" />,
      link: '/videos',
      extra: [''],
    })
    fetchHistory()
  }, [])

  return (
    <Layout.Content className="users-content">
      {data?.name ? (
        <PageHeader
          title={`${data?.name} - Edit History`}
          onBack={() => navigate(-1)}
          style={{ maxWidth: 1100, margin: 'auto' }}
        />
      ) : (
        <div style={{ maxWidth: 1100, margin: 'auto', height: 72, padding: 16 }}>
          <Spin />
        </div>
      )}
      <Table
        sticky
        scroll={{ x: 900 }}
        dataSource={data?.history}
        columns={columns}
        rowKey="_id"
        pagination={false}
        style={{ maxWidth: '1100px', margin: '0 auto' }}
        className="users-table"
      />
    </Layout.Content>
  )
}

export default VideoHistory
