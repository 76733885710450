import { useMemo, useState } from 'react'
import { Popover, Select, Button, Tooltip } from 'antd'
import { genderFilterOptions, ageFilterOptions, twoVariantFilterOptions } from './constants'
import { initialAvatarsFiltersState } from '../../hooks/useFilterAvatars'
import { removeDuplicatesFromArray } from '../../utils/helpers'
import Icon from '../Icon'
import './avatarsFilterPopover.less'
import { TagOptionFilter } from './tagOptionFilter'
import Scrollbars from 'react-custom-scrollbars'

export const AvatarsFilterPopover = ({
  children,
  placement,
  avatars,
  filters,
  setFilters,
  isOpenFiltersPopup,
  setIsOpenFiltersPopup,
  onApplyFilters,
  getPopupContainer,
}) => {
  const [gender, setGender] = useState(initialAvatarsFiltersState.gender)
  const [ages, setAges] = useState(initialAvatarsFiltersState.ages)
  const [ethnicities, setEthnicities] = useState(initialAvatarsFiltersState.ethnicities)
  const [looks, setLooks] = useState(initialAvatarsFiltersState.looks)
  const [premium, setPremium] = useState(initialAvatarsFiltersState.premium)
  const [fiveMinDuration, setFiveMinDuration] = useState(initialAvatarsFiltersState.fiveMinDuration)

  const handleOpenChange = (newOpen) => {
    setIsOpenFiltersPopup(newOpen)
    if (!newOpen) resetFilters()
  }

  const handleChangeGenderFilter = (value) => {
    setGender(value)
  }

  const handleChangePremiumFilter = (value) => {
    setPremium(value)
  }

  const handleChangeDurationFilter = (value) => {
    setFiveMinDuration(value)
  }

  const applyFilters = () => {
    setFilters({ gender, ages, ethnicities, looks, premium, fiveMinDuration })
    setIsOpenFiltersPopup(false)
    onApplyFilters?.()
  }

  const resetFilters = () => {
    const { gender, ages, ethnicities, looks, premium, fiveMinDuration } = filters
    setGender(gender)
    setAges(ages)
    setEthnicities(ethnicities)
    setLooks(looks)
    setPremium(premium)
    setFiveMinDuration(fiveMinDuration)
  }

  const avatarsFiltersData = useMemo(() => {
    let looks = []
    let ethnicities = []
    avatars?.forEach((avatar) => {
      if (avatar.variants) {
        avatar.variants.forEach((variant) => {
          looks.push(variant.name)
        })
      }
      if (avatar.ethnicity) ethnicities.push(avatar.ethnicity)
    })
    looks = removeDuplicatesFromArray(looks)
    ethnicities = removeDuplicatesFromArray(ethnicities)
    return { looks, ethnicities }
  }, [avatars])

  return (
    <Popover
      trigger="click"
      placement={placement}
      arrow={false}
      overlayClassName="avatars-filter-popover"
      open={isOpenFiltersPopup}
      onOpenChange={handleOpenChange}
      content={
        <div className="popover-content">
          <Scrollbars className="scrollbar">
            <div className="filters-wrapper">
              <div>
                <h4 className="filter-title">Gender</h4>
                <Select
                  value={gender}
                  options={genderFilterOptions}
                  suffixIcon={<Icon name="down_arrow" />}
                  className="filter-select"
                  block="true"
                  onChange={handleChangeGenderFilter}
                />
              </div>
              <TagOptionFilter options={ageFilterOptions} title="Age" filterValue={ages} setFilterValue={setAges} />
              <TagOptionFilter
                options={avatarsFiltersData.ethnicities}
                title="Ethnicity"
                filterValue={ethnicities}
                setFilterValue={setEthnicities}
              />
              <TagOptionFilter
                options={avatarsFiltersData.looks}
                title="Look"
                filterValue={looks}
                setFilterValue={setLooks}
              />
              <div>
                <div className="title-info-wrapper filter-title">
                  <h4 className="controls-title-wrapped">Show premium</h4>
                  <Tooltip title="Avatars that are available only in the Enterprise and Free plans.">
                    <Icon name="info" />
                  </Tooltip>
                </div>
                <Select
                  value={premium}
                  options={twoVariantFilterOptions}
                  suffixIcon={<Icon name="down_arrow" />}
                  className="filter-select"
                  block="true"
                  onChange={handleChangePremiumFilter}
                />
              </div>
              <div>
                <div className="title-info-wrapper filter-title">
                  <h4 className="controls-title-wrapped">Only 5-min Duration</h4>
                  <Tooltip title="Show only avatars that support slide duration up to 5 minutes.">
                    <Icon name="info" />
                  </Tooltip>
                </div>
                <Select
                  value={fiveMinDuration}
                  options={twoVariantFilterOptions}
                  suffixIcon={<Icon name="down_arrow" />}
                  className="filter-select"
                  block="true"
                  onChange={handleChangeDurationFilter}
                />
              </div>
            </div>
          </Scrollbars>
          <Button type="primary" className="btn-apply-filters" block onClick={applyFilters}>
            Apply Filters
          </Button>
        </div>
      }
    >
      {children}
    </Popover>
  )
}
