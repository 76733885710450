import { Popconfirm } from 'antd'

const LeavingPopconfirm = ({ children, isLeavingPopconfirmOpen, setIsLeavingPopconfirmOpen, onConfirm }) => {
  const handleOpenChangePopconfirm = (open) => setIsLeavingPopconfirmOpen(open)

  const handleClosePopconfirm = () => setIsLeavingPopconfirmOpen(false)

  return (
    <Popconfirm
      title="Leave the page?"
      description={
        <span style={{ width: 300, display: 'block' }}>
          You have unsaved changes. Do you really want to leave without saving? Your recent changes might be lost.
        </span>
      }
      placement="bottomLeft"
      open={isLeavingPopconfirmOpen}
      onConfirm={onConfirm}
      onCancel={handleClosePopconfirm}
      onOpenChange={handleOpenChangePopconfirm}
      okText="Confirm"
      cancelText="Cancel"
    >
      {children}
    </Popconfirm>
  )
}

export { LeavingPopconfirm }
