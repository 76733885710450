import { useState } from 'react'
import { Progress, Spin } from 'antd'

import { useStore } from '../../../../../store'
import { request } from '../../../../../utils/api'

import videoIcon from '../../../../../assets/images/video.svg'

const style = {
  progress: { margin: '35% 5%', width: '90%' },
  convertStatus: { margin: '0 auto', fontSize: 12, color: '#999' },
  spanCard: { display: 'block', height: 95 },
  cardLink: { position: 'relative', top: 0, left: 0 },
  videoIcon: { display: 'block', position: 'absolute', top: 0, left: 10, width: 10, height: 10 },
  svgIcon: { position: 'absolute', top: -17, left: 8, fontWeight: 700, fontSize: 12 },
}

export const UploadItem = (props) => {
  const { file, progress, isFrames, setFile, handleRemove, handlePreview, setIsOpenPreview } = props

  const isAdmin = useStore((stores) => stores.authStore.user.isAdmin)

  const [loading, setLoading] = useState(false)

  const removeItem = async () => {
    setLoading(true)
    const { used } = await request({ method: 'POST', url: '/uploads/checkUploadUsage', data: { url: file.url } })
    handleRemove(file, used)
    setLoading(false)
  }

  const handlePreviewClick = () => {
    if (isFrames || (file.status && file.status !== 'ready')) return
    setIsOpenPreview(true)
    handlePreview(file)
  }

  const handlePlusClick = (e) => {
    e.stopPropagation()
    setFile?.(file)
  }

  const handleDeleteClick = (e) => {
    e.stopPropagation()
    removeItem()
  }

  return (
    <div
      className="ant-upload-list-item ant-upload-list-item-list-type-picture-card uploadItemImg"
      onClick={handlePreviewClick}
    >
      {progress?.isOpen && file.status === 'uploading' ? (
        <Progress showInfo={false} percent={progress.percent} strokeWidth={4} style={style.progress} />
      ) : file.status === 'converting' ? (
        <div style={style.convertStatus}>Converting...</div>
      ) : (
        <>
          <div className="ant-upload-list-item-info">
            <span className="ant-upload-span" style={style.spanCard}>
              <a
                className="ant-upload-list-item-thumbnail"
                href={file.url}
                target="_blank"
                rel="noopener noreferrer  "
                style={style.cardLink}
              >
                {file.type === 'video' && <img src={videoIcon} style={style.videoIcon} />}
                {file.type === 'gif' && <span style={style.svgIcon}>GIF</span>}
                {file.name?.split('.')[1] === 'svg' && file.type !== 'frame' && <span style={style.svgIcon}>SVG</span>}
                <img
                  src={file.thumbnail}
                  alt={file.name}
                  crossOrigin="anonymous"
                  className="ant-upload-list-item-image"
                />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="ant-upload-list-item-name"
                title={file.name}
                href={file.url}
              >
                {file.url}
              </a>
            </span>
          </div>
          <span className="ant-upload-list-item-actions">
            <Spin spinning={loading}>
              <span role="img" aria-label="plus" className="anticon anticon-eye">
                <svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="plus"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                  onClick={handlePlusClick}
                >
                  <path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"></path>
                  <path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z"></path>
                </svg>
              </span>
              {!isFrames && (
                <span role="img" aria-label="eye" className="anticon anticon-eye">
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="eye"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"></path>
                  </svg>
                </span>
              )}
              {(!isFrames || isAdmin) && (
                <span role="img" aria-label="delete" tabIndex="-1" className="anticon anticon-delete">
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="delete"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                    onClick={handleDeleteClick}
                  >
                    <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"></path>
                  </svg>
                </span>
              )}
              {file.type !== 'frame' && <span className="ant-upload-list-item-name">{file.name}</span>}
            </Spin>
          </span>
        </>
      )}
    </div>
  )
}
