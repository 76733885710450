import { Tooltip, Modal } from 'antd'
import Icon from '../../../../components/Icon'
import { useBrandkitState } from './useBrandkitState'
import { BrandkitControls } from '../../../../components/BrandkitControls/brandkitControls'

export const Brandkit = (props) => {
  const { account, isBrandkitSelected, setIsBrandkitSelected, refreshSession } = props

  const { onClick, handleClose, isModalOpen, isBrandkitExists, isBrandkitAvaliable } = useBrandkitState({
    account,
    setIsBrandkitSelected,
    refreshSession,
  })

  return (
    <>
      <div className="brand-kit-modal-wrapper">
        <div className="brand-kit-title">
          <span className="brand-kit-text brandbook-info-icon">Apply Brandbook</span>
          <Tooltip title="You can apply your Brandbook to this template. Colors in the template will be replaced with those from your Brandbook. The logo will be added to the first slide and will replace any existing logos in the template. Click on the Brandbook below to apply.">
            <Icon name="info" className="brandbook-info-icon" />
          </Tooltip>
        </div>
        <div
          className="brandkit-modal-content"
          onClick={onClick}
          style={{
            border: isBrandkitSelected ? '1px solid #4868ff' : '1px dashed #d9d9d9',
            cursor: isBrandkitAvaliable ? 'pointer' : 'not-allowed',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {isBrandkitAvaliable ? (
            isBrandkitExists ? (
              <>
                {isBrandkitSelected && <Icon name="blue_save" className="brand-kit-checked" />}
                {Object.keys(account.logo).length > 0 && (
                  <img
                    crossOrigin="anonymous"
                    src={account.logo.thumbnail}
                    style={{
                      maxWidth: '40px',
                      maxHeight: '14px',
                      width: '100%',
                      height: '100%',
                      marginRight: '2px',
                      objectFit: 'scale-down',
                    }}
                  />
                )}
                {account.corporateColors.length > 0 &&
                  account.corporateColors.map((color) => (
                    <div key={color} className="brandkit-color" style={{ backgroundColor: color }} />
                  ))}
              </>
            ) : (
              <div className="add-brand-kit">
                <Icon name="plus" className="brandbook-info-icon" style={{ marginRight: '8px' }} />
                Add Brandbook
              </div>
            )
          ) : (
            <Tooltip title="Upgrade to Advanced or Enterprise plan" style={{ cursor: 'not-allowed' }}>
              <div className="add-brand-kit">
                <Icon name="plus" className="brandbook-info-icon" style={{ marginRight: '8px' }} />
                Add Brandbook
              </div>
            </Tooltip>
          )}
        </div>
      </div>
      <Modal
        title="Create Brandbook"
        centered
        forceRender={true}
        destroyOnClose={true}
        open={isModalOpen}
        width={400}
        bodyStyle={{ padding: 15, paddingBottom: 0, height: 560 }}
        footer={null}
        onCancel={handleClose}
      >
        <BrandkitControls showApplyButton={true} onApplyBrandkit={handleClose} />
      </Modal>
    </>
  )
}
