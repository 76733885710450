export const backgrounds = [
  {
    id: 'photo1',
    thumbnail: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/photo1_thumbnail.jpg',
    url: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/photo1.jpg',
  },
  {
    id: 'photo2',
    thumbnail: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/photo2_thumbnail.jpg',
    url: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/photo2.jpg',
  },
  {
    id: 'photo3',
    thumbnail: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/photo3_thumbnail.jpg',
    url: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/photo3.jpg',
  },
  {
    id: 'photo4',
    thumbnail: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/photo4_thumbnail.jpg',
    url: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/photo4.jpg',
  },
  {
    id: 'photo5',
    thumbnail: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/photo5_thumbnail.jpg',
    url: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/photo5.jpg',
  },
  {
    id: 'photo6',
    thumbnail: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/photo6_thumbnail.jpg',
    url: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/photo6.jpg',
  },
  {
    id: 'photo7',
    thumbnail: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/photo7_thumbnail.jpg',
    url: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/photo7.jpg',
  },
  {
    id: 'photo8',
    thumbnail: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/photo8_thumbnail.jpg',
    url: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/photo8.jpg',
  },
  {
    id: 'photo9',
    thumbnail: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/photo9_thumbnail.jpg',
    url: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/photo9.jpg',
  },
  {
    id: 'photo10',
    thumbnail: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/photo10_thumbnail.jpg',
    url: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/photo10.jpg',
  },
  {
    id: 'photo11',
    thumbnail: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/photo11_thumbnail.jpg',
    url: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/photo11.jpg',
  },
  {
    id: 'abstract1',
    thumbnail: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/abstract1_thumbnail.jpg',
    url: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/abstract1.jpg',
  },
  {
    id: 'abstract2',
    thumbnail: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/abstract2_thumbnail.jpg',
    url: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/abstract2.jpg',
  },
  {
    id: 'abstract3',
    thumbnail: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/abstract3_thumbnail.jpg',
    url: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/abstract3.jpg',
  },
  {
    id: 'abstract4',
    thumbnail: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/abstract4_thumbnail.jpg',
    url: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/abstract4.jpg',
  },
  {
    id: 'gradient1',
    thumbnail: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/gradient1_thumbnail.jpg',
    url: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/gradient1.jpg',
  },
  {
    id: 'gradient2',
    thumbnail: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/gradient2_thumbnail.jpg',
    url: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/gradient2.jpg',
  },
  {
    id: 'gradient3',
    thumbnail: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/gradient3_thumbnail.jpg',
    url: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/gradient3.jpg',
  },
  {
    id: 'shape1',
    thumbnail: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/shape1_thumbnail.jpg',
    url: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/shape1.jpg',
  },
  {
    id: 'shape2',
    thumbnail: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/shape2_thumbnail.jpg',
    url: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/shape2.jpg',
  },
  {
    id: 'shape3',
    thumbnail: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/shape3_thumbnail.jpg',
    url: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/shape3.jpg',
  },
  {
    id: 'shape4',
    thumbnail: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/shape4_thumbnail.jpg',
    url: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/shape4.jpg',
  },
  {
    id: 'shape5',
    thumbnail: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/shape5_thumbnail.jpg',
    url: 'https://d3u63mhbhkevz8.cloudfront.net/backgrounds/shape5.jpg',
  },
]
