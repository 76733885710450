import BundledEditor from './bundledEditor'

export const Editor = (props) => {
  const { editorRef, value, plugins } = props
  return (
    <div>
      <BundledEditor
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={value}
        init={{
          height: 500,
          menubar: false,
          plugins: plugins,
          toolbar:
            'alignright alignjustify alignleft aligncenter bullist numlist | blocks | forecolor bold italic | outdent indent | undo redo | code ' +
            'fullscreen ' +
            'insertdatetime ' +
            'charmap ' +
            'removeformat ' +
            'media image ' +
            'link ' +
            'visualchars visualblocks' +
            'preview',

          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }',
          media_live_embeds: true,
          extended_valid_elements: 'iframe[src|width|height|name|align]',
          iframe_template_callback: (data) =>
            `<iframe title="${data.title}" width="${data.width}" height="${data.height}" src="${data.source}" sandbox="allow-scripts"></iframe>`,
        }}
      />
    </div>
  )
}
