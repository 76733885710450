import { useState } from 'react'

const NAV_HISTORY_LENGTH = 150

const navigationStore = () => {
  const [navigationHistory, setNavigationHistory] = useState([])

  const addLocationToNavHistory = (location) => {
    if (navigationHistory[navigationHistory.length - 1] === location) return
    const updatedHistory = [...navigationHistory, location]
    if (navigationHistory.length > NAV_HISTORY_LENGTH) updatedHistory.shift()
    setNavigationHistory(updatedHistory)
  }

  return {
    navigationHistory,
    addLocationToNavHistory,
  }
}

export { navigationStore }
