import { fabric } from 'fabric'
import lottie from 'lottie-web'

lottie.setQuality('low')

const Lottie = fabric.util.createClass(fabric.Image, {
  type: 'lottie',
  lockRotation: true,
  lockSkewingX: true,
  lockSkewingY: true,
  srcFromAttribute: false,

  initialize: function (path, options) {
    if (!options.width) options.width = 480
    if (!options.height) options.height = 480

    this.path = path
    this.tmpCanvasEl = fabric.util.createCanvasElement()
    this.tmpCanvasEl.width = options.width
    this.tmpCanvasEl.height = options.height

    this.callSuper('initialize', this.tmpCanvasEl, options)

    this.setControlsVisibility({ mb: false, ml: false, mr: false, mt: false, mtr: false })
  },

  load: async function () {
    return new Promise((resolve, reject) => {
      this.lottieItem = lottie.loadAnimation({
        renderer: 'canvas',
        loop: true,
        autoplay: false,
        path: this.path,
        rendererSettings: {
          context: this.tmpCanvasEl.getContext('2d'),
          preserveAspectRatio: 'xMidYMid meet',
        },
      })
      if (!this.lottieItem.path) reject(new Error('Lottie was not loaded, please check that the URL is correct'))

      this.lottieItem.addEventListener('DOMLoaded', () => {
        resolve()
      })
      this.lottieItem.addEventListener('data_failed', () => {
        reject(new Error('Lottie was not loaded, some animation data is failed'))
      })

      this.lottieItem.addEventListener('enterFrame', () => {
        if (this.canvas?.getObjects().length) this.canvas.requestRenderAll()
      })
    })
  },

  play: function () {
    this.lottieItem?.play()
  },
  stop: function () {
    this.lottieItem?.pause()
  },
  getSrc: function () {
    return this.path
  },
})

Lottie.fromObject = function (_object, callback) {
  const object = fabric.util.object.clone(_object)
  fabric.Image.prototype._initFilters.call(object, object.filters, function (filters) {
    object.filters = filters || []
    fabric.Image.prototype._initFilters.call(object, [object.resizeFilter], function (resizeFilters) {
      object.resizeFilter = resizeFilters[0]
      fabric.util.enlivenObjects([object.clipPath], function (enlivedProps) {
        object.clipPath = enlivedProps[0]
        const lottie = new fabric.Lottie(object.src, object)
        lottie
          .load()
          .then(() => {
            callback(lottie, false)
            lottie.play()
          })
          .catch(() => callback(null, true))
      })
    })
  })
}

Lottie.async = true

export default Lottie
