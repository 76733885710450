import { Button, Tooltip } from 'antd'
import Icon from '../Icon'
import { BRANDBOOK_TOOLTIP } from './constants'
import { UpdateLogo } from './components/updateLogo'
import { UpdateColors } from './components/updateColors'
import { UpdateFonts } from './components/updateFonts'
import Scrollbars from 'react-custom-scrollbars'

const BrandkitControls = ({ onChooseFile, updateVideo, onApplyBrandkit, showApplyButton = false }) => {
  return (
    <Scrollbars className="scrollbar">
      <div>
        <div className="brandbook-header-block">
          <p className="brandbook-tab-header">Logo</p>
          <Tooltip title={BRANDBOOK_TOOLTIP.brandLogo}>
            <Icon name="info" className="brandbook-info-icon" />
          </Tooltip>
        </div>
        <UpdateLogo onChooseFile={onChooseFile} />
      </div>

      <div className="brandbook-segment" style={{ marginTop: '10px' }}>
        <div className="brandbook-header-block">
          <p className="brandbook-tab-header">Colors</p>
          <Tooltip title={BRANDBOOK_TOOLTIP.brandColors}>
            <Icon name="info" className="brandbook-info-icon" />
          </Tooltip>
        </div>
        <UpdateColors />
      </div>

      <div className="brandbook-segment">
        <div className="brandbook-header-block">
          <p className="brandbook-tab-header">Fonts</p>
          <Tooltip title={BRANDBOOK_TOOLTIP.brandFonts}>
            <Icon name="info" className="brandbook-info-icon" />
          </Tooltip>
        </div>
        <UpdateFonts updateVideo={updateVideo} />
      </div>

      {showApplyButton && (
        <Button type="primary" style={{ marginTop: 8, float: 'right', width: 150 }} onClick={onApplyBrandkit}>
          Save Brandbook
        </Button>
      )}
    </Scrollbars>
  )
}

export { BrandkitControls }
