import { Modal, Button } from 'antd'

import { HeaderRow } from './components/headerRow'
import { FilterPanel } from './components/filterPanel'
import OpenedTemplate from './components/openedTemplate'
import { TemplatesList } from './components/templatesList'
import { CreateStoryVideo } from './components/createStory'
import { CreateVideoFromPresentation } from './components/createVideoFromPresentation/createVideoFromPresentation'

import useCreateVideoModal from './useCreateVideoModal'

import './createVideo.less'
import { CATEGORIES, STYLES } from './constants'
import create_video_icon from '../../assets/images/video_creation_modal/create-video-icon.svg'

export const CreateVideoModal = (props) => {
  const { isNewVideoModalOpen, setIsNewVideoModalOpen, defaultOpenedTemplate } = props

  const {
    pdfFile,
    setPdfFile,
    handleClose,
    isGenerationProgressVisible,
    htmlToVideo,
    templateTags,
    storyOption,
    setStoryOption,
    pastedContent,
    setPastedContent,
    setIsGenerationProgressVisible,
    setHtmlToVideo,
    openedTemplate,
    isVideoCreating,
    templatesFilter,
    templatesListData,
    setOpenedTemplate,
    searchedTemplates,
    setTemplatesFilter,
    gptValue,
    setGPTValue,
    generationProgress,
    setIsVideoCreating,
    handleCategoryClick,
    showMoreTemplatesData,
    containsPrivateTemplates,
    visibleCategory,
    createBlankVideo,
    handleAfterCreateVideo,
    createVideoFromTemplate,
    handleNavigateBackToTemplates,
  } = useCreateVideoModal({ isNewVideoModalOpen, setIsNewVideoModalOpen, defaultOpenedTemplate })

  return (
    <Modal
      closable={!(isVideoCreating && isGenerationProgressVisible)}
      open={isNewVideoModalOpen}
      width={1136}
      className="video-creation-modal videos"
      maskStyle={STYLES.maskStyle}
      centered
      destroyOnClose
      title={
        <div className="modal-header">
          <div style={STYLES.modalHeader}>
            <img src={create_video_icon} style={STYLES.marginRight16} width="24" alt="video creation icon" />{' '}
            <span>Create new video</span>
          </div>
        </div>
      }
      footer={
        openedTemplate?.name ? (
          <Button
            type="primary"
            disabled={(!openedTemplate && !htmlToVideo) || isVideoCreating}
            loading={isVideoCreating}
            onClick={() => createVideoFromTemplate(openedTemplate)}
          >
            Create video
          </Button>
        ) : null
      }
      bodyStyle={STYLES.bodyStyle}
      onCancel={handleClose}
    >
      {
        <div>
          {!openedTemplate && (
            <HeaderRow
              isVideoCreating={isVideoCreating}
              handleCategoryClick={handleCategoryClick}
              setIsNewVideoModalOpen={setIsNewVideoModalOpen}
              visibleCategory={visibleCategory}
            />
          )}
          <div style={{ display: openedTemplate ? 'none' : 'block' }}>
            {visibleCategory === CATEGORIES.regular && (
              <FilterPanel
                templateTags={templateTags}
                templatesFilter={templatesFilter}
                setTemplatesFilter={setTemplatesFilter}
                containsPrivateTemplates={containsPrivateTemplates}
              />
            )}
            <div style={{ display: visibleCategory === CATEGORIES.regular ? 'block' : 'none' }}>
              <TemplatesList
                searchedTemplates={searchedTemplates}
                isVideoCreating={isVideoCreating}
                templatesListData={templatesListData}
                setOpenedTemplate={setOpenedTemplate}
                showMoreTemplatesData={showMoreTemplatesData}
                createBlankVideo={createBlankVideo}
              />
            </div>
            <div style={{ display: visibleCategory === CATEGORIES.story ? 'block' : 'none' }}>
              <CreateStoryVideo
                option={storyOption}
                gptValue={gptValue}
                setOption={setStoryOption}
                setGPTValue={setGPTValue}
                htmlToVideo={htmlToVideo}
                pastedContent={pastedContent}
                setPastedContent={setPastedContent}
                isVideoCreating={isVideoCreating}
                setIsGenerationProgressVisible={setIsGenerationProgressVisible}
                setHtmlToVideo={setHtmlToVideo}
                setIsVideoCreating={setIsVideoCreating}
                generationProgress={generationProgress}
                handleAfterCreateVideo={handleAfterCreateVideo}
              />
            </div>
            <div style={{ display: visibleCategory === CATEGORIES.pdf ? 'block' : 'none' }}>
              <CreateVideoFromPresentation
                pdfFile={pdfFile}
                setPdfFile={setPdfFile}
                setIsVideoCreating={setIsVideoCreating}
                isVideoCreating={isVideoCreating}
                setIsNewVideoModalOpen={setIsNewVideoModalOpen}
              />
            </div>
          </div>
          {openedTemplate && (
            <OpenedTemplate
              isVideoCreating={isVideoCreating}
              isGenerationProgressVisible={isGenerationProgressVisible}
              openedTemplate={openedTemplate}
              generationProgress={generationProgress}
              handleNavigateBackToTemplates={handleNavigateBackToTemplates}
            />
          )}
        </div>
      }
    </Modal>
  )
}
