import { Drawer, Row, Col, Button, Tooltip, Tag } from 'antd'
import { ArrowLeftOutlined, CaretRightOutlined } from '@ant-design/icons'
import Scrollbars from 'react-custom-scrollbars'
import { style } from '../constants'
import Icon from '../../../../../components/Icon'
import { isSignedUrl } from '../../../../../utils/helpers'

const arrowLeftIcon = <ArrowLeftOutlined />
const caretRightIcon = <CaretRightOutlined />

const AvatarDrawer = ({
  title,
  avatarDrawer,
  data,
  setActiveAvatar,
  onAvatarDrawerChange,
  onChooseAvatar,
  addListeningAvatar,
}) => {
  return (
    <Drawer
      title={title}
      placement="right"
      width="100%"
      closeIcon={arrowLeftIcon}
      headerStyle={style.headerStyle}
      bodyStyle={style.bodyStyle}
      getContainer={false}
      onClose={onAvatarDrawerChange}
      open={avatarDrawer.isOpen}
      rootStyle={style.rootStyle}
    >
      <Scrollbars className="avatars-scrollbar-drawer scrollbar">
        <Row gutter={[16, 16]} style={{ paddingRight: 15 }}>
          {avatarDrawer?.avatar?.variants.map((a, index) => {
            const src = a.gif || a.thumbnail || a.canvas || avatarDrawer.avatar.thumbnail || avatarDrawer.avatar.canvas
            const isOwnLink = isSignedUrl(src)
            return (
              <Col span={12} key={a.code}>
                <div
                  className={`drawer-avatar ${
                    `${avatarDrawer.avatar.code}.${a.code}` === data.avatar.code ? 'active' : ''
                  }`}
                >
                  <div className="avatar-img-wrapper">
                    <Tooltip
                      title={`This avatar supports slide duration up to ${Math.floor((a.limit || 60) / 60)} minutes`}
                    >
                      <Tag className="minutes-label">{Math.floor((a.limit || 60) / 60)} m</Tag>
                    </Tooltip>
                    {a.listeningAvatar && (
                      <Tooltip
                        title={`Add ${avatarDrawer.avatar.name} as listener: video with avatar, listening to the leading avatar. This is how you can create dialogs.`}
                      >
                        <Icon name="avatar_listener" className="listener-icon" onClick={() => addListeningAvatar(a)} />
                      </Tooltip>
                    )}
                    <img
                      src={src}
                      className="drawer-avatar-img"
                      onClick={() => onChooseAvatar(a, avatarDrawer.avatar)}
                      {...(isOwnLink ? { crossOrigin: 'anonymous' } : {})}
                    />
                  </div>
                  <div className={`avatar-item-text ${a.intro ? 'intro' : ''}`}>
                    <span className="avatar-name">{a.name}</span>
                    {a.intro && (
                      <Button
                        size="small"
                        icon={caretRightIcon}
                        className="item-preview-btn"
                        onClick={() => setActiveAvatar({ avatar: avatarDrawer.avatar, variantIndex: index })}
                      >
                        Preview
                      </Button>
                    )}
                  </div>
                </div>
              </Col>
            )
          })}
        </Row>
      </Scrollbars>
    </Drawer>
  )
}

export default AvatarDrawer
