import { useCallback, useState } from 'react'

import { request } from '../utils/api'

export default () => {
  const [voices, setVoices] = useState([])

  const fetchVoices = useCallback(async () => {
    const result = await request({ method: 'get', url: '/voices' })
    setVoices(result)
  }, [])

  return {
    voices,
    fetchVoices,
  }
}
