import { Typography, Card, Collapse, Layout } from 'antd'
import Icon from '../../../components/Icon'
import configService from '../../../utils/config'

const { Sider } = Layout
const { Panel } = Collapse

const isSmallLaptopView = document.body.clientHeight < 968

export const Sidebar = () => {
  const { hideElaiMentions } = configService.get().features

  return (
    <Sider className="video-story-sider">
      <Collapse
        {...(isSmallLaptopView
          ? { defaultActiveKey: ['1'], accordion: true }
          : { activeKey: ['1', '2', '3'], accordion: false })}
        className="sidebar-collapse"
        style={{ pointerEvents: isSmallLaptopView ? 'all' : 'none' }}
        expandIconPosition="end"
        expandIcon={() => (isSmallLaptopView ? <Icon name="down_arrow" style={{ fontSize: 24 }} /> : null)}
        ghost
      >
        <Panel header="About AI Video Storyboard" key="1">
          <Typography.Text type="secondary">
            AI Video Storyboard is a text-based video editor that allows you to work with your script just like regular
            text. Once you are satisfied with the script, you can easily convert it into slides and generate the video.
          </Typography.Text>
        </Panel>
        <Panel header="Capabilities" key="2">
          <Typography.Text type="secondary">
            Use the latest OpenAI language models to create or rewrite scripts, modify slides, and add missing text.
          </Typography.Text>
          <Typography.Text type="secondary">
            Generate and update the content on your slides according to your design layouts.
          </Typography.Text>
        </Panel>
        <Panel header="Limitations" key="3">
          <Typography.Text type="secondary">
            Trial users are limited to 5,000 GPT tokens and use GPT-3.5. Paid users enjoy unlimited access to the most
            advanced language models.
          </Typography.Text>
          <Typography.Text type="secondary">
            Each slide's duration is limited to either 60 seconds or 5 minutes, depending on your plan.
          </Typography.Text>
        </Panel>
      </Collapse>
      {!hideElaiMentions && (
        <Card className="tutorial-card">
          <h4>AI Video Storyboard tutorial</h4>
          <Typography.Text type="secondary">
            Learn how to use story editor and generate video from any text
          </Typography.Text>
          <div className="video-container">
            <iframe
              className="youtube-video"
              src="https://www.youtube.com/embed/vn41CeEgLYg"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </Card>
      )}
    </Sider>
  )
}
