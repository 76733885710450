import { freepikGetAiContent } from '../freepik.service'
import Icon from '../../../../../../../components/Icon'

export const aiConfig = {
  getContent: freepikGetAiContent,

  restrictedPlans: ['basic'],
  restrictedMessage: 'Image generation is exclusive to Advanced+ plans. Upgrade to access this feature!',
  prompt: {
    key: 'prompt',
    label: 'Prompt',
    placeholder: 'Describe your image',
  },
  nPrompt: {
    key: 'negative_prompt',
    label: 'Negative prompt',
    placeholder: 'Describe things to avoid on the image',
  },
  filters: [
    {
      type: 'collapse',
      subType: 'image',
      key: 'styling[style]',
      label: 'Style',
      values: [
        {
          value: 'photo',
          label: 'Photo',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-in-photo-style.jpg',
        },
        {
          value: 'digital-art',
          label: 'Digital Art',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-in-digital-art-style.jpg',
        },
        {
          value: '3d',
          label: '3d',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-in-3d-style.jpg',
        },
        {
          value: 'painting',
          label: 'Painting',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-in-painting-style.jpg',
        },
        {
          value: 'low-poly',
          label: 'Low Poly',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-in-low-poly-style.jpg',
        },
        {
          value: 'pixel-art',
          label: 'Pixel Art',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-in-pixel-art-style.jpg',
        },
        {
          value: 'anime',
          label: 'Anime',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-in-anime-style.jpg',
        },
        {
          value: 'cyberpunk',
          label: 'Cyberpunk',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-in-cyberpunk-style.jpg',
        },
        {
          value: 'comic',
          label: 'Comic',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-in-comic-style.jpg',
        },
        {
          value: 'vintage',
          label: 'Vintage',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-in-vintage-style.jpg',
        },
        {
          value: 'cartoon',
          label: 'Cartoon',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-in-cartoon-style.jpg',
        },
        {
          value: 'vector',
          label: 'Vector',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-in-vector-style.jpg',
        },
        {
          value: 'studio-shot',
          label: 'Studio Shot',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-in-studio-shot-style.jpg',
        },
        {
          value: 'dark',
          label: 'Dark',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-in-dark-style.jpg',
        },
        {
          value: 'sketch',
          label: 'Sketch',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-in-sketch-style.jpg',
        },
        {
          value: 'mockup',
          label: 'Mockup',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-in-mockup-style.jpg',
        },
        {
          value: '2000s-pone',
          label: "2000's Pone",
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-in-2000s-pone-style.jpg',
        },
        {
          value: '70s-vibe',
          label: '70s Vibe',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-in-70s-vibe-style.jpg',
        },
        {
          value: 'watercolor',
          label: 'Watercolor',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-in-watercolor-style.jpg',
        },
        {
          value: 'art-nouveau',
          label: 'Art Nouveau',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-in-art-nouveau-style.jpg',
        },
        {
          value: 'origami',
          label: 'Origami',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-in-origami-style.jpg',
        },
        {
          value: 'surreal',
          label: 'Surreal',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-in-surreal-style.jpg',
        },
        {
          value: 'fantasy',
          label: 'Fantasy',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-in-fantasy-style.jpg',
        },
        {
          value: 'traditional-japan',
          label: 'Traditional Japan',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-in-traditional-japan-style.jpg',
        },
      ],
    },
    {
      type: 'collapse',
      subType: 'image',
      key: 'styling[color]',
      label: 'Color',
      values: [
        {
          value: 'b&w',
          label: 'Black & white',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-black-and-white.jpg',
        },
        {
          value: 'pastel',
          label: 'Pastel',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-pastel.jpg',
        },
        {
          value: 'sepia',
          label: 'Sepia',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-sepia.jpg',
        },
        {
          value: 'dramatic',
          label: 'Dramatic',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-dramatic.jpg',
        },
        {
          value: 'vibrant',
          label: 'Vibrant',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-vibrant.jpg',
        },
        {
          value: 'orange&teal',
          label: 'Orange & Teal',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-orange-and-teal.jpg',
        },
        {
          value: 'film-filter',
          label: 'Film Filter',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-film-filter.jpg',
        },
        {
          value: 'split',
          label: 'Split',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-split.jpg',
        },
        {
          value: 'electric',
          label: 'Electric',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-electric-colors.jpg',
        },
        {
          value: 'pastel-pink',
          label: 'Pastel Pink',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-patel-pink-colors.jpg',
        },
        {
          value: 'gold-glow',
          label: 'Gold Glow',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-gold-glow-colors.jpg',
        },
        {
          value: 'autumn',
          label: 'Autumn',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-autumn-colors.jpg',
        },
        {
          value: 'muted-green',
          label: 'Muted Green',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-muted-green-colors.jpg',
        },
        {
          value: 'deep-teal',
          label: 'Deep Teal',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-deep-teal.jpg',
        },
        {
          value: 'duotone',
          label: 'Duo Tone',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-duo-tone.jpg',
        },
        {
          value: 'terracotta&teal',
          label: 'Terracotta & Teal',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-terracotta-and-teal-colors.jpg',
        },
        {
          value: 'red&blue',
          label: 'Red & Blue',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-red-and-blue-colors.jpg',
        },
        {
          value: 'cold-neon',
          label: 'Cold Neon',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-cold-neon-colors.jpg',
        },
        {
          value: 'burgundy&blue',
          label: 'Burgundy & Blue',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-burgundy-and-blue-colors.jpg',
        },
      ],
    },
    {
      type: 'collapse',
      subType: 'image',
      key: 'styling[framing]',
      label: 'Framing',
      values: [
        {
          value: 'portrait',
          label: 'Portrait',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/portrait-with-country-house.jpg',
        },
        {
          value: 'macro',
          label: 'Macro',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/macro-view-on-flowers-with-country-house.jpg',
        },
        {
          value: 'panoramic',
          label: 'Panoramic',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/panoramic-view-on-country-house.jpg',
        },
        {
          value: 'aerial-view',
          label: 'Aerial View',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/aerial-view-with-country-house.jpg',
        },
        {
          value: 'close-up',
          label: 'Close-Up',
          image:
            'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/close-up-view-on-blue-buterfly-with-country-house.jpg',
        },
        {
          value: 'cinematic',
          label: 'Cinematic',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/cinematic-view-on-country-house.jpg',
        },
        {
          value: 'high-angle',
          label: 'High-Angle',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/high-angle-view-on-country-house.jpg',
        },
        {
          value: 'low-angle',
          label: 'Low-Angle',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/low-angle-view-on-country-house.jpg',
        },
        {
          value: 'symmetry',
          label: 'Symmetry',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/symmetry-view-on-country-house.jpg',
        },
        {
          value: 'fish-eye',
          label: 'Fish Eye',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/fish-eye-view-on-country-house.jpg',
        },
        {
          value: 'first-person',
          label: 'First-Person',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/first-person-view-on-country-house.jpg',
        },
      ],
    },
    {
      type: 'collapse',
      subType: 'image',
      key: 'styling[lighting]',
      label: 'Lightning',
      values: [
        {
          value: 'studio',
          label: 'Studio',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/toy-country-house-with-studio-light.jpg',
        },
        {
          value: 'warm',
          label: 'Warm',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-warm-light.jpg',
        },
        {
          value: 'cinematic',
          label: 'Cinematic',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-cinematic-light.jpg',
        },
        {
          value: 'volumetric',
          label: 'Volumetric',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-volumetric-light.jpg',
        },
        {
          value: 'golden-hour',
          label: 'Golden Hour',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-golden-hour-light.jpg',
        },
        {
          value: 'long-exposure',
          label: 'Long Exposure',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-long-exposure.jpg',
        },
        {
          value: 'cold',
          label: 'Cold',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-cold-light.jpg',
        },
        {
          value: 'iridescent',
          label: 'Iridescent',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-with-iridescent-light.jpg',
        },
        {
          value: 'dramatic',
          label: 'Dramatic',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-dramatic-light.jpg',
        },
        {
          value: 'hardlight',
          label: 'Hardlight',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-with-hardlight.jpg',
        },
        {
          value: 'redscale',
          label: 'Red Scale',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-red-scale-light.jpg',
        },
        {
          value: 'indoor-light',
          label: 'Indoor Light',
          image: 'https://d3u63mhbhkevz8.cloudfront.net/assets/freepik/ai/country-house-indoor-light.jpg',
        },
      ],
    },
    {
      type: 'collapse',
      subType: 'complex',
      key: 'settings',
      label: 'Settings',
      values: [
        {
          type: 'select',
          label: 'Size',
          key: 'image[size]',
          defaultValue: 'square_1_1',
          options: [
            {
              value: 'square_1_1',
              label: (
                <span>
                  <Icon name="focus" />
                  1:1 Square
                </span>
              ),
            },
            {
              value: 'portrait_2_3',
              label: (
                <span>
                  <Icon name="orientation_vertical" />
                  2:3 Portrait
                </span>
              ),
            },
            {
              value: 'social_post_4_5',
              label: (
                <span>
                  <Icon name="orientation_vertical" />
                  4:5 Social post
                </span>
              ),
            },
            {
              value: 'social_story_9_16',
              label: (
                <span>
                  <Icon name="orientation_vertical" />
                  9:16 Social story
                </span>
              ),
            },
            {
              value: 'standard_3_2',
              label: (
                <span>
                  <Icon name="orientation_horizontal" />
                  3:2 Standard
                </span>
              ),
            },
            {
              value: 'classic_4_3',
              label: (
                <span>
                  <Icon name="orientation_horizontal" />
                  4:3 Classic
                </span>
              ),
            },
            {
              value: 'widescreen_16_9',
              label: (
                <span>
                  <Icon name="orientation_horizontal" />
                  16:9 Widescreen
                </span>
              ),
            },
          ],
        },
      ],
    },
  ],
}
