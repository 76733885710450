import { SearchOutlined } from '@ant-design/icons'

export const STYLES = {
  width100: { width: '100%' },
  marginRight16: { marginRight: 16 },
  marginBottom24: { marginBottom: 24 },
  pageHeader: { padding: '0 0 16px 8px' },
  maskStyle: { backgroundColor: 'rgba(0,0,0,0.8)' },
  modalHeader: { display: 'flex', alignItems: 'center' },
  bodyStyle: { background: '#f3f6fb', overflow: 'hidden', padding: `20px 30px 0 30px` },
}

export const CATEGORIES = {
  story: 'story',
  pdf: 'from_pdf',
  avatars: 'avatars',
  regular: 'regular',
}

export const maxTTVGenerationTimeSec = 15
export const searchOutlinedIcon = <SearchOutlined />
