import { Button, Form, Input } from 'antd'
import { LoginOutlined, UserOutlined } from '@ant-design/icons'

import { useStore } from '../store'

export const AdminLoginByEmail = () => {
  const authStore = useStore((stores) => stores.authStore)
  const [form] = Form.useForm()

  const onFinish = async ({ email }) => {
    const response = await authStore.adminLoginAs({ email })
    if (response !== false) window.location.reload()
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onFinish}>
        <Form.Item name="email" rules={[{ required: true, type: 'email' }]}>
          <Input prefix={<UserOutlined />} style={{ maxWidth: 200 }} placeholder="Login as admin email" />
        </Form.Item>
        <Form.Item>
          <Button type="ghost" icon={<LoginOutlined />} htmlType="submit" />
        </Form.Item>
      </Form>
    </div>
  )
}
