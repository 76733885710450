import { useEffect } from 'react'
import { fontFamilies } from '../data/fonts'
import { useStore } from '../store'
import { getFontFamilyWithFallback } from '../utils/fabric/fontUtils'

const Fonts = () => {
  const videosStore = useStore((stores) => stores.videosStore)

  useEffect(() => {
    videosStore.fetchFonts()
  }, [])

  /* This is required for canvas (fabric.js) to properly render fonts.
      Fonts should be loaded to DOM before canvas is initialized. */
  return (
    <div style={{ visibility: 'hidden', position: 'absolute', left: '-9999px', top: '-9999px' }}>
      {fontFamilies.map((font) => (
        <div key={font}>
          <span style={{ fontFamily: getFontFamilyWithFallback(font) }}>aаgї试บرרןחָןजाँド</span>
          <span style={{ fontFamily: getFontFamilyWithFallback(font), fontStyle: 'italic' }}>aаgї试บرרןחָןाँド</span>
          <span style={{ fontFamily: getFontFamilyWithFallback(font), fontWeight: 'bold' }}>aаgї试บرרןחָןाँド</span>
          <span style={{ fontFamily: getFontFamilyWithFallback(font), fontWeight: 'bold', fontStyle: 'italic' }}>
            aаgї试บرרןחָןाँド
          </span>
        </div>
      ))}
      {videosStore.fonts.map((font) => (
        <div key={font._id}>
          <span style={{ fontFamily: `"${font.name}"` }}>{font.name}aаgї试บرרןחָןाँド</span>
          <span style={{ fontFamily: `"${font.name}"`, fontStyle: 'italic' }}>aаgї试บرרןחָןाँド</span>
          <span style={{ fontFamily: `"${font.name}"`, fontWeight: 'bold' }}>aаgї试บرרןחָןाँド</span>
          <span style={{ fontFamily: `"${font.name}"`, fontWeight: 'bold', fontStyle: 'italic' }}>
            aаgї试บرרןחָןाँド
          </span>
        </div>
      ))}
    </div>
  )
}

export default Fonts
