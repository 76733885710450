import { LoadingOutlined } from '@ant-design/icons'

import Icon from '../../../../../../../components/Icon'

export const deleteIcon = <Icon name="delete" />
export const playIcon = <Icon name="play" />
export const pauseIcon = <Icon name="pause" />
export const loadingIcon = <LoadingOutlined />

export const DEFAULT_VOICE = 'en-US-AriaNeural'
export const DEFAULT_VOICE_PROVIDER = 'azure'
export const ANY_LANGUAGE_OPTION = { label: 'Any language', value: 'Any' }
