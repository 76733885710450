import { Modal, Button } from 'antd'
import { useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import Icon from '../../../../components/Icon'
import onboarding_image from '../../../../assets/images/onboarding-image.jpg'
import './onboardingModal.less'
import { track } from '../../../../utils/analytics'

export const OnboardingModal = () => {
  const location = useLocation()
  const videoRef = useRef(null)
  const [isOpenOnboardingModal, setIsOpenOnboardingModal] = useState(false)
  const [isVisiblePlayButton, setIsVisiblePlayButton] = useState(true)

  const handleCancel = () => setIsOpenOnboardingModal(false)

  const onClickPlayButton = () => {
    setIsVisiblePlayButton(false)
    videoRef.current.play()
    track('onboarding_video_clicked')
  }

  useEffect(() => {
    if (location.state?.showOnboardingModal) {
      setIsOpenOnboardingModal(true)
    }
  }, [location.state?.showOnboardingModal])

  return (
    <Modal
      open={isOpenOnboardingModal}
      closeIcon={<Icon name="close_simple" />}
      className="onboarding-modal"
      onCancel={handleCancel}
      centered
      destroyOnClose
      footer={[
        <Button key="skip" className="btn-skip" type="link" onClick={handleCancel}>
          Skip for now <Icon name="right_arrow" />
        </Button>,
      ]}
    >
      <h2 className="onboarding-modal-title">Welcome to Elai!</h2>
      <p className="onboarding-modal-subtitle">
        👋 I'm Gia, your AI video presenter. Create your first AI video today! You can follow a quick guide in the
        video, or start creating right away.
      </p>
      <div className={`onboarding-image-container ${isVisiblePlayButton ? '' : 'hidden'}`}>
        <Button
          className="btn-play-circle"
          size="large"
          shape="circle"
          icon={<Icon name="play_filled" />}
          onClick={onClickPlayButton}
        />
        <img className="onboarding-image" src={onboarding_image} alt="Onboarding video thumbnail" />
      </div>
      <div className={`onboarding-video-container ${isVisiblePlayButton ? 'hidden' : ''}`}>
        <video
          ref={videoRef}
          className="onboarding-video"
          src="https://d3u63mhbhkevz8.cloudfront.net/assets/videos/onboarding-tour.mp4"
          controls
          controlsList="nodownload noremoteplayback"
        />
      </div>
    </Modal>
  )
}
