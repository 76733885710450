import React, { useState } from 'react'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button, Collapse, Drawer, Form, Input, Select } from 'antd'
import { arrowDownIcon } from '../../../interactivity/constants'
import { Scrollbars } from 'react-custom-scrollbars'
import MediaPreviewModal from './mediaPreviewModal'
import { aiConfig } from './content/ai.config'

const { Panel } = Collapse
const { TextArea } = Input

const STYLE = {
  select: { width: '180px' },
  drawerBodyStyle: { padding: '0 1px 0 0' },
  drawerHeaderStyle: { padding: '8px 0', borderBottom: 0 },
  submitButton: { margin: '10px 0' },
}

const GenerateWithAiDrawer = (props) => {
  const { isAiGenerateDrawerVisible, setIsAiGenerateDrawerVisible, onChooseFile, setShouldUpdateUploads } = props
  const [filter, setFilter] = useState({})
  const [isContentCreating, setIsContentCreating] = useState(false)
  const [previewFile, setPreviewFile] = useState(null)
  const [form] = Form.useForm()

  const { filters, prompt, nPrompt, getContent } = aiConfig

  const filterOptionHandler = ({ key, value }) => {
    if (isKeyActive({ key, value })) value = undefined
    setFilter((prev) => ({ ...prev, [key]: value }))
  }

  const isKeyActive = ({ key, value }) => filter[key] === value

  const closeHandler = () => {
    setIsAiGenerateDrawerVisible(false)
  }

  const onFinish = async (form) => {
    setIsContentCreating(true)
    const file = await getContent({ ...filter, ...form })
    setIsContentCreating(false)
    if (!file) return
    file.type = 'image'
    delete file.thumbnail
    if (setShouldUpdateUploads) setShouldUpdateUploads(true)
    setPreviewFile(file)
  }

  return (
    <Form form={form} onFinish={onFinish} requiredMark={false} layout="vertical" scrollToFirstError>
      <Drawer
        placement="right"
        width="100%"
        autoFocus={false}
        closeIcon={<ArrowLeftOutlined />}
        title="Return to stock images"
        headerStyle={STYLE.drawerHeaderStyle}
        bodyStyle={STYLE.drawerBodyStyle}
        rootClassName="generate-with-ai-drawer"
        getContainer={false}
        onClose={closeHandler}
        open={isAiGenerateDrawerVisible}
        afterOpenChange={() => document.body.classList.remove('drawer')}
      >
        <div style={{ height: 'calc(100% - 60px)' }}>
          <Scrollbars autoHide>
            {prompt && (
              <div className="generate-with-ai-prompt-wrapper">
                <Form.Item
                  name={prompt.key}
                  label={prompt.label}
                  validateTrigger="onBlur"
                  rules={[{ required: true, type: 'string', message: 'Prompt is required' }]}
                >
                  <TextArea rows="3" placeholder={prompt.placeholder} value={filter[prompt.key]} />
                </Form.Item>
              </div>
            )}
            <Collapse
              ghost
              accordion
              expandIconPosition="end"
              expandIcon={() => arrowDownIcon}
              className="sidebar-collapse-transparent generate-with-ai-collapse"
            >
              {filters
                .filter(({ type }) => type === 'collapse')
                .map(({ key, label, values, subType }) => (
                  <Panel key={key} header={<h4 className="sidebar-collapse-title">{label}</h4>}>
                    {subType === 'image' && (
                      <div className="generate-with-ai-collapse-content-3-cols">
                        {values?.map(({ label: subLabel, value, image }) => (
                          <div key={key + value}>
                            <div
                              style={{ background: `url(${image}) 0% 0% / 98px 98px no-repeat` }}
                              className={`element-item ${isKeyActive({ key, value }) ? 'element-item-selected' : ''}`}
                              onClick={() => filterOptionHandler({ key, value })}
                            />
                            <div>{subLabel}</div>
                          </div>
                        ))}
                      </div>
                    )}
                    {subType === 'complex' &&
                      values?.map(
                        ({ type, label, key, defaultValue, options }) =>
                          type === 'select' && (
                            <div className="select-wrapper" key={key}>
                              {label}
                              <Select
                                key={key}
                                options={options}
                                defaultValue={defaultValue}
                                style={STYLE.select}
                                className="select-options-with-icons"
                                popupClassName="select-options-with-icons"
                                onChange={(v) => filterOptionHandler({ key, value: v })}
                              />
                            </div>
                          ),
                      )}
                    {key === 'settings' && nPrompt && (
                      <div className="generate-with-ai-negative-prompt-wrapper">
                        <Form.Item name={nPrompt.key} label={nPrompt.label}>
                          <TextArea rows="3" placeholder={nPrompt.placeholder} value={filter[prompt.key]} />
                        </Form.Item>
                      </div>
                    )}
                  </Panel>
                ))}
            </Collapse>
          </Scrollbars>
        </div>
        <Button type="primary" htmlType="submit" block style={STYLE.submitButton} loading={isContentCreating}>
          Create
        </Button>
      </Drawer>
      <MediaPreviewModal onChooseFile={onChooseFile} previewFile={previewFile} setPreviewFile={setPreviewFile} />
    </Form>
  )
}

export default GenerateWithAiDrawer
