import { useEffect, useState } from 'react'
import { Segmented } from 'antd'

import { Brandbook } from './brandbook'
import { UpdateLogo } from '../../../../components/BrandkitControls/components/updateLogo'
import { UpdateFonts } from '../../../../components/BrandkitControls/components/updateFonts'
import { UpdateColors } from '../../../../components/BrandkitControls/components/updateColors'
import { FileUploader } from './fileUploader'

import { OPTIONS } from './constants'

import { useStore } from '../../../../store'

import './assets.less'
import { useElaiNotification } from '../../../../hooks/useElaiNotification'

/**
 *
 * Assets Component for sidebar
 * @returns JSX element
 */
export const Assets = (props) => {
  const {
    setShouldUpdateUploads,
    isShouldUpdateUploads,
    onChooseFile,
    handleUploadRemoved,
    updateVideo,
    video,
    slide,
    onVideoColorsChange,
    value,
    setValue,
    activeTab,
    setActiveObjectModifier,
  } = props

  // current active tab
  const [displayContent, setDisplayContent] = useState('general')
  const notification = useElaiNotification()
  const userPlan = useStore((stores) => stores.authStore.user.account.plan)

  const isBrandkitAvaliable = userPlan !== 'basic'

  // change active tab
  const handleChange = (value) => {
    if (!isBrandkitAvaliable && value === 'brandbook') {
      notification.warning({ message: 'Upgrade to Advanced or Enterprise plan to use Brandbook.' })
      return
    }
    setValue(value)
  }

  // reset display content if tab was changed
  useEffect(() => {
    if (activeTab !== 'assets' && displayContent !== 'general') {
      setDisplayContent('general')
    }
  }, [activeTab])

  switch (displayContent) {
    case 'general': {
      return (
        <>
          <Segmented
            block
            value={value}
            options={OPTIONS}
            onChange={handleChange}
            className="segmented-default assets-tab-buttons"
          />
          {value === 'assets' ? (
            <FileUploader
              visible={true}
              isVideoAllowed={true}
              onChooseFile={onChooseFile}
              handleUploadRemoved={handleUploadRemoved}
              isShouldUpdateUploads={isShouldUpdateUploads}
              setShouldUpdateUploads={setShouldUpdateUploads}
            />
          ) : (
            <Brandbook
              video={video}
              slide={slide}
              updateVideo={updateVideo}
              onChooseFile={onChooseFile}
              setDisplayContent={setDisplayContent}
              onVideoColorsChange={onVideoColorsChange}
              setActiveObjectModifier={setActiveObjectModifier}
            />
          )}
        </>
      )
    }
    case 'fonts': {
      return (
        <UpdateFonts
          setDisplayContent={setDisplayContent}
          updateVideo={updateVideo}
          setActiveObjectModifier={setActiveObjectModifier}
        />
      )
    }
    case 'colors': {
      return <UpdateColors setDisplayContent={setDisplayContent} />
    }
    case 'logo': {
      return <UpdateLogo setDisplayContent={setDisplayContent} onChooseFile={onChooseFile} />
    }
    default: {
      return <h1>Something bad happens</h1>
    }
  }
}
