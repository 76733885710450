import { useMemo } from 'react'
import { Row, Col, InputNumber } from 'antd'
import Scrollbars from 'react-custom-scrollbars'

import ColorEditorButton from '../../../colorsEditor/colorEditorButton'

import { CommonControls } from '../commonControls'
import { AnimationControls } from '../animationControls'
import InteractivityControls from '../interactivityControls'
import LazySlider from '../../../../../components/LazySlider'
import { FontDecoration, FontSelection, TextAlign, TextType } from './components'

import { STYLE } from './constants'
import { textTypes } from '../../../../../utils/canvas/canvas'
import { useTextControlsState } from './useTextControlsState'
import { BrandbookLink } from '../../../components/brandbookLink'

export const TextControls = (props) => {
  const {
    data,
    video,
    activeSlide,
    canvasActiveObject,
    setActiveObjectModifier,
    activeObjectType,
    setCanvasActiveObject,
    setActiveTab,
    setAssetActiveTab,
  } = props

  const {
    onStep,
    userFonts,
    onSliderChange,
    manualFontSize,
    colorEditButton,
    onFontSizeChange,
    onBlurFontSizeInput,
    activeObjectProps,
    handleColorChange,
    customFontsForSelect,
    getTextSelectionStyle,
    getTextSelectionFontFamily,
    handleLineHeightChange,
  } = useTextControlsState({ canvasActiveObject, setActiveObjectModifier })

  const fontSize = getTextSelectionStyle(activeObjectProps, 'fontSize')
  const fontSizeValue = manualFontSize === false ? fontSize : manualFontSize

  const textAlign = useMemo(
    () =>
      canvasActiveObject?.type !== 'activeSelection' ||
      canvasActiveObject?.getObjects().every((obj, index, array) => obj.type === array[0].type) ? (
        <Row style={STYLE.marginBottom16}>
          <Col span={5}>
            <h4 style={STYLE.marginColor}>List</h4>
          </Col>
          <Col span={18}>
            <TextType activeObjectProps={activeObjectProps} setActiveObjectModifier={setActiveObjectModifier} />
          </Col>
        </Row>
      ) : null,
    [activeObjectProps, canvasActiveObject],
  )

  return (
    <>
      {canvasActiveObject && textTypes.includes(activeObjectType) && (
        <Scrollbars className="text-controls-scrollbar scrollbar">
          <div className="text-controls tab-content">
            <Row wrap={false}>
              <FontSelection
                userFonts={userFonts}
                activeObjectProps={activeObjectProps}
                customFontsForSelect={customFontsForSelect}
                getTextSelectionStyle={getTextSelectionStyle}
                getTextSelectionFontFamily={getTextSelectionFontFamily}
                setActiveObjectModifier={setActiveObjectModifier}
              />
              <FontDecoration
                userFonts={userFonts}
                activeObjectProps={activeObjectProps}
                getTextSelectionStyle={getTextSelectionStyle}
                getTextSelectionFontFamily={getTextSelectionFontFamily}
                setActiveObjectModifier={setActiveObjectModifier}
              />
              <TextAlign
                activeObjectProps={activeObjectProps}
                getTextSelectionStyle={getTextSelectionStyle}
                setActiveObjectModifier={setActiveObjectModifier}
              />
            </Row>

            <BrandbookLink
              setActiveTab={setActiveTab}
              setAssetActiveTab={setAssetActiveTab}
              setCanvasActiveObject={setCanvasActiveObject}
              text="Manage fonts in"
              secondText="My Assets"
              className="corporate-colors-link"
              style={STYLE.brandbookLink}
            />

            <Row style={STYLE.marginBottom16}>
              <Col span={5}>
                <h4 style={STYLE.marginColor}>Color</h4>
              </Col>
              <Col span={18}>
                <ColorEditorButton color={colorEditButton} onChangeColor={handleColorChange} />
              </Col>
            </Row>
            {textAlign}
            <Row align="middle" style={STYLE.marginBottom10}>
              <Col span={4}>
                <h4>Size</h4>
              </Col>
              <Col span={5}>
                <InputNumber
                  value={fontSizeValue}
                  style={STYLE.width60}
                  controls={false}
                  onChange={onFontSizeChange}
                  onBlur={onBlurFontSizeInput}
                />
              </Col>
              <Col span={15}>
                <LazySlider
                  value={fontSize}
                  linkedElement={canvasActiveObject}
                  linkedElementKey="fontSize"
                  min={3}
                  max={300}
                  tooltip={{ open: false }}
                  onStep={onStep}
                  onChange={onSliderChange}
                />
              </Col>
            </Row>
            <Row align="middle">
              <Col span={9}>
                <h4>Line height</h4>
              </Col>
              <Col span={15}>
                <LazySlider
                  value={activeObjectProps.lineHeight}
                  linkedElement={canvasActiveObject}
                  linkedElementKey="lineHeight"
                  min={0.5}
                  max={2.5}
                  step={0.01}
                  onChange={handleLineHeightChange}
                />
              </Col>
            </Row>
            <CommonControls
              canvasActiveObject={canvasActiveObject}
              activeObjectProps={activeObjectProps}
              activeObjectType={activeObjectType}
              setActiveObjectModifier={setActiveObjectModifier}
              isActiveSelection={canvasActiveObject.type === 'activeSelection'}
            />
            <AnimationControls
              data={data}
              activeObjectProps={activeObjectProps}
              setActiveObjectModifier={setActiveObjectModifier}
            />
            <InteractivityControls
              video={video}
              activeSlide={activeSlide}
              activeObjectProps={activeObjectProps}
              setActiveObjectModifier={setActiveObjectModifier}
            />
          </div>
        </Scrollbars>
      )}
    </>
  )
}
