import ColorEditorButton from '../../../../colorsEditor/colorEditorButton'

const marginRight10 = { marginRight: '10px' }

export const ColorEditorButtonComponent = (props) => {
  const { index, activeObjectProps, setActiveObjectModifier } = props

  const handleChangeColor = (color) => {
    let colorStops = []
    if (index === 0) {
      colorStops = [
        { offset: 0, color },
        {
          offset: 1,
          color: activeObjectProps.fill.colorStops[1].color,
        },
      ]
    } else {
      colorStops = [
        {
          offset: 0,
          color: activeObjectProps.fill.colorStops[0].color,
        },
        { offset: 1, color },
      ]
    }

    setActiveObjectModifier({
      change: 'gradient',
      fill: {
        ...activeObjectProps.fill,
        colorStops,
      },
    })
  }

  return (
    <div key={index} style={marginRight10}>
      <ColorEditorButton color={activeObjectProps.fill.colorStops?.[index].color} onChangeColor={handleChangeColor} />
    </div>
  )
}
