import { notification, Upload, Spin } from 'antd'

import { FontSizeOutlined } from '@ant-design/icons'

import Icon from '../../Icon'
import { useStore } from '../../../store'
import configService from '../../../utils/config'
import { customUploadRequest } from '../../../utils/api'
import { textTypes } from '../../../utils/canvas/canvas'
import { getFontFamilyWithFallback, mapSubfamilyToStyle } from '../../../utils/fabric/fontUtils'

const { Dragger } = Upload

export const UpdateFonts = (props) => {
  const { apiUrl } = configService.get().urls
  const { updateVideo } = props

  const { fetchFonts, fonts: userFonts, loadingFonts, deleteFont } = useStore((stores) => stores.videosStore)

  const onDraggerChange = async ({ file, fileList }) => {
    if (!fileList.every((file) => file.status === 'done' || file.status === 'error')) return

    const uploadedFonts = fileList.filter((file) => file.status === 'done')
    if (uploadedFonts.length === 0) return

    notification.success({
      message: `New ${uploadedFonts.length > 1 ? 'fonts were' : 'font was'} uploaded successfully.`,
    })

    await fetchFonts()
  }

  const handleDeleteFont = (font) => () => {
    deleteFont(font._id)
    updateVideo?.(
      ({ slides }) => ({
        slides: slides.map((slide) => {
          slide.canvas.objects = slide.canvas.objects.map((object) => {
            if (textTypes.includes(object.type) && object.fontFamily === font.name) {
              return {
                ...object,
                fontFamily: getFontFamilyWithFallback('Georgia'),
              }
            }
            return object
          })
          return slide
        }),
      }),
      { updateActiveCanvas: true },
    )
  }

  return (
    <>
      <div style={{ marginBottom: '16px' }}>
        <Dragger
          name="file"
          action={`${apiUrl}/fonts/`}
          accept=".ttf"
          multiple
          customRequest={customUploadRequest}
          onChange={onDraggerChange}
          showUploadList={false}
        >
          <p className="ant-upload-drag-icon">
            <FontSizeOutlined />
          </p>
          <p className="ant-upload-text">Click or drag a TTF file to this area to upload</p>
        </Dragger>
      </div>
      {loadingFonts ? (
        <Spin className="spin-centered" />
      ) : (
        userFonts.map((font) => (
          <div key={font._id} className="brandbook-font-item">
            <div style={{ ...mapSubfamilyToStyle(font.subfamily) }}>{font.name}</div>
            <div style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={handleDeleteFont(font)}>
              <Icon name="delete" />
            </div>
          </div>
        ))
      )}
    </>
  )
}
