import { useEffect, useState } from 'react'
import { Button, Card } from 'antd'

import { StepsComponent } from './components'

import { useStore } from '../../../store'
import { createSniperLink } from '../../../utils/account'

import useAppRedirect from '../../../hooks/useAppRedirect'

import { rightOutlinedIcon } from './constants'

import steps_shape from '../../../assets/images/steps-shape.png'
import hand_icon from '../../../assets/images/hand-icon.png'

import './poll.less'

export const Poll = () => {
  const user = useStore(({ authStore }) => authStore.user)
  const [currentStep, setCurrentStep] = useState(0)

  const { handleRedirect } = useAppRedirect()

  useEffect(() => {
    if (currentStep === 'final') {
      const layout = document.getElementsByClassName('layout')[0]
      if (layout) {
        layout.style.height = '100vh'
      }
    }
  }, [currentStep])

  const handleComplete = () => {
    handleRedirect({ isJustRegistered: true })
  }

  return (
    <div className="poll-wrapper">
      <Card className={`signup-poll ${currentStep === 'final' && 'last-poll-step'}`}>
        {currentStep === 'final' ? (
          <div>
            <div className="card-title last-step-title">
              <h3>
                Wonderful
                <img src={hand_icon} className="hand-icon-img" alt="Thumb up icon" />
              </h3>
              <p className="last-step-text">
                Now, check your mailbox to confirm your email and create your first video!
              </p>
            </div>
            <div className="btns-final-container">
              <Button
                type="primary"
                icon={rightOutlinedIcon}
                className="btn-login btn-arrow last-step-button"
                size="large"
                href={createSniperLink(user.email)}
                target="_blank"
                rel="noreferrer"
              >
                Check mailbox
              </Button>
              <Button
                icon={rightOutlinedIcon}
                className="btn-login btn-arrow last-step-button"
                size="large"
                onClick={handleComplete}
              >
                Skip for now
              </Button>
            </div>
          </div>
        ) : (
          <StepsComponent
            name={user.name}
            isPersonal={user.account.additionalInfo?.useType === 'Personal'}
            isPublicEmail={user.account.additionalInfo?.isPublicEmail}
            redirectUrl={localStorage.getItem('redirectUrl')}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        )}
        <img src={steps_shape} className="steps-shape-img" />
      </Card>
    </div>
  )
}
