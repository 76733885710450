export const useShapeState = (props) => {
  const { setActiveObjectModifier, activeObjectProps, setIsOpenGradient } = props

  const switchShapeBackground = (checked) => {
    if (checked) {
      setActiveObjectModifier({
        fill: activeObjectProps.fill,
        change: 'gradient',
      })
    } else {
      setActiveObjectModifier({
        change: 'fill',
        value: activeObjectProps.fill.colorStops[1].color,
      })
    }
    setIsOpenGradient(checked)
  }

  const handleChangeColor = (color) => {
    setActiveObjectModifier({ change: 'fill', value: color })
  }

  /**
   *
   * @param {number[]} value
   * @param {'horizontal' | 'vertical'} direction
   * @returns
   */
  const handleCoordsChange = (value, direction) => {
    let coords = undefined
    if (direction === 'horizontal') {
      coords = Object.assign({}, activeObjectProps.fill.coords, { x1: value[0], x2: value[1] })
    } else if (direction === 'vertical') {
      coords = Object.assign({}, activeObjectProps.fill.coords, { y1: value[0], y2: value[1] })
    }
    if (!coords) return
    setActiveObjectModifier({
      change: 'gradient',
      fill: {
        ...activeObjectProps.fill,
        coords,
      },
    })
  }

  return {
    handleChangeColor,
    handleCoordsChange,
    switchShapeBackground,
  }
}
