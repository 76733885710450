import { PAUSE_EMOJI } from './constants'

// Map key - search regex, value - emoji
const replacements = new Map([
  [/<break time=['"]0\.25s['"] \/>/g, PAUSE_EMOJI.small],
  [/<break time=['"]0\.5s['"] \/>/g, PAUSE_EMOJI.standard],
  [/<break time=['"]1s['"] \/>/g, PAUSE_EMOJI.medium],
  [/<break time=['"]1\.5s['"] \/>/g, PAUSE_EMOJI.large],
])

const pausEmojiToTextMap = new Map([
  [PAUSE_EMOJI.small, '<break time="0.25s" />'],
  [PAUSE_EMOJI.standard, '<break time="0.5s" />'],
  [PAUSE_EMOJI.medium, '<break time="1s" />'],
  [PAUSE_EMOJI.large, '<break time="1.5s" />'],
])

// more straighforward way
export const replace = (value) => {
  let userFriendly = value
  replacements.forEach((emoji, searchRegex) => {
    userFriendly = userFriendly.replace(searchRegex, emoji)
  })

  return userFriendly
}

export const normalizePauses = (value) => {
  let normalized = value
  pausEmojiToTextMap.forEach((replacementText, emoji) => {
    normalized = normalized.split(emoji).join(replacementText)
  })
  return normalized
}

export const findFirstDifferenceIndex = (str1, str2) => {
  const minLength = Math.min(str1.length, str2.length)
  for (let i = 0; i < minLength; i++) {
    if (str1[i] !== str2[i]) {
      return i
    }
  }
  return minLength
}
