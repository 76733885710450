import { useState } from 'react'
import { Row, Col, Button } from 'antd'
import { DownOutlined, UpOutlined } from '@ant-design/icons'

export default ({ activeTab }) => {
  const [isOpenExamples, setIsOpenExamples] = useState(false)

  return (
    <div
      className={`examples tabs-content-wrapper ${avatarsExamples[activeTab].examples.length > 4 ? 'collapsible' : ''}`}
    >
      <h2>See examples of {avatarsExamples[activeTab].title}</h2>
      <div className={`examples-videos ${isOpenExamples ? 'open' : ''} ${activeTab}`}>
        <Row
          gutter={[
            { xs: 10, sm: 16, xl: 30 },
            { xs: 10, sm: 16, xl: 30 },
          ]}
        >
          {avatarsExamples[activeTab].examples.map(({ name, url, companyLogo, poster }, index) => (
            <Col key={index} lg={activeTab === 'selfie' ? 8 : 6} md={activeTab === 'selfie' ? 8 : 6} sm={12} xs={12}>
              <div className="example-wrapper">
                {companyLogo && (
                  <div className="complany-logo">
                    <img src={companyLogo} height={30} alt="Logo of the customer's company" />
                  </div>
                )}
                <video
                  src={url}
                  poster={poster}
                  controls
                  controlsList="nodownload"
                  playsInline
                  width="100%"
                  preload={poster ? 'none' : 'metadata'}
                />
                {name && <p>{name}</p>}
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <Button
        type="primary"
        size="large"
        icon={isOpenExamples ? <UpOutlined /> : <DownOutlined />}
        className="btn-arrow"
        onClick={() => setIsOpenExamples(!isOpenExamples)}
      >
        See {isOpenExamples ? 'less' : 'more'}
      </Button>
    </div>
  )
}

const avatarsExamples = {
  studio: {
    title: 'studio avatars',
    examples: [
      {
        name: 'Gia',
        url: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/gia.mp4',
        poster: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/gia.png',
      },
      {
        name: 'Kamal',
        url: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/kamal.mp4',
        poster: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/kamal.png',
      },
      {
        name: 'Kira',
        url: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/kira.mp4',
        poster: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/kira.png',
      },
      {
        name: 'Max',
        url: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/max.mp4',
        poster: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/max.png',
      },
    ],
  },
  selfie: {
    title: 'selfie avatars',
    examples: [
      {
        url: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/vitalii.mp4',
        poster: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/vitalii.png',
      },
      {
        url: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/dasha.mp4',
        poster: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/dasha.png',
      },
      {
        url: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/olga.mp4',
        poster: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/olga.png',
      },
    ],
  },
  mascot: {
    title: 'cartoon mascots',
    examples: [
      {
        name: 'Professor Agile',
        url: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/prof-agile---office-jipp.it.mp4',
        poster: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/prof-agile---office-jipp.it.png',
        companyLogo: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/jipp-logo.png',
      },
      {
        name: 'Professor with stick and Girl dialogue',
        url: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/prof-with-stick-and-girl-dialogue.mp4',
        poster: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/prof-with-stick-and-girl-dialogue.png',
        companyLogo: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/werte-macher-logo.png',
      },
      {
        name: 'Boy with a ball',
        url: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/boy-with-a-ball.mp4',
        poster: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/boy-with-a-ball.png',
        companyLogo: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/the-utopian-society-project.png',
      },
      {
        name: 'Standing mascot',
        url: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/standing-cartoon-mascot.mp4',
        poster: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/standing-cartoon-mascot.png',
      },
      {
        name: 'Boy with a skateboard',
        url: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/boy-with-a-skateboard.mp4',
        poster: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/boy-with-a-skateboard.png',
        companyLogo: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/the-utopian-society-project.png',
      },
      {
        name: 'Boy with a laptop',
        url: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/boy-with-a-laptop.mp4',
        poster: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/boy-with-a-laptop.png',
        companyLogo: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/the-utopian-society-project.png',
      },
      {
        name: 'Boy with an apple',
        url: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/boy-with-an-apple.mp4',
        poster: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/boy-with-an-apple.png',
        companyLogo: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/the-utopian-society-project.png',
      },
      {
        name: 'Sitting mascot',
        url: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/sitting-mascot.mp4',
        poster: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/sitting-mascot.png',
      },
      {
        name: 'Basketball mascot',
        url: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/basket-ball-mascot.mp4',
        poster: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/basket-ball-mascot.png',
      },
      {
        name: 'Superhero mascot',
        url: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/super-hero.mp4',
        poster: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/super-hero.png',
      },
    ],
  },
  photo: {
    title: 'photo avatars',
    examples: [
      {
        url: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/photo-avatar-5.mp4',
        poster: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/photo-avatar-5.png',
      },
      {
        url: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/photo-avatar-6.mp4',
        poster: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/photo-avatar-6.png',
      },
      {
        url: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/photo-avatar-4.mp4',
        poster: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/photo-avatar-4.png',
      },
      {
        url: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/photo-avatar-9.mp4',
        poster: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/photo-avatar-9.png',
      },
      {
        url: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/photo-avatar-7.mp4',
        poster: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/photo-avatar-7.png',
      },
      {
        url: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/photo-avatar-8.mp4',
        poster: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/photo-avatar-8.png',
      },
      {
        url: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/photo-avatar-10.mp4',
        poster: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/photo-avatar-10.png',
      },
      {
        url: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/photo-avatar-11.mp4',
        poster: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/photo-avatar-11.png',
      },
      {
        url: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/photo-avatar-12.mp4',
        poster: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/photo-avatar-12.png',
      },
      {
        url: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/photo-avatar-1.mp4',
        poster: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/photo-avatar-1.png',
      },
      {
        url: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/photo-avatar-2.mp4',
        poster: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/photo-avatar-2.png',
      },
      {
        url: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/photo-avatar-3.mp4',
        poster: 'https://d3u63mhbhkevz8.cloudfront.net/avatars-examples/photo-avatar-3.jpg',
      },
    ],
  },
}
