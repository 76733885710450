import React, { useState } from 'react'
import { Button, Dropdown, Tooltip } from 'antd'
import Icon from '../Icon'
import { useStore } from '../../store'
import CreateWorkspaceModal from '../CreateWorkspaceModal/createWorkspaceModal'
import './workspaces.less'

const WorkspacesMenu = () => {
  const { user, setSubAccountId, refreshSession } = useStore((stores) => stores.authStore)
  const [isOpenCreateWorkspaceModal, setIsOpenCreateWorkspaceModal] = useState()
  const { account, canChangeWorkspace, canManageWorkspace } = user
  const canAddWorkspace = ['custom', 'unlimited'].includes(user.account.plan)

  const handleSelectAccount = async ({ key }) => {
    setSubAccountId(key)
    await refreshSession()
    window.location.reload()
  }

  const handleAddNewWorkspace = () => {
    if (canAddWorkspace) setIsOpenCreateWorkspaceModal(true)
  }

  const items = user.accounts.map((a) => ({
    key: a._id,
    label: (
      <div className="workspaces-menu-content-title">
        <span>{a.name}</span>
        {a._id === account.id && <Icon name="check" />}
      </div>
    ),
  }))

  if (!canChangeWorkspace || !canAddWorkspace) return null

  return (
    <>
      <Dropdown
        menu={{ items, onClick: handleSelectAccount, selectable: true, defaultSelectedKeys: [user.account.id] }}
        trigger={['click']}
        id="workspaces-menu"
        dropdownRender={(menu) => (
          <div id="workspaces-menu-content">
            {React.cloneElement(menu)}
            <Tooltip
              placement="right"
              title={!canManageWorkspace ? 'Please contact your account admin to add more workspaces.' : ''}
            >
              <div
                className={`workspaces-menu-content-add-button ${!canManageWorkspace ? 'disabled' : ''}`}
                onClick={handleAddNewWorkspace}
              >
                <Icon name="plus" />
                Add workspace
              </div>
            </Tooltip>
          </div>
        )}
      >
        <Button onClick={(e) => e.preventDefault()} id="workspaces-menu-button" block>
          <span className="workspaces-menu-button-text">{user.accounts.find((a) => a._id === account.id)?.name}</span>
          <Icon name="up_down" />
        </Button>
      </Dropdown>
      <CreateWorkspaceModal
        isOpenCreateWorkspaceModal={isOpenCreateWorkspaceModal}
        setIsOpenCreateWorkspaceModal={setIsOpenCreateWorkspaceModal}
      />
    </>
  )
}

export default WorkspacesMenu
