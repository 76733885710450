import { Select, Row, Col, Radio } from 'antd'

import Icon from '../../Icon'

import { STYLES, searchOutlinedIcon } from '../constants'

export const FilterPanel = (props) => {
  const { templatesFilter, setTemplatesFilter, containsPrivateTemplates, templateTags } = props

  const onTagsChange = (tags) => setTemplatesFilter((f) => ({ ...f, tags }))
  const onCategoryChange = (e) => setTemplatesFilter((f) => ({ ...f, format: e.target.value }))

  return (
    <Row gutter={[8, 16]} style={STYLES.marginBottom24}>
      <Col xs={24} sm={24} md={24} lg={18}>
        <Radio.Group className="format-radio-btns" value={templatesFilter.format} onChange={onCategoryChange}>
          <Radio.Button value="all" className={`format-radio-btn ${templatesFilter.format === 'all' ? 'active' : ''}`}>
            <Icon name="page_collection" />
            <span>All</span>
          </Radio.Button>
          {containsPrivateTemplates && (
            <Radio.Button
              value="private"
              className={`format-radio-btn ${templatesFilter.format === 'private' ? 'active' : ''}`}
            >
              <Icon name="multiple_image" />
              <span>My templates</span>
            </Radio.Button>
          )}
          <Radio.Button
            value="16_9"
            className={`format-radio-btn ${templatesFilter.format === '16_9' ? 'active' : ''}`}
          >
            <Icon name="monitor" />
            <span>Horizontal</span>
          </Radio.Button>
          <Radio.Button
            value="9_16"
            className={`format-radio-btn ${templatesFilter.format === '9_16' ? 'active' : ''}`}
          >
            <Icon name="mobile" />
            <span>Vertical</span>
          </Radio.Button>
          <Radio.Button value="1_1" className={`format-radio-btn ${templatesFilter.format === '1_1' ? 'active' : ''}`}>
            <Icon name="focus" />
            <span>Square</span>
          </Radio.Button>
        </Radio.Group>
      </Col>
      <Col xs={24} sm={24} md={12} lg={6}>
        <Select
          value={templatesFilter.tags}
          mode="multiple"
          style={STYLES.width100}
          placeholder="Search by tags"
          suffixIcon={searchOutlinedIcon}
          showArrow="true"
          onChange={onTagsChange}
        >
          {templateTags.map((t) => (
            <Select.Option key={t}>{t}</Select.Option>
          ))}
        </Select>
      </Col>
    </Row>
  )
}
