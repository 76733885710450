import { memo } from 'react'
import { Col, Dropdown, Tooltip } from 'antd'

import Icon from '../../../../../components/Icon'

import { STYLE, distributeMenuItems } from './constants'

export const GroupedAlignment = memo((props) => {
  const { setActiveObjectModifier } = props

  const onAlignLeftClick = () => setActiveObjectModifier({ change: 'groupedAlignment', value: 'left' })

  const onAlignCenterClick = () => setActiveObjectModifier({ change: 'groupedAlignment', value: 'center' })

  const onAlignRightCLick = () => setActiveObjectModifier({ change: 'groupedAlignment', value: 'right' })

  const onAlignTopClick = () => setActiveObjectModifier({ change: 'groupedAlignment', value: 'top' })

  const onAlignMiddleClick = () => setActiveObjectModifier({ change: 'groupedAlignment', value: 'middle' })

  const onAlignBottomClick = () => setActiveObjectModifier({ change: 'groupedAlignment', value: 'bottom' })

  const onDistributeClick = ({ key }) => setActiveObjectModifier({ change: 'groupedAlignment', value: key })

  return (
    <Col style={STYLE.marginTop20}>
      <h4>Alignment</h4>
      <div className="grouped-alignment" style={STYLE.spaceBetween}>
        <Tooltip title="Left">
          <Icon className="align-icon" name="align_left" onClick={onAlignLeftClick} />
        </Tooltip>
        <Tooltip title="Center">
          <Icon className="align-icon" name="align_center" onClick={onAlignCenterClick} />
        </Tooltip>
        <Tooltip title="Right">
          <Icon name="align_right" className="align-icon" onClick={onAlignRightCLick} />
        </Tooltip>
        <Tooltip title="Top">
          <Icon name="align_top" className="align-icon" onClick={onAlignTopClick} />
        </Tooltip>
        <Tooltip title="Middle">
          <Icon name="align_middle" className="align-icon" onClick={onAlignMiddleClick} />
        </Tooltip>
        <Tooltip title="Bottom">
          <Icon name="align_bottom" className="align-icon" onClick={onAlignBottomClick} />
        </Tooltip>
        <Dropdown
          menu={{
            items: distributeMenuItems,
            onClick: onDistributeClick,
          }}
          trigger={['click']}
          placement="bottomRight"
        >
          <div className="btn-distribute">
            <Icon name="distribute_horizontal" className="align-icon" />
            <Icon name="down_arrow" className="align-icon" />
          </div>
        </Dropdown>
      </div>
    </Col>
  )
})
