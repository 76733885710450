import { notification } from 'antd'
import { useCallback, useEffect, useState } from 'react'

const CODES = ['ArrowUp', 'ArrowDown']

export const useSlideListState = (props) => {
  const { video, updateVideo, updateActiveSlide, canvasActiveObject, activeSlide } = props

  const [activeTransition, setActiveTransition] = useState()
  const [transitionModal, setTransitionModal] = useState(false)

  useEffect(() => {
    setActiveTransition(video.slides[transitionModal]?.transition)
  }, [transitionModal])

  const moveSlide = (dragIndex, hoverIndex) => {
    const dragSlides = video.slides
    const dragSlide = video.slides[dragIndex]
    dragSlides.splice(dragIndex, 1)
    dragSlides.splice(hoverIndex, 0, dragSlide)
    updateVideo({ slides: Array.from(dragSlides) }, { updatedSlideId: dragSlide.id })
    updateActiveSlide(hoverIndex)
  }

  const canApplyTransitions = (slide = undefined) => {
    const slidesToCheck = slide ? [slide] : video.slides
    // can be added more checks in the future
    const interactivityCheck = !slidesToCheck.some((slideToCheck) =>
      slideToCheck.canvas.objects.some((obj) => obj?.meta?.interactivity || obj.type === 'question'),
    )
    return interactivityCheck
  }

  // NOTE: maybe, need to add vide.slides as dependencies as well
  const setTransition = useCallback(() => {
    const { slides } = video
    slides[transitionModal] = Object.assign({}, slides[transitionModal], { transition: activeTransition })
    updateVideo({ slides })
  }, [activeTransition])

  const setTransitionOnAllSlides = useCallback(() => {
    const { slides } = video
    const updatedSlides = slides.map((slide, index) => {
      if (index === slides.length - 1) return slide
      return { ...slide, transition: canApplyTransitions(slide) ? activeTransition : undefined }
    })
    updateVideo({ slides: updatedSlides })
  }, [activeTransition])

  const switchActiveSlide = (e, activeSlide) => {
    const isSpeechEditor = e.target.classList?.contains('resizableEditor')
    if (!CODES.includes(e.code) || isSpeechEditor) {
      return
    }
    e.preventDefault()
    if (e.code === 'ArrowUp') updateActiveSlide(activeSlide - 1 < 0 ? video.slides.length - 1 : activeSlide - 1)
    else if (e.code === 'ArrowDown') updateActiveSlide(activeSlide + 1 > video.slides.length - 1 ? 0 : activeSlide + 1)
  }

  useEffect(() => {
    if (canvasActiveObject) return
    const handleListener = (e) => switchActiveSlide(e, activeSlide)
    document.addEventListener('keydown', handleListener)
    return () => document.removeEventListener('keydown', handleListener)
  }, [canvasActiveObject, activeSlide])

  const closeTransitionModal = useCallback(() => {
    setTransitionModal(false)
  }, [])

  const handleSetTransition = useCallback(() => {
    if (activeTransition && !canApplyTransitions(video.slides[transitionModal])) {
      notification.error({
        message:
          'Slides with interactive elements don’t support transitions. Please remove interactive elements or skip transition.',
      })
      return
    }
    setTransition()
    setTransitionModal(false)
  }, [setTransition])

  const applyTransitionToAllSlides = useCallback(() => {
    if (activeTransition && !canApplyTransitions()) {
      notification.warning({ message: 'Transition was applied only for slides without interactive elements.' })
    }
    setTransitionOnAllSlides()
    setTransitionModal(false)
  }, [setTransitionOnAllSlides])

  return {
    activeTransition,
    setActiveTransition,
    setTransitionModal,
    transitionModal,
    moveSlide,
    setTransition,
    switchActiveSlide,
    handleSetTransition,
    applyTransitionToAllSlides,
    closeTransitionModal,
  }
}
