import { useMemo } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Col, Card, Avatar, Select, Tooltip, Typography, Popover } from 'antd'

import { VoiceItemTag } from './voiceItemTag'
import Icon from '../../../../../../components/Icon'

import { flagsUrl } from '../constants'

import userIcon from '../../../../../../assets/images/user-icon.svg'
import { track } from '../../../../../../utils/analytics'

export const VoiceList = (props) => {
  const {
    voice,
    index,
    isAdmin,
    activeVoice,
    isActiveVoice,
    setActiveVoice,
    playVoicePreview,
    checkVoiceContainsPreview,
    pauseVoicePreview,
    voicePreviewPlayer,
    isPremiumAvailable,
    setVoicePreviewPlayer,
  } = props

  const isVoiceActive = isActiveVoice(voice)
  const defaultStyle = voice.defaultStyle || 'default'

  const onStyleClick = () => {
    if (voicePreviewPlayer.state === 'playing') pauseVoicePreview()
  }

  const pauseVoicePreviewEvent = (e) => {
    e.stopPropagation()
    pauseVoicePreview()
  }

  const cardAvatar = (
    <Avatar src={voice.icon ? `${flagsUrl}${voice.icon}.svg` : userIcon} size="large" alt={voice.name} />
  )

  // handle voice style change
  const onStyleChange = (v) => setActiveVoice({ ...activeVoice, voice: { ...activeVoice.voice, style: v } })

  // handle preview voice
  const onPlayClick = (e) => {
    e.stopPropagation()

    track('editor_voice_preview', { provider: voice.voiceProvider, voice: voice.voice })
    playVoicePreview(voice)
  }

  // handle voice style select click
  const onClick = () => {
    if (isVoiceActive) {
      return
    }
    setActiveVoice({
      ...activeVoice,
      voice: { ...voice, id: voice.voice || voice.playedTags[0].id, style: defaultStyle },
    })
  }

  const voiceOptions = useMemo(() => voice.styleList?.map((style) => ({ value: style, label: style })) || [], [voice])
  const options = useMemo(() => [{ value: 'default', label: 'default' }, ...voiceOptions], [voiceOptions])

  return (
    <Col key={index} lg={6} md={8} sm={12} xs={24}>
      <Popover
        mouseEnterDelay={1}
        overlayStyle={{ visibility: isAdmin && isVoiceActive ? 'visible' : 'hidden' }}
        content={
          <div className="min-width160px">
            <b>ID: </b> {activeVoice.voice.id}
            <br />
            <b>Style: </b> {activeVoice.voice.style}
          </div>
        }
      >
        <Card
          className={`voice-item ${isVoiceActive ? 'active' : ''}`}
          title={
            <Card.Meta
              avatar={cardAvatar}
              title={
                <div className="item-title-wrapper">
                  <span className="card-title">
                    {voice.character
                      ? `${voice.character} (${voice.name.replace(/ *\([^)]*\) */g, `-${voice.icon.toUpperCase()}`)})`
                      : voice.name}
                  </span>
                  <Tooltip title={`Provider: ${voice.voiceProvider}`}>
                    <Icon name="info" />
                  </Tooltip>
                </div>
              }
              description={
                <>
                  <div className="item-tags-container">
                    {voice.playedTags &&
                      (voice.playedTags.length > 1 ? (
                        voice.playedTags.map((t) => (
                          <VoiceItemTag
                            item={t}
                            voice={voice}
                            activeVoice={activeVoice}
                            isVoiceActive={isVoiceActive}
                            setActiveVoice={setActiveVoice}
                            pauseVoicePreview={pauseVoicePreview}
                            voicePreviewPlayer={voicePreviewPlayer}
                            setVoicePreviewPlayer={setVoicePreviewPlayer}
                          />
                        ))
                      ) : (
                        <Typography.Text ellipsis type="secondary" className="card-text">
                          {voice.playedTags.map((t) => (
                            <span key={t.name}>{t.name} </span>
                          ))}
                        </Typography.Text>
                      ))}
                    {voice.styleList && (
                      <Select
                        value={isVoiceActive ? activeVoice.voice.style : defaultStyle}
                        defaultValue="default"
                        size="small"
                        className="item-styles-select"
                        options={options}
                        onChange={onStyleChange}
                        onClick={onStyleClick}
                      />
                    )}
                    {voice.tags && (
                      <Typography.Text ellipsis type="secondary" className="font-size-11px">
                        {voice.tags.map((t) => (
                          <span key={t}>{t} </span>
                        ))}
                      </Typography.Text>
                    )}
                  </div>
                  {voice.premium && (
                    <Tooltip
                      title="Upgrade to Advanced or Enterprise plan to use this voice"
                      overlayStyle={{ visibility: isPremiumAvailable ? 'hidden' : 'visible' }}
                    >
                      <div className="voice-item-premium">
                        <Icon
                          name="diamond"
                          style={{ height: '12px', width: '12px', marginRight: '4px', color: 'white' }}
                        />
                        Premium
                      </div>
                    </Tooltip>
                  )}
                  <div className="active-icon">
                    <Icon name="check" />
                  </div>
                </>
              }
            />
          }
          extra={
            voicePreviewPlayer.state !== 'idle' &&
            (voice.playedTags?.some((t) => t.id === voicePreviewPlayer.id) ||
              voice.voice === voicePreviewPlayer.id ||
              voice.voice === voicePreviewPlayer.id.split(':')[0]) ? (
              <>
                {voicePreviewPlayer.state === 'playing' ? (
                  <Icon name="pause" onClick={pauseVoicePreviewEvent} />
                ) : (
                  <LoadingOutlined className="loading-icon" />
                )}
              </>
            ) : (
              checkVoiceContainsPreview(voice) && <Icon name="play" onClick={onPlayClick} />
            )
          }
          onClick={onClick}
        />
      </Popover>
    </Col>
  )
}
