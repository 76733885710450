import { useCallback, useEffect, useRef } from 'react'
import { videoFormats } from '../../data/formats'

const WORKING_AREA_PADDING_PX = 20 * 2

const getSuitableMaxZoom = () => {
  if (window.innerHeight < 800) return 1.2
  if (window.innerHeight < 1000) return 1.5
  return 2
}

export const useVideoFormat = (video, canvasRegistry, workingAreaRef, canvasesContainerRef, isOpenSidebar) => {
  const formatLimiters = useRef()
  const canvasPrestretched = useRef(false)

  const calculateZoom = (workingArea = workingAreaRef?.current) => {
    if (!workingArea) return 1
    const zoom = (workingArea.parentElement.clientWidth - WORKING_AREA_PADDING_PX) / 640
    return Math.min(getSuitableMaxZoom(), Math.max(1, zoom))
  }

  const getFormatLimiters = (area) =>
    (formatLimiters.current = formatLimiters.current || Array.from(area.querySelectorAll('.video-format-limiter')))

  const isDomElement = (el) => el instanceof Element

  const resizeCanvasesContainer = (area, canvas, zoom) => {
    if (!isDomElement(canvas)) return

    const styles = window.getComputedStyle(canvas)
    const borderWidth = parseInt(styles.borderWidth, 10)
    const width = 640 * zoom + borderWidth * 2
    const height = Math.round(width / (640 / 360))

    canvas.style.width = width + 'px'
    canvas.style.height = height + 'px'
    area.style.width = width + 'px'

    getFormatLimiters(area).forEach((el) => {
      el.style.height = height - borderWidth + 'px'
    })
  }

  const resizeFormatLimiters = (zoom) => {
    if (!workingAreaRef?.current) return
    const videoFormatWidth = videoFormats[video.data?.format || '16_9'].width
    zoom = zoom || calculateZoom()
    getFormatLimiters(workingAreaRef.current).forEach((el) => {
      el.style.borderWidth = (videoFormatWidth === 640 ? 0 : 1) + 'px'
      el.style.width = Math.round(((640 - videoFormatWidth) / 2) * zoom) + 'px'
    })
  }

  const setCanvasesZoom = (zoom) => {
    Object.values(canvasRegistry.current).forEach((canvas) => {
      if (!canvas?.lowerCanvasEl) return
      canvas.setZoom(zoom)
      canvas.setHeight(Math.round(360 * zoom))
      canvas.setWidth(Math.round(640 * zoom))
      canvas.renderAllSafe()
    })
  }

  const stretchCanvases = useCallback(() => {
    if (!video || !workingAreaRef?.current) return

    const zoom = calculateZoom()
    setCanvasesZoom(zoom)
    resizeCanvasesContainer(workingAreaRef.current, canvasesContainerRef.current, zoom)
    resizeFormatLimiters(zoom)
  }, [video])

  const clearFormatLimiters = () => (formatLimiters.current = null)

  /**
   * For maximum fast stretching of canvases container to prevent content jumping at initial load
   */
  useEffect(() => {
    if (canvasPrestretched.current) return
    const area = document.querySelector('.working-area')
    const canvas = document.querySelector('.canvas')
    if (!area || !canvas) return
    const zoom = calculateZoom(area)
    resizeCanvasesContainer(area, canvas, zoom)
    canvasPrestretched.current = true
  }, [])

  useEffect(() => {
    if (!workingAreaRef?.current || !canvasesContainerRef?.current) return
    stretchCanvases()
  }, [isOpenSidebar, workingAreaRef?.current, canvasesContainerRef?.current])

  useEffect(() => {
    if (video && workingAreaRef?.current) resizeFormatLimiters()
  }, [video?.data?.format])

  useEffect(() => {
    window.addEventListener('resize', stretchCanvases, { passive: true })
    return () => window.removeEventListener('resize', stretchCanvases)
  }, [stretchCanvases])

  return {
    calculateZoom,
    resizeFormatLimiters,
    clearFormatLimiters,
  }
}
