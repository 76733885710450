import { memo } from 'react'
import { Modal, Button, Table, Form, Input, Col, Row } from 'antd'

import { usePhonemeModal } from './usePhonemeModal'
import Icon from '../../../../../../../components/Icon'
import configService from '../../../../../../../utils/config'

const iconPlus = <Icon name="plus" />
const iconInfo = <Icon name="info" />

export const PhonemeModal = memo((props) => {
  const {
    data,
    voice,
    languages,
    audioCache,
    resetAudio,
    wordForDictionary,
    isOpenPhonemeModal,
    setWordForDictionary,
    setIsOpenPhonemeModal,
  } = props

  const {
    phonemes,
    components,
    columns,
    form,
    handleAdd,
    savePhonemes,
    onCancelModal,
    onSearchPhoneme,
    filteredPhonemes,
    isSavingPhonemes,
  } = usePhonemeModal({
    data,
    voice,
    languages,
    audioCache,
    resetAudio,
    wordForDictionary,
    setWordForDictionary,
    setIsOpenPhonemeModal,
  })

  const { hideElaiMentions } = configService.get().features

  return (
    <Modal
      centered
      open={isOpenPhonemeModal}
      width={700}
      className="phoneme-modal"
      destroyOnClose
      footer={[
        !hideElaiMentions && (
          <div key="info" className="info-wrapper">
            <span>{iconInfo}</span>
            <span>
              Check{' '}
              <a
                href="https://elai.freshdesk.com/support/solutions/articles/73000615467-phoneme-guide"
                target="_blank"
                rel="noreferrer"
              >
                this guide
              </a>{' '}
              on how to make pronunciation just right.
            </span>
          </div>
        ),
        <Button key="btn-save" type="primary" loading={isSavingPhonemes} onClick={savePhonemes}>
          Save
        </Button>,
      ]}
      onCancel={onCancelModal}
    >
      <h2>Phoneme Dictionary</h2>
      <p className="subheader">
        Your list of phonemes that will be used in all your videos across the account. Any word here will be spelled as
        in “Pronunciation“ column in all your videos.
      </p>
      <Row align="middle" justify="space-between">
        <Col>
          <Button type="primary" disabled={isSavingPhonemes} icon={iconPlus} className="btn-add" onClick={handleAdd}>
            Add phoneme
          </Button>
        </Col>
        <Col span={12}>
          <Input.Search
            className="input-search"
            placeholder="Input word"
            onSearch={onSearchPhoneme}
            enterButton
            allowClear
          />
        </Col>
      </Row>
      <Form form={form} component={false} validateTrigger="onSubmit">
        <Table
          components={components}
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={filteredPhonemes || phonemes}
          columns={columns}
          pagination={{
            pageSize: 6,
            hideOnSinglePage: true,
            showSizeChanger: false,
          }}
        />
      </Form>
    </Modal>
  )
})
