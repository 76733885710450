import { minutesAdvanced, minutesBasic } from '../data/plans'
import { request } from '../utils/api'

const getImpactClickId = () =>
  Promise.race([
    new Promise((resolve) => {
      window.ire('generateClickId', (clickid) => resolve(clickid))
    }),
    // Wait for 1 second and then resolve with undefined, if impact has not loaded correctly
    new Promise((resolve) => setTimeout(resolve, 1000)),
  ])

export const createCheckoutSession = async (data) => {
  const url = await request({
    method: 'post',
    url: 'payment/create-checkout-session',
    data: {
      ...data,
      referenceId: window.Rewardful?.referral || undefined,
      impactClickId: window.ire ? await getImpactClickId() : undefined,
    },
  })
  if (url) window.location.href = url
}

export const createRequestDataForPlanCheckout = async (purchaseData) => {
  const [plan, billingCycle, minutes] = purchaseData.split(',')
  const availableMinutes = plan === 'basic' ? minutesBasic : minutesAdvanced

  return {
    plan,
    annual: billingCycle === 'annual',
    minutes: availableMinutes.includes(parseInt(minutes)) ? parseInt(minutes) : availableMinutes[0],
    backUrl: '/',
  }
}

export const upgradeAccount = async (account) => {
  if (account.plan === 'unlimited') {
    window.open('https://calendly.com/elai_io/demo?utm_source=app', '_blank').focus()
    return
  }
  if (account.status === 'paid' && ['monthly', 'annual'].includes(account.billingCycle)) {
    const url = await request({ method: 'get', url: 'payment/create-portal-session' })
    window.location.href = url
    return
  }
  window.location.hash = '#subscription'
}
