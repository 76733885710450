import { fabric } from 'fabric'

const activeVideos = []
let isPlayingVideos = false

const getDuration = (obj) => Math.min(obj.getElement().duration, obj.trimEnd) - obj.trimStart

const getRealTime = (obj, time, duration = getDuration(obj)) =>
  ((obj.trimStart + time * obj.speedRate) % (obj.trimStart + duration)) -
  (obj.animation?.startTime ? obj.animation.startTime : 0)

const seek = (video, time) => {
  if (video.fastSeek) video.fastSeek(time)
  else video.currentTime = time
}

const changeSpeedRate = (obj, slideDuration) => {
  const videoDuration = Math.min(obj.video.duration, obj.trimEnd) - obj.trimStart
  if (obj.durationFitAudio && slideDuration) obj.speedRate = Math.round((videoDuration / slideDuration) * 100) / 100

  if (obj.speedRate > 16) obj.speedRate = 16
  if (obj.speedRate < 0.3) obj.speedRate = 0.3
}

let ellapsedTimeMs = 0
let nowMs

const playVideos = (canvas, time = 0) => {
  if (isPlayingVideos && !time) return
  isPlayingVideos = true

  activeVideos.length = 0
  ellapsedTimeMs = time * 1000

  const objects = getAllVideoObjects(canvas.getObjects())
  for (let i = 0; i < objects.length; i++) {
    const obj = objects[i]

    const duration = getDuration(obj)

    const video = obj.getElement()
    video.loop = obj.loop

    const realTime = getRealTime(obj, time, duration)
    if (!video.loop && realTime >= video.duration) {
      video.pause()
      continue
    }

    video.ontimeupdate = () => {
      if (video.currentTime >= obj.trimStart + duration) {
        if (obj.loop) video.currentTime = obj.trimStart
        else video.pause()
      }
    }

    if (obj.animation?.startTime && obj.animation.startTime * 1000 > ellapsedTimeMs) {
      if (!video.paused) {
        try {
          video.pause?.()
        } catch {
          // ignore
        }
      }
      video.currentTime = 0
      activeVideos.push({
        video,
        waiting: true,
        startAtMs: obj.animation.startTime * 1000,
        obj,
      })
    } else {
      seek(video, realTime)

      activeVideos.push({
        video,
        playPromise: video.play?.(),
        obj,
      })
    }
  }
  if (!activeVideos.length) return
  // start rendering loop
  nowMs = Date.now()
  fabric.util.requestAnimFrame(function render() {
    if (!canvas.contextContainer || !isPlayingVideos) return

    const currentMs = Date.now()
    ellapsedTimeMs += currentMs - nowMs
    nowMs = currentMs

    activeVideos.forEach((v) => {
      if (v.waiting && v.startAtMs <= ellapsedTimeMs) {
        v.waiting = false
        v.playPromise = v.video.play?.()
      }
    })

    fabric.util.requestAnimFrame(render)
  })
}

const seekVideos = async (canvas, slideDuration, time = 0) => {
  ellapsedTimeMs = time * 1000
  nowMs = Date.now()

  const objects = getAllVideoObjects(canvas.getObjects())
  const promises = []
  for (let i = 0; i < objects.length; i++) {
    const obj = objects[i]

    const video = obj.getElement()

    if (obj.animation?.startTime && obj.animation.startTime * 1000 > ellapsedTimeMs) {
      if (!video.paused) {
        try {
          video.pause?.()
        } catch {
          // ignore
        }
      }
      return
    }

    changeSpeedRate(obj, slideDuration)
    seek(video, getRealTime(obj, time))

    promises.push(
      new Promise((resolve) => {
        video.addEventListener('seeked', resolve, {
          once: true,
        })
      }),
    )
  }
  await Promise.all(promises)
  canvas.renderAll()
}

const pauseVideos = function () {
  isPlayingVideos = false

  activeVideos.forEach(({ playPromise, video, obj }) => {
    if (playPromise) {
      playPromise.then?.(() => {
        video.pause?.()
        video.onended = null
        video.ontimeupdate = null
      })
    } else {
      video.onended = null
      video.ontimeupdate = null
    }
    obj.set('visible', true)
  })
  activeVideos.length = 0
}

const resetVideosToStart = () => {
  pauseVideos()
  fabric.Video.resetVideosInCache()
}

const syncVideoState = (canvas, obj, changedFields, slideDuration) => {
  changeSpeedRate(obj, slideDuration)

  const video = obj.getElement()
  video.playbackRate = obj.speedRate
  video.volume = obj.volume

  let renderRequired = false
  if ((changedFields && changedFields.trimStart) || video.currentTime < obj.trimStart) {
    video.currentTime = obj.trimStart
    renderRequired = true
  }
  if ((changedFields && changedFields.trimEnd) || video.currentTime > obj.trimEnd) {
    video.currentTime = obj.trimEnd
    renderRequired = true
  }
  if (renderRequired)
    video.addEventListener('seeked', () => canvas.renderAll(), {
      once: true,
    })
}

const syncVideosWithDuration = (canvas, slideDuration) => {
  const objects = getAllVideoObjects(canvas.getObjects())
  objects.forEach((obj) => {
    syncVideoState(canvas, obj, null, slideDuration)
  })
}

const getAllVideoObjects = (objects, result = []) => {
  for (const obj of objects) {
    if (obj.type === 'video') result.push(obj)
    else if (obj.type === 'group') getAllVideoObjects(obj.getObjects(), result)
  }
  return result
}

export { playVideos, seekVideos, pauseVideos, syncVideoState, syncVideosWithDuration, resetVideosToStart }
