import { Link } from 'react-router-dom'
import { Row, Col, Button } from 'antd'
import Icon from '../../../../components/Icon'
import webcam_avatar from '../../../../assets/images/public_custom_avatar/webcam-avatar.png'
import photo_avatar from '../../../../assets/images/public_custom_avatar/personal-cartoon.png'
import cartoon_avatar from '../../../../assets/images/public_custom_avatar/animated-mascot.png'

const Avatars = () => {
  return (
    <div className="avatars-container content-wrapper">
      <h2 className="section-title">Create a lifelike digital twin with realistic voice and appearance</h2>
      <Row className="cards-container" gutter={[34, 30]}>
        <Col lg={9} md={0} sm={0} xs={0}>
          <div className="avatar-card selfie-avatar">
            <h3>Selfie Avatar</h3>
            <p>Use a smartphone or web camera to create an animated Avatar of yourself in no time</p>
            <img src={webcam_avatar} alt="Selfie avatar" />
            <Link to="/buy-avatar#selfie" target="_blank">
              <Button type="link" size="large" className="btn-get-started">
                Create now <Icon name="right_full_arrow" />
              </Button>
            </Link>
          </div>
        </Col>
        <Col lg={15} md={24} sm={24} xs={24}>
          <Row gutter={[20, 20]} style={{ height: '100%' }}>
            <Col span={24}>
              <div className="avatar-card studio-avatar">
                <h3>Studio Avatar</h3>
                <p>
                  Get a professional-looking Talking presenter that will be available only for you. It only requires
                  high-quality studio video footage!
                </p>
                <Link to="/buy-avatar#studio" target="_blank">
                  <Button type="link" size="large" className="btn-get-started">
                    Create now <Icon name="right_full_arrow" />
                  </Button>
                </Link>
              </div>
            </Col>
            <Col lg={12} md={8} sm={8} xs={24}>
              <div className="avatar-card photo-avatar">
                <h3>Photo Avatar</h3>
                <div className="card-content-container">
                  <p>Create your Cartoon Talking head just from a single photo</p>
                  {/* eslint-disable-next-line jsx-a11y/img-redundant-alt*/}
                  <img src={photo_avatar} alt="Photo avatar" />
                </div>

                <Link to="/buy-avatar#photo" target="_blank">
                  <Button type="link" size="large" className="btn-get-started">
                    Create now <Icon name="right_full_arrow" />
                  </Button>
                </Link>
              </div>
            </Col>
            <Col lg={12} md={8} sm={8} xs={24}>
              <div className="avatar-card cartoon-avatar">
                <h3>Animated Mascot</h3>
                <div className="card-content-container">
                  <p>Get your fully customized animated talking character</p>
                  <img src={cartoon_avatar} alt="Cartoon mascot" />
                </div>
                <Link to="/buy-avatar#mascot" target="_blank">
                  <Button type="link" size="large" className="btn-get-started">
                    Create now <Icon name="right_full_arrow" />
                  </Button>
                </Link>
              </div>
            </Col>
            <Col lg={0} md={8} sm={8} xs={24}>
              <div className="avatar-card selfie-avatar">
                <h3>Selfie Avatar</h3>
                <div className="card-content-container">
                  <p>Use a smartphone or web camera to create an animated Avatar of yourself in no time</p>
                  <img src={webcam_avatar} alt="Selfie avatar" />
                </div>
                <Link to="/buy-avatar#selfie" target="_blank">
                  <Button type="link" size="large" className="btn-get-started">
                    Create now <Icon name="right_full_arrow" />
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default Avatars
