import { useState } from 'react'
import { track } from '../utils/analytics'

export default () => {
  const [isPaymentModalOpen, setIsPaymentModalOpenInner] = useState(false)
  const [isNewVideoModalOpen, setIsNewVideoModalOpen] = useState(false)
  const [header, setHeader] = useState({ title: '', icon: null, link: '', extra: null })

  const setIsPaymentModalOpen = (open) => {
    if (open) {
      track('payment_modal_open')
    }
    setIsPaymentModalOpenInner(open)
  }

  return {
    isPaymentModalOpen,
    setIsPaymentModalOpen,
    isNewVideoModalOpen,
    setIsNewVideoModalOpen,
    header,
    setHeader,
  }
}
