import axios from 'axios'
import { fabric } from 'fabric'
import { backoffMs, isSignedUrl, queueMicrotask, sleep, elaiNotification as notification } from '../helpers'

const isSafari = navigator.userAgent.indexOf('Chrome') === -1 && navigator.userAgent.indexOf('Safari') > -1

const videoCache = new Map()

const videoOriginStates = new Map()
const videoChangedStates = new Map()

const defaultHeight = 240

let slowConnection = false
let saveData = false

const checkConnection = () => {
  slowConnection = navigator.connection.effectiveType !== '4g' || navigator.connection.downlink <= 1.4
  saveData = !!navigator.connection.saveData
}

if (navigator.connection?.effectiveType) {
  checkConnection()
  navigator.connection.addEventListener('change', checkConnection)
}

const once = (element, eventName, callback) => element.addEventListener(eventName, callback, { once: true })

const checkVideoIsRemoved = async (url) => {
  const videoReq = await fetch(url, { mode: 'cors' })
  if (!videoReq.ok && videoReq.status === 403 && url.includes('pexels.com')) return true
  return !videoReq.ok && [404, 410].includes(videoReq.status)
}

const Video = fabric.util.createClass(fabric.Image, {
  type: 'video',
  bg: false,
  loop: false,
  durationFitAudio: false,
  speedRate: 1,
  volume: 0,
  hasAudio: false,
  objectCaching: false,
  lockRotation: true,
  lockSkewingX: true,
  lockSkewingY: true,
  lockScalingFlip: true,

  initialize: function (video, options = {}) {
    options.originState = videoOriginStates.get(options.id)
    this.originState = options.originState
    this.src = video.src
    this.initialSrc = video.initialSrc
    this.video = video
    if ('_hasAudio' in video) this.hasAudio = video._hasAudio
    if (video.videoWidth) {
      this.width = video.videoWidth
      this.height = video.videoHeight
      video.width = this.width
      video.height = this.height
    }
    options.width = options.width || video.videoWidth
    options.height = options.height || video.videoHeight
    options.scaleX = options.scaleX || Math.ceil((defaultHeight / video.videoHeight) * 100) / 100
    options.scaleY = options.scaleY || options.scaleX
    options.trimStart = options.trimStart || 0
    options.trimEnd = options.trimEnd || video.duration
    this.animation = options.animation
    this.thumbnail = options.thumbnail
    if (options.speedRate) video.playbackRate = options.speedRate
    video.volume = options.volume || 0

    if (!video._loaded && !video.currentTime) video.currentTime = options.trimStart

    if (video._fallback && options.thumbnail) {
      this.convertToFallback(video, options)

      this.callSuper('initialize', video.thumbnail, options)
    } else {
      this.convertFromFallback(options)
      this.callSuper('initialize', video, options)
    }

    this.setControlsVisibility({ mt: false, ml: false, mr: false, mb: false, mtr: false })

    const obj = this

    if (video._loaded) return queueMicrotask(() => obj.canvas?.renderAll())
    if (!video._fallback) {
      if (saveData) {
        return queueMicrotask(() => obj.canvas?.renderAll())
      }

      once(video, 'loadeddata', () => {
        video._loaded = true
        queueMicrotask(() => obj.canvas?.renderAll())
      })
    }
  },

  convertToFallback: function (video, options) {
    // for sharing between newly recreated instances
    if (!options.originState) {
      options.originState = {
        scaleX: options.scaleX,
        scaleY: options.scaleY,
        width: options.width,
        height: options.height,
        top: options.top,
      }
      videoOriginStates.set(options.id, options.originState)
    }
    let changedState = videoChangedStates.get(options.id)
    if (changedState) {
      options.scaleX = changedState.scaleX
      options.scaleY = changedState.scaleY
      options.width = changedState.width
      options.height = changedState.height
    } else {
      const width = options.originState.scaleX * options.originState.width
      const height = options.originState.scaleY * options.originState.height
      options.scaleX = width / video.thumbnail.width
      options.scaleY = height / video.thumbnail.height
      options.width = video.thumbnail.width
      options.height = video.thumbnail.height
    }

    // save in current instance for return correct values in toObject()
    this.originState = options.originState
  },

  convertFromFallback: function (options) {
    if (!options.originState) return

    let changedState = videoChangedStates.get(options.id)
    if (changedState) {
      const width = changedState.scaleX * changedState.width
      const height = changedState.scaleY * changedState.height
      options.scaleX = width / options.originState.width
      options.scaleY = height / options.originState.height
      options.width = options.originState.width
      options.height = options.originState.height
      videoChangedStates.delete(options.id)
    } else {
      options.scaleX = options.originState.scaleX
      options.scaleY = options.originState.scaleY
      options.width = options.originState.width
      options.height = options.originState.height
    }
    delete options.originState
    delete this.originState
    videoOriginStates.delete(options.id)
  },

  calculateRealDimensions: function () {
    if (this.video?._fallback && !this.originState) this.originState = videoOriginStates.get(this.id)

    let scaleX = this.originState?.scaleX || this.scaleX
    let scaleY = this.originState?.scaleY || this.scaleY
    let width = this.originState?.width || this.width
    let height = this.originState?.height || this.height
    let top = this.top
    let left = this.left
    if (this.video?._fallback && this.originState) {
      videoChangedStates.set(this.id, {
        scaleX: this.scaleX,
        scaleY: this.scaleY,
        width: this.width,
        height: this.height,
      })
      const newWidth = this.scaleX * this.width
      const newHeight = this.scaleY * this.height
      scaleX = newWidth / this.originState.width
      scaleY = newHeight / this.originState.height
      width = this.originState.width
      height = this.originState.height
      if (this.bg) {
        let scale
        if (width >= height) {
          scale = (Math.ceil((360 / height) * 100) / 100) * Math.max(1, 640 / 360 / (width / height))
        } else {
          scale = Math.ceil((640 / width) * 100) / 100
        }
        top = (360 - height * scale) / 2
        left = (640 - width * scale) / 2
        scaleX = scale
        scaleY = scale
      }
    }
    return { scaleX, scaleY, width, height, top, left }
  },

  toObject: function () {
    const { scaleX, scaleY, width, height, top, left } = this.calculateRealDimensions()
    return fabric.util.object.extend(this.callSuper('toObject'), {
      id: this.id,
      src: this.initialSrc || this.src,
      bg: this.bg,
      loop: this.loop,
      durationFitAudio: this.durationFitAudio,
      speedRate: this.speedRate,
      volume: this.volume,
      hasAudio: this.hasAudio,
      trimStart: this.trimStart,
      trimEnd: this.trimEnd,
      animation: this.animation,
      thumbnail: this.thumbnail,
      meta: this.meta,
      scaleX,
      scaleY,
      width,
      height,
      top,
      left,
    })
  },
})

Video.fromObject = function (object, callback) {
  Video.preloadData(object.src, object.thumbnail)
    .then((video) => callback(new fabric.Video(video, object)))
    .catch(() => callback(null, true))
}

const prepareThumbnailFallback = (url) =>
  new Promise((resolve, reject) => {
    const videoFallback = { _fallback: true }
    const image = new Image()
    image.src = url
    image.crossOrigin = 'anonymous'
    image.onload = () => {
      videoFallback.thumbnail = image
      resolve(videoFallback)
    }
    image.onerror = reject
  })

/**
 * Pre-loading video data
 * added video to canvas fails to load
 * throw an error
 */
Video.preloadData = (url, thumbnailFallback, attempts = 0) => {
  const controller = new AbortController()
  return Promise.race([
    getVideo(url, controller),
    thumbnailFallback
      ? new Promise((resolve, reject) =>
          setTimeout(
            () => {
              if (controller.signal.aborted) return

              prepareThumbnailFallback(thumbnailFallback).then(resolve, reject)
            },
            slowConnection ? 10000 : 5000,
          ),
        )
      : new Promise((_, reject) =>
          setTimeout(
            () =>
              reject(
                new Error(
                  `Failed to load video from ${url}. Please try again later. Target resource might be unavailble right now.`,
                ),
              ),
            30000,
          ),
        ),
  ]).catch(async (err) => {
    if (!err.gone && attempts < 3) {
      await sleep(backoffMs(attempts))
      return Video.preloadData(url, thumbnailFallback, attempts + 1)
    }
    if (thumbnailFallback) {
      const video = await prepareThumbnailFallback(thumbnailFallback)
      // if it's a video from pexels, so we can mark it as removed and show default modal about missing media
      video.removed = err.gone && (url.includes('player.vimeo.com') || url.includes('pexels.com'))
      return video
    }
    notification.warning({
      message: 'Sorry, seems like this media is not available at the moment. Please try to choose different one.',
    })
    throw err
  })
}

const activeVideoInitializations = {}

const waitForVideoData = (video) =>
  new Promise((resolve, reject) => {
    once(video, 'loadedmetadata', () => {
      video._loaded = true
      resolve(video)
    })
    video.onerror = (err) => reject(video.error ?? err)
  })

const checkAudio = (video) => {
  return video.mozHasAudio || Boolean(video.webkitAudioDecodedByteCount) || Boolean(video.audioTracks?.length)
}

const checkVideoGotAudio = (video) => {
  return new Promise((resolve, reject) => {
    if (isSafari) video.currentTime = 0.99
    else once(video, 'canplay', () => (video.currentTime = 0.99))

    once(video, 'seeked', () => {
      // return to beginning
      video.currentTime = 0
      once(video, 'seeked', () => {
        video.removeEventListener('error', reject)
        resolve(checkAudio(video))
      })
    })

    once(video, 'error', reject)
  })
}

const getVideo = async (url, abortController) => {
  if (activeVideoInitializations[url]) await activeVideoInitializations[url]

  if (videoCache.has(url)) return videoCache.get(url)

  let resolve
  let reject
  activeVideoInitializations[url] = new Promise((_resolve, _reject) => {
    resolve = _resolve
    reject = _reject
  })

  const finish = () => {
    resolve()
    delete activeVideoInitializations[url]
    abortController.abort()
  }

  const video = document.createElement('video')
  video.setAttribute('accel-video', true)
  video.setAttribute('preload', 'metadata')
  video.setAttribute('playsinline', true)
  video.setAttribute('disablepictureinpicture', true)
  video.setAttribute('crossorigin', 'anonymous')
  video.style.display = 'none'
  video.loop = false
  video.autoplay = false

  if (await checkVideoIsRemoved(url)) {
    const err = new Error(`Video ${url} was deleted`)
    err.gone = true
    finish()
    throw err
  }

  if (isSafari) {
    const {
      request: { responseURL },
    } = !isSignedUrl(url) ? await axios.head(url).catch(() => ({ request: {} })) : { request: {} }
    // Safari has a bug with CORS when url contains redirect, so we need to retrieve and use the final url
    if (responseURL && responseURL !== url) {
      video.src = responseURL
      video.initialSrc = url
    } else video.src = url
  } else {
    video.src = url
  }

  await waitForVideoData(video).catch((err) => {
    reject(err)
    delete activeVideoInitializations[url]
    throw err
  })
  if (video.duration === Infinity) {
    video.currentTime = 1e101
    video.ontimeupdate = () => {
      video.ontimeupdate = null
      video.currentTime = 0
    }
  }

  const hasAudio = await checkVideoGotAudio(video)
  video.currentTime = 0
  video._hasAudio = hasAudio
  videoCache.set(url, video)
  finish()
  return video
}

Video.dropCache = () => {
  videoCache.clear()
  videoOriginStates.clear()
  videoChangedStates.clear()
}

Video.resetVideosInCache = () => videoCache.forEach((video) => (video.currentTime = 0))

export { Video as default }
