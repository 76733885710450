import Scrollbars from 'react-custom-scrollbars'

import QuestionControls from './components/questionControls'
import { GeneralControls } from './components/generalControls'

import './interactivity.less'

const Interactivity = ({
  data,
  video,
  activeSlide,
  canvasActiveObject,
  setActiveObjectModifier,
  updateSlide,
  handleBackArrowClick,
}) => {
  return (
    <Scrollbars className="interactivity-scrollbar scrollbar">
      <div className="interactivity tab-content">
        {canvasActiveObject && canvasActiveObject.type === 'question' ? (
          <QuestionControls
            video={video}
            activeSlide={activeSlide}
            canvasActiveObject={canvasActiveObject}
            handleBackArrowClick={handleBackArrowClick}
            setActiveObjectModifier={setActiveObjectModifier}
          />
        ) : (
          <GeneralControls
            data={data}
            video={video}
            activeSlide={activeSlide}
            updateSlide={updateSlide}
            setActiveObjectModifier={setActiveObjectModifier}
          />
        )}
      </div>
    </Scrollbars>
  )
}

export default Interactivity
