import whiteLogoUrl from '../assets/images/logo_white.svg'
import logoUrl from '../assets/images/logo.svg'
import favIcon from '../assets/images/favicon_elai.ico'
import { deepMerge } from './helpers'

const defaultConfig = {
  theme: {
    defaultAvatarCode: process.env.REACT_APP_DEFAULT_AVATAR_CODE || 'neyson.business',
    logoUrl,
    whiteLogoUrl,
    favIcon,
    title: 'Elai.io - Generate L&D and marketing videos with humans from just text',
    description:
      'Elai.io is a leading L&D AI video platform that allows you to build customized AI videos with a presenter using only text.',
  },
  features: {
    hideElaiMentions: false,
    hideAvatarsCreation: false,
    signupLinksEnabled: true,
    hideAcademy: false,
  },
  urls: {
    apiUrl: process.env.REACT_APP_API_URL || 'https://apis.elai.io',
    playerUrl: process.env.REACT_APP_PLAYER_URL || 'https://player.elai.io',
    chatUrl: process.env.REACT_APP_CHAT_URL,
  },
  keys: {
    pexels: process.env.REACT_APP_PEXELS_KEY,
    giphy: process.env.REACT_APP_GIPHY_KEY,
    thinkific: process.env.REACT_APP_THINKIFIC_KEY,
    turnstile: process.env.REACT_APP_TURNSTILE_SITE_KEY,
  },
  env: {
    isTesting: process.env.NODE_ENV === 'development' || process.env.CI === 'true',
    globalProd: process.env.NODE_ENV === 'production',
  },
}

class ConfigService {
  static instance = null
  static config = null
  #isConfigExists = true

  constructor() {
    if (ConfigService.instance) return ConfigService.instance
    ConfigService.instance = this
  }

  async load(url, revalidate) {
    if (this.config && !revalidate) return this.config

    try {
      if (!this.#isConfigExists) throw new Error()
      const response = await fetch(url)
      if (!response.ok) throw new Error()
      const data = await response.json()
      this.config = deepMerge(defaultConfig, data)
      if (data.domain) this.config.env.globalProd = false
    } catch {
      this.#isConfigExists = false
      this.config = defaultConfig
    }
    return this.config
  }

  get() {
    if (!this.config) {
      throw new Error('Config not loaded yet. Call loadConfig() first.')
    }
    return this.config
  }

  async revalidate(url) {
    return this.load(url, true)
  }
}

const configService = new ConfigService()

export default configService
