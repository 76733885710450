import { Carousel } from 'antd'
import { useEffect, useRef } from 'react'

import { isSignedUrl } from '../utils/helpers'

/**
 *
 * @param {collection, id, onCarouselClick} props
 * @returns JSX element ( antd carousel)
 */
const SlidesCarousel = ({ collection, id, onCarouselClick }) => {
  const carousels = useRef([])
  let playSlidesTimer = useRef()

  const playSlides = (id, carousels) => {
    carousels.current[id].next()
    stopSlides()
    playSlidesTimer.current = setInterval(() => {
      carousels.current[id].next()
    }, 400)
  }

  const stopSlides = () => clearInterval(playSlidesTimer.current)

  useEffect(() => {
    return () => stopSlides()
  }, [])

  const onMouseEnter = () => {
    playSlides(id, carousels)
  }

  const onMouseLeave = () => {
    stopSlides()
  }

  const onClick = () => {
    stopSlides()
    if (onCarouselClick) onCarouselClick(collection)
  }

  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={onClick} className="full-height">
      <Carousel
        rootClassName="full-height"
        ref={(el) => (carousels.current[id] = el)}
        dots={false}
        style={{ cursor: 'pointer', height: '100%' }}
      >
        {collection.items.map((item) => (
          <div className="full-height" key={item.id}>
            <img
              key={item.id}
              src={item.src}
              loading="lazy"
              className="carousel-img full-height"
              {...(isSignedUrl(item.src) ? { crossOrigin: 'anonymous' } : {})}
            />
          </div>
        ))}
      </Carousel>
    </div>
  )
}

export default SlidesCarousel
