import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDrag, useDrop, useDragLayer } from 'react-dnd'
import { Card, Dropdown, Button, Modal } from 'antd'
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import Icon from '../../components/Icon'
import { request } from '../../utils/api'
import ItemPopconfirm from './itemPopconfirm'
import { useElaiNotification } from '../../hooks/useElaiNotification'

const { confirm } = Modal

const FolderItem = ({ folder, folderId, isDeletedVideos, activeItem, setActiveItem, fetchFolders, fetchVideos }) => {
  const navigate = useNavigate()
  const notification = useElaiNotification()
  const [listItemButtonVisible, setListItemButtonVisible] = useState(false)
  const [isFolderLoading, setIsFolderLoading] = useState(false)
  const [popconfirmAction, setPopconfirmAction] = useState(false)

  const [{ isOver }, drop] = useDrop(() => ({
    accept: ['folder', 'video'],
    drop: async (item) => {
      if (item && folder && item._id !== folder._id) {
        await moveToFolder(item, folder._id)
        notification.success({
          key: 'moved',
          duration: 8,
          message: (
            <span>
              "{item.name}" was moved to the "{folder.name}"
              <Button
                type="link"
                style={{ fontWeight: 500, fontSize: 'inherit' }}
                onClick={async () => {
                  await moveToFolder(item, folderId)
                  notification.destroy('moved')
                }}
              >
                Cancel
              </Button>
            </span>
          ),
        })
      }
    },
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }
    },
  }))

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'folder',
      item: folder,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [folder],
  )

  const { itemType } = useDragLayer((monitor) => ({
    itemType: monitor.getItemType(),
  }))

  const moveToFolder = async (item, folderId) => {
    if (item.slides) {
      await request({ method: 'patch', url: `/videos/${item._id}`, data: { folderId: folderId || !!folderId } })
      await fetchVideos()
    } else {
      await request({ method: 'patch', url: `/folders/${item._id}`, data: { parentFolderId: folderId || !!folderId } })
      await fetchFolders()
    }
  }

  const handleClickFolderMenu = async (folder, { key, domEvent }) => {
    domEvent.stopPropagation()
    if (key === 'rename' || key === 'delete' || key === 'move') return setPopconfirmAction(key)
    if (key === 'duplicate') copyFolder(folder._id)
    else if (key === 'restore') restoreFolder(folder._id)
  }

  const saveFolderName = async (editingData) => {
    const { id, name } = editingData
    if (!name) return notification.error({ message: 'Folder name is required' })
    await request({ method: 'patch', url: `/folders/${id}`, data: { name } })
    await fetchFolders()
  }

  const deleteFolder = async () => {
    if (isDeletedVideos) await request({ method: 'delete', url: `folders/${folder._id}` })
    else {
      await request({ method: 'patch', url: `/folders/${folder._id}`, data: { deleted: true } })
    }
    await fetchFolders()
  }

  const restoreFolder = async (id) => {
    setIsFolderLoading(id)
    await request({ method: 'patch', url: `/folders/${id}`, data: { deleted: false } })
    await fetchFolders()
    setIsFolderLoading(false)
  }

  const copyFolder = async (id) => {
    setIsFolderLoading(id)
    await request({ method: 'post', url: `/folders/copy/${id}` })
    await fetchFolders()
    setIsFolderLoading(false)
  }

  const moveFolder = async (id) => {
    await request({ method: 'patch', url: `/folders/${folder._id}`, data: { parentFolderId: id || !!id } })
    await fetchFolders()
  }

  const showRestoringConfirm = (id) => {
    confirm({
      title: 'This folder has been deleted',
      icon: <ExclamationCircleOutlined />,
      content: 'Restore this folder to view its contents',
      okText: 'Restore',
      onOk() {
        return restoreFolder(id)
      },
    })
  }

  const getFolderMenuItems = (folder) =>
    isDeletedVideos
      ? [
          {
            label: 'Restore',
            key: 'restore',
            icon: isFolderLoading === folder._id ? <LoadingOutlined /> : <Icon name="redo" />,
          },
          {
            label: 'Delete',
            key: 'delete',
            icon: <Icon name="delete" />,
          },
        ]
      : [
          {
            label: 'Rename',
            key: 'rename',
            icon: <Icon name="edit" />,
          },
          {
            label: 'Move',
            key: 'move',
            icon: <Icon name="move_folder" />,
          },
          {
            label: 'Duplicate',
            key: 'duplicate',
            icon: isFolderLoading === folder._id ? <LoadingOutlined /> : <Icon name="copy" />,
          },
          {
            label: 'Delete',
            key: 'delete',
            icon: <Icon name="delete" />,
          },
        ]

  return (
    <ItemPopconfirm
      item={folder}
      isDeletedVideos={isDeletedVideos}
      popconfirmAction={popconfirmAction}
      setPopconfirmAction={setPopconfirmAction}
      setActiveItem={setActiveItem}
      setListItemButtonVisible={setListItemButtonVisible}
      saveItemName={saveFolderName}
      deleteItem={deleteFolder}
      moveItem={moveFolder}
      fetchFolders={fetchFolders}
      itemComponent={
        <div ref={drop} className={isOver && !isDragging ? 'folder-card-active' : ''}>
          {itemType === 'folder' ? (
            <Card className="folder-card">
              <Icon name="folder" />
              <p>{folder.name}</p>
            </Card>
          ) : (
            <Card
              ref={drag}
              style={{ opacity: isDragging ? 0.4 : 1 }}
              className="folder-card"
              onClick={() => (isDeletedVideos ? showRestoringConfirm(folder._id) : navigate(`/videos/${folder._id}`))}
            >
              {!activeItem && (
                <Dropdown
                  menu={{
                    items: getFolderMenuItems(folder),
                    style: { width: 190, padding: '20px 4px' },
                    onClick: (e) => handleClickFolderMenu(folder, e),
                  }}
                  trigger={['click']}
                  onOpenChange={(open) => setListItemButtonVisible(open ? folder._id : false)}
                >
                  <Button
                    icon={<Icon name="menu" />}
                    className={`btn-dropdown-menu ${listItemButtonVisible === folder._id ? 'visible' : ''}`}
                    onClick={(e) => e.stopPropagation()}
                  ></Button>
                </Dropdown>
              )}
              <Icon name="folder" />
              <p>{folder.name}</p>
            </Card>
          )}
        </div>
      }
    />
  )
}

export default FolderItem
