import { RightOutlined } from '@ant-design/icons'
import { Input, Button } from 'antd'
import React, { useRef, useEffect } from 'react'
import { useElaiNotification } from '../../../hooks/useElaiNotification'

const DOMAIN_BLACKLIST = ['medium.com', 'amazon.com', 'aliexpress', 'facebook.com', 'g2.com']
const rightOutlinedIcon = <RightOutlined />
const marginBottom = { marginBottom: 10 }

const isValidText = (text) => {
  if (text.startsWith('http')) {
    if (text.split('\n').length > 1 || /\s/g.test(text)) return false
    if (DOMAIN_BLACKLIST.some((domain) => text.includes(domain))) throw new Error('Unsupported domain')
    return true
  }

  const doc = new DOMParser().parseFromString(text, 'text/html')
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1)
}

export default ({ htmlToVideo, setHtmlToVideo, setIsGenerationProgressVisible, handleSubmit, isVideoCreating }) => {
  const notification = useElaiNotification()

  const inputRef = useRef(null)

  useEffect(() => {
    setTimeout(() => inputRef.current?.focus({ cursor: 'end' }), 100)
  })

  const handlePaste = (e) => {
    try {
      let text = e.clipboardData.getData('text')
      // when copy-paste raw html or link, 'text' prevents escaping
      if (isValidText(text)) {
        return setHtmlToVideo(text)
      }
      // when copy-paste html from page with selection some part or ctrl+a
      text = e.clipboardData.getData('text/html')
      if (isValidText(text)) setHtmlToVideo(text)
    } catch (err) {
      notification.info({
        message: err.message,
        description:
          "Sorry, but we don't support links from this source: select and copy-paste relevant content directly from an article.",
        duration: 10,
      })
      setHtmlToVideo(null)
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace' || (e.key === 'Escape' && htmlToVideo.length)) {
      e.stopPropagation()
      setHtmlToVideo(null)
    }
  }

  // submit
  const handleClick = () => {
    // clear input after button click
    setIsGenerationProgressVisible(true)
    handleSubmit()
  }

  return (
    <div className="text-container">
      <ul style={{ marginLeft: '4px' }}>
        <li>Copy blog post url OR text directly from the article</li>
        <li>
          This process can be automated using{' '}
          <a target="_blank" href="https://elai.readme.io/reference/video-generation-api" rel="noreferrer">
            API
          </a>{' '}
          and custom templates
        </li>
      </ul>
      <Input.TextArea
        ref={inputRef}
        rows={isVideoCreating ? 4 : 6}
        value={htmlToVideo}
        onPaste={handlePaste}
        onKeyDown={handleKeyDown}
        placeholder="Paste HTML or link to a web page here"
        style={marginBottom}
      />
      <Button
        type="primary"
        className="btn-arrow"
        icon={rightOutlinedIcon}
        disabled={!htmlToVideo}
        onClick={handleClick}
        loading={isVideoCreating}
      >
        Generate slides
      </Button>
    </div>
  )
}
