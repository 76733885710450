import { SearchOutlined } from '@ant-design/icons'

export const STYLE = {
  padding0: { padding: 0 },
  flex: { display: 'flex' },
  width100: { width: '100%' },
  marginRight16: { marginRight: 16 },
  emptyImage: { margin: '10% auto' },
  marginVertical6: { margin: '6px 0' },
  marginBottom16: { marginBottom: 16 },
  marginBottom5: { marginBottom: '5px' },
  pageHeader: { padding: '0 0 16px 8px' },
  header: { display: 'flex', alignItems: 'center' },
  title: { display: 'flex', justifyContent: 'space-between' },
  bodyStyle: {
    overflow: 'hidden',
    background: '#f3f6fb',
    padding: '20px 30px 0 30px',
  },
}

export const searchOutlinedIcon = <SearchOutlined />
