import { Row, Col, Modal, Segmented, Popconfirm } from 'antd'

import CustomAvatarsTab from './components/customAvatarsTab'
import AvatarsLibraryTab from './components/avatarsLibraryTab'
import { LayerControl } from '../../../../components/LayerControl'
import ColorEditorButton from '../../colorsEditor/colorEditorButton'
import CreatePhotoAvatarModal from '../../../../components/CreatePhotoAvatarModal/createPhotoAvatarModal'

import { style, OPTIONS, TAB_VALUES } from './constants'
import { useAvatarsTabState } from './useAvatarsTabState'

import './avatars.less'

export const Avatars = (props) => {
  const { data, video, canvasActiveObject, activeObjectModifier, setActiveObjectModifier, updateVideo } = props

  const {
    avatarsData,
    avatarList,
    filteredAvatars,
    title,
    customAvatars,
    setSearchFilter,
    avatarDrawer,
    setAvatarDrawer,
    isAdmin,
    activeAvatar,
    setActiveAvatar,
    isOpenPhotoAvatarModal,
    setIsOpenPhotoAvatarModal,
    activeAvatarTab,
    isOpenConfirmAvatar,
    isOpenConfirmType,
    filters,
    setFilters,
    isOpenFiltersPopup,
    setIsOpenFiltersPopup,
    popconfirmApplyProps,
    onChooseAvatar,
    onAvatarClick,
    handleAvatarType,
    handleAvatarTypeClick,
    onChangeColor,
    onAvatarDrawerChange,
    addListeningAvatar,
    handleChangeAvatarsTab,
    handleClickPhotoAvatar,
    onPopconfirmTypeApply,
    onPopconfirmTypeOpenChange,
    onPopconfirmAvatarApply,
    onPopconfirmAvatarOpenChange,
    onChooseAvatarViaPreviewModal,
    popConfirmOption,
    resetActiveAvatar,
  } = useAvatarsTabState({ data, video, canvasActiveObject, setActiveObjectModifier, updateVideo })

  return (
    <>
      <div className="avatars tab-content">
        {canvasActiveObject?.type === 'avatar' && (
          <>
            <Segmented
              value={canvasActiveObject.avatarType}
              className="segmented-default avatar-type"
              block
              options={OPTIONS}
              onChange={handleAvatarType}
              onClick={handleAvatarTypeClick}
            />
            <Popconfirm
              {...popconfirmApplyProps}
              overlayClassName="avatar-apply-all-popconfirm dark-popconfirm"
              // show Popconfirm only when activeObjectModifier is dropped (null), so can be sure that new version of avatar is applied to slide
              open={isOpenConfirmType && !activeObjectModifier}
              title={
                <>
                  <span className="current-label">Current: </span>
                  <span className="type-label">{popConfirmOption.title}</span>
                </>
              }
              placement={popConfirmOption.placement}
              onConfirm={onPopconfirmTypeApply}
              onOpenChange={onPopconfirmTypeOpenChange}
            >
              <div style={style.width100} />
            </Popconfirm>
            <Row
              align="middle"
              justify={canvasActiveObject.avatarType === 'circle' ? 'center' : 'space-between'}
              style={style.marginTop16}
              className="layer-control"
            >
              <Col span={4}>
                {canvasActiveObject.avatarType === 'circle' ? (
                  <ColorEditorButton color={canvasActiveObject.fill} onChangeColor={onChangeColor} />
                ) : (
                  <h4 style={style.margin0}>Layering</h4>
                )}
              </Col>
              <LayerControl setActiveObjectModifier={setActiveObjectModifier} />
            </Row>
          </>
        )}
        <Segmented
          block
          className="segmented-default avatars-libararies"
          options={TAB_VALUES}
          onChange={handleChangeAvatarsTab}
        />
        <AvatarsLibraryTab
          data={data}
          filteredAvatars={filteredAvatars}
          avatarList={avatarList}
          activeAvatarTab={activeAvatarTab}
          avatarsData={avatarsData}
          setSearchFilter={setSearchFilter}
          avatarDrawer={avatarDrawer}
          setAvatarDrawer={setAvatarDrawer}
          title={title}
          isAdmin={isAdmin}
          filters={filters}
          setFilters={setFilters}
          isOpenFiltersPopup={isOpenFiltersPopup}
          setIsOpenFiltersPopup={setIsOpenFiltersPopup}
          setIsOpenPhotoAvatarModal={setIsOpenPhotoAvatarModal}
          setActiveAvatar={setActiveAvatar}
          onAvatarClick={onAvatarClick}
          onChooseAvatar={onChooseAvatar}
          onAvatarDrawerChange={onAvatarDrawerChange}
          addListeningAvatar={addListeningAvatar}
          handleClickPhotoAvatar={handleClickPhotoAvatar}
        />
        <CustomAvatarsTab
          data={data}
          title={title}
          avatarDrawer={avatarDrawer}
          activeAvatarTab={activeAvatarTab}
          customAvatars={customAvatars}
          isAdmin={isAdmin}
          onAvatarClick={onAvatarClick}
          onChooseAvatar={onChooseAvatar}
          setIsOpenPhotoAvatarModal={setIsOpenPhotoAvatarModal}
          setActiveAvatar={setActiveAvatar}
          onAvatarDrawerChange={onAvatarDrawerChange}
        />
        <Modal
          className="avatar-intro-modal"
          title={activeAvatar?.avatar.name}
          open={!!activeAvatar}
          destroyOnClose
          onOk={onChooseAvatarViaPreviewModal}
          okText="Choose"
          onCancel={resetActiveAvatar}
          cancelText="Close"
        >
          <video
            controls
            controlsList="nodownload"
            src={
              !isNaN(activeAvatar?.variantIndex)
                ? activeAvatar.avatar.variants[activeAvatar.variantIndex].intro
                : activeAvatar?.avatar.intro || activeAvatar?.avatar.variants?.[0].intro
            }
          >
            Your browser doesn&apost support video player
          </video>
        </Modal>
        <CreatePhotoAvatarModal
          successMessage="Your avatar is ready! You can find it in your avatar's library."
          isOpenPhotoAvatarModal={isOpenPhotoAvatarModal}
          setIsOpenPhotoAvatarModal={setIsOpenPhotoAvatarModal}
        />
        <Popconfirm
          {...popconfirmApplyProps}
          title={
            <>
              <span className="current-label">Current: </span>
              <span className="type-label">{isOpenConfirmAvatar.name}</span>
            </>
          }
          arrow={false}
          open={isOpenConfirmAvatar.open}
          overlayClassName="avatar-apply-all-popconfirm avatar-apply  dark-popconfirm"
          onConfirm={onPopconfirmAvatarApply}
          onOpenChange={onPopconfirmAvatarOpenChange}
        />
      </div>
    </>
  )
}
