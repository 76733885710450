import { pexelsGetContent, pexelsGetDefaultContent } from '../pexels.service'
import Icon from '../../../../../../../components/Icon'
import { freepikGetContent, freepikGetDefaultContent } from '../freepik.service'
import colorPaletteIcon from '../../../../../../../assets/images/color_palette.svg'
import starShapeIcon from '../../../../../../../assets/images/star-shape.svg'
import { StarFilled, StarOutlined } from '@ant-design/icons'

export const mediaStockProvidersPathMap = {
  video: 'videos',
  image: 'images',
  icon: 'icons',
}

export const mediaStockProvidersMap = {
  pexels: {
    name: 'pexels',
    label: 'Pexels',
    href: 'https://www.pexels.com',
    availableTypes: ['image', 'video'],
    getContent: pexelsGetContent,
    getDefaultContent: pexelsGetDefaultContent,
    searchKey: 'query',
    colorsMap: {
      red: '#FB5252',
      orange: '#FCA120',
      pink: '#FFC0CB',
      yellow: '#FCDB7E',
      green: '#4AD295',
      turquoise: '#92CDFA',
      blue: '#1273EB',
      violet: '#8080F1',
      black: '#1D262D',
      gray: '#BAC8D3',
      white: '#FFFFFF',
    },
    isCustomColorEnabled: true,
    colorEnabled: ['image'],
    filters: {
      video: [
        {
          key: 'orientation',
          label: 'Orientation',
          values: [
            { value: 'landscape', label: 'Horizontal', icon: <Icon name="orientation_horizontal" /> },
            { value: 'portrait', label: 'Vertical', icon: <Icon name="orientation_vertical" /> },
            { value: 'square', label: 'Square', icon: <Icon name="square" /> },
          ],
        },
      ],
      image: [
        {
          key: 'orientation',
          label: 'Orientation',
          values: [
            { value: 'landscape', label: 'Horizontal', icon: <Icon name="orientation_horizontal" /> },
            { value: 'portrait', label: 'Vertical', icon: <Icon name="orientation_vertical" /> },
            { value: 'square', label: 'Square', icon: <Icon name="square" /> },
          ],
        },
      ],
    },
  },
  freepik: {
    name: 'freepik',
    label: 'Freepik',
    href: 'https://www.freepik.com',
    availableTypes: ['image', 'icon'],
    getContent: freepikGetContent,
    getDefaultContent: freepikGetDefaultContent,
    restrictedPlans: {
      image: {
        plans: ['basic', 'advanced'],
        message: 'Freepik images stock is available only for Enterprise plan. Upgrade to gain access.',
      },
    },
    searchKey: 'term',
    colorsMap: {
      red: '#FB5252',
      orange: '#FCA120',
      pink: '#FFC0CB',
      yellow: '#FCDB7E',
      green: '#4AD295',
      cyan: '#92CDFA',
      blue: '#1273EB',
      purple: '#8080F1',
      black: '#1D262D',
      gray: '#BAC8D3',
      white: '#FFFFFF',
    },
    colorEnabled: ['image'],
    filters: {
      icon: [
        {
          key: 'filters[color]',
          label: 'Color',
          values: [
            { value: 'gradient', label: 'Gradient', icon: <Icon name="gradient" className="own-colors" /> },
            {
              value: 'multicolor',
              label: 'Multicolor',
              icon: (
                <Icon
                  name="square"
                  className="display-none"
                  style={{ background: `url(${colorPaletteIcon}) 0% 0% / 14px 14px no-repeat`, width: 14, height: 14 }}
                />
              ),
            },
            { value: 'solid-black', label: 'Black', icon: <Icon name="filled_circle" className="own-colors" /> },
            { value: 'azure', label: 'Azure', icon: <Icon name="filled_circle" className="own-colors azure" /> },
            { value: 'blue', label: 'Blue', icon: <Icon name="filled_circle" className="own-colors blue" /> },
            {
              value: 'chartreuse',
              label: 'Chartreuse',
              icon: <Icon name="filled_circle" className="own-colors chartreuse" />,
            },
            { value: 'cyan', label: 'Cyan', icon: <Icon name="filled_circle" className="own-colors cyan" /> },
            { value: 'gray', label: 'Gray', icon: <Icon name="filled_circle" className="own-colors gray" /> },
            { value: 'green', label: 'Green', icon: <Icon name="filled_circle" className="own-colors green" /> },
            { value: 'orange', label: 'Orange', icon: <Icon name="filled_circle" className="own-colors orange" /> },
            { value: 'red', label: 'Red', icon: <Icon name="filled_circle" className="own-colors red" /> },
            { value: 'rose', label: 'Rose', icon: <Icon name="filled_circle" className="own-colors rose" /> },
            { value: 'violet', label: 'Violet', icon: <Icon name="filled_circle" className="own-colors violet" /> },
            { value: 'white', label: 'White', icon: <Icon name="filled_circle" className="own-colors white" /> },
            { value: 'yellow', label: 'Yellow', icon: <Icon name="filled_circle" className="own-colors yellow" /> },
          ],
        },
        {
          key: 'filters[shape]',
          label: 'Style',
          values: [
            {
              value: 'outline',
              label: 'Outline',
              icon: <StarOutlined className="own-colors" style={{ fontSize: 14 }} />,
            },
            { value: 'fill', label: 'Fill', icon: <StarFilled className="own-colors" style={{ fontSize: 14 }} /> },
            {
              value: 'lineal-color',
              label: 'Lineal Color',
              icon: (
                <Icon
                  name="star_hand_drawn"
                  className="display-none"
                  style={{ background: `url(${starShapeIcon}) 0% 0% / 16px 16px no-repeat`, width: 16, height: 16 }}
                />
              ),
            },
            { value: 'hand-drawn', label: 'Hand Drawn', icon: <Icon name="star_hand_drawn" className="own-colors" /> },
          ],
        },
      ],
      image: [
        {
          key: 'filters[people]',
          label: 'People',
          values: [
            {
              value: '1',
              label: 'With people',
              subKey: '[include]',
              icon: <Icon name="users" />,
              enabledProps: [
                'filters[people][number]',
                'filters[people][age]',
                'filters[people][gender]',
                'filters[people][ethnicity]',
              ],
            },
            {
              value: '1',
              label: 'No people',
              subKey: '[exclude]',
              icon: <Icon name="user_remove" />,
              disabledProps: [
                'filters[people][number]',
                'filters[people][age]',
                'filters[people][gender]',
                'filters[people][ethnicity]',
              ],
            },
          ],
        },
        {
          key: 'filters[people][number]',
          label: 'Number of People',
          disabled: true,
          values: [
            { value: '1', label: 'One' },
            { value: '2', label: 'Two' },
            { value: '3', label: 'Three' },
            { value: 'more_than_three', label: 'More than three' },
          ],
        },
        {
          key: 'filters[people][age]',
          label: 'People Age',
          disabled: true,
          values: [
            { value: 'infant', label: 'Infant' },
            { value: 'child', label: 'Child' },
            { value: 'teen', label: 'Teen' },
            { value: 'young-adult', label: 'Young adult' },
            { value: 'adult', label: 'Adult' },
            { value: 'senior', label: 'Senior' },
            { value: 'elder', label: 'Elder' },
          ],
        },
        {
          key: 'filters[people][gender]',
          disabled: true,
          label: 'People gender',
          values: [
            { value: 'male', label: 'Male' },
            { value: 'female', label: 'Female' },
          ],
        },
        {
          key: 'filters[people][ethnicity]',
          disabled: true,
          label: 'People ethnicity',
          values: [
            { value: 'south-asian', label: 'South Asian' },
            { value: 'middle-eastern', label: 'Middle Eastern' },
            { value: 'east-asian', label: 'East Asian' },
            { value: 'black', label: 'Black' },
            { value: 'hispanic', label: 'Hispanic' },
            { value: 'indian', label: 'Indian' },
            { value: 'white', label: 'White' },
            { value: 'multiracial', label: 'Multiracial' },
          ],
        },
        {
          key: 'filters[orientation]',
          label: 'Orientation',
          values: [
            {
              subKey: '[landscape]',
              value: '1',
              label: 'Horizontal',
              icon: <Icon name="orientation_horizontal" />,
            },
            { subKey: '[portrait]', value: '1', label: 'Vertical', icon: <Icon name="orientation_vertical" /> },
            { subKey: '[square]', value: '1', label: 'Square', icon: <Icon name="square" /> },
            {
              subKey: '[panoramic]',
              value: '1',
              label: 'Panoramic',
              icon: <Icon name="panoramic" />,
            },
          ],
        },
      ],
    },
  },
}

export const videoStoreMediaStockProviderInitial = {
  image: 'pexels',
  video: 'pexels',
  icon: 'freepik',
}
