import React, { useEffect, useState } from 'react'
import { Tooltip } from 'antd'
import { useSlideDuration } from '../../../hooks/useSlideDuration'
import { ACCOUNT_PLAN } from '../../../utils/constants'
import { useStore } from '../../../store'

const DurationInfo = ({ data, children }) => {
  const [tooltipTitle, setTooltipTitle] = useState('')

  const { slideDurationOrApprox, slideDurationApproxLimit, slideDurationLimit } = useSlideDuration({ slide: data })

  const { account } = useStore((stores) => stores.authStore.user)

  useEffect(() => {
    const prefix = data.duration ? '' : 'Approximately '
    if (slideDurationApproxLimit <= 60) {
      if (slideDurationOrApprox <= 60) {
        setTooltipTitle(
          `${prefix}${slideDurationOrApprox}s will be used from ${slideDurationLimit}s available during video render.`,
        )
      } else {
        if (account.plan === ACCOUNT_PLAN.BASIC) {
          setTooltipTitle(
            `${prefix}${slideDurationOrApprox}s will be used from ${slideDurationLimit}s available during video render. Please Upgrade to Advanced or Enterprise plan to use longer slides up to 5 minutes.`,
          )
        }
        if (account.plan !== ACCOUNT_PLAN.BASIC && account.status === 'paid') {
          setTooltipTitle(
            `${prefix}${slideDurationOrApprox}s will be used from ${slideDurationLimit}s available during video render. Please use a 5-minute avatar to increase the limit.`,
          )
        }
      }
    } else {
      if (slideDurationOrApprox < slideDurationApproxLimit) {
        setTooltipTitle(
          `${prefix}${slideDurationOrApprox}s will be used from ${slideDurationLimit}s available during video render.`,
        )
      } else {
        setTooltipTitle(
          `${prefix}${slideDurationOrApprox}s will be used from ${slideDurationLimit}s available during video render. Please reduce your speech to fit the limit. The real limit is ${slideDurationLimit}s but we recommend fitting ${slideDurationApproxLimit}s to avoid issues with dynamic duration during render.`,
        )
      }
    }
  }, [account.status, account.plan, slideDurationOrApprox, slideDurationApproxLimit])

  return (
    <div className={`duration-info ${slideDurationOrApprox > slideDurationApproxLimit ? 'duration-info-warning' : ''}`}>
      <Tooltip title={tooltipTitle} placement="bottom">
        <div className="duration-info-title">{children}</div>
      </Tooltip>
    </div>
  )
}

export default DurationInfo
