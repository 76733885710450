export const voiceStatuses = [
  { text: 'Unpaid', color: 'cyan' },
  { text: 'Need data', color: 'magenta' },
  { text: 'In progress', color: 'purple' },
  { text: 'Public', color: 'green' },
]

export const avatarTypes = [
  { name: 'Human', color: 'blue' },
  { name: 'Selfie', color: 'orange' },
  { name: 'Studio', color: 'geekblue' },
  { name: 'Mascot', color: 'magenta' },
  { name: 'Cartoon', color: 'magenta' },
  { name: 'Photo', color: 'yellow' },
]

export const avatarStatuses = [
  { text: 'Disabled', color: 'blue' },
  { text: 'Only For Admin', color: 'orange' },
  { text: 'Ready', color: '#4868ff' },
  { text: 'Need payment', color: '#31353b' },
  { text: 'Need data', color: '#31353b' },
  { text: 'In progress', color: '#c971ff' },
]
