import { useEffect, useRef, useState } from 'react'
import backgroundIcon from '../../../assets/images/color-wheel.png'
import SketchPickerWithControls from '../../../components/SketchPickerWithControls'

const ColorPicker = ({ isOpen, setIsOpen, onChangeColor, disabled }) => {
  const sketchPicker = useRef()
  const [selectedColor, setSelectedColor] = useState('')

  const onChangeColorHandler = ({ hex }) => {
    setSelectedColor(hex)
    onChangeColor(hex)
    setIsOpen(false)
  }
  const onCancel = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isOpen && sketchPicker.current && !sketchPicker.current.contains(e.target)) setIsOpen(false)
    }
    if (isOpen) document.addEventListener('mousedown', checkIfClickedOutside)
    else document.removeEventListener('mousedown', checkIfClickedOutside)
  }, [isOpen])

  return (
    <div ref={sketchPicker} style={{ position: 'relative' }} className="search-media-filter-sketch-picker">
      <button
        className="color-item search-media-filter-color-picker"
        style={{ backgroundImage: `url(${backgroundIcon})` }}
        onClick={() => !disabled && setIsOpen(true)}
      >
        +
      </button>
      {isOpen && (
        <div
          className="sketchpicker-container"
          style={{
            position: 'absolute',
            top: '-15px',
            left: '0',
            transform: `translateX(-${sketchPicker.current?.offsetLeft - 60}px)`,
          }}
        >
          <SketchPickerWithControls
            color={selectedColor}
            disableAlpha={true}
            onChangeComplete={onChangeColorHandler}
            onCancel={onCancel}
          />
        </div>
      )}
    </div>
  )
}

export default ColorPicker
