/**
 * https://easings.net/
 */

const linear = (x) => x

const easeInSine = (x) => 1 - Math.cos((x * Math.PI) / 2)

const easeOutSine = (x) => Math.sin((x * Math.PI) / 2)

const easeInOutSine = (x) => -(Math.cos(Math.PI * x) - 1) / 2

const easeInBack = (x) => {
  const c1 = 1.70158
  const c3 = c1 + 1

  return c3 * x * x * x - c1 * x * x
}

const easeOutBack = (x) => {
  const c1 = 1.70158
  const c3 = c1 + 1

  return 1 + c3 * Math.pow(x - 1, 3) + c1 * Math.pow(x - 1, 2)
}

const easeInOutBack = (x) => {
  const c1 = 1.70158
  const c2 = c1 * 1.525

  return x < 0.5
    ? (Math.pow(2 * x, 2) * ((c2 + 1) * 2 * x - c2)) / 2
    : (Math.pow(2 * x - 2, 2) * ((c2 + 1) * (x * 2 - 2) + c2) + 2) / 2
}

const easeInCirc = (x) => 1 - Math.sqrt(1 - Math.pow(x, 2))

const easeOutCirc = (x) => Math.sqrt(1 - Math.pow(x - 1, 2))

const easeInQuad = (x) => x * x

const easeOutQuad = (x) => x * (2 - x)

const easeInCubic = (x) => x * x * x

const easeOutCubic = (x) => 1 - Math.pow(1 - x, 3)

const easeInQuart = (x) => Math.pow(x, 4)

const easeOutQuart = (x) => 1 - Math.pow(1 - x, 4)

const easeInQuint = (x) => Math.pow(x, 5)

const easeOutQuint = (x) => 1 - Math.pow(1 - x, 5)

const easeInExpo = (x) => (x === 0 ? 0 : Math.pow(2, 10 * x - 10))

const easeOutExpo = (x) => (x === 1 ? 1 : 1 - Math.pow(2, -10 * x))

const easeInOutExpo = (x) => {
  return x === 0 ? 0 : x === 1 ? 1 : x < 0.5 ? Math.pow(2, 20 * x - 10) / 2 : (2 - Math.pow(2, -20 * x + 10)) / 2
}

const easeInElastic = (x) => {
  const c4 = (2 * Math.PI) / 3
  return x === 0 ? 0 : x === 1 ? 1 : -Math.pow(2, 10 * x - 10) * Math.sin((x * 10 - 10.75) * c4)
}

const easeOutElastic = (x) => {
  const c4 = (2 * Math.PI) / 3

  return x === 0 ? 0 : x === 1 ? 1 : Math.pow(2, -10 * x) * Math.sin((x * 10 - 0.75) * c4) + 1
}

const easeInBounce = (x) => 1 - easeOutBounce(1 - x)

const easeOutBounce = (x) => {
  const n1 = 7.5625
  const d1 = 2.75

  if (x < 1 / d1) {
    return n1 * x * x
  } else if (x < 2 / d1) {
    return n1 * (x -= 1.5 / d1) * x + 0.75
  } else if (x < 2.5 / d1) {
    return n1 * (x -= 2.25 / d1) * x + 0.9375
  } else {
    return n1 * (x -= 2.625 / d1) * x + 0.984375
  }
}

const easeInOutBounce = (x) => {
  return x < 0.5 ? (1 - easeOutBounce(1 - 2 * x)) / 2 : (1 + easeOutBounce(2 * x - 1)) / 2
}

export const DEFAULT_ANIMATION_EASING = 'easeOutCirc'

export const easings = {
  linear,
  easeInSine,
  easeOutSine,
  easeInOutSine,
  easeInBack,
  easeOutBack,
  easeInOutBack,
  easeInCirc,
  easeOutCirc,
  easeInQuad,
  easeOutQuad,
  easeInCubic,
  easeOutCubic,
  easeInQuart,
  easeOutQuart,
  easeInQuint,
  easeOutQuint,
  easeInExpo,
  easeOutExpo,
  easeInOutExpo,
  easeInElastic,
  easeOutElastic,
  easeInBounce,
  easeOutBounce,
  easeInOutBounce,
}

export const lookupEasing = (name) => {
  return easings[name] || easings[DEFAULT_ANIMATION_EASING]
}

const upperFirst = (str) => str.charAt(0).toUpperCase() + str.slice(1)

const splitPascalCase = (str) => str.replace(/([a-z])([A-Z])/g, '$1 $2')

const camelToSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (letter, index) => (index === 0 ? letter.toLowerCase() : `_${letter.toLowerCase()}`))

export const easingsList = Object.keys(easings).map((name) => ({
  name,
  label: splitPascalCase(upperFirst(name.replace('ease', ''))),
  icon: camelToSnakeCase(name.replace('ease', '')),
}))
