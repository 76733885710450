import { useMemo, useState, useCallback } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { Collapse, Row, Input, Button, Spin } from 'antd'
import { debounce } from 'throttle-debounce'

import AvatarItem from './avatarItem'
import AvatarDrawer from './avatarDrawer'
import HorizontalScroll from '../../../../../components/HorizontalScroll/horizontalScroll'

import { useStore } from '../../../../../store'

import { style, arrowDownIcon, plusIcon, portraitIcon } from '../constants'
import Icon from '../../../../../components/Icon'
import { AvatarsFilterPopover } from '../../../../../components/AvatarsFilterPopover/avatarsFilterPopover'
import configService from '../../../../../utils/config'

const { Panel } = Collapse

const AvatarsLibraryTab = (props) => {
  const {
    data,
    setSearchFilter,
    avatarList,
    filteredAvatars,
    activeAvatarTab,
    avatarsData,
    avatarDrawer,
    setAvatarDrawer,
    isAdmin,
    filters,
    setFilters,
    isOpenFiltersPopup,
    setIsOpenFiltersPopup,
    title,
    setIsOpenPhotoAvatarModal,
    setActiveAvatar,
    onAvatarClick,
    onChooseAvatar,
    onAvatarDrawerChange,
    addListeningAvatar,
    handleClickPhotoAvatar,
  } = props

  const [searchValue, setSearchValue] = useState()

  const { user } = useStore((stores) => stores.authStore)
  const { hideAvatarsCreation } = configService.get().features

  const photoAvatars = useMemo(() => {
    const customPhotoAvatars = avatarsData.filter((avatar) => avatar.type === 'photo' && avatar.accountId)
    const generalPhotoAvatars = avatarsData.filter((avatar) => avatar.type === 'photo' && !avatar.accountId)
    return customPhotoAvatars.concat(generalPhotoAvatars)
  }, [avatarsData])

  const debounceChangeSearchValue = useCallback(
    debounce(800, (value) => {
      setSearchFilter(value)
      if (avatarDrawer.isOpen) setAvatarDrawer({ isOpen: false })
    }),
    [],
  )

  const onChangeSearchValue = (event) => {
    const value = event.target.value
    setSearchValue(value)
    debounceChangeSearchValue(value)
  }

  const clearSearch = () => {
    setSearchValue('')
    setSearchFilter('')
  }
  const closeIcon = <Icon name="close" onClick={clearSearch} />

  return (
    <>
      <div
        className="avatars-library-tab"
        style={{ display: activeAvatarTab === 'general' ? 'flex' : 'none', ...style.marginTop24 }}
      >
        {!hideAvatarsCreation && user.account.status === 'paid' && (
          <Collapse
            className="sidebar-collapse-transparent photo-avatars-collapse"
            expandIconPosition="end"
            expandIcon={() => arrowDownIcon}
            ghost
          >
            <Panel
              header={
                <div className="avatars-title-tags">
                  <h4 className="sidebar-collapse-title">Photo avatars</h4>
                </div>
              }
              key="1"
            >
              <HorizontalScroll
                items={[
                  <div
                    key="add-custom-avatar"
                    className="card-add-avatar photo-avatar-add"
                    onClick={() => setIsOpenPhotoAvatarModal(true)}
                  >
                    {portraitIcon}
                    <span className="card-label">{plusIcon} Create</span>
                  </div>,
                  ...photoAvatars.map((avatar) => (
                    <div key={avatar.id} className="photo-avatar-item" onClick={() => handleClickPhotoAvatar(avatar)}>
                      <div className="photo-avatar-image" style={{ backgroundImage: `url(${avatar.thumbnail})` }}></div>
                    </div>
                  )),
                ]}
              />
            </Panel>
          </Collapse>
        )}
        <div style={{ ...style.displayFlex, ...style.flex, ...style.flexCol, ...style.positionRel }}>
          <h4 className="avatars-title">Studio avatars</h4>
          <div className="avatar-filter">
            <Input
              prefix={<Icon name="search" />}
              suffix={searchValue && closeIcon}
              className="avatar-search"
              value={searchValue}
              placeholder="Search by name"
              onChange={onChangeSearchValue}
            />
            <AvatarsFilterPopover
              placement="left"
              avatars={avatarList}
              filters={filters}
              setFilters={setFilters}
              isOpenFiltersPopup={isOpenFiltersPopup}
              setIsOpenFiltersPopup={setIsOpenFiltersPopup}
              onApplyFilters={onAvatarDrawerChange}
              getPopupContainer={null}
            >
              <Button type={isOpenFiltersPopup ? 'primary' : 'default'} icon={<Icon name="filters" />} />
            </AvatarsFilterPopover>
          </div>
          <div style={{ ...style.positionRel, ...style.flex }}>
            <Scrollbars style={{ minHeight: 300 }} className="scrollbar">
              <Row gutter={[8, 8]} style={{ marginBottom: 10 }}>
                {filteredAvatars === undefined ? (
                  <Spin className="spin-centered" style={{ marginTop: 20 }} />
                ) : filteredAvatars?.length > 0 ? (
                  filteredAvatars.map((a, i) => (
                    <AvatarItem
                      key={i}
                      avatar={a}
                      avatarIndex={i}
                      data={data}
                      isAdmin={isAdmin}
                      onAvatarClick={onAvatarClick}
                      onChooseAvatar={onChooseAvatar}
                      setActiveAvatar={setActiveAvatar}
                      addListeningAvatar={addListeningAvatar}
                    />
                  ))
                ) : (
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <p>Nothing found</p>
                  </div>
                )}
              </Row>
            </Scrollbars>
            {avatarDrawer.avatar?.type !== 'photo' && (
              <AvatarDrawer
                title={title}
                avatarDrawer={avatarDrawer}
                data={data}
                setActiveAvatar={setActiveAvatar}
                onAvatarDrawerChange={onAvatarDrawerChange}
                onChooseAvatar={onChooseAvatar}
                addListeningAvatar={addListeningAvatar}
              />
            )}
          </div>
          {avatarDrawer.avatar?.type === 'photo' && (
            <AvatarDrawer
              title={title}
              avatarDrawer={avatarDrawer}
              data={data}
              setActiveAvatar={setActiveAvatar}
              onAvatarDrawerChange={onAvatarDrawerChange}
              onChooseAvatar={onChooseAvatar}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default AvatarsLibraryTab
