import React, { Fragment, useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { ElementItem } from '../elementItem'
import { track } from '../../../../../../utils/analytics'
import { Button, Spin } from 'antd'
import { useStore } from '../../../../../../store'
import MediaPreviewModal from './mediaPreviewModal'
import { mediaStockProvidersMap } from './content/media-provider.config'

const STYLE = {
  width100: { width: '100%' },
  linkColor: { color: '#8c8d8f' },
  minHeight500: { minHeight: 500 },
  emptyResponse: { textAlign: 'center', marginTop: 20 },
  providedBy: { color: '#8c8d8f', fontSize: '12px', textAlign: 'center', marginTop: 10 },
}

const contentMap = {
  image: 'Photos',
  icon: 'Icons',
  video: 'Videos',
}

const LibraryContent = (props) => {
  const { onChooseFile, filesType, content, metaData, onShowMore, isLoading } = props

  const [previewFile, setPreviewFile] = useState(null)

  const { mediaStockProvider } = useStore((stores) => stores.videosStore)
  const { name, label, href } = mediaStockProvidersMap[mediaStockProvider[filesType]]

  const handlePreview = (file) => {
    setPreviewFile(file)
  }

  return (
    <div className="search-media-wrapper">
      <Scrollbars className="pexels-scrollbar scrollbar" style={STYLE.minHeight500}>
        <div className="search-items-container">
          {content?.map((file, index) => (
            <Fragment key={index}>
              <ElementItem
                // file.video_pictures[0].picture -> file.image (changed by CORS policy in OPTIONS request)
                // if it'll possible needs to be reverted as pictures size is smaller
                thumbnail={file.thumbnail}
                isVisibleActions={true}
                onClickItem={() => {
                  handlePreview(file)
                }}
                type={filesType}
                onChooseFile={() => {
                  track('editor_stock_media_added', { provider: name, type: file.type })
                  onChooseFile(file)
                }}
              />
            </Fragment>
          ))}
        </div>
        <div className="media-items-controls">
          {!isLoading && metaData?.nextPage && (
            <Button type="primary" ghost block onClick={onShowMore}>
              Show more
            </Button>
          )}
          {isLoading && <Spin style={STYLE.width100} />}
          {content?.length ? (
            <p style={STYLE.providedBy}>
              {contentMap[filesType]} provided by{' '}
              <a href={href} target="_blank" style={STYLE.linkColor} rel="noreferrer">
                {label}
              </a>
            </p>
          ) : (
            !isLoading && <p style={STYLE.emptyResponse}>Nothing found</p>
          )}
        </div>
      </Scrollbars>
      <MediaPreviewModal onChooseFile={onChooseFile} previewFile={previewFile} setPreviewFile={setPreviewFile} />
    </div>
  )
}

export default LibraryContent
