import { Button, Result } from 'antd'
import Lottie from 'lottie-web'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

export default () => {
  useEffect(() => {
    Lottie.loadAnimation({
      container: document.querySelector('#anim'),
      path: 'https://assets1.lottiefiles.com/packages/lf20_q2pevjuc.json',
    })
  }, [])
  return (
    <Result
      title="404"
      icon={<div id="anim" style={{ maxWidth: 500, margin: '0 auto' }} />}
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Link to="/">
          <Button type="primary">Back Home</Button>
        </Link>
      }
    />
  )
}
