import { useNavigate } from 'react-router-dom'
import { Col, Tooltip, Button, Progress, Tag } from 'antd'
import { CaretRightOutlined, EditOutlined } from '@ant-design/icons'

import Icon from '../../../../../components/Icon'
import SlidesCarousel from '../../../../../components/SlidesCarousel'
import configService from '../../../../../utils/config'

import { style } from '../constants'
import { PREMIUM_AVATARS_LABEL_TOOLTIP } from '../../../../avatars/constants'

const caretRightIcon = <CaretRightOutlined />
const plusFilledIcon = <Icon name="plus_filled" className="plus-filled-icon" />

const AvatarItem = ({
  avatar,
  avatarIndex,
  data,
  isAdmin,
  onAvatarClick,
  onChooseAvatar,
  setActiveAvatar,
  addListeningAvatar,
}) => {
  const navigate = useNavigate()
  const { hideAvatarsCreation } = configService.get().features

  const handleClickListeningAvatar = () => {
    if (avatar.variants.length) {
      const variantListener = avatar.variants.find((v) => v.listeningAvatar)
      if (variantListener) addListeningAvatar(variantListener)
    } else addListeningAvatar(avatar)
  }

  const getAvatarMinutesLimit = () => {
    const variantWithMaxLimit = avatar.variants?.reduce(
      (max, variant) => (variant.limit > max.limit ? variant : max),
      avatar.variants?.[0],
    )
    const limit = variantWithMaxLimit?.limit || avatar.limit || 60
    return Math.floor(limit / 60)
  }

  const previewVariantIndex = avatar.variants?.findIndex((v) => v.intro)

  return (
    <Col
      className={`avatar-item ${
        avatar.code === data.avatar.code ||
        avatar.variants?.find((v) => `${avatar.code}.${v.code}` === data.avatar.code)
          ? 'active'
          : ''
      }`}
      span={12}
    >
      <div className="avatar-item-content">
        {avatar.code === 'elai-cartoon' && !hideAvatarsCreation && (
          <Tooltip
            title={
              <>
                This is a sample of Elai mascot avatar. Click{' '}
                <a target="_blank" href="https://app.elai.io/buy-avatar#mascot" rel="noreferrer">
                  here
                </a>{' '}
                if you want to have your own.
              </>
            }
          >
            {plusFilledIcon}
          </Tooltip>
        )}
        {(avatar.listeningAvatar || avatar.variants.some((v) => v.listeningAvatar)) && (
          <Tooltip
            title={`Add ${avatar.name} as listener: video with avatar, listening to the leading avatar. This is how you can create dialogs.`}
          >
            <Icon name="avatar_listener" className="listener-icon" onClick={handleClickListeningAvatar} />
          </Tooltip>
        )}
        <div className="avatar-item-img">
          <div className="avatar-labels-wrapper">
            {avatar.premium && (
              <Tooltip title={PREMIUM_AVATARS_LABEL_TOOLTIP}>
                <Tag className="premium-card-sidebar">
                  <Icon name="diamond" /> Premium
                </Tag>
              </Tooltip>
            )}
            <Tooltip title={`This avatar supports slide duration up to ${getAvatarMinutesLimit()} minutes`}>
              <Tag className="minutes-label">{getAvatarMinutesLimit()} m</Tag>
            </Tooltip>
            {avatar.variants?.length > 1 && (
              <Tooltip title="This avatar has a variety of looks to choose from.">
                <Tag className="users_group-icon">
                  <Icon name="users_group" />
                </Tag>
              </Tooltip>
            )}
          </div>
          {!isAdmin && avatar.status !== 2 ? (
            <div className="custom-avatar">
              <Icon name="user" />
              {avatar.status === 5 ? (
                <div className="custom-avatar-mask">
                  <Tooltip title="Your avatar is being prepared">
                    <Progress
                      percent={100}
                      status="active"
                      showInfo={false}
                      className="progress"
                      strokeColor={style.strokeColor}
                    />
                  </Tooltip>
                </div>
              ) : (
                <EditOutlined className="edit-icon" onClick={() => navigate(`/avatar/${avatar.code}`)} />
              )}
            </div>
          ) : avatar.variants?.length > 1 ? (
            <SlidesCarousel
              collection={{
                ...avatar,
                items: avatar.variants.map((variant) => ({ ...variant, src: variant.thumbnail, id: variant.code })),
              }}
              id={avatarIndex}
              onCarouselClick={onAvatarClick}
            />
          ) : (
            <img
              onClick={() => {
                if (avatar.variants?.length) onChooseAvatar(avatar.variants[0], avatar)
                else onChooseAvatar(avatar)
              }}
              src={avatar.thumbnail || avatar.variants?.[0]?.thumbnail || avatar.canvas}
            />
          )}
        </div>
        <div className={`avatar-item-text ${avatar.intro ? 'intro' : ''}`}>
          <span className="avatar-name">{avatar.name}</span>
          {avatar.intro || previewVariantIndex >= 0 ? (
            <Button
              size="small"
              icon={caretRightIcon}
              className="item-preview-btn"
              onClick={() =>
                setActiveAvatar({ avatar, variantIndex: previewVariantIndex >= 0 ? previewVariantIndex : undefined })
              }
            >
              Preview
            </Button>
          ) : null}
        </div>
      </div>
    </Col>
  )
}

export default AvatarItem
