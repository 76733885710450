import { useEffect, useRef } from 'react'
import SketchPickerWithControls from './SketchPickerWithControls'

/**
 * Customizable color picker
 * @param {boolean} isOpen show color picker or not
 * @param {function} setter for isOpen
 * @param {string} color selected color
 * @param {ref} sketchPicker ref for div where to open ColorPiker
 * @param {boolean} isColorLimitReached is color limit reached
 * @param {function | undefined} onClose callback that called when ColorPicked is closed
 */
export const SimpleColorPicker = ({
  setIsOpen,
  isOpen,
  onColorChange,
  resetCurrentColor,
  color,
  isColorLimitReached,
}) => {
  const sketchPicker = useRef()

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isOpen && sketchPicker.current && !sketchPicker.current.contains(e.target)) setIsOpen(false)
    }
    if (isOpen) document.addEventListener('mousedown', checkIfClickedOutside)
    else document.removeEventListener('mousedown', checkIfClickedOutside)
  }, [isOpen])

  const handleColorChange = (selectedColor) => {
    if (!selectedColor || isColorLimitReached) return

    onColorChange(selectedColor.hex)
    setIsOpen(false)
  }

  const onCancel = () => {
    resetCurrentColor?.()
    setIsOpen(false)
  }

  return (
    <>
      {isOpen && (
        <div
          ref={sketchPicker}
          className="sketchpicker-container"
          style={{
            position: 'absolute',
            top: '-15px',
            left: '0',
            transform: `translateX(-${sketchPicker.current?.offsetLeft - 60}px)`,
            pointerEvents: isColorLimitReached ? 'none' : 'all',
          }}
        >
          <SketchPickerWithControls
            color={color}
            disableAlpha={true}
            onChangeComplete={handleColorChange}
            onCancel={onCancel}
          />
        </div>
      )}
    </>
  )
}
