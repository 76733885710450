const genderFilterOptions = [
  {
    value: 'all',
    label: 'View all',
  },
  {
    value: 'male',
    label: 'Male',
  },
  {
    value: 'female',
    label: 'Female',
  },
]

const twoVariantFilterOptions = [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
]

const YOUNG_AGE_FILTER = 'Young'
const MID_AGE_FILTER = 'Middle-aged'
const SENIOR_AGE_FILTER = 'Senior'

const ageFilterOptions = [YOUNG_AGE_FILTER, MID_AGE_FILTER, SENIOR_AGE_FILTER]

export {
  genderFilterOptions,
  twoVariantFilterOptions,
  ageFilterOptions,
  YOUNG_AGE_FILTER,
  MID_AGE_FILTER,
  SENIOR_AGE_FILTER,
}
