import { useCallback } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { Select, Input, Checkbox, Typography, Spin, Button, Tooltip, Radio } from 'antd'

import CommentCard from './commentCard'
import { CommentsBadge } from './commentsBadge'

import { useCommentsState } from './useCommentsState'

import { sorterOptions, getSlideSelectOptions, downArrowIcon, paperPlaneIcon } from './constants'

import './comments.less'

export const Comments = (props) => {
  const { video, activeSlide, isEnabledSwitchingSlide, defaultAnchorToSlides, updateVideo, updateActiveSlide } = props

  const {
    userId,
    comments,
    createComment,
    isPreviewPage,
    selectedSlide,
    loadingComment,
    editingComment,
    commentsFilter,
    unviewedComments,
    setEditingComment,
    setLoadingComment,
    handleSortComments,
    handleFilterComments,
    setUnviewedComments,
    slidesCheckboxValue,
    commentTextAreaValue,
    handleChangeCommentText,
    handleChangeSelectedSlide,
    handleChangeSlidesCheckbox,
  } = useCommentsState({
    video,
    activeSlide,
    updateVideo,
    defaultAnchorToSlides,
  })

  const menuFilter = useCallback(
    (menu) => (
      <>
        <p className="options-title">Sort by</p>
        <div className="menu-wrapper">{menu}</div>
        <p className="options-title">Filter by</p>
        <Radio.Group className="status-options" onChange={handleFilterComments} value={commentsFilter.filter}>
          <Radio value="all">All</Radio>
          <Radio value="open">Only open</Radio>
          <Radio value="resolved">Only resolved</Radio>
        </Radio.Group>
      </>
    ),
    [commentsFilter],
  )

  return (
    <div className="comments">
      <div className="comments-title-wrapper">
        <CommentsBadge comments={video.comments} userId={userId}>
          <h3 className="comments-title">Comments</h3>
        </CommentsBadge>
        <Select
          value={commentsFilter.sort}
          options={sorterOptions}
          bordered={false}
          className="comments-sorter-select"
          popupClassName="comments-sorter-dropdown"
          dropdownMatchSelectWidth={146}
          placement="bottomRight"
          suffixIcon={downArrowIcon}
          onChange={handleSortComments}
          dropdownRender={menuFilter}
        />
      </div>
      <Scrollbars className="comments-scrollbar scrollbar">
        {loadingComment && <Spin className="spin-comments" />}
        {comments?.length ? (
          comments.map((comment) => (
            <CommentCard
              key={comment._id}
              comment={comment}
              video={video}
              userId={userId}
              isEnabledSwitchingSlide={isEnabledSwitchingSlide}
              editingComment={editingComment}
              setEditingComment={setEditingComment}
              loadingComment={loadingComment}
              setLoadingComment={setLoadingComment}
              updateVideo={updateVideo}
              updateActiveSlide={updateActiveSlide}
              unviewedComments={unviewedComments}
              setUnviewedComments={setUnviewedComments}
            />
          ))
        ) : !loadingComment ? (
          <Typography.Text type="secondary" className="comments-message">
            {commentsFilter.opened && commentsFilter.resolved
              ? 'There are no comments yet'
              : commentsFilter.opened
              ? 'There are no open comments'
              : 'There are no resolved comments'}
          </Typography.Text>
        ) : null}
      </Scrollbars>
      <div className="comments-create-section" style={{ marginBottom: isPreviewPage && '30px' }}>
        <div className="controls-wrapper">
          <Select
            value={selectedSlide}
            options={getSlideSelectOptions(video.slides)}
            disabled={!slidesCheckboxValue}
            className="slides-select"
            suffixIcon={downArrowIcon}
            dropdownMatchSelectWidth={90}
            onChange={handleChangeSelectedSlide}
          />
          <Tooltip title="Please check if the comment is related to a specific slide rather than to the whole video.">
            <Checkbox checked={slidesCheckboxValue} onChange={handleChangeSlidesCheckbox}>
              Attach to slide
            </Checkbox>
          </Tooltip>
        </div>
        <div className="textarea-container">
          <Input.TextArea
            value={commentTextAreaValue}
            className="comment-textarea"
            placeholder="Write your comment here"
            disabled={loadingComment}
            autoSize={{ minRows: 3, maxRows: 5 }}
            onChange={handleChangeCommentText}
            onPressEnter={createComment}
          />
          <Button
            className="btn-send"
            disabled={!commentTextAreaValue || loadingComment}
            type="primary"
            shape="circle"
            size="small"
            icon={paperPlaneIcon}
            onClick={createComment}
          />
        </div>
      </div>
    </div>
  )
}
