import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Progress, Tooltip } from 'antd'
import { useStore } from '../store'
import { formatSeconds } from '../utils/videos'

const PlanMinutesStatus = () => {
  const {
    user: { account },
    syncAvailableMinutes,
  } = useStore((stores) => stores.authStore)
  const [percent, setPercent] = useState(0)
  const location = useLocation()
  const lastNavigationSyncTime = useRef(Date.now())

  /**
   * Sync minutes on navigation once in 60 seconds
   */
  useEffect(() => {
    if (lastNavigationSyncTime.current + 60000 > Date.now()) return
    lastNavigationSyncTime.current = Date.now()
    syncAvailableMinutes()
  }, [location.pathname])

  useEffect(() => {
    setPercent((account.minutesUsed / account.minutesAvailable) * 100)
  }, [account])

  const minutesUsed = account?.minutesUsed ? Math.round(account.minutesUsed * 60) : 0

  return (
    <Tooltip title={` ${formatSeconds(minutesUsed) || '0m'} used from ${account.minutesAvailable}m available`}>
      <div className="minutes-status">
        <span className="minutes-status-value">
          {formatSeconds(minutesUsed) || '0m'}/{account.minutesAvailable}m
        </span>
        <Progress percent={percent} showInfo={false} status="normal" size="small" strokeColor="#4868FF" />
      </div>
    </Tooltip>
  )
}

export default PlanMinutesStatus
