import { textTypes } from '../canvas/canvas'

const requiredStyles = ['fill', 'fontWeight', 'fontStyle']

export const applyFabricMigration = (video) => {
  for (const slide of video.slides) {
    if (!slide.canvas?.objects?.length) continue
    for (const obj of slide.canvas.objects) {
      applyTextMigration(obj)
    }
  }
}

const checkForMissingOrCorruptedStyle = (styles) => {
  if (!Object.keys(styles || {}).length) return true
  return Object.values(styles).every((row) =>
    Object.values(row).every((style) => {
      return Object.keys(style).every((key) => !requiredStyles.includes(key))
    }),
  )
}

export const applyTextMigration = (obj) => {
  if (
    textTypes.includes(obj.type) &&
    (!obj.styles || (!Array.isArray(obj.styles) && checkForMissingOrCorruptedStyle(obj.styles)))
  ) {
    // styles are not an array or corrupted, so just reset them
    obj.styles = []
  }
}
