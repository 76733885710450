import { useMemo } from 'react'
import { Card, Col } from 'antd'

import SlidesCarousel from '../../../../components/SlidesCarousel'

import { style } from './constants'

export const TemplatesColumn = (props) => {
  const { video, index, setOpenedTemplate } = props

  const handleOpenTemplate = () => setOpenedTemplate(video)

  const items = useMemo(() => video.slides.map((slide) => ({ ...slide, src: slide.screenshot })), [video])

  return (
    <Col style={style.flex} xs={12} sm={12} md={8}>
      <Card
        className={`template-card ${video.data?.format === '9_16' ? 'vertical' : ''} ${
          video.data?.format === '1_1' ? 'square' : ''
        }`}
        bodyStyle={style.padding0}
        onClick={handleOpenTemplate}
      >
        <SlidesCarousel
          collection={{
            ...video,
            items,
          }}
          index={index}
        />
      </Card>
    </Col>
  )
}
