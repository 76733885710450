export const languageDurationCoefficient = {
  English: 16.8,
  Spanish: 16.5,
  Italian: 15.7,
  Portuguese: 15.1,
  German: 16.3,
  Arabic: 12,
  Persian: 13.8,
  Swedish: 15.7,
  Hindi: 16.8,
  Telugu: 15,
  Afrikaans: 16.6,
  Punjabi: 16.3,
  Marathi: 14.6,
  Catalan: 16.6,
  Russian: 16.1,
  Georgian: 16.5,
  Turkish: 15.9,
  French: 17.2,
  Slovak: 15.7,
  Bosnian: 16.6,
  Indonesian: 15.2,
  Polish: 15.9,
  Tamil: 16.2,
  Nepali: 15,
  Malay: 15.7,
  Croatian: 15.8,
  Romanian: 15.2,
  Urdu: 13.3,
  Chinese: 7,
  Czech: 14.8,
  Welsh: 15.5,
  Vietnamese: 17.9,
  Dutch: 16.5,
  Bengali: 16.3,
  Hungarian: 16.8,
  Filipino: 16.5,
  Gujarati: 14.8,
  Irish: 16.5,
  Malayalam: 17.8,
  Swahili: 14.5,
  Finnish: 14.8,
  Hebrew: 13.5,
  Azerbaijani: 17,
  Amharic: 12.8,
  Kazakh: 23.3,
  Galician: 17,
  Korean: 10.4,
  Bangla: 14.4,
  Basque: 15.3,
  Greek: 17.6,
  Ukrainian: 13.7,
  Japanese: 7.2,
  Slovenian: 17,
  Burmese: 12,
  Somali: 15,
  Thai: 13.5,
  Mongolian: 19,
  Macedonian: 16,
  Khmer: 16,
  Javanese: 13,
  Norwegian: 15.6,
  Danish: 18.3,
  Bulgarian: 14,
  Estonian: 16,
  Lithuanian: 16,
  Armenian: 16,
  Serbian: 17.3,
  Uzbek: 16,
  Zulu: 13,
  Sundanese: 15,
  Sinhala: 16,
  Kannada: 17.3,
  Albanian: 17,
  Latvian: 17,
  Icelandic: 15,
  Pashto: 19,
  Maltese: 13,
  Lao: 11,
  'Chinese - Mandarin': 7.2,
  'Chinese (Mandarin, Simplified)': 4,
  'Chinese (Taiwanese Mandarin)': 9.3,
}

export const APPROX_DURATION_COEFFICIENT = 1.15
