export const OPTIONS = [
  {
    label: <span>Assets</span>,
    value: 'assets',
    title: 'Assets',
    placement: 'bottomLeft',
  },
  {
    label: 'Brandbook',
    value: 'brandbook',
    placement: 'bottomRight',
  },
]
