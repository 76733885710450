import { useCallback, useRef, useState } from 'react'
import useIntersection from '../../hooks/useIntersection'

const isSafari = navigator.userAgent.indexOf('Chrome') === -1 && navigator.userAgent.indexOf('Safari') > -1

export const VideoPreview = ({ avatar }) => {
  const [isInView, setIsInView] = useState(false)
  const ref = useRef()

  const callback = useCallback(() => {
    setIsInView(true)
  }, [setIsInView])

  useIntersection(ref, callback)

  return (
    <video
      ref={ref}
      controls
      preload={avatar.introPoster ? 'none' : isSafari ? 'auto' : 'metadata'}
      controlsList="nodownload"
      poster={isInView ? avatar.introPoster : undefined}
      className="avatar-intro-video"
      src={isInView ? avatar.intro : undefined}
    >
      Your browser does not support the element <code>video</code>.
    </video>
  )
}
