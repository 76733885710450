import { useMemo, useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Empty, Popconfirm, Table, Layout, Space, Modal, Tooltip, Select, Tag } from 'antd'
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { request } from '../../utils/api'
import { useStore } from '../../store'
import Icon from '../../components/Icon'
import { CreateTemplateModal } from './createModal'
import { requestDuplicateVideo } from '../../utils/videoCreation/videoCreation'
import { track } from '../../utils/analytics'

const { confirm } = Modal

const Templates = () => {
  const navigate = useNavigate()

  const [selectedVideos, setSelectedVideos] = useState([])
  const [isVideoCreating, setIsVideoCreating] = useState(false)
  const [isTemplateCopying, setIsTemplateCopying] = useState(-1)
  const [isNewTemplateModalOpen, setIsNewTemplateModalOpen] = useState(false)

  const userAccount = useStore((stores) => stores.authStore.user.account)
  const { templates, templateTags, loadingTemplates, syncTemplatesTags, fetchTemplates } = useStore(
    (stores) => stores.videosStore,
  )
  const { setHeader } = useStore((stores) => stores.domStore)

  const videos = useMemo(() => {
    if (!templates) return undefined
    return templates
      .filter((v) => v.template.private || v.accountId === userAccount.id)
      .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
  }, [templates])

  const onSelectTag = async (tag, video) => {
    if (/^\s*$/.test(tag)) return
    if (!video.template.tags?.length) video.tags = []
    video.template.tags.push(tag)
    await request({ method: 'patch', url: `/videos/${video._id}`, data: { template: video.template } })
    syncTemplatesTags()
  }

  const onDeselectTag = async (tag, video) => {
    if (video.template.tags?.length) video.template.tags.splice(video.tags.indexOf(tag), 1)
    await request({ method: 'patch', url: `/videos/${video._id}`, data: { template: video.template } })
    syncTemplatesTags()
  }

  const copyTemplate = async (id) => {
    setIsTemplateCopying(id)
    const newId = await requestDuplicateVideo({ sourceId: id, createTemplate: true })
    if (newId) await fetchTemplates()
    setIsTemplateCopying(-1)
  }

  const deleteTemplate = async (id) => {
    await request({ method: 'delete', url: `videos/${id}` })
    await fetchTemplates()
  }

  const deleteSelectedVideos = async () => {
    await Promise.all(
      selectedVideos.map(async (video) => await request({ method: 'delete', url: `videos/${video._id}` })),
    )
    await fetchTemplates()
    setSelectedVideos([])
  }

  const showDeletingConfirm = () => {
    confirm({
      title: 'Are you sure you want to delete the selected templates permanently? This action is irreversible',
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          {selectedVideos.map((video) => (
            <p
              key={video._id}
              style={{ marginBottom: '3px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
            >
              {video.name}
            </p>
          ))}
        </div>
      ),
      onOk() {
        deleteSelectedVideos()
      },
      okText: 'Ok',
      cancelText: 'Cancel',
    })
  }

  const createVideo = async (template) => {
    setIsVideoCreating(template._id)
    const videoId = await requestDuplicateVideo({ sourceId: template._id })
    setIsVideoCreating(false)
    if (!videoId) return
    navigate(`/video/${videoId}`)
  }

  useEffect(() => {
    setHeader({
      title: 'My templates',
      icon: <Icon name="multiple_image" />,
      link: '/templates',
      extra: (
        <Button icon={<Icon name="add_template" />} onClick={() => setIsNewTemplateModalOpen(true)}>
          Create template
        </Button>
      ),
    })

    if (localStorage.getItem('isNewTemplateModalOpen')) setIsNewTemplateModalOpen(true)

    // fetch templates when user load this page
    fetchTemplates()
  }, [])

  const columns = [
    {
      dataIndex: 'thumbnail',
      render: (url, record) => (
        <img
          src={url}
          width={record.data?.format === '9_16' ? 30 : 80}
          style={{ display: 'block', margin: '0 auto' }}
        ></img>
      ),
      responsive: ['xl'],
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name, record) => (
        <Link to={`/video/${record._id}`} style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
          {name}
          {record.template.enabled && (
            <>
              {' '}
              <Tag color="green">Published</Tag>
            </>
          )}
        </Link>
      ),
    },
    {
      title: 'Tags',
      dataIndex: ['template', 'tags'],
      responsive: ['lg'],
      filters: templateTags
        .map((t) => ({
          text: t,
          value: t,
        }))
        .filter((t) => t.value),
      render: (tags, video) => {
        return (
          <Select
            defaultValue={tags}
            mode="tags"
            placeholder="Add tags"
            bordered={false}
            size="small"
            style={{ width: '100%' }}
            onSelect={(tag) => onSelectTag(tag, video)}
            onDeselect={(tag) => onDeselectTag(tag, video)}
          >
            {templateTags.map((t) => {
              if (t) return <Select.Option key={t}>{t}</Select.Option>
              else return null
            })}
          </Select>
        )
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render: (d) => new Date(d).toLocaleString(),
      responsive: ['md'],
      sorter: (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      render: (d) => new Date(d).toLocaleString(),
      responsive: ['md'],
      sorter: (a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
    },
    {
      title:
        selectedVideos.length > 0 ? (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <Icon name="delete" onClick={showDeletingConfirm} />
            <span style={{ marginLeft: '6px' }}>{selectedVideos.length}</span>
          </span>
        ) : null,
      key: 'action',
      colSpan: 1,
      render: (text, record) => (
        <Space align="center">
          {isVideoCreating === record._id ? (
            <LoadingOutlined />
          ) : (
            <Tooltip title="Create video based on a template">
              <Icon name="add_document" onClick={() => createVideo(record)} />
            </Tooltip>
          )}
          <Tooltip title="Duplicate/copy template">
            {isTemplateCopying === record._id ? (
              <LoadingOutlined />
            ) : (
              <Icon name="copy" onClick={() => copyTemplate(record._id)} />
            )}
          </Tooltip>
          <Popconfirm
            title={'Are you sure you want to delete this template permanently? This action is irreversible'}
            onConfirm={() => deleteTemplate(record._id)}
            okText="Ok"
            cancelText="Cancel"
            placement="topRight"
          >
            <Tooltip title="Delete template">
              <Icon name="delete" />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
    Table.SELECTION_COLUMN,
  ]

  const onCreateTemplateClick = () => setIsNewTemplateModalOpen(true)

  return (
    <Layout.Content className="videos-content layout-container">
      {videos?.length === 0 ? (
        <Empty description="Create your first template by clicking a button below.">
          <Button onClick={onCreateTemplateClick}>Create template</Button>
        </Empty>
      ) : (
        <Table
          columns={columns}
          dataSource={videos}
          rowKey="_id"
          className="videos-table"
          loading={loadingTemplates}
          rowSelection={{
            selectedRowKeys: selectedVideos.map((v) => v._id),
            onChange: (ids, videos) => setSelectedVideos(videos),
          }}
          pagination={{
            pageSize: 50,
            showSizeChanger: false,
            position: ['bottomCenter'],
            hideOnSinglePage: true,
          }}
          locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> }}
        />
      )}
      <CreateTemplateModal
        templates={templates}
        isNewTemplateModalOpen={isNewTemplateModalOpen}
        setIsNewTemplateModalOpen={setIsNewTemplateModalOpen}
        onTemplateCreated={(id) => {
          track('template_created')
          navigate(`/video/${id}`)
        }}
      />
    </Layout.Content>
  )
}

export default Templates
