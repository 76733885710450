import { useEffect, useMemo, useState } from 'react'
import { Layout, Table, Spin, Input, Space, Button, Tag, Modal, Form, Select, Alert, Popconfirm } from 'antd'
import { SearchOutlined, EditOutlined, CopyOutlined, PlusOutlined } from '@ant-design/icons'
import { useStore } from '../../store'
import { request } from '../../utils/api'
import { voiceStatuses } from './constants'
import Icon from '../../components/Icon'
import PageHeader from '../../components/PageHeader/pageHeader'
import { useElaiNotification } from '../../hooks/useElaiNotification'

const Voices = () => {
  const notification = useElaiNotification()
  const [voices, setVoices] = useState()
  const [modalEditVoice, setModalEditVoice] = useState()
  const [elevenLabVoices, setElevenLabVoices] = useState()
  const [loadingVoice, setLoadingVoice] = useState(false)
  const authStore = useStore((stores) => stores.authStore)

  const [voiceForm] = Form.useForm()
  const elevenId = Form.useWatch('elevenId', voiceForm)
  const elevenApiKey = Form.useWatch('apiKey', voiceForm)

  const fetchVoices = async () => {
    const voicesData = await request({ method: 'get', url: 'admin/voices' })
    setVoices(voicesData)
  }

  const fetchCustomVoices = async () => {
    const elevenlabs = await request({ method: 'get', url: 'admin/voices/elevenlab-voices' })
    setElevenLabVoices(elevenlabs)
  }

  useEffect(() => {
    fetchVoices()
    fetchCustomVoices()
  }, [])

  useEffect(() => {
    const string = voiceForm.getFieldValue('voiceString')
    if (!string && !elevenId) return

    let [voiceId, modelId, st = 80, cl = 50] = (string || '').split(':')
    const res = [elevenId || voiceId]

    if (modelId || elevenApiKey) {
      res.push(modelId || 'eleven_multilingual_v2', st, cl)
    }
    if (elevenApiKey) res.push(modalEditVoice.accountId)

    console.log({ elevenId, string, res })

    voiceForm.setFieldValue('voiceString', res.join(':'))
  }, [elevenId, elevenApiKey])

  const onFinishVoiceForm = async ({ accountId, name, status, voiceString, apiKey }) => {
    setLoadingVoice(true)
    const data = {
      voiceName: name,
      customVoiceId: voiceString, //`${id}:${model || ''}:${stability || ''}:${clarity || ''}`,
      voiceStatus: status,
      provider: 'elevenlabs',
    }

    if (!apiKey && modalEditVoice.elevenlabsApiKey) data.elevenlabsApiKey = null
    else if (apiKey) data.elevenlabsApiKey = apiKey

    let res
    if (!modalEditVoice.accountId) {
      res = await request({
        method: 'post',
        url: 'admin/voices',
        data: { ...data, accountId },
      })
    } else {
      res = await request({
        method: 'patch',
        url: `admin/voices/${modalEditVoice.accountId}/${modalEditVoice._id}`,
        data,
      })
    }

    if (res !== false) {
      fetchVoices()
      voiceForm.resetFields()
      notification.success({ message: 'Voice data saved' })
      setModalEditVoice(false)
    }
    setLoadingVoice(false)
  }

  const onClickEditVoice = (voice) => {
    const id = voice.elevenlabs.id
    voiceForm.setFieldsValue({
      name: voice.name,
      status: voice.status,
      elevenId: id?.split(':')?.[0],
      voiceString: voice.elevenlabs?.id,
      apiKey: voice.elevenlabsApiKey,
    })
    setModalEditVoice(voice)
  }

  const onDeleteVoice = async (voice) => {
    const res = await request({
      method: 'delete',
      url: `admin/voices/${voice.accountId}/${voice._id}`,
    })

    if (res !== false) {
      fetchVoices()
      notification.success({ message: 'Voice was removed' })
    }
  }

  const onClickCreateVoice = () => {
    voiceForm.setFieldsValue({
      status: 1,
    })
    setModalEditVoice(true)
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={confirm}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters()
              confirm()
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex] && record[dataIndex].toLowerCase().includes(value.toLowerCase()),
  })

  const columns = useMemo(
    () => [
      {
        title: 'Account ID',
        dataIndex: 'accountId',
        ...getColumnSearchProps('accountId'),
        render: (accountId) => {
          return (
            <a
              onClick={async () => {
                await authStore.adminLoginAs({ accountId })
                window.open('/profile', '_blank').focus()
              }}
            >
              {accountId}
            </a>
          )
        },
      },
      {
        title: 'Account name',
        dataIndex: 'accountName',
        ...getColumnSearchProps('accountName'),
      },
      {
        title: 'Name',
        dataIndex: 'name',
      },
      {
        title: 'Elevenlabs ID',
        render: (_, record) => record.elevenlabs?.id || record.descript?.id,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        filters: voiceStatuses.map(({ text }, index) => ({ text, value: index })),
        filterMultiple: false,
        onFilter: (status, record) => record.status === status,
        render: (status) => <Tag color={voiceStatuses[status].color}>{voiceStatuses[status].text}</Tag>,
      },

      {
        title: 'Subscription ID',
        dataIndex: 'stripe',
        align: 'center',
        render: (stripe) =>
          stripe.subscriptionId ? <Icon name="check" style={{ color: '#4bb543', fontSize: 22 }} /> : null,
      },
      {
        title: 'Custom API Key',
        dataIndex: 'elevenlabsApiKey',
        render: (k) => (k ? <Icon name="check" style={{ color: '#4bb543', fontSize: 22 }} /> : null),
      },
      {
        title: 'Created at',
        dataIndex: 'createdAt',
        render: (d) => (d ? new Date(d).toLocaleString() : null),
      },
      {
        title: 'Actions',
        key: 'action',
        align: 'center',
        render: (_, record) => (
          <Space>
            <Button type="ghost" icon={<EditOutlined />} onClick={() => onClickEditVoice(record)} />
            <Popconfirm
              title="Are you sure to delete the voice? This is not reversable!"
              onConfirm={() => onDeleteVoice(record)}
              okText="Delete"
            >
              <Icon name="delete" />
            </Popconfirm>
          </Space>
        ),
      },
    ],
    [onClickEditVoice, getColumnSearchProps, authStore.adminLoginAs],
  )

  const pl = '{voiceId}:{modelId}:{stability}:{clarity}:{apiKeyAccountId}'

  return (
    <Layout.Content className="admin-content">
      <div className="content">
        <PageHeader
          title="Voices"
          style={{ padding: 0 }}
          extra={[
            <Button icon={<PlusOutlined />} type="primary" key="create-video-btn" onClick={onClickCreateVoice}>
              New Voice
            </Button>,
          ]}
        />
        {voices ? (
          <Table sticky columns={columns} dataSource={voices} rowKey="_id" pagination={false} />
        ) : (
          <Spin size="large" />
        )}
      </div>
      <Modal
        title={modalEditVoice?.accountId ? `${modalEditVoice?.name} - Edit` : 'Create voice'}
        open={!!modalEditVoice}
        width={500}
        footer={null}
        centered
        onCancel={() => {
          setModalEditVoice(null)
          voiceForm.resetFields()
        }}
      >
        {modalEditVoice && (
          <>
            <Form form={voiceForm} layout="vertical" onFinish={onFinishVoiceForm}>
              {modalEditVoice.accountId ? (
                <>
                  <h4>
                    <b>name:</b> <span>{modalEditVoice?.name}</span>{' '}
                    <Tag color="blue">
                      <b>status:</b> {modalEditVoice?.status}
                    </Tag>
                  </h4>
                  <div>
                    <h4 style={{ marginBottom: 10 }}>
                      Footage Link{' '}
                      <Button
                        size="small"
                        type="primary"
                        icon={<CopyOutlined />}
                        style={{ marginLeft: 10 }}
                        onClick={() => {
                          navigator.clipboard.writeText(modalEditVoice.data.footageLink)
                        }}
                      ></Button>
                    </h4>
                    <a
                      href={modalEditVoice.data.footageLink}
                      target="_blank"
                      style={{ display: 'block', marginBottom: 16, width: '80%', whiteSpace: 'break-spaces' }}
                      rel="noreferrer"
                    >
                      {modalEditVoice.data.footageLink}
                    </a>
                  </div>
                </>
              ) : (
                <Form.Item
                  name="accountId"
                  label="Account ID"
                  validateTrigger="onBlur"
                  rules={[
                    {
                      required: true,
                      message: 'Account ID is required',
                    },
                  ]}
                >
                  <Input placeholder="Account ID" />
                </Form.Item>
              )}
              <Form.Item
                name="name"
                label="Voice Name"
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    message: 'Name is required',
                  },
                ]}
              >
                <Input placeholder="Voice name" />
              </Form.Item>
              <Form.Item
                name="status"
                label="Status"
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    message: 'Status is required',
                  },
                ]}
              >
                <Select
                  placeholder="Select status"
                  value={modalEditVoice.status}
                  options={voiceStatuses.map(({ text }, index) => ({ label: text, value: index }))}
                />
              </Form.Item>
              <Form.Item name="elevenId" label="Voice ID">
                {elevenLabVoices?.length ? (
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    placeholder="Select custom ID"
                    value={modalEditVoice.voiceId}
                    options={elevenLabVoices?.map((voice) => ({
                      label: voice.name,
                      value: voice.voice_id,
                    }))}
                  />
                ) : (
                  <Alert type="error" message="No voices loaded" />
                )}
              </Form.Item>
              <Form.Item name="voiceString" label="Voice Custom String" rules={[{ required: true }]}>
                <Input placeholder={pl} />
              </Form.Item>
              <p>
                Voice value is a string that contains elements divided by ':'' - {pl}
                <br />
                <b>VoiceId</b> - choose from select or get from 11Labs UI
                <br />
                <b>ModelId</b> - eleven_monolingual_v1, eleven_multilingual_v2, eleven_turbo_v2_5, more to come: see in
                11labs UI
                <br />
                <b>Stability</b> - Number from 0 to 100 (default 80)
                <br />
                <b>Clarity</b> - Number from 0 to 100 (default 50)
                <br />
                <b>ApiKeyAccountId</b> - Should be set automatically if custom API key is used
              </p>
              <Form.Item
                name="apiKey"
                label="Custom API Key (ElevenLabs custom API key applied to whole account. Use with confidence!)"
              >
                <Input />
              </Form.Item>
              <Form.Item>
                <Button loading={loadingVoice} type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </>
        )}
      </Modal>
    </Layout.Content>
  )
}

export default Voices
