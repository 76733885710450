import flagIcon from './icons/mark.svg'
import speakerIcon from './icons/phoneme.svg'
import pauseIcon from './icons/pause.svg'
import { Tooltip } from 'antd'

export const MARKER_ICON = flagIcon
export const PHONEME_ICON = speakerIcon
export const PAUSE_ICON = pauseIcon

export const formatSpeech = (speech) => {
  return speech
    .replace(/(\s){2,}</g, '$1<')
    .replace(/>(\s){2,}/g, '>$1')
    .replace(/(\s){2,}{/g, '$1{')
    .replace(/}(\s){2,}/g, '}$1')
}

export const PAUSE_EMOJI = {
  small: '🕛',
  standard: '🕐',
  medium: '🕑',
  large: '🕝',
}

export const MARK_EMOJI = [
  '🟧',
  '🟩',
  '🟦',
  '🟪',
  '⬛️',
  '🟫',
  '🔲',
  '🟨',
  '🔴',
  '🟢',
  '⚫️',
  '🔵',
  '🟣',
  '🟤',
  '🟡',
  '🔘',
  '🔶',
  '🔷',
  '🔺',
  '🔻',
]
export const MARK_REGEX = /<mark name=['"](\d+)['"] \/>/g
export const MULTIPLE_PHONEME_REGEX = /<phoneme ph=['"]([^']+)?['"]>([^<]+)<\/phoneme>/g
export const SINGLE_PHONEME_REGEX = /^<phoneme ph=['"]([^']+)?['"]>([^<]+)<\/phoneme>$/
export const SPECIAL_CHARS_PHONEME_REGEX =
  // eslint-disable-next-line no-useless-escape
  /(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~`\/\\|=~-])|(?=.*[0-9])|(?=.*\s)|(?=.*[A-Z])/g

export const pause_menu = [
  {
    label: <p className="pause_menu">{PAUSE_EMOJI.small} 0.25s</p>,
    key: '1',
  },
  {
    label: <p className="pause_menu">{PAUSE_EMOJI.standard} 0.5s</p>,
    key: '2',
  },
  {
    key: '3',
    label: <p className="pause_menu">{PAUSE_EMOJI.medium} 1s</p>,
  },
  {
    key: '4',
    label: <p className="pause_menu">{PAUSE_EMOJI.large} 1.5s</p>,
  },
]

export const phoneme_menu = [
  {
    key: 'apply',
    label: (
      <Tooltip
        placement="rightBottom"
        title={
          <>
            Change pronunciation only for this particular word in this speech. Add pronunciation into <b>ph</b> property
            of {'<phoneme>'} tag between 2 brackets.
          </>
        }
      >
        <div className="item-content">
          <span>🎧</span>
          <span>Apply phoneme</span>
        </div>
      </Tooltip>
    ),
  },
  {
    key: 'dictionary',
    label: (
      <Tooltip placement="rightBottom" title="Change the word pronunciation in all the videos within your account.">
        <div className="item-content">
          <span>📋</span>
          <span>Add to dictionary</span>
        </div>
      </Tooltip>
    ),
  },
]

export const HISTORY_SIZE = 200
