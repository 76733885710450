import { useState } from 'react'

export const useBrandkitState = (props) => {
  const { account, setIsBrandkitSelected } = props
  const [isModalOpen, setIsModalOpen] = useState(false)

  const isBrandkitExists = Object.keys(account.logo).length > 0 || account.corporateColors.length > 0
  const isBrandkitAvaliable = account.plan !== 'basic'

  const onClick = () => {
    if (!isBrandkitAvaliable) return
    if (!isBrandkitExists) {
      setIsModalOpen(true)
      return
    }
    setIsBrandkitSelected((prevState) => !prevState)
  }

  const handleClose = () => {
    setIsModalOpen(false)
  }

  return {
    onClick,
    handleClose,
    isModalOpen,
    isBrandkitExists,
    isBrandkitAvaliable,
  }
}
