import { useEffect } from 'react'

const useClickOutside = (refs, callback) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!refs.some((ref) => ref.current?.contains(event.target))) {
        callback()
      }
    }

    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [...refs, callback])
}

export default useClickOutside
