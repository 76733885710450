import { textTypes } from '../../../utils/canvas/canvas'
import fabric from '../../../utils/fabric/fabric'
import { uniqueId } from '../../../utils/helpers'

const getLongColor = (color) => {
  color = color.replace('#', '')
  if (color.length === 3) color = [...color].reduce((acc, c) => `${acc}${c.repeat(2)}`, '')
  return color
}

const getLuma = (color) => {
  const rgb = hexToRGB(color)
  return rgb.reduce((acc, c) => acc + c, 0) / rgb.length
}

const hexToRGB = (color) => {
  color = normalizeColor(color).replace('#', '')
  const rgb = []
  for (let i = 0; i <= 2; i++) rgb[i] = parseInt(color.substr(i * 2, 2), 16)
  return rgb
}

const rgbToHex = (r, g, b) => '#' + [r, g, b].map((c) => c.toString(16).padStart(2, '0')).join('')

const rgbStringToHex = (color) => {
  return rgbToHex(
    ...color
      .match(/\((.+?)\)/)[1]
      .split(',')
      .map((c) => Number(c)),
  )
}

const rgbaStringToHex = (color) => {
  return rgbToHex(
    ...color
      .match(/\((.+?)\)/)[1]
      .split(',')
      .slice(0, 3)
      .map((c) => Number(c)),
  )
}

/**
 * for colors like: black, orange, blue etc.
 */
const standardizeColor = (humanReadableColor) => {
  const ctx = document.createElement('canvas').getContext('2d')
  ctx.fillStyle = humanReadableColor
  return ctx.fillStyle
}

const normalizeColor = (color) => {
  if (!color || typeof color !== 'string') color = '#000'
  if (color.startsWith('rgba')) {
    //transparent color only for shapes
    if (color === 'rgba(0, 0, 0, 0)') return 'rgba(0, 0, 0, 0)'
    return rgbaStringToHex(color)
  }
  if (color.startsWith('rgb')) return rgbStringToHex(color)
  if (color.startsWith('#')) {
    if (color.length === 4) return `#${getLongColor(color.toLowerCase())}`
    else return color.toLowerCase()
  }
  return standardizeColor(color)
}

const flattenNestedObjects = (objects) =>
  objects.flatMap((obj) => (obj.type === 'group' ? flattenNestedObjects(obj.objects) : obj))

const grabVideoColors = (video, normalize = false) => {
  const colors = new Set()
  video.slides.forEach((slide) => {
    colors.add(normalize ? normalizeColor(slide.canvas.background) : slide.canvas.background)
    flattenNestedObjects(slide.canvas.objects).forEach((obj) => {
      if (obj.type === 'avatar' && obj.avatarType !== 'circle') return
      if (obj.fill?.type === 'linear')
        obj.fill.colorStops.forEach((cs) => colors.add(normalize ? normalizeColor(cs.color) : cs.color))
      else if (textTypes.includes(obj.type) && obj.styles && Object.keys(obj.styles).length) {
        if (Array.isArray(obj.styles) && obj.styles[0].style) {
          for (const { style } of obj.styles) {
            if (style.fill) colors.add(normalize ? normalizeColor(style.fill) : style.fill)
          }
        } else {
          for (const row in obj.styles) {
            for (const col in obj.styles[row]) {
              const fill = obj.styles[row][col].fill
              colors.add(normalize ? normalizeColor(fill) : fill)
            }
          }
        }
      }
      if (obj.fill && typeof obj.fill === 'string') colors.add(normalize ? normalizeColor(obj.fill) : obj.fill)
    })
  })
  if (colors.has('rgba(0, 0, 0, 0)')) colors.delete('rgba(0, 0, 0, 0)')
  return [...colors].filter(Boolean)
}

const checkAndSetSlideStatus = (slide) => (slide.status = slide.status === 'ready' ? 'avatarReady' : 'edited')

const applyVideoColors = (video, colorProducer, match = () => true, keepSlideStatus = false) => {
  const extraLayouts = (video.extraLayouts || []).map((layout) => ({ canvas: layout }))
  video.slides.concat(extraLayouts).forEach((slide) => {
    let colorChanged = false

    if (match(slide.canvas.background)) {
      slide.canvas.background = colorProducer(slide.canvas.background)
      colorChanged = true
    }

    flattenNestedObjects(slide.canvas.objects).forEach((obj) => {
      if (obj.fill?.type === 'linear') {
        obj.fill.colorStops.forEach((cs) => {
          if (match(normalizeColor(cs.color), obj)) {
            cs.color = colorProducer(cs.color)
            colorChanged = true
          }
        })
      } else if (textTypes.includes(obj.type) && obj.styles && Object.keys(obj.styles).length) {
        if (Array.isArray(obj.styles) && obj.styles[0].style) {
          for (const { style } of obj.styles) {
            if (match(style.fill, obj)) {
              style.fill = colorProducer(style.fill)
              colorChanged = true
            }
          }
        } else {
          for (const row in obj.styles) {
            for (const col in obj.styles[row]) {
              const fill = obj.styles[row][col].fill
              if (match(fill, obj)) {
                obj.styles[row][col].fill = colorProducer(fill)
                colorChanged = true
              }
            }
          }
        }
      }
      if (obj.fill && typeof obj.fill === 'string' && match(obj.fill)) {
        obj.fill = colorProducer(obj.fill)
        colorChanged = true
      }
    })

    if (colorChanged && !keepSlideStatus) checkAndSetSlideStatus(slide)
  })
}

const countColorUsage = (video, color) => {
  if (!color) return 0
  let counter = 0
  video.slides.forEach((slide) => {
    if (slide.canvas.background === color) counter++
    flattenNestedObjects(slide.canvas.objects).forEach((obj) => {
      if (obj.fill?.type === 'linear') {
        obj.fill.colorStops.forEach((cs) => {
          if (normalizeColor(cs.color) === color) counter++
        })
      } else if (textTypes.includes(obj.type) && obj.styles && Object.keys(obj.styles).length) {
        if (Array.isArray(obj.styles) && obj.styles[0].style) {
          for (const { style } of obj.styles) {
            if (style.fill === color) counter++
          }
        } else {
          for (const row in obj.styles) {
            for (const col in obj.styles[row]) if (obj.styles[row][col].fill === color) counter++
          }
        }
      }
      if (obj.fill && typeof obj.fill === 'string' && obj.fill === color) counter++
    })
  })
  return counter
}

const applyLogo = async (video, logo, activeSlideId, setActiveObjectModifier) => {
  if (!logo?.url) return
  const cleanLogoUrl = logo.url.split('?')[0]

  const isSvg = logo.url.includes('.svg')
  const image = await fabric[isSvg ? 'svgFromURL' : 'imageFromURL'](logo.url)
  image.scale(Math.min(100 / image.width, 50 / image.height))
  image.set({ top: 16, left: 16, type: 'logo' })

  const imageObj = image.toObject()
  if (isSvg) {
    imageObj.type = 'group'
    imageObj.meta = { type: 'logo' }
  }

  const extraLayouts = (video.extraLayouts || []).map((layout) => ({ canvas: layout }))
  for (const [index, slide] of video.slides.concat(extraLayouts).entries()) {
    const logoObj = slide.canvas.objects.find((obj) => obj.type === 'logo' || obj.meta?.type === 'logo')
    if (logoObj && (isSvg || logoObj.objects || cleanLogoUrl !== logoObj.src?.split('?')[0])) {
      logoObj.src = logo.url
      if (isSvg) {
        logoObj.objects = imageObj.objects
        logoObj.type = 'group'
        logoObj.meta = { type: 'logo' }
      } else {
        logoObj.type = 'logo'
        delete logoObj.objects
        delete logoObj.meta
      }

      const scaleFactor = Math.min(logoObj.width / image.width, logoObj.height / image.height)
      Object.assign(logoObj, {
        height: image.height,
        width: image.width,
        scaleX: logoObj.scaleX * scaleFactor,
        scaleY: logoObj.scaleY * scaleFactor,
      })
    } else if (!logoObj && index === 0) {
      if (activeSlideId === slide.id && setActiveObjectModifier) {
        setActiveObjectModifier({ newObject: 'logo', url: logo.url })
      } else {
        imageObj.id = uniqueId()
        slide.canvas.objects.push(imageObj)
      }
    }
  }
}

const checkLumasEqual = (a, b) => {
  if (a.length !== b.length) return false
  return a.every((la, idx) => la.color === b[idx].color)
}

const compareLumas = (corporateLumas, videoLumas) => {
  const matchedCorporateLumas = corporateLumas.filter(
    (cl) => videoLumas.findIndex((vl) => cl.color === vl.color) !== -1,
  )
  return checkLumasEqual(matchedCorporateLumas, videoLumas)
}

const sortByLumas = (colors) => {
  const lumas = colors.map((c) => ({ color: c, luma: getLuma(c) }))
  lumas.sort((a, b) => b.luma - a.luma)
  return lumas
}

const mapLumaIndex = (videoLumaIndex, videoLumasCount, corporateLumasCount) => {
  if (videoLumasCount === 1) return 0
  return Math.round((videoLumaIndex / (videoLumasCount - 1)) * (corporateLumasCount - 1))
}

const applyCorporateColors = (
  video,
  corporateColors,
  videoColors = grabVideoColors(video, true),
  keepSlideStatus = false,
) => {
  if (!corporateColors?.length) return false

  if (videoColors.length > corporateColors.length) {
    const videoColorsUsage = videoColors.map((c) => ({ color: c, usage: countColorUsage(video, c) }))
    videoColorsUsage.sort((a, b) => b.usage - a.usage)
    videoColors = videoColorsUsage.slice(0, corporateColors.length).map((c) => c.color)
  }

  const corporateLumas = sortByLumas(corporateColors)
  const videoLumas = sortByLumas(videoColors)

  if (compareLumas(corporateLumas, videoLumas)) return false

  const colorMap = videoLumas.reduce((colorMap, vl, idx) => {
    colorMap[vl.color] = corporateLumas[mapLumaIndex(idx, videoLumas.length, corporateLumas.length)].color
    return colorMap
  }, {})
  applyVideoColors(
    video,
    (color) => colorMap[color] || color,
    (color) => colorMap[color],
    keepSlideStatus,
  )

  return true
}

export {
  hexToRGB,
  getLuma,
  normalizeColor,
  grabVideoColors,
  applyVideoColors,
  countColorUsage,
  applyLogo,
  compareLumas,
  sortByLumas,
  applyCorporateColors,
}
