import React, { useState } from 'react'
import { Button, Modal, Spin } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

const plusOutlinedIcon = <PlusOutlined />

const STYLE = {
  openPreview: { width: '100%', maxWidth: '700px', objectFit: 'contain', maxHeight: '75vh' },
  spin: { width: '100%', height: '50px', padding: '15px' },
  nonStretchableIcon: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    objectFit: 'contain',
    maxHeight: '75vh',
  },
  nonStretchableIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '20px',
    boxSizing: 'border-box',
  },
}

const MediaPreviewModal = (props) => {
  const { onChooseFile, previewFile, setPreviewFile } = props
  const [isOpenPreviewLoader, setIsOpenPreviewLoader] = useState(false)

  const previewSrc = previewFile?.type === 'video' ? previewFile?.url : previewFile?.thumbnail || previewFile?.url

  const isNonStretchableIcon = previewFile?.type === 'icon' && !previewSrc?.endsWith('.svg')

  const onLoadedMetadata = () => setIsOpenPreviewLoader(false)

  const handleCancel = () => {
    setPreviewFile(null)
  }

  const onAddClick = () => {
    onChooseFile(previewFile)
    setPreviewFile(null)
  }

  return (
    <Modal
      open={!!previewFile}
      closable={false}
      width={700}
      className="media-preview-modal"
      destroyOnClose
      onCancel={handleCancel}
      footer={[
        <Button type="primary" icon={plusOutlinedIcon} key="add" onClick={onAddClick}>
          Add
        </Button>,
      ]}
    >
      {isOpenPreviewLoader || !previewFile ? (
        <Spin style={STYLE.spin} />
      ) : previewFile.type === 'video' ? (
        <video
          style={STYLE.openPreview}
          src={previewSrc}
          controlsList="nodownload"
          crossOrigin="anonymous"
          onLoadedMetadata={onLoadedMetadata}
          muted
          controls
        />
      ) : isNonStretchableIcon ? (
        <div style={STYLE.nonStretchableIconWrapper}>
          <img
            alt="example"
            style={STYLE.nonStretchableIcon}
            src={previewSrc}
            onLoad={onLoadedMetadata}
            crossOrigin="anonymous"
          />
        </div>
      ) : (
        <img
          alt="example"
          style={STYLE.openPreview}
          src={previewSrc}
          onLoad={onLoadedMetadata}
          crossOrigin="anonymous"
        />
      )}
    </Modal>
  )
}

export default MediaPreviewModal
